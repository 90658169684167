import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { AuthService } from '../../../common/services/auth.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { timeSheetDefinition } from '../time-sheet.definition'

@Component({
  selector: 'app-time-sheet-list',
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class TimeSheetListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = timeSheetDefinition

  filters: Filter[] = [
    {
      label: 'Recherche de timesheets',
      placeholder: 'N° de mission, collaborateur, responsable mission...',

      properties: {
        projectIds: 'projectIds',
        userIds: 'userIds',
        referentIds: 'referentIds'
      },
      searchResources: ['projects', 'users', 'referents'],
      inputType: InputType.MultiSearch,
      className: 'is-12-touch is-6-tablet p-x-0-mobile'
    },
    {
      label: `Periode`,
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      inputType: InputType.DateRange,
      className: 'is-6 p-x-0-mobile'
    },
    {
      label: 'Responsable client',
      properties: {
        value: 'workSupervisorId'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleName: 'partner'
        }),
      inputType: InputType.Select,
      className: 'is-12-mobile is-6-tablet is-3-fullhd p-x-0-mobile'
    },
    {
      label: 'Resp. opérationnel',
      properties: {
        value: 'projectManagerIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users'),
      inputType: InputType.MultiSelect,
      className:
        'is-12-mobile is-6-tablet is-4-widescreen is-3-fullhd p-x-0-mobile'
    },
    {
      label: 'Practice',
      placeholder: 'Tous',
      properties: {
        value: 'departmentId'
      },

      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('departments'),
      className:
        'is-12-mobile is-6-tablet is-6-widescreen is-3-fullhd p-x-0-mobile'
    },
    {
      label: 'Mission billables',
      placeholder: 'Toutes',
      properties: {
        value: 'projectBillability'
      },
      inputType: InputType.Select,
      selectOptions: [
        {
          label: 'Missions billables uniquement',
          value: 'billableProjectsOnly'
        },
        {
          label: 'Missions non-billables uniquement',
          value: 'notBillableProjectsOnly'
        }
      ],
      className:
        'is-12-mobile is-6-tablet is-6-widescreen is-3-fullhd p-x-0-mobile'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService,
    private authService: AuthService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.authService
      .can('browseTimeSheets')
      .then((canBrowseTimeSheets: boolean) => {
        if (!canBrowseTimeSheets) {
          this.definition.yields = this.definition.yields.filter(
            (y) => y.property !== 'value'
          )
          this.definition.keyNumbers = []
          this.definition.path = 'mes-time-sheets'
        }
        this.initListView()
      })
  }
}
