import { Component, Input } from '@angular/core'

import { MessageType } from '../../../enums/message-type.enum'

@Component({
  selector: 'abc-percentage-yield',
  template: `<span
    [ngClass]="{
      'has-text-danger': messageType === MessageType.Danger,
      'has-text-warning': messageType === MessageType.Warning,
      'has-text-success': messageType === MessageType.Success,
      'has-text-info': messageType === MessageType.Info
    }"
    >{{ value | percent: '1.0-2' }}
  </span>`
})
export class PercentageYieldComponent {
  @Input() value: number
  @Input() messageType: MessageType

  MessageType = MessageType
}
