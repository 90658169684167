import { Component, Input, OnInit } from '@angular/core'

import { ProjectStatus } from '../../../../resources/project/project-status.enum'

@Component({
  selector: 'abc-progress-bar-yield',
  templateUrl: './progress-bar-yield.component.html',
  styleUrls: ['./progress-bar-yield.component.scss']
})
export class ProgressBarYieldComponent implements OnInit {
  @Input() status: any
  @Input() isDisabled: boolean
  statusNumber: number
  isAbandoned: boolean

  ngOnInit() {
    this.statusNumber = Object.values(ProjectStatus).indexOf(this.status)
  }
}
