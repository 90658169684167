import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import moment from 'moment'
import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { User } from '../user.interface'
import { bonusDefinition } from '../user-bonuses/bonus.definition'

@Component({
  selector: 'app-user-bonuses-list',
  templateUrl: './user-bonuses-list.component.html',
  styleUrls: ['./user-bonuses-list.component.scss']
})
export class UserBonusesListComponent
  extends AbcListComponent
  implements OnInit
{
  users: User[]
  definition: ResourceDefinition = Object.assign(bonusDefinition, {
    slug: 'users/bonuses'
  })
  browseItemsPermissionName = 'browseProjects'

  filters: Filter[] = [
    {
      label: `Date`,
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      inputType: InputType.DateRange,
      className: 'is-12-mobile is-12-tablet is-6-widescreen p-x-0-mobile ',
      initialValue: {
        dateFrom: moment()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        dateTo: moment()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD')
      }
    },
    {
      label: 'Collaborateurs',
      properties: {
        value: 'userIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users'),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet is-6-widescreen p-x-0-mobile'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
