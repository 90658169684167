<a
  class="add-event--alt button is-primary is-hidden-touch"
  (click)="showCreateHolidayModal = true"
  *abcHasPermission="'addHolidays'"
  ><i class="icon icon-calendar m-r-xs"></i> Ajouter des congés</a
>
<!-- TimeSheet table -->
<div class="flex flex-mobile align-center justify-center p-y-gutter">
  <div
    class="button is-light is-rounded m-x-gutter"
    mwlCalendarPreviousView
    [view]="'month'"
    [(viewDate)]="viewDate"
    (viewDateChange)="goToMonth($event)"
  >
    <i class="icon icon-chevron-left is-size-3"></i>
  </div>
  <h3 class="title is-3 is-light m-b-0 has-text-centered">
    {{ viewDate | calendarDate : 'monthViewTitle' }}
  </h3>
  <div
    class="button is-light is-rounded m-x-gutter"
    mwlCalendarNextView
    [view]="'month'"
    [(viewDate)]="viewDate"
    (viewDateChange)="goToMonth($event)"
  >
    <i class="icon icon-chevron-right is-size-3"></i>
  </div>
</div>

<!-- Calendar -->
<mwl-calendar-month-view
  [ngClass]="{
    'cannot-add-ts': !canAddTimeSheets
  }"
  [events]="events"
  [viewDate]="viewDate"
  [weekStartsOn]="1"
  (dayClicked)="openCreateModal($event.day)"
  [cellTemplate]="dayTemplate"
  [tooltipTemplate]="emptyTooltipTemplate"
  *ngIf="events"
></mwl-calendar-month-view>

<!-- We keep an empty tooltip template to get project.name (from ng-reflect attr) without displaying tooltip on hover -->
<ng-template #emptyTooltipTemplate>
  <div class="do-not-delete"></div>
</ng-template>

<!-- Custom day template-->
<ng-template #dayTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-number">
      {{ day.date | calendarDate : 'monthViewDayNumber' : locale }}
    </span>
  </div>

  <div class="cal-events">
    <div
      class="cal-event {{ event.cssClass }} is-1-of-{{
        day.events.length
      }} tooltip has-tooltip-multiline"
      [attr.data-tooltip]="event.meta.reference ? event.meta.reference : null"
      *ngFor="let event of day.events"
      (click)="openEditModal($event, event)"
    >
      <span>
        <ng-container *ngIf="event.meta.hoursOfWork">
          <span class="is-bold">{{ event.meta.hoursOfWork }}</span
          >|
        </ng-container>
        {{ event.title }}
      </span>
      <a
        class="event-close p-x-md"
        (click)="promptDeleteTimeSheet($event, event.id)"
        *ngIf="!event.meta.isDayOff"
      >
        <i class="icon icon-x is-size-6"></i>
      </a>
    </div>
  </div>
</ng-template>

<!-- Table -->
<div class="table-responsive">
  <table class="table" *ngIf="timeSheetTableLines">
    <thead class="top-margin">
      <tr>
        <th>Semaine</th>
        <th class="has-text-right">Missions</th>
        <th class="has-text-right">Jours offs</th>
        <th class="has-text-right">Congés</th>
        <th class="has-text-right">TS</th>
        <th class="has-text-right">TS billable</th>
        <th class="has-text-right">TS non billable</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let tableLine of timeSheetTableLines">
        <td>{{ tableLine.weekLabel }}</td>
        <td class="has-text-right">
          {{
            tableLine.projectIds && tableLine.projectIds.length
              | number : '1.0-2'
          }}
        </td>
        <td class="has-text-right">
          {{ (daysOffPerWeek && daysOffPerWeek[tableLine.weekNumber]) || 0 }}
        </td>
        <td class="has-text-right">
          {{ tableLine.holidaysCount | number : '1.0-2' }}
        </td>
        <td class="has-text-right">
          {{ tableLine.timeSheetCount | number : '1.0-2' }}
        </td>
        <td class="has-text-right">
          {{ tableLine.billableTimeSheetCount | number : '1.0-2' }}
        </td>
        <td class="has-text-right">
          {{ tableLine.nonBillableTimeSheetCount | number : '1.0-2' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-time-sheet-create-edit-modal
  [fieldSpecialRules]="formRules"
  [mode]="createEditMode"
  [item]="timeSheetToEdit"
  (closedModal)="showCreateEditModal = false"
  (submitSuccessful)="onSubmitSuccessful()"
  *ngIf="showCreateEditModal"
></app-time-sheet-create-edit-modal>

<app-holiday-create-edit-modal
  [fieldSpecialRules]="holidayFormRules"
  (closedModal)="showCreateHolidayModal = false"
  (submitSuccessful)="showCreateHolidayModal = false; getTimeSheets()"
  *ngIf="showCreateHolidayModal"
></app-holiday-create-edit-modal>

<abc-confirm-delete-modal
  [emitConfirmation]="true"
  [resourceToDelete]="timeSheetToDelete"
  (deleteCanceled)="timeSheetToDelete = null"
  (deleteConfirmed)="deleteTimeSheet(timeSheetToDelete)"
  *ngIf="timeSheetToDelete"
></abc-confirm-delete-modal>
