import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core'
import { gsap, Power2 } from 'gsap'
import * as moment from 'moment'

import { environment } from '../../../../../environments/environment'
import { AuthService } from '../../../../common/services/auth.service'
import { EventService } from '../../../../common/services/event.service'
import { User } from '../../../../resources/user/user.interface'

@Component({
  selector: 'app-touch-menu',
  templateUrl: './touch-menu.component.html',
  styleUrls: ['./touch-menu.component.scss']
})
export class TouchMenuComponent implements OnInit {
  @Output() onLogout: EventEmitter<void> = new EventEmitter()

  @ViewChild('menu') menuEl: ElementRef
  @ViewChild('hidingLayer') hidingLayerEl: ElementRef
  @ViewChild('menuItems') menuItemsEl: ElementRef

  currentUser: User
  showMenu: boolean
  storagePath: string = environment.storagePath

  activeAccordion: string

  showUserMenu: boolean
  showLinkMenu: boolean

  defaultMonitoringParams = {
    dateFrom: moment()
      .subtract(3, 'month')
      .startOf('month')
      .format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD')
  }

  defaultDaysOffParams = {
    year: moment().format('YYYY')
  }

  isAlt: boolean = environment.isAltVersion

  constructor(
    private authService: AuthService,
    private eventService: EventService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })

    // CLose menus on changing route
    this.eventService.routeChanged.subscribe(() => {
      if (this.showMenu) {
        this.closeMenu()
      }
    })
  }
  toggleMenu(): void {
    this.showMenu = !this.showMenu
    this.triggerMenuAnimation()
  }

  toggleAccordion(accordion: string): void {
    if (this.activeAccordion === accordion) {
      this.activeAccordion = null
    } else {
      this.activeAccordion = accordion
    }
  }

  logout(): void {
    this.onLogout.emit()
  }

  closeMenu(): void {
    this.showMenu = false
    this.triggerMenuAnimation()
  }

  triggerMenuAnimation() {
    if (this.showMenu) {
      gsap.to(this.menuEl.nativeElement, {
        className: '+=is-open',
        duration: 0.01
      })

      gsap.to(this.menuItemsEl.nativeElement, {
        opacity: 1,
        left: '0',
        ease: Power2.easeInOut,
        duration: 0.5
      })
      gsap.to(this.hidingLayerEl.nativeElement, {
        autoAlpha: 0.9,
        ease: Power2.easeInOut,
        duration: 0.5
      })
      this.renderer.addClass(document.querySelector('html'), 'is-clipped')
    } else {
      gsap.to(this.menuEl.nativeElement, {
        className: '-=is-open',
        duration: 0.5
      })

      gsap.to(this.menuItemsEl.nativeElement, {
        opacity: 0.8,
        left: '-100%',
        ease: Power2.easeInOut,
        duration: 0.5,
        delay: 0.1
      })
      gsap.to(this.hidingLayerEl.nativeElement, {
        autoAlpha: 0,
        ease: Power2.easeInOut,
        duration: 0.5
      })
      this.renderer.removeClass(document.querySelector('html'), 'is-clipped')
    }
  }
}
