import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { SelectOption } from '../interfaces/select-option.interface'

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  constructor(private http: HttpClient) {}

  list(resourceName: string, refineParams?: object): Promise<any> {
    return this.http
      .get(`${environment.apiBaseUrl}/${resourceName}`, {
        params: this.convertObjectToHttpParams(refineParams)
      })
      .pipe(map((res) => res))
      .toPromise()
  }

  listSelectOptions(
    resourceName: string,
    refineParams?: object
  ): Promise<SelectOption[]> {
    return this.list(`${resourceName}/select-options`, refineParams)
  }

  show(
    resourceName: string,
    id: number | string,
    suffix?: string,
    paramsObject?: object
  ): Promise<any> {
    return this.http
      .get(
        `${environment.apiBaseUrl}/${resourceName}/${id}` +
          (suffix ? `/${suffix}` : ''),
        { params: this.convertObjectToHttpParams(paramsObject) }
      )
      .pipe(map((res) => res))
      .toPromise()
  }

  store(resourceName: string, body: any): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}/${resourceName}`, body)
      .pipe(map((res) => res))
  }

  duplicate(resourceName: string, id: number | string): Observable<any> {
    return this.http
      .post(`${environment.apiBaseUrl}/${resourceName}/${id}/duplicate`, {})
      .pipe(map((res) => res))
  }

  update(
    resourceName: string,
    id: number | string,
    body: any
  ): Observable<any> {
    return this.http
      .put(`${environment.apiBaseUrl}/${resourceName}/${id}`, body)
      .pipe(map((res) => res))
  }

  patch(
    resourceName: string,
    id?: number | string,
    suffix?: string,
    body?: any
  ): Observable<any> {
    const url =
      id && suffix
        ? `${environment.apiBaseUrl}/${resourceName}/${id}/${suffix}`
        : `${environment.apiBaseUrl}/${resourceName}`
    return this.http.patch(url, body).pipe(map((res) => res))
  }

  delete(resourceName: string, id: number | string): Observable<any> {
    return this.http
      .delete(`${environment.apiBaseUrl}/${resourceName}/${id}`)
      .pipe(map((res) => res))
  }

  private convertObjectToHttpParams(paramsObject: object): HttpParams {
    let httpParams = new HttpParams()

    if (paramsObject) {
      Object.keys(paramsObject).forEach((key: string) => {
        if (Array.isArray(paramsObject[key])) {
          paramsObject[key].forEach((value: string) => {
            httpParams = httpParams.append(key, value)
          })
        } else {
          httpParams = httpParams.set(key, paramsObject[key])
        }
      })
    }

    return httpParams
  }
}
