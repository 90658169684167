import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms'

import { AbcInput } from '../../../../common/interfaces/abc-input.interface'
import { SelectOption } from '../../../../common/interfaces/select-option.interface'

@Component({
  selector: 'abc-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements AbcInput, OnInit, OnChanges {
  @Input() label: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() initialValue: { value: string | number }
  @Input() selectOptions: SelectOption[]
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() readonly: boolean

  @Output() valueChanged: EventEmitter<{
    value: string
  }> = new EventEmitter()

  form: UntypedFormGroup
  required: boolean

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      select: ['', this.validators || []]
    })

    if (this.initialValue && this.initialValue.value) {
      setTimeout(() => {
        this.form.get('select').setValue(this.initialValue.value)
      })
    }

    this.required = this.validators.includes(Validators.required)
  }

  // Reset form value if we change select options.
  ngOnChanges(changes: { selectOptions?: SimpleChange }) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    if (this.form && changes.selectOptions) {
      this.form.get('select').setValue('')
      this.valueChanged.emit({ value: null })
    }
  }

  onSelect(newValue: string) {
    this.valueChanged.emit({ value: newValue })
  }
}
