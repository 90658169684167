import { Component, Input, OnChanges } from '@angular/core'
import { Router } from '@angular/router'
import { CalendarEvent } from 'angular-calendar'
import * as moment from 'moment'
import { forkJoin } from 'rxjs'

import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { AuthService } from '../../../common/services/auth.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { DayOff } from '../../day-off/day-off.interface'
import { holidayDefinition } from '../../time-sheet/holiday.definition'
import { timeSheetDefinition } from '../../time-sheet/time-sheet.definition'
import { TimeSheet } from '../../time-sheet/time-sheet.interface'
import { User } from '../user.interface'

@Component({
  selector: 'app-user-time-sheets',
  templateUrl: './user-time-sheets.component.html',
  styleUrls: ['./user-time-sheets.component.scss']
})
export class UserTimeSheetsComponent implements OnChanges {
  @Input() userId: number
  // MM-YYYY formatted date.
  @Input() suggestedViewDate?: string
  @Input() timeSheetToEditId?: string
  @Input() redirectTo?: string

  timeSheets: TimeSheet[]
  events: CalendarEvent[]
  timeSheetTableLines: TimeSheetTableLine[]
  daysOffPerWeek: { [key: number]: number }

  timeSheetToDelete: TimeSheet
  timeSheetToEdit: TimeSheet
  viewDate: Date = new Date()
  calendarDate: moment.Moment = moment()
  showCreateEditModal = false
  showCreateHolidayModal = false
  createEditMode: string
  timeSheetDefinition: ResourceDefinition = timeSheetDefinition
  holidayDefinition: ResourceDefinition = holidayDefinition
  formRules: FieldSpecialRule[] = []
  holidayFormRules: FieldSpecialRule[] = []

  canAddTimeSheets: boolean
  canEditTimeSheets: boolean

  projectIds: number[]

  constructor(
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService,
    private authService: AuthService,
    private router: Router
  ) {
    moment.locale('fr')
  }

  async ngOnChanges(): Promise<void> {
    if (this.suggestedViewDate) {
      this.calendarDate = moment(this.suggestedViewDate, 'MM-YYYY')
      this.viewDate = this.calendarDate.toDate()
    }

    await this.getTimeSheets()

    if (this.timeSheetToEditId) {
      this.timeSheetToEdit = this.timeSheets.find(
        (tS) => tS.id.toString() === this.timeSheetToEditId
      )
      this.createEditMode = 'edit'
      this.showCreateEditModal = true
    }

    // Auto-select current user on create edit modal.
    this.formRules = [
      {
        fieldId: 'userId',
        forcedValue: {
          userId: this.userId
        },
        hidden: true
      }
    ]
    this.holidayFormRules = [
      {
        fieldId: 'userId',
        forcedValue: {
          userId: this.userId
        },
        hidden: true
      }
    ]

    this.authService.currentUser.subscribe(async (userRes: User) => {
      if (userRes.id === this.userId) {
        this.canAddTimeSheets = await this.authService.can('addOwnTimeSheets')
        this.canEditTimeSheets = await this.authService.can('editOwnTimeSheets')
      } else {
        this.canAddTimeSheets = await this.authService.can('addTimeSheets')
        this.canEditTimeSheets = await this.authService.can('editTimeSheets')
      }
    })
  }

  async getTimeSheets(): Promise<void> {
    const dateFrom: string = this.calendarDate
      .clone()
      .locale('fr')
      .startOf('month')
      .startOf('week')
      .format('YYYY-MM-DD')

    const dateTo: string = this.calendarDate
      .clone()
      .locale('fr')
      .endOf('month')
      .endOf('week')
      .format('YYYY-MM-DD')

    return forkJoin([
      this.resourceService.list('time-sheets', {
        userId: this.userId,
        withoutPagination: true,
        dateFrom,
        dateTo
      }),
      this.resourceService.list('days-off', {
        withoutPagination: true,
        dateFrom,
        dateTo
      })
    ])
      .toPromise()
      .then(([timeSheetRes, daysOffRes]: [TimeSheet[], DayOff[]]) => {
        this.timeSheets = timeSheetRes
        this.projectIds = Array.from(
          new Set(
            timeSheetRes.filter((ts) => ts.project).map((tS) => tS.project.id)
          )
        )
        this.events = [
          ...this.formatTimeSheetsIntoCalendarEvents(timeSheetRes),
          ...this.formatDaysOffIntoCalendarEvents(daysOffRes)
        ]

        this.timeSheetTableLines = this.getTimeSheetTableLines(timeSheetRes)
        this.daysOffPerWeek = this.getDaysOffPerWeek(daysOffRes)

        return
      })
  }

  goToMonth(event: Date) {
    this.calendarDate = moment(event)
    this.getTimeSheets()
  }

  openCreateModal(event: { date: Date }) {
    if (!this.canAddTimeSheets) {
      return
    }

    const momentClickedDate: moment.Moment = moment(event.date)

    // Impossible to create TimeSheet for the future.
    if (moment().endOf('day').isBefore(momentClickedDate)) {
      return
    }

    this.formRules = [
      {
        fieldId: 'userId',
        forcedValue: {
          userId: this.userId
        },
        hidden: true
      },
      {
        fieldId: 'date',
        forcedValue: { value: momentClickedDate.format('YYYY-MM-DD') }
      }
    ]
    this.timeSheetToEdit = null
    this.createEditMode = 'create'
    this.showCreateEditModal = true
  }

  openEditModal(clickEvent: MouseEvent, event: CalendarEvent): void {
    clickEvent.stopPropagation()

    if (!this.canEditTimeSheets) {
      return
    }

    if (event.meta.isHoliday || event.meta.isDayOff) {
      return
    }

    this.timeSheetToEdit = this.timeSheets.find((tS) => tS.id === event.id)
    this.createEditMode = 'edit'
    this.showCreateEditModal = true
  }

  onSubmitSuccessful() {
    this.showCreateEditModal = false
    if (this.redirectTo) {
      this.router.navigateByUrl(this.redirectTo)
    } else {
      this.showCreateEditModal = false
      this.getTimeSheets()
    }
  }

  formatTimeSheetsIntoCalendarEvents(timeSheets: TimeSheet[]): CalendarEvent[] {
    return timeSheets.map((timeSheet: TimeSheet) => ({
      id: timeSheet.id,
      start: new Date(timeSheet.date),
      title: timeSheet.project ? timeSheet.project.name : timeSheet.reference,
      cssClass: `is-color-${
        timeSheet.project
          ? this.projectIds.indexOf(timeSheet.project.id)
          : 'holiday'
      }`,
      meta: {
        hoursOfWork: timeSheet.hoursOfWork,
        projectId: timeSheet.project && timeSheet.project.id,
        reference: timeSheet.reference,
        isHoliday: !timeSheet.project
      }
    }))
  }

  formatDaysOffIntoCalendarEvents(daysOff: DayOff[]): CalendarEvent[] {
    return daysOff.map((dayOff: DayOff) => ({
      start: new Date(dayOff.date),
      title: dayOff.name,
      cssClass: 'is-color-day-off',
      meta: {
        isDayOff: true,
        reference: `Jour off : ${dayOff.name}`
      }
    }))
  }

  getTimeSheetTableLines(timeSheets: TimeSheet[]): TimeSheetTableLine[] {
    return timeSheets
      .reduce((acc: TimeSheetTableLine[], timeSheet: TimeSheet) => {
        const weekNumber: number = moment(timeSheet.date).isoWeek()
        const weekNumberTableLine: TimeSheetTableLine = acc.find(
          (item: TimeSheetTableLine) => item.weekNumber === weekNumber
        )

        if (weekNumberTableLine) {
          // Holiday
          if (!timeSheet.project && !timeSheet.service) {
            weekNumberTableLine.holidaysCount += timeSheet.daysOfWork
          } else {
            if (
              !weekNumberTableLine.projectIds.includes(timeSheet.project.id)
            ) {
              weekNumberTableLine.projectIds.push(timeSheet.project.id)
            }
            weekNumberTableLine.timeSheetCount += timeSheet.daysOfWork
            if (timeSheet.project.notBillable) {
              weekNumberTableLine.nonBillableTimeSheetCount +=
                timeSheet.daysOfWork
            } else {
              weekNumberTableLine.billableTimeSheetCount += timeSheet.daysOfWork
            }
          }
        } else {
          const momentWeek: moment.Moment = moment(weekNumber, 'w')

          acc.push({
            weekNumber,
            weekLabel: `Du ${momentWeek
              .startOf('week')
              .format('DD/MM')} au ${momentWeek.endOf('week').format('DD/MM')}`,
            projectIds: timeSheet.project ? [timeSheet.project.id] : [],
            holidaysCount:
              !timeSheet.project && !timeSheet.service
                ? timeSheet.daysOfWork
                : 0,
            timeSheetCount:
              timeSheet.project && timeSheet.service ? timeSheet.daysOfWork : 0,
            billableTimeSheetCount:
              timeSheet.project && timeSheet.project.notBillable
                ? 0
                : timeSheet.daysOfWork,
            nonBillableTimeSheetCount:
              timeSheet.project && timeSheet.project.notBillable
                ? timeSheet.daysOfWork
                : 0
          })
        }
        return acc
      }, [])
      .sort((a, b) => a.weekNumber - b.weekNumber)
  }

  getDaysOffPerWeek(daysOff: DayOff[]): { [key: number]: number } {
    return daysOff.reduce((acc: { [key: number]: number }, dayOff: DayOff) => {
      const weekNumber: number = moment(dayOff.date).isoWeek()

      if (acc[weekNumber]) {
        acc[weekNumber] = acc[weekNumber] + 1
      } else {
        acc[weekNumber] = 1
      }

      return acc
    }, [])
  }

  promptDeleteTimeSheet(clickEvent: MouseEvent, timeSheetId: number) {
    clickEvent.stopPropagation()
    this.timeSheetToDelete = this.timeSheets.find((tS) => tS.id === timeSheetId)
  }

  deleteTimeSheet(timeSheet: TimeSheet): void {
    this.resourceService
      .delete('time-sheets', timeSheet.id)
      .subscribe((res) => {
        this.getTimeSheets()
        this.flashMessageService.success('Le time sheet a bien été effacée.')
      })
  }
}

interface TimeSheetTableLine {
  weekNumber: number
  weekLabel: string
  projectIds: number[]
  holidaysCount: number
  timeSheetCount: number
  billableTimeSheetCount: number
  nonBillableTimeSheetCount: number
}
