<section *ngIf="user">
  <div class="flex flex-mobile space-between align-center m-b-gutter">
    <div class="left-part">
      <div class="flex flex-mobile align-center">
        <app-image
          [path]="user.image"
          [title]="user.name"
          [alt]="user.name"
          [style]="{ 'box-shadow': '0 0 0 4px' + user.color }"
          className="avatar-sm is-hidden-mobile m-r-md m-l-xs"
        >
        </app-image>

        <h1 class="title is-2 is-light m-b-0">
          <span class="m-r-md">
            {{ user.name }}
          </span>

          <span class="is-regular m-r-md has-text-grey-dark is-size-4">
            {{ user.position.name }}
          </span>

          <span
            class="tag is-info is-medium is-hidden-touch"
            *ngIf="user.isActive"
          >
            En activité
          </span>
          <span
            class="tag is-dark is-medium is-hidden-touch"
            *ngIf="!user.isActive"
          >
            Inactif
          </span>
        </h1>
      </div>
    </div>
    <div class="right-part">
      <a
        [routerLink]="['/collaborateurs', user.id, 'edit']"
        class="button is-primary is-hidden-touch"
        *abcHasPermission="'editUsers'"
      >
        Modifier
      </a>

      <a
        [routerLink]="['/collaborateurs', user.id, 'edit']"
        class="button is-primary is-rounded is-hidden-desktop"
        *abcHasPermission="'editUsers'"
      >
        <i class="icon icon-edit-2"></i>
      </a>
      <a
        [routerLink]="['/collaborateurs', 'myself', 'edit']"
        class="button is-primary is-rounded is-hidden-desktop"
        *abcHasPermission="{ hideIf: ['editUsers'] }"
      >
        <i class="icon icon-edit-2"></i>
      </a>
    </div>
  </div>
  <div class="card p-a-0 p-b-colgap m-t-colgap m-b-lg">
    <div class="columns">
      <div class="column">
        <div class="tabs is-centered">
          <ul>
            <li
              [ngClass]="{ 'is-active': selectedTab === 'dashboard' }"
              (click)="selectedTab = 'dashboard'"
            >
              <a>Tableau de bord</a>
            </li>
            <li
              [ngClass]="{ 'is-active': selectedTab === 'projects' }"
              (click)="selectedTab = 'projects'"
            >
              <a>Missions</a>
            </li>
            <li
              [ngClass]="{ 'is-active': selectedTab === 'planning' }"
              (click)="selectedTab = 'planning'"
            >
              <a>Planning</a>
            </li>
            <li
              [ngClass]="{ 'is-active': selectedTab === 'timeSheets' }"
              (click)="selectedTab = 'timeSheets'"
              *ngIf="hasBrowseTimeSheetsPermission || isCurrentUser"
            >
              <a>Timesheet</a>
            </li>
            <li
              [ngClass]="{ 'is-active': selectedTab === 'bonuses' }"
              (click)="selectedTab = 'bonuses'"
              *ngIf="canSeeBonuses"
            >
              <a>Primes</a>
            </li>

            <li
              [ngClass]="{ 'is-active': selectedTab === 'reports' }"
              (click)="selectedTab = 'reports'"
              *ngIf="canSeeReports"
            >
              <a>Rapports</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="columns">
      <!-- Dashboard -->
      <div class="column is-12" *ngIf="selectedTab === 'dashboard'">
        <div class="p-x-gutter p-b-gutter">
          <app-user-time-sheet-dashboard
            [userId]="user.id"
          ></app-user-time-sheet-dashboard>
        </div>
      </div>

      <!-- Projects -->
      <div
        class="column is-12"
        *ngIf="
          selectedTab === 'projects' &&
          projectPaginator &&
          projectPaginator.data
        "
      >
        <div class="has-table p-a-0">
          <div class="table-responsive">
            <abc-table
              [items]="projectPaginator.data"
              [definition]="projectDefinition"
              [hiddenProps]="['team']"
              [allowOrderBy]="false"
              (reloadPrompted)="reload()"
              *ngIf="projectPaginator.data.length"
            ></abc-table>
            <div class="p-a-gutter" *ngIf="!projectPaginator.data.length">
              <h2 class="title is-4">Aucune mission à afficher</h2>
              <p>
                Vous êtes affecté à une mission dans l'une des conditions
                suivantes :
              </p>
              <br />
              <ol class="m-l-gutter">
                <li>Vous êtes réservé sur celle-ci</li>
                <li>Vous êtes le responsable opérationnel</li>
                <li>Vous êtes le partner mission</li>
              </ol>
            </div>
          </div>
        </div>

        <abc-pagination
          [paginator]="projectPaginator"
          (pageChange)="onProjectPageChanged($event)"
        ></abc-pagination>
      </div>
      <div
        class="column is-12"
        *ngIf="selectedTab === 'projects' && loadingProjects"
      >
        <div *ngIf="loadingProjects" class="list-loading p-t-xl">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div class="is-hidden-touch"></div>
          <div class="is-hidden-touch"></div>
          <div class="is-hidden-touch"></div>
          <div class="is-hidden-touch"></div>
          <div class="is-hidden-touch"></div>
          <div class="is-hidden-touch"></div>
          <div class="is-hidden-touch"></div>
        </div>
      </div>

      <!-- TimeSheet Calendar -->
      <div class="column" *ngIf="selectedTab === 'timeSheets'">
        <div class="p-x-gutter p-b-gutter">
          <app-user-time-sheets
            [userId]="user.id"
            [suggestedViewDate]="viewDate"
            [timeSheetToEditId]="timeSheetToEdit"
            [redirectTo]="redirectTo"
          ></app-user-time-sheets>
        </div>
      </div>

      <!-- Planning -->
      <div class="column is-12 p-t-0" *ngIf="selectedTab === 'planning'">
        <div class="has-table p-a-0">
          <app-user-planning [userId]="user.id"></app-user-planning>
        </div>
      </div>

      <!-- Bonuses -->
      <div
        class="column is-12 p-t-0"
        *ngIf="selectedTab === 'bonuses' && canSeeBonuses"
      >
        <app-user-bonuses [userId]="user.id"></app-user-bonuses>
      </div>

      <!-- Reports -->
      <div
        class="column is-12 p-t-0"
        *ngIf="selectedTab === 'reports' && canSeeReports"
      >
        <app-user-reports [userId]="user.id"></app-user-reports>
      </div>
    </div>
  </div>
</section>
