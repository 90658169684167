<div [formGroup]="form">
  <div class="control">
    <label for="password-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >
    <input
      id="password-input-{{ uniqueId }}"
      type="password"
      class="input form-control"
      [placeholder]="placeholder || label + '...'"
      [ngClass]="{ 'is-danger': showErrors && form.invalid }"
      formControlName="password"
      (keyup)="onChange($event.target.value)"
    />
    <p class="help" *ngIf="helpText">{{ helpText }}</p>
  </div>
</div>
