import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms'

import { AbcInput } from '../../../../common/interfaces/abc-input.interface'
import { FlashMessageService } from '../../../services/flash-message.service'

@Component({
  selector: 'abc-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnChanges, AbcInput {
  @Input() label: string
  @Input() initialValue: { value: number }
  @Input() placeholder: string
  @Input() helpText: string
  @Input() showErrors = false
  @Input() readonly = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string

  // Custom number input params.
  @Input() step: string
  @Input() min: number
  @Input() max: number

  @Output() valueChanged: EventEmitter<{ value: number }> = new EventEmitter()

  numberForm: UntypedFormGroup
  required: boolean

  constructor(
    private formBuilder: UntypedFormBuilder,
    private flashMessageService: FlashMessageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    this.numberForm = this.formBuilder.group({
      number: [
        this.initialValue ? this.initialValue.value : null,
        this.validators
      ]
    })
    this.required = this.validators.includes(Validators.required)

    if (this.readonly) {
      this.numberForm.disable()
    } else {
      this.numberForm.enable()
    }
  }

  onChange(newValue: string) {
    const valueNumber = parseFloat(newValue)

    // Warning if value is not between min and max.
    if (
      valueNumber !== 0 &&
      ((typeof this.max === 'number' && valueNumber > this.max) ||
        (typeof this.min === 'number' && valueNumber < this.min))
    ) {
      let message: string
      if (typeof this.min === 'number' && typeof this.max !== 'number') {
        message = `La valeur du champ doit être supérieure à ${this.min}.`
      } else if (typeof this.min !== 'number' && typeof this.max === 'number') {
        message = `La valeur du champ doit être inférieure à ${this.max}.`
      } else {
        message = `La valeur du champ doit être comprise entre ${this.min} et ${this.max}.`
      }

      this.flashMessageService.warning(message)
    }

    this.valueChanged.emit({ value: valueNumber })
  }
}
