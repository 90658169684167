<div class="columns">
  <div
    class="column m-t-gutter m-b-colgap is-12-mobile is-10-desktop is-offset-1-desktop is-8-widescreen is-offset-2-widescreen is-6-fullhd is-offset-3-fullhd"
  >
    <div class="p-x-gutter-until-widescreen">
      <h3 class="title--small has-text-black">Activité</h3>
      <a (click)="openCreateModal()" class="p-l-md is-size-8"
        ><i class="icon icon-plus has-text-grey-dark m-r-sm"></i>Ajouter une
        activité</a
      >

      <div
        class="flex flex-mobile align-center justify-start activity-item"
        *ngFor="let activity of activities"
      >
        <div class="activity-img">
          <img
            src="./assets/img/activity-status.svg"
            *ngIf="activity.nature === ActivityNature.StatusChanged && !isAlt"
          />
          <img
            src="./assets/img/activity-status-alt.svg"
            *ngIf="activity.nature === ActivityNature.StatusChanged && isAlt"
          />
          <img
            src="./assets/img/activity-paid.svg"
            *ngIf="activity.nature === ActivityNature.PaymentDone"
          />
          <img
            src="./assets/img/activity-create.svg"
            *ngIf="activity.nature === ActivityNature.ProjectCreated"
          />
          <img
            src="./assets/img/activity-reactivate.svg"
            *ngIf="activity.nature === ActivityNature.ProjectReactivated"
          />
          <img
            src="./assets/img/activity-drop.svg"
            *ngIf="activity.nature === ActivityNature.ProjectAbandoned"
          />

          <img
            src="./assets/img/activity-comment.svg"
            *ngIf="activity.nature === ActivityNature.Comment"
          />
        </div>
        <div class="container">
          <div class="columns">
            <div class="column">
              <div class="activity-content">
                <span class="is-bold">{{ activity.user.name }}</span>
                {{ activity.value }}
                <span
                  class="badge p-x-md is-status-{{
                    getStatusNumber(activity.newStatus)
                  }}"
                  *ngIf="activity.newStatus"
                  >{{ activity.newStatus }}</span
                >
                <span class="date has-text-grey-dark"
                  >le {{ activity.date | date: ' d MMM  y à HH:mm ' }}
                  <ng-container *ngIf="activity.modifier"
                    >(modifié par {{ activity.modifier.name }})
                  </ng-container>
                  <a
                    class="p-l-sm p-r-xs edit-link"
                    (click)="openEditModal(activity)"
                    ><i class="icon icon-edit has-text-grey"></i>Editer</a
                  >
                </span>

                <div class="activity-comment has-text-grey-dark m-t-xs">
                  {{ activity.comments }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p *ngIf="activities && !activities.length">
        Aucune activité sur cette mission pour l'instant.
      </p>
    </div>
  </div>
</div>

<app-activity-create-edit-modal
  *ngIf="showCreateEditModal"
  [fieldSpecialRules]="[
    {
      fieldId: 'projectId',
      hidden: true,
      forcedValue: { projectId: projectId }
    }
  ]"
  [item]="activityToEdit"
  [mode]="createEditMode"
  (closedModal)="showCreateEditModal = false"
  (submitSuccessful)="showCreateEditModal = false; getActivities()"
></app-activity-create-edit-modal>
