import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'

import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { EstimatedWork } from '../../estimated-work/estimated-work.interface'

@Component({
  selector: 'app-service-estimated-work',
  templateUrl: './service-estimated-work.component.html',
  styleUrls: ['./service-estimated-work.component.scss']
})
export class ServiceEstimatedWorkComponent implements OnInit {
  @Input() estimatedWorks: EstimatedWork[]
  @Input() isReadonly: boolean
  @Input() pricingYear: number

  @Output()
  estimatedWorkChanged: EventEmitter<{
    totalAmount: number
    estimatedWorks: EstimatedWork[]
  }> = new EventEmitter()

  positions: SelectOption[]
  estimatedWorkAmounts: number[] = [0]

  form: UntypedFormGroup = this.formBuilder.group({
    estimatedWorks: new UntypedFormArray([])
  })

  get estimatedWorkFormArray(): UntypedFormArray {
    return this.form.get('estimatedWorks') as UntypedFormArray
  }

  constructor(
    private resourceService: ResourceService,
    private formBuilder: UntypedFormBuilder
  ) {}

  async ngOnInit() {
    this.positions = await this.resourceService.listSelectOptions('positions', {
      pricingYear: this.pricingYear,
      hasReferenceYearDailyRate: true
    })

    if (this.estimatedWorks.length) {
      this.estimatedWorks.forEach((eW: EstimatedWork) => {
        this.estimatedWorkFormArray.push(this.formBuilder.group(eW))
      })
      this.estimatedWorkAmounts = this.estimatedWorks.map(
        (eW) => eW.daysOfWork * eW.dailyRate
      )
    } else {
      this.addEstimatedWork()
    }

    if (this.isReadonly) {
      this.estimatedWorkFormArray.disable()
    }

    this.form.valueChanges.subscribe(
      (newValues: {
        estimatedWorks: {
          positionId: number
          daysOfWork: number
          dailyRate?: number
        }[]
      }) => {
        // Calculate amounts.
        this.estimatedWorkAmounts = this.getEstimatedWorkAmounts(
          newValues.estimatedWorks
        )

        this.estimatedWorkChanged.emit({
          totalAmount: this.estimatedWorkAmounts.reduce(
            (sum: number, curr: number) => sum + curr,
            0
          ),
          estimatedWorks: newValues.estimatedWorks.map((eW, i: number) => {
            eW.dailyRate = this.estimatedWorkAmounts[i] / eW.daysOfWork
            return eW
          })
        })
      }
    )
  }

  addEstimatedWork() {
    this.estimatedWorkFormArray.push(
      this.formBuilder.group({
        positionId: this.positions[0].value,
        daysOfWork: 0
      })
    )
  }

  removeEstimatedWork(index: number) {
    this.estimatedWorkFormArray.removeAt(index)
  }

  getEstimatedWorkAmounts(
    estimatedWorks: {
      positionId: number
      daysOfWork: number
      dailyRate?: number
    }[]
  ): number[] {
    return estimatedWorks.map((estimatedWork) => {
      const position: SelectOption = this.positions.find(
        (p: SelectOption) => p.value === estimatedWork.positionId.toString()
      )

      if (!position || !estimatedWork.daysOfWork) {
        return 0
      }

      return position['referenceYearDailyRate'] * estimatedWork.daysOfWork
    })
  }
}
