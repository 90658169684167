import { Component, Input, OnInit } from '@angular/core'
import { forkJoin } from 'rxjs'

import { ResourceService } from '../../../common/services/resource.service'
import { BookedWork } from '../../booked-work/booked-work.interface'
import { PlannedWork } from '../../planned-work/planned-work.interface'
import { TimeSheet } from '../../time-sheet/time-sheet.interface'
import { User } from '../../user/user.interface'

@Component({
  selector: 'app-project-follow-up',
  templateUrl: './project-follow-up.component.html',
  styleUrls: ['./project-follow-up.component.scss']
})
export class ProjectFollowUpComponent implements OnInit {
  @Input() projectId: number

  users: User[] = []

  totalBookedWorks: number
  totalPlannedWorks: number
  totalTimeSheets: number
  expandedUserId: number

  constructor(private resourceService: ResourceService) {}

  ngOnInit() {
    forkJoin([
      this.resourceService.list(`projects/${this.projectId}/booked-works`, {
        includePast: true
      }),
      this.resourceService.list(`projects/${this.projectId}/booked-works`),
      this.resourceService.list(`projects/${this.projectId}/planned-works`),
      this.resourceService.list(`projects/${this.projectId}/time-sheets`)
    ]).subscribe(
      ([bookedWorkRes, futureBookedWorks, plannedWorkRes, timeSheetRes]: [
        BookedWork[],
        BookedWork[],
        PlannedWork[],
        TimeSheet[]
      ]) => {
        this.totalBookedWorks = bookedWorkRes.reduce(
          (sum, bW) => sum + bW.daysOfWork,
          0
        )
        this.totalPlannedWorks = plannedWorkRes.reduce(
          (sum, pW) => sum + pW.daysOfWork,
          0
        )
        this.totalTimeSheets = timeSheetRes.reduce(
          (sum, tS) => sum + tS.daysOfWork,
          0
        )

        bookedWorkRes.forEach((bookedWork: BookedWork) => {
          const user: User = this.users.find((u) => u.id === bookedWork.user.id)

          if (user) {
            user.bookedWorks.push(bookedWork)
          } else {
            const newUser: User = bookedWork.user
            newUser.bookedWorks = [bookedWork]
            newUser.plannedWorks = []
            newUser.timeSheets = []
            this.users.push(newUser)
          }
        })

        futureBookedWorks.forEach((futureBookedWork: BookedWork) => {
          const user: User = this.users.find(
            (u) => u.id === futureBookedWork.user.id
          )

          if (user) {
            user.futureBookedWorks
              ? user.futureBookedWorks
              : (user.futureBookedWorks = [])
            user.futureBookedWorks.push(futureBookedWork)
          } else {
            const newUser: User = futureBookedWork.user
            newUser.futureBookedWorks = [futureBookedWork]
            newUser.plannedWorks = []
            newUser.timeSheets = []
            this.users.push(newUser)
          }
        })

        plannedWorkRes.forEach((plannedWork: PlannedWork) => {
          const user: User = this.users.find(
            (u) => u.id === plannedWork.user.id
          )

          if (user) {
            user.plannedWorks.push(plannedWork)
          } else {
            const newUser: User = plannedWork.user
            newUser.plannedWorks = [plannedWork]
            newUser.bookedWorks = []
            newUser.timeSheets = []
            this.users.push(newUser)
          }
        })

        timeSheetRes.forEach((timeSheet: TimeSheet) => {
          const user: User = this.users.find((u) => u.id === timeSheet.user.id)

          if (user) {
            user.timeSheets.push(timeSheet)
          } else {
            const newUser: User = timeSheet.user
            newUser.timeSheets = [timeSheet]
            newUser.bookedWorks = []
            newUser.plannedWorks = []
            this.users.push(newUser)
          }
        })

        this.users.forEach((user: User) => {
          user.bookedWorkTotalDays = user.bookedWorks.reduce(
            (sum, bW) => sum + bW.daysOfWork,
            0
          )
          user.futureBookedWorkDays = user.futureBookedWorks?.reduce(
            (sum, bW) => sum + bW.daysOfWork,
            0
          )
          user.plannedWorkTotalDays = user.plannedWorks.reduce(
            (sum, pW) => sum + pW.daysOfWork,
            0
          )
          user.timeSheetTotalDays = user.timeSheets.reduce(
            (sum, tS) => sum + tS.daysOfWork,
            0
          )
        })
      }
    )
  }

  toggleExpandUser(userId: number) {
    if (this.expandedUserId === userId) {
      this.expandedUserId = null
    } else {
      this.expandedUserId = userId
    }
  }
}
