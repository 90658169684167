import { Component, Input } from '@angular/core'

@Component({
  selector: 'abc-currency-yield',
  template: `<span class="has-text-link is-bold m-r-lg" *ngIf="percentage">{{
      percentage | percent
    }}</span
    >{{ amount | euros }}`
})
export class CurrencyYieldComponent {
  @Input() amount: number
  @Input() percentage: number
}
