<ng-container [ngSwitch]="type">
  <abc-text-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [readonly]="readonly"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Text"
  ></abc-text-input>

  <abc-textarea-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [readonly]="readonly"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Textarea"
  ></abc-textarea-input>

  <abc-checkbox-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Checkbox"
  ></abc-checkbox-input>

  <abc-color-picker-input
    [label]="label"
    [uniqueId]="uniqueId"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.ColorPicker"
  ></abc-color-picker-input>

  <abc-datepicker-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [readonly]="readonly"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Datepicker"
  ></abc-datepicker-input>

  <abc-date-range-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [copyDateFromOnDateTo]="copyDateFromOnDateTo"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.DateRange"
  ></abc-date-range-input>

  <abc-month-range-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.MonthRange"
  ></abc-month-range-input>

  <abc-email-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    placeholder="Custom placeholder for email input..."
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Email"
  ></abc-email-input>

  <abc-multi-search-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [resources]="searchResources"
    [params]="searchParams"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [maxSelectedItems]="maxSelectedItems"
    [readonly]="readonly"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.MultiSearch"
  ></abc-multi-search-input>

  <abc-multi-select-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [selectOptions]="selectOptions"
    itemNameSingular="élément"
    itemNamePlural="éléments"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.MultiSelect"
  >
  </abc-multi-select-input>

  <abc-number-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [min]="min"
    [max]="max"
    [step]="step"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [readonly]="readonly"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Number"
  ></abc-number-input>

  <abc-time-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Time"
  ></abc-time-input>

  <abc-time-duration-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.TimeDuration"
  ></abc-time-duration-input>

  <abc-rich-text-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.RichText"
  ></abc-rich-text-input>

  <abc-select-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [selectOptions]="selectOptions"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [readonly]="readonly"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Select"
  ></abc-select-input>

  <abc-radio-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [selectOptions]="selectOptions"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Radio"
  ></abc-radio-input>

  <abc-tel-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Tel"
  ></abc-tel-input>

  <abc-password-input
    [label]="label"
    [uniqueId]="uniqueId"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Password"
  ></abc-password-input>

  <abc-toggle-input
    [label]="label"
    [uniqueId]="uniqueId"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Toggle"
  ></abc-toggle-input>

  <abc-file-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [accept]="accept"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [resourceName]="resourceName"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.File"
  >
  </abc-file-input>

  <abc-image-input
    [label]="label"
    [uniqueId]="uniqueId"
    [placeholder]="placeholder"
    [initialValue]="initialValue"
    [showErrors]="showErrors"
    [validators]="validators"
    [helpText]="helpText"
    [resourceName]="resourceName"
    (valueChanged)="onValueChanged($event)"
    *ngSwitchCase="InputType.Image"
  >
  </abc-image-input>
</ng-container>
