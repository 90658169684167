<div class="columns">
  <div class="column m-t-colgap">
    <h3 class="title--small has-text-black">Facturation de frais</h3>
  </div>
</div>

<!-- Form-->
<form [formGroup]="form">
  <div class="columns flex flex-wrap">
    <div
      class="column is-12-mobile is-6-tablet is-5-desktop is-5-fullhd p-x-0-mobile"
    >
      <abc-input
        [type]="InputType.Text"
        label="Libellé"
        (valueChanged)="form.patchValue({ reference: $event.value })"
        [validators]="requiredValidators"
        [showErrors]="showErrors"
      ></abc-input>
    </div>
    <div
      class="column is-12-mobile is-6-tablet is-5-desktop is-5-fullhd p-x-0-mobile"
    >
      <abc-input
        [type]="InputType.Text"
        [initialValue]="{ value: form.value.billingAddress }"
        [validators]="requiredValidators"
        label="Adresse de facturation"
        placeholder="Adresse de facturation..."
        [showErrors]="showErrors"
        (valueChanged)="form.patchValue({ billingAddress: $event.value })"
        *ngIf="form.value.billingAddress !== null"
      ></abc-input>
    </div>
    <div
      class="column is-12-mobile is-6-tablet is-4-desktop is-2-fullhd p-x-0-mobile no-label"
    >
      <abc-input
        [type]="InputType.Checkbox"
        label="Emise à l'étranger"
        (valueChanged)="form.patchValue({ international: $event.value })"
        [showErrors]="showErrors"
      ></abc-input>
    </div>
  </div>
  <div class="columns">
    <div
      class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd p-x-0-mobile"
    >
      <abc-input
        [type]="InputType.MultiSelect"
        [selectOptions]="expensesSelectOptions"
        [initialValue]="{ value: form.value.expenseIds }"
        label="Frais concernés"
        (valueChanged)="form.patchValue({ expenseIds: $event.value })"
        [validators]="requiredValidators"
        [showErrors]="showErrors"
        *ngIf="expensesSelectOptions"
      ></abc-input>
    </div>
    <div
      class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd p-x-0-mobile"
    >
      <label for="disabled-select-periodicity"
        >Periodicité <span class="has-text-danger">*</span></label
      >
      <div class="control">
        <div class="select">
          <select class="select" id="disabled-select-periodicity" disabled>
            <option selected>Aucune periodicité</option>
          </select>
        </div>
      </div>
    </div>
    <div
      class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd p-x-0-mobile"
    >
      <div class="control">
        <label for="disabled-input-invoice-count"
          >Nombre de factures <span class="has-text-danger">*</span></label
        >
        <input
          type="number"
          class="input"
          value="1"
          id="disabled-input-invoice-count"
          disabled
        />
      </div>
    </div>
    <div
      class="column is-12-mobile is-6-tablet is-4-desktop is-3-fullhd p-x-0-mobile"
    >
      <abc-input
        [type]="InputType.Datepicker"
        label="Date 1ère facture"
        [initialValue]="{ value: form.value.issueDate }"
        (valueChanged)="form.patchValue({ issueDate: $event.value })"
        [validators]="requiredValidators"
        [showErrors]="showErrors"
      ></abc-input>
    </div>
  </div>
</form>

<!-- Preview -->
<div class="card no-shadow m-y-gutter">
  <div class="columns">
    <div class="column">
      <h3 class="title--small has-text-black">
        Prévisualisation de la facture de frais générée
      </h3>
    </div>
  </div>
  <div class="columns flex flex-mobile invoice-list">
    <div class="column is-4-mobile is-4-tablet">
      <div class="flex flex-mobile align-center flex-start">
        <i class="icon m-r-md icon-file-text is-size-4 is-hidden-mobile"></i>
        <span>Facture de frais 1</span>
      </div>
    </div>
    <div class="column is-4-mobile is-4-tablet is-3-widescreen p-x-0-mobile">
      {{ form.value.issueDate | date }}
    </div>
    <div
      class="column is-4-mobile is-4-tablet is-3-widescreen has-text-right p-l-0-mobile"
    >
      {{ amount | euros }}
    </div>
  </div>
</div>
<div class="columns">
  <div class="column is-12 has-text-right">
    <a (click)="submit()" class="button is-primary">Enregistrer</a>
  </div>
</div>
