<!-- Heading -->
<div
  class="flex flex-mobile space-between align-center m-b-gutter"
  *ngIf="definition"
>
  <div class="left-part">
    <h1 class="title is-2 is-light">{{ definition.title }}</h1>
  </div>
  <div class="right-part">
    <a
      *ngIf="definition.slug === 'invoices'"
      class="button is-warning m-l-gutter is-hidden-touch"
      routerLink="/{{ definition.path || definition.slug }}"
      [queryParams]="{ toXLS: 'true', creditVersion: 'true' }"
      queryParamsHandling="merge"
    >
      Exporter les avoirs
    </a>

    <!-- Export button -->
    <ng-container
      *ngIf="
        definition.buttons.indexOf(LinkType.EXPORT) > -1 &&
        browseItemsPermissionName
      "
    >
      <a
        *abcHasPermission="browseItemsPermissionName"
        class="button is-link m-l-gutter is-hidden-touch"
        routerLink="/{{ definition.path || definition.slug }}"
        [queryParams]="{ toXLS: 'true', creditVersion: 'false' }"
        queryParamsHandling="merge"
      >
        Exporter
      </a>
    </ng-container>

    <!-- Create button -->
    <ng-container
      *ngIf="
        definition.buttons.indexOf(LinkType.CREATE) > -1 &&
        addItemPermissionName
      "
    >
      <a
        class="button is-primary m-l-gutter is-hidden-touch"
        routerLink="/{{ definition.path || definition.slug }}/create"
        *abcHasPermission="addItemPermissionName"
      >
        Ajouter {{ definition.gender === 'Masculine' ? 'un' : 'une' }}
        {{ definition.nameSingular }}
      </a>
      <a
        class="button is-rounded is-primary m-l-gutter is-hidden-desktop"
        routerLink="/{{ definition.path || definition.slug }}/create"
        *abcHasPermission="addItemPermissionName"
      >
        <i class="icon icon-plus"></i>
      </a>
    </ng-container>
  </div>
</div>

<!-- Filters -->
<section *ngIf="resolvedFilters && resolvedFilters.length">
  <div class="card">
    <div class="columns">
      <div class="column">
        <h2 class="title is-6 is-bold is-uppercase text-is-grey">Filtres</h2>
      </div>
    </div>
    <div class="columns flex flex-wrap">
      <div
        class="column"
        [ngClass]="filter.className"
        *ngFor="let filter of resolvedFilters"
      >
        <abc-input
          [type]="filter.inputType"
          [properties]="filter.properties"
          [initialValue]="filter.initialValue"
          [selectOptions]="filter.selectOptions"
          [showClearDateButton]="filter.showClearDateButton"
          [label]="filter.label"
          [searchResources]="filter.searchResources"
          [placeholder]="filter.placeholder"
          [validators]="filter.validators || []"
          (valueChanged)="onFilterValueChanged($event, filter)"
        ></abc-input>
      </div>
    </div>
  </div>
</section>

<!-- List -->
<ng-container *ngIf="paginator">
  <div class="flex space-between align-center relative">
    <abc-meta [paginator]="paginator"></abc-meta>

    <!-- Key numbers -->
    <div class="total total--alt is-hidden-mobile">
      <span
        class="badge m-b-md m-t-lg"
        [ngClass]="keyNumber.className"
        *ngFor="let keyNumber of definition.keyNumbers"
      >
        <ng-container *ngIf="keyNumber.loading">Loading...</ng-container>

        <ng-container *ngIf="!keyNumber.loading"
          >{{ keyNumber.label }} :&nbsp;

          <abc-currency-yield
            *ngIf="keyNumber.type === YieldType.Currency"
            [amount]="keyNumber.value"
          ></abc-currency-yield>

          <abc-number-yield
            *ngIf="keyNumber.type === YieldType.Number"
            [value]="keyNumber.value"
          ></abc-number-yield>

          <abc-percentage-yield
            *ngIf="keyNumber.type === YieldType.Percentage"
            [value]="keyNumber.value"
          ></abc-percentage-yield>
        </ng-container>
      </span>
    </div>
  </div>
  <div class="card p-a-0 has-table m-b-lg">
    <div class="table-responsive">
      <abc-table
        [items]="paginator.data"
        [definition]="definition"
        [orderBy]="orderBy"
        [orderByDesc]="orderByDesc"
        (orderByChanged)="onOrderByChanged($event)"
        (reloadPrompted)="reload()"
      ></abc-table>
    </div>
  </div>

  <abc-pagination
    [paginator]="paginator"
    (pageChange)="onPageChanged($event)"
  ></abc-pagination>
</ng-container>

<div class="modal is-active" *ngIf="loadingExport">
  <div class="modal-background"></div>
  <div class="modal-content notification is-light o-y-auto">
    <div class="columns">
      <div class="column">
        <h1 class="title is-2 is-light">
          L'export de resources est en préparation...
        </h1>
        <div class="list-loading list-loading--modal m-b-md">
          <div></div>
        </div>
        <p>
          Cette opération peut prendre quelques minutes. Gardez votre fenêtre
          ouverte, le téléchargement s'effectuera automatiquement une fois le
          fichier prêt.
        </p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading" class="list-loading p-t-xl">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
</div>
