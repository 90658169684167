<!-- Heading -->
<div class="flex flex-mobile space-between align-center m-b-gutter">
  <div class="left-part">
    <h1 class="title is-2 is-light">
      {{ definition.title }}
    </h1>
  </div>
  <div class="right-part">
    <!-- Export button -->
    <a
      *ngIf="definition.buttons.indexOf(LinkType.EXPORT) > -1"
      class="button is-link m-l-gutter is-hidden-touch"
      routerLink="/{{ definition.path || definition.slug }}"
      [queryParams]="{ toXLS: 'true' }"
      queryParamsHandling="merge"
    >
      Exporter
    </a>
    <!-- Create button -->
    <a
      *ngIf="definition.buttons.indexOf(LinkType.CREATE) > -1"
      class="button is-primary m-l-gutter is-hidden-touch"
      routerLink="/{{ definition.path || definition.slug }}/create"
    >
      Ajouter {{ definition.gender === 'Masculine' ? 'un' : 'une' }}
      {{ definition.nameSingular }}
    </a>
    <a
      *ngIf="definition.buttons.indexOf(LinkType.CREATE) > -1"
      class="button is-rounded is-primary m-l-gutter is-hidden-desktop"
      routerLink="/{{ definition.path || definition.slug }}/create"
    >
      <i class="icon icon-plus"></i>
    </a>
  </div>
</div>

<!-- Filters -->
<section *ngIf="resolvedFilters && resolvedFilters.length">
  <div class="card">
    <div class="columns">
      <div class="column">
        <h2 class="title is-6 is-bold is-uppercase text-is-grey">Filtres</h2>
      </div>
    </div>
    <div class="columns flex flex-wrap">
      <div
        class="column"
        [ngClass]="filter.className"
        *ngFor="let filter of resolvedFilters"
      >
        <abc-input
          [type]="filter.inputType"
          [properties]="filter.properties"
          [initialValue]="filter.initialValue"
          [selectOptions]="filter.selectOptions"
          [label]="filter.label"
          [searchResources]="filter.searchResources"
          [placeholder]="filter.placeholder"
          (valueChanged)="onFilterValueChanged($event, filter)"
        ></abc-input>
      </div>
    </div>
  </div>
</section>

<!-- List -->
<ng-container *ngIf="paginator">
  <div class="flex space-between align-center relative">
    <abc-meta [paginator]="paginator"></abc-meta>

    <!-- Key numbers -->
    <div class="total total--alt is-hidden-mobile">
      <span
        class="badge m-b-md m-t-lg"
        [ngClass]="keyNumber.className"
        *ngFor="let keyNumber of definition.keyNumbers"
      >
        <ng-container *ngIf="keyNumber.loading">Loading...</ng-container>
        <ng-container *ngIf="!keyNumber.loading"
          >{{ keyNumber.label }} : {{ keyNumber.value | euros }}</ng-container
        >
      </span>
    </div>
  </div>
  <div class="card has-table m-b-gutter" *ngFor="let user of paginator.data">
    <div class="columns">
      <div class="column">
        <div class="flex space-between align-center">
          <h2 class="m-y-md title is-6 is-bold is-uppercase text-is-grey">
            {{ user.name }}
          </h2>
          <strong class="badge is-link m-r-gutter"
            >Disponibilité :
            {{ user.availableDays | number: '1.0-2' }} j</strong
          >
        </div>

        <table class="table is-bordered">
          <thead>
            <tr>
              <th width="176">Référence</th>
              <th>Titre</th>
              <th>Tâches</th>
              <th class="has-text-right">Jours planifiés</th>
            </tr>
          </thead>
          <tbody>
            <tr
              is-link
              m-r-gutter
              *ngFor="let project of user.projects"
              [ngClass]="{
                'has-text-success bg-success-light': project.notBillable
              }"
            >
              <td>{{ project.reference }}</td>
              <td>{{ project.name }}</td>
              <td>{{ project.plannedWorkDescriptions }}</td>
              <td class="has-text-right">
                {{ project.plannedWorkTotalDays | number: '1.0-2' }} j
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="has-text-right">
          <span class="m-r-gutter">
            <span>Total billable : </span>
            <span class="is-bold">
              {{ user.billablePlannedWorkTotalDays | number: '1.0-2' }} j</span
            >
          </span>
          <span class="m-r-gutter">
            <span>Total non billable : </span>
            <span class="is-bold">
              {{ user.notBillablePlannedWorkTotalDays | number: '1.0-2' }}
              j</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>

  <abc-pagination
    [paginator]="paginator"
    (pageChange)="onPageChanged($event)"
  ></abc-pagination>
</ng-container>

<!-- Loading spinner -->
<div *ngIf="loading" class="list-loading p-t-xl">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
</div>
