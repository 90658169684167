import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { userDefinition } from '../user.definition'

@Component({
  selector: 'app-user-list',
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class UserListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = userDefinition

  filters: Filter[] = [
    {
      label: 'Rechercher par nom',
      placeholder: 'Rechercher un utilisateur par nom...',
      properties: {
        userIds: 'userIds'
      },
      searchResources: ['users'],
      inputType: InputType.MultiSearch,
      className: 'is-12-touch is-6 p-x-0-mobile'
    },
    {
      label: 'Inclure les collaborateurs inactifs',
      properties: {
        value: 'includeNotActive'
      },
      inputType: InputType.Checkbox,
      className: 'is-3 no-label p-x-0-mobile'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
