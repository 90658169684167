export enum InputType {
  Checkbox,
  ColorPicker,
  DateRange,
  Datepicker,
  Email,
  File,
  Image,
  MonthRange,
  MultiSearch,
  MultiSelect,
  Number,
  RichText,
  Select,
  Tel,
  Text,
  Textarea,
  Password,
  Toggle,
  Radio,
  Time,
  TimeDuration
}
