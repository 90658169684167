<!-- Heading -->
<div
  class="flex flex-mobile space-between align-center m-b-gutter"
  *ngIf="definition"
>
  <div class="left-part">
    <h1 class="title is-2 is-light">
      {{ definition.title }}
    </h1>
  </div>
  <div class="right-part">
    <!-- Export button -->
    <ng-container
      *ngIf="
        definition.buttons.indexOf(LinkType.EXPORT) > -1 &&
        browseItemsPermissionName
      "
    >
      <a
        *abcHasPermission="browseItemsPermissionName"
        class="button is-link m-l-gutter is-hidden-touch"
        routerLink="/{{ definition.path || definition.slug }}"
        [queryParams]="{ toXLS: 'true' }"
        queryParamsHandling="merge"
      >
        Exporter
      </a>
    </ng-container>

    <!-- Create button -->
    <ng-container
      *ngIf="
        definition.buttons.indexOf(LinkType.CREATE) > -1 &&
        addItemPermissionName
      "
    >
      <a
        class="button is-primary m-l-gutter is-hidden-touch"
        routerLink="/{{ definition.path || definition.slug }}/create"
        *abcHasPermission="addItemPermissionName"
      >
        Ajouter {{ definition.gender === 'Masculine' ? 'un' : 'une' }}
        {{ definition.nameSingular }}
      </a>
      <a
        class="button is-rounded is-primary m-l-gutter is-hidden-desktop"
        routerLink="/{{ definition.path || definition.slug }}/create"
        *abcHasPermission="addItemPermissionName"
      >
        <i class="icon icon-plus"></i>
      </a>
    </ng-container>
  </div>
</div>

<!-- Filters -->
<section *ngIf="resolvedFilters && resolvedFilters.length">
  <div class="card">
    <div class="columns">
      <div class="column">
        <h2 class="title is-6 is-bold is-uppercase text-is-grey">Filtres</h2>
      </div>
    </div>
    <div class="columns flex flex-wrap">
      <div
        class="column"
        [ngClass]="filter.className"
        *ngFor="let filter of resolvedFilters"
      >
        <abc-input
          [type]="filter.inputType"
          [properties]="filter.properties"
          [initialValue]="filter.initialValue"
          [selectOptions]="filter.selectOptions"
          [showClearDateButton]="filter.showClearDateButton"
          [label]="filter.label"
          [searchResources]="filter.searchResources"
          [placeholder]="filter.placeholder"
          [validators]="filter.validators || []"
          (valueChanged)="onFilterValueChanged($event, filter)"
        ></abc-input>
      </div>
    </div>
  </div>
</section>

<!-- List -->
<ng-container *ngIf="paginator">
  <div class="flex space-between align-center relative">
    <abc-meta [paginator]="paginator"></abc-meta>

    <!-- Key numbers -->
    <div class="total total--alt is-hidden-mobile">
      <span
        class="badge m-b-md m-t-lg"
        [ngClass]="keyNumber.className"
        *ngFor="let keyNumber of definition.keyNumbers"
      >
        <ng-container *ngIf="keyNumber.loading">Loading...</ng-container>

        <ng-container *ngIf="!keyNumber.loading"
          >{{ keyNumber.label }} :&nbsp;

          <abc-currency-yield
            *ngIf="keyNumber.type === YieldType.Currency"
            [amount]="keyNumber.value"
          ></abc-currency-yield>

          <abc-number-yield
            *ngIf="keyNumber.type === YieldType.Number"
            [value]="keyNumber.value"
          ></abc-number-yield>

          <abc-percentage-yield
            *ngIf="keyNumber.type === YieldType.Percentage"
            [value]="keyNumber.value"
          ></abc-percentage-yield>
        </ng-container>
      </span>
    </div>
  </div>
  <div class="card p-a-0 has-table m-b-lg">
    <div class="table-responsive">
      <table class="table is-fullwidth is-hoverable" width="100%">
        <thead class="top-margin">
          <tr>
            <th
              *ngFor="let yield of definition.yields"
              class="{{ yield.headingClassName }}"
            >
              <span *ngIf="yield.disableOrderBy"
                >{{ yield.label }}
                <span
                  class="tooltip has-tooltip has-tooltip-bottom has-tooltip-multiline"
                  [attr.data-tooltip]="yield.tooltip"
                >
                  <i
                    class="icon icon-info is-size-5 has-text-link"
                    *ngIf="yield.tooltip"
                  ></i></span
              ></span>
              <a class="no-deco" *ngIf="!yield.disableOrderBy"
                >{{ yield.label }}
                <span
                  class="tooltip has-tooltip has-tooltip-bottom has-tooltip-multiline"
                  [attr.data-tooltip]="yield.tooltip"
                >
                  <i
                    class="icon icon-info is-size-5 has-text-link"
                    *ngIf="yield.tooltip"
                  ></i>
                </span>
                <i
                  *ngIf="
                    orderBy &&
                    (orderBy === yield.orderByProperty ||
                      orderBy === yield.property)
                  "
                  class="icon"
                  [ngClass]="{
                    'icon-chevron-up': this.orderByDesc,
                    'icon-chevron-down': !this.orderByDesc
                  }"
                ></i>
              </a>
            </th>
            <th
              class="has-text-right"
              *ngIf="
                definition.actionButtons && definition.actionButtons.length
              "
            ></th>
            <th
              class="has-text-right"
              width="115"
              *ngIf="definition.dropdownLinks?.length"
            ></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let user of paginator.data; let i = index">
            <tr
              [ngClass]="{
                'is-danger': user.isDangerHighlight,
                'is-warning': user.isWarningHighlight,
                'is-info': user.isInfoHighlight,
                'is-success': user.isSuccessHighlight,
                'is-disabled': user.isDisabledHighlight
              }"
            >
              <td>
                <abc-image-yield
                  [imageObjects]="user.imageObjects"
                  [label]="user.name"
                ></abc-image-yield>
              </td>
              <td>
                {{ user.holidays | number : '1.0-2' }}
              </td>
              <td>
                {{ user.signedBookedWorkDaysOfWork | number : '1.0-2' }}
              </td>
              <td>
                {{ user.projectBookedWorkDaysOfWork | number : '1.0-2' }}
              </td>
              <td>
                <abc-analog-progress-bar-yield
                  [progressPercentage]="
                    user.signedBookedWorkDaysOfWorkPercentage
                  "
                  [isLarge]="true"
                  [isColorOpposite]="true"
                ></abc-analog-progress-bar-yield>
              </td>
              <td>
                <abc-analog-progress-bar-yield
                  [progressPercentage]="
                    user.projectBookedWorkDaysOfWorkPercentage
                  "
                  [isLarge]="true"
                  [isColorOpposite]="true"
                ></abc-analog-progress-bar-yield>
              </td>
              <td class="has-text-right">
                <a (click)="toggleExpandUser(user)">
                  <i
                    class="icon is-size-4 extend-user-button"
                    [ngClass]="{
                      'icon-chevron-down':
                        !expandedUser || expandedUser.id !== user.id,
                      'icon-chevron-up':
                        expandedUser && expandedUser.id === user.id
                    }"
                  ></i>
                </a>
              </td>
            </tr>
            <tr *ngIf="expandedUser && expandedUser.id === user.id">
              <td class="bg-white-bis" colspan="7">
                <div
                  class="expanded-user-box columns flex-mobile flex-wrap-mobile"
                >
                  <div class="column is-12-mobile is-12-tablet">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Détail des réservations par mission</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-12-mobile is-12-tablet p-t-0">
                    <table class="table is-narrow">
                      <thead>
                        <tr>
                          <th>Prestation</th>
                          <th>Mission</th>
                          <th>Jours reservés</th>
                          <th>Client</th>
                          <th>Statut</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let bookedWork of expandedUser.bookedWorks">
                          <td>
                            {{
                              bookedWork.service ? bookedWork.service.name : '-'
                            }}
                          </td>
                          <td>{{ bookedWork.project.name }}</td>
                          <td>{{ bookedWork.daysOfWork }}</td>
                          <td>
                            {{
                              bookedWork.project.customer
                                ? bookedWork.project.customer.name
                                : '-'
                            }}
                          </td>
                          <td>
                            <abc-progress-bar-yield
                              [status]="bookedWork.project.status"
                              [isDisabled]="bookedWork.project.isAbandoned"
                            ></abc-progress-bar-yield>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <abc-pagination
    [paginator]="paginator"
    (pageChange)="onPageChanged($event)"
  ></abc-pagination>
</ng-container>

<div class="modal is-active" *ngIf="loadingExport">
  <div class="modal-background"></div>
  <div class="modal-content notification is-light o-y-auto">
    <div class="columns">
      <div class="column">
        <h1 class="title is-2 is-light">
          L'export de resources est en préparation...
        </h1>
        <div class="list-loading list-loading--modal m-b-md">
          <div></div>
        </div>
        <p>
          Cette opération peut prendre quelques minutes. Gardez votre fenêtre
          ouverte, le téléchargement s'effectuera automatiquement une fois le
          fichier prêt.
        </p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading" class="list-loading p-t-xl">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
</div>
