<!-- Create Document modal -->
<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content notification is-light modal--large">
    <div class="columns">
      <div class="column">
        <h1 class="title is-2 is-light" *ngIf="mode === 'create'">
          Ajouter une réservation
        </h1>
        <h1 class="title is-2 is-light" *ngIf="mode === 'edit'">
          Modifier une réservation
        </h1>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <table class="table is-bordered">
          <tbody>
            <tr>
              <td>Date de début : {{ project.estimatedStartDate | date }}</td>
              <td class="is-hidden-mobile">
                Date de fin : {{ project.estimatedEndDate | date }}
              </td>
            </tr>
            <tr class="is-hidden-tablet">
              <td>Date de fin : {{ project.estimatedEndDate | date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="columns flex flex-wrap">
      <div class="column is-12-mobile is-6-tablet p-x-0-mobile">
        <ng-container *ngFor="let field of resolvedFields">
          <div [ngClass]="field.className" *ngIf="!field.hidden">
            <abc-input
              [type]="field.inputType"
              [initialValue]="field.initialValue"
              [searchResources]="field.searchResources"
              [searchParams]="field.searchParams"
              [maxSelectedItems]="field.maxSelectedItems"
              [selectOptions]="field.selectOptions"
              [min]="field.min"
              [max]="field.max"
              [label]="field.label"
              [placeholder]="field.placeholder"
              [validators]="field.validators"
              [showErrors]="showErrors"
              (valueChanged)="onValueChanged($event, field)"
            ></abc-input>

            <p
              class="is-regular has-text-danger p-t-xs"
              *ngIf="
                showLateBookedWorkSelectionWarning &&
                (field.id === 'month' || field.id === 'months')
              "
            >
              Attention : Vous avez sélectionné un mois ultérieur à la date de
              fin de la mission. Souhaitez-vous toutefois valider cette
              réservation ?
            </p>
          </div>
        </ng-container>
      </div>

      <!-- Custom user select with availabilities. -->
      <div div class="column is-12-mobile is-6-tablet p-x-0-mobile">
        <app-user-with-availability-select-input
          [positionId]="form.value.positionId"
          [months]="form.value.months || [form.value.month]"
          [projectId]="project.id"
          [userId]="form.value.userId"
          (userSelected)="onUserSelected($event)"
          *ngIf="form && (form.value.months || form.value.month)"
        ></app-user-with-availability-select-input>
        <!-- Service recap -->
        <div class="columns m-t-xs" *ngIf="selectedService">
          <div class="column">
            <p class="title is-6 m-b-sm">
              Récapitulatif de la prestation selectionnée
            </p>
            <table class="table is-bordered">
              <tbody>
                <tr>
                  <td class="p-l-md">{{ selectedService.name }}</td>
                  <td class="is-hidden-mobile">
                    {{ selectedService.amount | euros }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Service recap -->
    <div class="columns m-t-xs" *ngIf="selectedService">
      <div class="column">
        <!-- EstimatedWorks recap -->
        <ng-container
          *ngIf="
            !selectedService.flatFeePricing &&
            selectedService.estimatedWorks.length
          "
        >
          <h3 class="title is-6 m-b-sm">Estimations budgetées</h3>
          <table class="table is-bordered">
            <tbody>
              <tr>
                <th>Profil</th>
                <th>Montant budgeté</th>
                <th>Jrs budgetés</th>
                <th>Jrs restants</th>
              </tr>
              <tr
                *ngFor="let position of positions"
                [ngClass]="{
                  'has-text-danger':
                    position.estimatedDaysOfWork - position.bookedDaysOfWork < 0
                }"
              >
                <td class="p-l-md">{{ position.name }}</td>
                <td>{{ position.amount | euros }}</td>
                <td>{{ position.estimatedDaysOfWork | number: '1.0-2' }}</td>
                <td>
                  {{
                    position.estimatedDaysOfWork - position.bookedDaysOfWork
                      | number: '1.0-2'
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
    </div>
    <div class="columns">
      <div class="column m-t-colgap p-b-0">
        <div class="flex align-stretch flex-mobile space-between">
          <button class="button p-x-0-mobile is-transparent" (click)="close()">
            Annuler
          </button>
          <button
            class="button is-primary is-hidden-touch"
            (click)="submit()"
            [ngClass]="{ 'is-loading': loadingSubmit }"
          >
            Enregistrer
          </button>
          <button
            class="button is-primary is-rounded is-hidden-desktop"
            (click)="submit()"
            [ngClass]="{ 'is-loading': loadingSubmit }"
          >
            <i class="icon icon-save"></i>
          </button>
        </div>
      </div>
    </div>
    <button class="button is-light close" aria-label="close" (click)="close()">
      <i class="icon icon-x is-size-2"></i>
    </button>
  </div>
</div>
