<div [formGroup]="emailForm">
  <div class="control">
    <label for="email-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >
    <input
      type="email"
      class="input form-control"
      [placeholder]="placeholder || label + '...'"
      [ngClass]="{ 'is-danger': showErrors && emailForm.invalid }"
      formControlName="email"
      id="email-input-{{ uniqueId }}"
      (keyup)="onKeyup($event.target.value)"
    />

    <p class="help" *ngIf="helpText">{{ helpText }}</p>
  </div>
</div>
