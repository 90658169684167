<form [formGroup]="form">
  <div class="control">
    <input
      class="input form-control"
      formControlName="name"
      placeholder="Nom du module..."
      #nameInput
    />
  </div>
</form>
