<form [formGroup]="form" novalidate>
  <div class="control" [ngClass]="{ 'is-danger': showErrors && form.invalid }">
    <label for="month-from-{{ uniqueId }}-input">{{ label }}</label>
    <div class="flex">
      <div class="input-text">Du</div>
      <div class="is-relative input-wrapper input-wrapper--mydp">
        <input
          id="month-from-{{ uniqueId }}-input"
          class="input"
          [placeholder]="placeholder || 'Choisissez une date...'"
          formControlName="dateFrom"
          type="month"
          min="2015-01"
          [max]="form.get('dateTo').value"
          required
        />
      </div>
      <div class="input-text">au</div>
      <div class="is-relative input-wrapper input-wrapper--mydp">
        <input
          id="month-to-{{ uniqueId }}-input"
          class="input"
          [placeholder]="placeholder || 'Choisissez une date...'"
          formControlName="dateTo"
          type="month"
          [min]="form.get('dateFrom').value"
          required
        />
      </div>
    </div>
    <p class="help" *ngIf="helpText">{{ helpText }}</p>
  </div>
</form>
