import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { documentDefinition } from '../document.definition'
import { Document } from '../document.interface'

@Component({
  selector: 'app-document-create-edit-modal',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit-modal.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class DocumentCreateEditModalComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  @Input() projectId: number
  @Input() mode = 'create'
  @Input() fieldSpecialRules?: FieldSpecialRule[] = []
  @Input() item: Document

  @Output() closedModal: EventEmitter<void> = new EventEmitter()

  definition: ResourceDefinition = documentDefinition
  isModal = true

  fields: Field[] = [
    {
      id: 'projectId',
      label: 'Mission',
      placeholder: `Choisissez une mission...`,
      properties: {
        projectId: 'projectId'
      },
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      searchResources: ['projects'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-6'
    },
    {
      label: 'Nom du document',
      properties: {
        value: 'name'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'is-12'
    },
    {
      label: 'Fichier',
      placeholder: 'Choisir un fichier',
      helpText: 'Taille limite des documents : 10 Mo',
      properties: { value: 'path' },
      className: 'is-12',
      inputType: InputType.File,
      validators: [Validators.required]
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }

  close() {
    this.closedModal.emit()
  }

  @HostListener('document:keydown.escape') onEnterKeydown() {
    this.close()
  }
}
