import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const userAvailabilityDefinition: ResourceDefinition = {
  title: 'Plannings individuels',
  nameSingular: 'mission',
  namePlural: 'missions',
  hasDetailPage: true,
  hasListPage: true,
  gender: Gender.Masculine,
  slug: 'analytics/user-availability',
  path: 'analytics/rapport-planning-individuels',
  buttons: [],
  defaultLink: LinkType.DETAIL,
  yields: []
}
