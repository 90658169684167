import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AbcCreateEditComponent } from '../../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../../common/services/flash-message.service'
import { ResourceService } from '../../../../common/services/resource.service'
import { Activity } from '../../../activity/activity.interface'
import { activityDefinition } from './activity.definition'

@Component({
  selector: 'app-activity-create-edit-modal',
  templateUrl:
    '../../../../common/base-components/abc-create-edit/abc-create-edit-modal.component.html',
  styleUrls: [
    '../../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class ActivityCreateEditModalComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  @Input() item: Activity
  @Input() mode = 'create'
  @Input() fieldSpecialRules?: FieldSpecialRule[] = []

  @Output() closedModal: EventEmitter<void> = new EventEmitter()

  definition: ResourceDefinition = activityDefinition
  isModal = true

  fields: Field[] = [
    {
      id: 'projectId',
      hidden: true,
      label: 'Mission',
      placeholder: 'Mission',
      properties: {
        projectId: 'projectId'
      },
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      searchResources: ['projects'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12'
    },
    {
      label: 'Commentaire',
      properties: {
        value: 'comments'
      },
      inputType: InputType.Textarea,
      validators: [],
      className: 'is-12'
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }

  close() {
    this.closedModal.emit()
  }

  @HostListener('document:keydown.escape') onEnterKeydown() {
    this.close()
  }
}
