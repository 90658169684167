<div style="display: block; height: 400px">
  <canvas
    baseChart
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="chartOptions"
    [legend]="false"
    [type]="'bar'"
    *ngIf="chartData && chartLabels"
  >
  </canvas>
</div>
