<div class="flex flex-mobile m-t-gutter m-b-colgap reservation-list-level">
  <div>
    <h3 class="title--small has-text-black p-l-lg">Réservations</h3>
    <a
      class="p-l-md is-size-8"
      (click)="openCreateModal()"
      *ngIf="hasServices && canManageBookedWorks"
      ><i class="icon icon-plus has-text-grey-dark m-r-sm"></i>Ajouter une
      réservation</a
    >
    <span class="p-l-md is-size-8" *ngIf="!hasServices"
      >Pour ajouter une réservation, veuillez d'abord créer une prestation dans
      l'onglet "Budget".</span
    >
  </div>
  <div class="is-flex flex-end p-l-lg wrap-touch">
    <span
      class="badge is-link m-r-gutter"
      *ngIf="futureBookedWorkTotalAmount || futureBookedWorkTotalDaysOfWork"
    >
      Jours Réservés à venir :
      {{ futureBookedWorkTotalDaysOfWork | number : '1.0-2' }} jour<ng-container
        *ngIf="futureBookedWorkTotalDaysOfWork > 0"
        >s</ng-container
      >
      | {{ futureBookedWorkTotalAmount | euros }}
    </span>

    <span
      class="badge is-link m-r-gutter"
      *ngIf="totalAmount || totalDaysOfWork"
    >
      incluant ce mois-ci:
      {{ totalDaysOfWork | number : '1.0-2' }} jour<ng-container
        *ngIf="totalDaysOfWork > 0"
        >s</ng-container
      >
      | {{ totalAmount | euros }}
    </span>
  </div>
</div>
<p class="p-x-lg p-b-colgap" *abcHasPermission="'browseBookedWorks'">
  La couleur des cellules du tableau représente le taux d'occupation du
  collaborateur pour le mois associé. Vert: entre 1% et 50% - Orange: entre 50%
  et 100%. Rouge: au delà de 100%.
</p>
<!-- BookedWork Table -->
<div class="table-responsive">
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Collaborateur</th>
        <th>Prestation</th>
        <th
          class="has-text-right"
          *ngFor="let month of months"
          [ngClass]="{ 'has-text-danger': month.isAfterEndDate }"
        >
          {{ month.label }}
        </th>
        <th *ngIf="canManageBookedWorks"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let tableLine of tableLines">
        <td>
          <a
            [routerLink]="['/collaborateurs', tableLine.user.id]"
            class="project-user m-b-0"
            *abcHasPermission="'readUsers'"
          >
            <app-image
              [path]="tableLine.user.image"
              [title]="tableLine.user.name"
              [alt]="tableLine.user.name"
              className="avatar-sm"
            >
            </app-image>
            <div>
              <span class="has-text-black is-regular">{{
                tableLine.user.name
              }}</span>
            </div>
          </a>
          <div
            class="project-user m-b-0"
            *abcHasPermission="{ hideIf: ['readUsers'] }"
          >
            <app-image
              [path]="tableLine.user.image"
              [title]="tableLine.user.name"
              [alt]="tableLine.user.name"
              className="avatar-sm"
            >
            </app-image>
            <div>
              <span class="has-text-black is-regular">{{
                tableLine.user.name
              }}</span>
            </div>
          </div>
        </td>
        <td class="is-truncate">{{ tableLine.service.name }}</td>
        <ng-container *ngFor="let month of months">
          <td
            class="p-a-0 has-text-right booked-td"
            [ngClass]="{
              'is-success-light':
                tableLine.months[month.value] &&
                tableLine.months[month.value].monthOccupationRate > 0 &&
                tableLine.months[month.value].monthOccupationRate < 0.5,
              'is-warning-light':
                tableLine.months[month.value] &&
                tableLine.months[month.value].monthOccupationRate >= 0.5 &&
                tableLine.months[month.value].monthOccupationRate < 1,
              'is-danger-light':
                tableLine.months[month.value] &&
                tableLine.months[month.value].monthOccupationRate >= 1,
                'has-no-event': !canManageBookedWorks
            }"
          >
            <ng-container *ngIf="tableLine.months[month.value].daysOfWork">
              <span class="p-x-colgap">{{
                tableLine.months[month.value].daysOfWork
              }}</span>
              <div
                class="visible-on-hover absolute bg-white"
                *ngIf="canManageBookedWorks"
              >
                <a
                  class="button is-rounded is-link button--add m-x-xs"
                  (click)="
                    openEditModal(
                      tableLine.months[month.value].bookedWork,
                      month.value
                    )
                  "
                  ><i class="icon icon-edit-2 is-size-5"></i
                ></a>
                <a
                  class="button is-rounded is-danger button--add m-x-xs"
                  (click)="
                    bookedWorkToDelete =
                      tableLine.months[month.value].bookedWork
                  "
                  ><i class="icon icon-trash-2 is-size-5"></i
                ></a>
              </div>
            </ng-container>
            <ng-container *ngIf="!tableLine.months[month.value].daysOfWork">
              <div
                class="visible-on-hover"
                (click)="
                  openCreateModal(
                    tableLine.user.id,
                    tableLine.service.id,
                    month.value
                  )
                "
                *ngIf="canManageBookedWorks"
              >
                <a class="button is-rounded is-link button--add"
                  ><i class="icon icon-plus is-size-2"></i>
                </a>
              </div>
            </ng-container>
          </td>
        </ng-container>

        <td
          class="tooltip has-tooltip has-tooltip-left"
          data-tooltip="Supprimer toutes les réservations de cet utilisateur pour cette prestation"
          (click)="
            deleteTableLineConfirmationText =
              getDeleteConfirmationText(tableLine);
            tableLineToDelete = tableLine
          "
          *ngIf="canManageBookedWorks"
        >
          <a class="dropdown-item is-size-3 become-link">
            <i class="icon icon-trash-2 is-size-5"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<app-booked-work-create-edit-modal
  [project]="project"
  [definition]="bookedWorkDefinition"
  [fieldSpecialRules]="formRules"
  [mode]="createEditMode"
  [item]="bookedWorkToEdit"
  (closedModal)="showCreateEditModal = false"
  (submitSuccessful)="
    showCreateEditModal = false; getBookedWorks(); emitChanges()
  "
  *ngIf="showCreateEditModal && canManageBookedWorks"
></app-booked-work-create-edit-modal>

<abc-confirm-delete-modal
  [emitConfirmation]="true"
  [resourceToDelete]="bookedWorkToDelete"
  (deleteCanceled)="bookedWorkToDelete = null"
  (deleteConfirmed)="deleteBookedWork(bookedWorkToDelete)"
  *ngIf="bookedWorkToDelete && canManageBookedWorks"
></abc-confirm-delete-modal>

<abc-confirm-delete-modal
  [emitConfirmation]="true"
  [confirmationText]="deleteTableLineConfirmationText"
  [resourceToDelete]="tableLineToDelete"
  (deleteCanceled)="tableLineToDelete = null"
  (deleteConfirmed)="deleteTableLine(tableLineToDelete)"
  *ngIf="tableLineToDelete && canManageBookedWorks"
></abc-confirm-delete-modal>
