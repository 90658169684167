import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const serviceTypeDefinition: ResourceDefinition = {
  title: 'Types de prestation',
  nameSingular: 'type de prestation',
  namePlural: 'types de prestation',
  gender: Gender.Masculine,
  slug: 'service-types',
  path: 'types-de-prestation',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [
    {
      propName: 'serviceCount',
      preventDeleteMessage: `Certaines prestations sont attribués à ce type.`
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editServiceTypes'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteServiceTypes'
    }
  ],
  yields: [
    {
      label: 'Nom',
      property: 'name'
    }
  ]
}
