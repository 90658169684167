import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Service } from '../../service/service.interface'
import { timeSheetDefinition } from '../time-sheet.definition'
import { TimeSheet } from '../time-sheet.interface'

@Component({
  selector: 'app-time-sheet-create-edit-modal',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit-modal.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class TimeSheetCreateEditModalComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  @Input() projectId: number
  @Input() mode = 'create'
  @Input() fieldSpecialRules?: FieldSpecialRule[] = []
  @Input() item: TimeSheet

  @Output() closedModal: EventEmitter<void> = new EventEmitter()

  definition: ResourceDefinition = timeSheetDefinition
  isModal = true

  fields: Field[] = [
    {
      id: 'userId',
      label: 'Collaborateur',
      placeholder: `Choisissez le collaborateur concerné...`,
      properties: {
        userId: 'userId'
      },
      retrievedItemProperties: {
        userId: 'user.id'
      },
      searchResources: ['users'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12 p-x-0-mobile'
    },
    {
      id: 'projectId',
      label: 'Mission',
      placeholder: `Choisissez la mission...`,
      properties: {
        projectId: 'projectId'
      },
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      searchResources: ['projects'],
      searchParams: {
        nonFinishedProjectsOnly: '1',
        ownProjectsOnly: '1',
        notAbandonedProjectsOnly: '1'
      },
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12 p-x-0-mobile',
      onChange: async (newValue: { projectId: string }) => {
        // Fill serviceIds select with selected Project Services.
        const services: Service[] = await this.componentResourceService
          .list('services', {
            projectId: newValue.projectId
          })
          .then((res) => res)
        this.getFieldById('serviceId').selectOptions = services.map((s) => ({
          label: s.name,
          value: s.id.toString()
        }))
      }
    },
    {
      id: 'serviceId',
      label: `Prestation`,
      placeholder: 'Prestation...',
      properties: { value: 'serviceId' },
      retrievedItemProperties: {
        serviceId: 'service.id'
      },
      inputType: InputType.Select,
      className: 'is-12 p-x-0-mobile',
      validators: [Validators.required],
      selectOptions: () =>
        this.mode === 'edit' && this.item
          ? this.componentResourceService
              .list('services', {
                projectId: this.item.project.id
              })
              .then((res: Service[]) =>
                res.map((s) => ({
                  label: s.name,
                  value: s.id.toString()
                }))
              )
          : Promise.resolve([]),
      onChange: async (newValue: { value: string }) => {
        if (newValue.value) {
          // Fill deliverableIds select with selected Project Services.
          const deliverables: Service[] = await this.componentResourceService
            .list('deliverables', {
              withoutPagination: true,
              serviceId: newValue.value
            })
            .then((res) => res)
          this.getFieldById('deliverableIds').selectOptions = deliverables.map(
            (d) => ({
              label: d.type.name,
              value: d.id.toString()
            })
          )
        }
      }
    },
    {
      id: 'date',
      label: `Date d'intervention`,
      placeholder: `Date de l'intervention...`,
      properties: {
        value: 'date'
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      className: 'is-12  p-x-0-mobile'
    },
    {
      id: 'hoursOfWork',
      label: "Durée de l'intervention",
      placeholder: 'Indiquez la durée...',
      properties: {
        value: 'hoursOfWork'
      },
      inputType: InputType.TimeDuration,
      validators: [Validators.required],
      className: 'is-12  p-x-0-mobile'
    },
    {
      id: 'reference',
      label: 'Tâche',
      placeholder: 'Descriptif de la tâche...',
      properties: {
        value: 'reference'
      },
      inputType: InputType.Textarea,
      validators: [],
      className: 'is-12  p-x-0-mobile'
    },
    {
      id: 'deliverableIds',
      label: `Livrables associés`,
      placeholder: 'Livrables...',
      properties: { value: 'deliverableIds' },
      inputType: InputType.MultiSelect,
      className: 'is-12  p-x-0-mobile',
      validators: []
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }

  close() {
    this.closedModal.emit()
  }

  @HostListener('document:keydown.escape') onEnterKeydown() {
    this.close()
  }
}
