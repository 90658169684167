<div [formGroup]="form">
  <div class="control">
    <label for="select-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span>
    </label>
    <div class="control">
      <div class="select">
        <select
          id="select-input-{{ uniqueId }}"
          class="select"
          (change)="onSelect($event.target.value)"
          [ngClass]="{ 'is-danger': showErrors && form.invalid }"
          formControlName="select"
        >
          <option value="" selected [disabled]="required || readonly">
            {{ placeholder || 'Aucun filtre' }}
          </option>
          <option
            [value]="option.value"
            [selected]="initialValue && initialValue.value === option.value"
            [disabled]="option.disabled || readonly"
            *ngFor="let option of selectOptions"
          >
            {{ option.label }}
          </option>
        </select>
      </div>

      <p class="help" *ngIf="helpText">{{ helpText }}</p>
    </div>
  </div>
</div>
