import { Gender } from '../../../common/enums/gender.enum'
import { YieldType } from '../../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'

export const reportDefinition: ResourceDefinition = {
  title: 'Rapports',
  nameSingular: 'rapport',
  namePlural: 'rapports',
  gender: Gender.Masculine,
  slug: 'reports',
  path: 'reports',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [],
  defaultLink: null,
  yields: [
    {
      label: '',
      property: 'path',
      width: '10%',
      type: YieldType.FileIcon
    },
    {
      label: 'Date',
      width: '15%',
      property: 'createdAt',
      type: YieldType.Date
    },
    {
      label: 'Nom',
      property: 'name'
    },
    {
      label: '',
      property: 'path',
      width: 53,
      type: YieldType.Download
    }
  ]
}
