<!-- Heading -->
<div class="flex flex-mobile space-between align-center m-b-gutter">
  <div class="left-part">
    <h1 class="title is-2 is-light">Primes</h1>
  </div>
  <div class="right-part">
    <ng-container>
      <a
        *abcHasPermission="browseItemsPermissionName"
        class="button is-link m-l-gutter is-hidden-touch"
        routerLink="/primes"
        [queryParams]="{ toXLS: 'true' }"
        queryParamsHandling="merge"
      >
        Exporter
      </a>
    </ng-container>
  </div>
</div>

<!-- Filters -->
<section *ngIf="resolvedFilters && resolvedFilters.length">
  <div class="card">
    <div class="columns">
      <div class="column">
        <h2 class="title is-6 is-bold is-uppercase text-is-grey">Filtres</h2>
      </div>
    </div>
    <div class="columns flex flex-wrap">
      <div
        class="column"
        [ngClass]="filter.className"
        *ngFor="let filter of resolvedFilters"
      >
        <abc-input
          [type]="filter.inputType"
          [properties]="filter.properties"
          [initialValue]="filter.initialValue"
          [selectOptions]="filter.selectOptions"
          [showClearDateButton]="filter.showClearDateButton"
          [label]="filter.label"
          [searchResources]="filter.searchResources"
          [placeholder]="filter.placeholder"
          [validators]="filter.validators || []"
          (valueChanged)="onFilterValueChanged($event, filter)"
        ></abc-input>
      </div>
    </div>
  </div>
</section>

<!-- List -->
<ng-container *ngIf="paginator">
  <div class="flex space-between align-center relative">
    <abc-meta [paginator]="paginator"></abc-meta>
  </div>
  <div class="card m-b-gutter" *ngFor="let user of paginator.data">
    <div class="flex space-between align-center">
      <h2 class="m-y-md title is-6 is-bold is-uppercase text-is-grey">
        {{ user.name }}
      </h2>
    </div>

    <div class="has-table p-a-0">
      <div class="table-responsive">
        <abc-table
          [items]="user.bonuses"
          [definition]="definition"
          [orderBy]="orderBy"
          [orderByDesc]="orderByDesc"
          (orderByChanged)="onOrderByChanged($event)"
          (reloadPrompted)="reload()"
        ></abc-table>
      </div>
    </div>
  </div>

  <abc-pagination
    [paginator]="paginator"
    (pageChange)="onPageChanged($event)"
  ></abc-pagination>
</ng-container>

<div *ngIf="loading" class="list-loading p-t-xl">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
</div>
