<form [formGroup]="form">
  <div class="columns">
    <div class="column">
      <h4 class="title is-4 is-light">
        Création / modification d'une prestation
      </h4>
    </div>
  </div>

  <!-- Service props. -->
  <div class="columns">
    <div class="column is-8">
      <div class="control">
        <label for="name-input"
          >Nom de la prestation <span class="has-text-danger">*</span></label
        >
        <input
          class="input form-control"
          formControlName="name"
          placeholder="Nom de la prestation..."
          id="name-input"
          type="text"
          #nameInput
          [readonly]="isReadonly"
        />
      </div>
    </div>
    <div class="column is-3">
      <div class="control">
        <label for=""
          >Type de prestation <span class="has-text-danger">*</span></label
        >
        <div class="select">
          <select
            name="service-type-select"
            class="select"
            formControlName="serviceTypeId"
          >
            <option value="" disabled selected>Choisir le type</option>
            <option
              *ngFor="let serviceType of serviceTypes"
              [value]="serviceType.value"
            >
              {{ serviceType.label }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div
      class="column is-2 flex align-end justify-start"
      *abcHasPermission="'addServiceTypes'"
    >
      <a
        (click)="
          this.showCreateServiceTypeModal = !this.showCreateServiceTypeModal
        "
        class="button is-rounded is-link button--add"
        *ngIf="!isReadonly"
      >
        <i class="icon icon-plus is-size-2"></i>
      </a>
    </div>
  </div>
  <div class="columns">
    <div class="column is-11">
      <div class="control">
        <label for="name-input">Description</label>

        <textarea
          name="textarea"
          cols="3"
          rows="7"
          class="input form-control textarea"
          placeholder="Description..."
          formControlName="description"
          [readonly]="isReadonly"
        ></textarea>
      </div>
    </div>
  </div>

  <div class="detailed-box">
    <!-- Estimated work. -->
    <div class="columns">
      <div class="column">
        <h4 class="title is-6 has-text-black is-uppercase is-bold">
          Chiffrage
        </h4>
      </div>
    </div>

    <!-- FlatFeePricing radio buttons -->
    <div class="columns">
      <div class="column is-6">
        <div
          class="flex align-center justify-start"
          [ngStyle]="{ 'pointer-events': isReadonly ? 'none' : 'auto' }"
        >
          <div class="control">
            <label class="radio m-r-xxl">
              <input
                type="radio"
                [value]="false"
                class="radio"
                formControlName="flatFeePricing"
                (click)="setAmountToZero()"
              />
              Chiffrage par profil
              <span class="checkmark"></span>
            </label>
          </div>

          <div class="control">
            <label class="radio">
              <input
                type="radio"
                [value]="true"
                formControlName="flatFeePricing"
              />
              Prestation packagée
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Relative pricing: Estimated works -->
    <app-service-estimated-work
      *ngIf="form.value.flatFeePricing === false"
      [estimatedWorks]="form.value.estimatedWorks"
      [isReadonly]="isReadonly"
      [pricingYear]="pricingYear"
      (estimatedWorkChanged)="onEstimatedWorkChanged($event)"
    ></app-service-estimated-work>

    <!-- Flat fee pricing : Amount -->
    <label for="flat-free-pricing-amount" *ngIf="form.value.flatFeePricing"
      >Montant <span class="has-text-danger">*</span></label
    >
    <input
      id="flat-free-pricing-amount"
      class="input"
      type="number"
      step="0.01"
      formControlName="amount"
      *ngIf="form.value.flatFeePricing"
      [readonly]="isReadonly"
    />
  </div>

  <!-- Deliverables. -->
  <div class="detailed-box m-t-gutter">
    <!-- Deliverables. -->
    <div class="columns">
      <div class="column">
        <h4 class="title is-6 has-text-black is-uppercase is-bold">
          Livrables
        </h4>
      </div>
    </div>
    <app-service-deliverable
      [deliverables]="form.value.deliverables"
      [isReadonly]="isReadonly"
      (deliverableChanged)="onDeliverableChanged($event)"
    ></app-service-deliverable>
  </div>
</form>

<!-- Create ServiceType modal -->
<div
  class="modal"
  [ngClass]="{ 'is-active': !isReadonly && showCreateServiceTypeModal }"
>
  <div class="modal-background"></div>
  <div class="modal-content notification is-light">
    <div class="columns">
      <div class="column">
        <h4 class="title is-4">Ajouter un type de prestation</h4>
        <div class="control">
          <abc-input
            [type]="InputType.Text"
            label="Nom"
            (valueChanged)="newServiceType = $event.value"
          ></abc-input>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column m-t-colgap p-b-0">
        <div class="flex align-stretch flex-mobile space-between">
          <button
            class="button"
            (click)="
              this.showCreateServiceTypeModal = !this.showCreateServiceTypeModal
            "
          >
            Annuler
          </button>
          <button
            class="button is-primary"
            (click)="
              this.showCreateServiceTypeModal =
                !this.showCreateServiceTypeModal;
              saveServiceType(newServiceType)
            "
          >
            Sauvegarder
          </button>
        </div>
      </div>
    </div>
    <button
      class="button is-light close"
      aria-label="close"
      (click)="
        this.showCreateServiceTypeModal = !this.showCreateServiceTypeModal
      "
    >
      <i class="icon icon-x is-size-2"></i>
    </button>
  </div>
</div>
