<div *ngIf="reports?.length" class="top-margin-none">
  <div class="has-table p-a-0">
    <div class="table-responsive">
      <abc-table
        [items]="reports"
        [definition]="reportDefinition"
        [allowOrderBy]="false"
      ></abc-table>
    </div>
  </div>
</div>
