import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn
} from '@angular/forms'
import { IAngularMyDpOptions, IMyDateModel } from '@nodro7/angular-mydatepicker'
import moment, { Moment } from 'moment'

import { DatepickerComponent } from '../../../base-components/datepicker.component'
import { AbcInput } from '../../../interfaces/abc-input.interface'

@Component({
  selector: 'abc-date-range-input',
  templateUrl: './date-range-input.component.html',
  styleUrls: ['./date-range-input.component.scss']
})
export class DateRangeInputComponent
  extends DatepickerComponent
  implements AbcInput, OnChanges
{
  @Input() label: string
  @Input() helpText: string
  // Accepts YYYY-MM-DD formatted dates
  @Input() initialValue: { dateFrom: string; dateTo: string }
  @Input() placeholder: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() copyDateFromOnDateTo = false

  @Output() valueChanged: EventEmitter<{
    dateFrom: string
    dateTo: string
  }> = new EventEmitter()

  dateFromOptions: IAngularMyDpOptions
  dateToOptions: IAngularMyDpOptions

  outputValues: { dateFrom: string; dateTo: string } = {
    dateFrom: null,
    dateTo: null
  }

  form: UntypedFormGroup = this.formBuilder.group({
    dateFrom: null,
    dateTo: null
  })

  constructor(private formBuilder: UntypedFormBuilder) {
    super()
    this.dateFromOptions = JSON.parse(JSON.stringify(this.datePickerOptions))
    this.dateToOptions = JSON.parse(JSON.stringify(this.datePickerOptions))
  }

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    if (this.initialValue) {
      this.form.setValue({
        dateFrom: this.initialValue.dateFrom
          ? this.formatStandardDate(this.initialValue.dateFrom)
          : null,
        dateTo: this.initialValue.dateTo
          ? this.formatStandardDate(this.initialValue.dateTo)
          : null
      })

      this.outputValues = {
        dateFrom: this.initialValue.dateFrom || null,
        dateTo: this.initialValue.dateTo || null
      }

      this.disableBeforeAndAfter(
        this.outputValues.dateFrom,
        this.outputValues.dateTo
      )
    }
  }

  // Emits YYYY-MM-DD date or or null if date was removed.
  onDateChanged(event: IMyDateModel, propName: string) {
    let wasNull: boolean
    if (
      this.outputValues.dateFrom === null &&
      this.outputValues.dateTo === null
    ) {
      wasNull = true
    }

    const newDate: string = event.singleDate.jsDate
      ? this.formatMyDatePickerDate(event)
      : null

    this.outputValues[propName] = newDate
    this.form.get(propName).setValue(newDate)

    if (this.copyDateFromOnDateTo && wasNull && this.outputValues.dateFrom) {
      this.form.patchValue({
        dateTo: {
          date: {
            year: event.singleDate.date.year,
            month: event.singleDate.date.month,
            day: event.singleDate.date.day
          }
        }
      })
      this.outputValues.dateTo = newDate
    }

    this.disableBeforeAndAfter(
      this.outputValues.dateFrom,
      this.outputValues.dateTo
    )

    this.valueChanged.emit(this.outputValues)
  }

  // Prevent that dateTo is before dateFrom.
  disableBeforeAndAfter(dateFrom: string, dateTo: string) {
    if (dateFrom) {
      const momentDateFrom: moment.Moment = moment(
        dateFrom,
        'YYYY-MM-DD'
      ).subtract(1, 'd')
      const dateToCopy = this.getCopyOfOptions('dateTo')

      dateToCopy.disableUntil = {
        year: parseInt(momentDateFrom.format('YYYY'), 10),
        month: parseInt(momentDateFrom.format('MM'), 10),
        day: parseInt(momentDateFrom.format('DD'), 10)
      }

      this.dateToOptions = dateToCopy
    }

    if (dateTo) {
      const momentDateTo: moment.Moment = moment(dateTo, 'YYYY-MM-DD').add(
        1,
        'd'
      )

      const dateFromCopy = this.getCopyOfOptions('dateFrom')
      dateFromCopy.disableSince = {
        year: parseInt(momentDateTo.format('YYYY'), 10),
        month: parseInt(momentDateTo.format('MM'), 10),
        day: parseInt(momentDateTo.format('DD'), 10)
      }

      this.dateFromOptions = dateFromCopy
    }
  }

  // Only way to change options dynamically : https://github.com/kekeh/ngx-mydatepicker/issues/99.
  getCopyOfOptions(propName: string): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this[propName + 'Options']))
  }
}
