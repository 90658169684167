<!-- Heading -->
<div class="flex flex-mobile space-between align-center m-b-gutter">
  <div class="left-part">
    <h1 class="title is-2 is-light">
      {{ definition.title }}
    </h1>
  </div>

  <div class="right-part">
    <!-- Export button -->
    <ng-container
      *ngIf="
        definition.buttons.indexOf(LinkType.EXPORT) > -1 &&
        browseItemsPermissionName
      "
    >
      <a
        *abcHasPermission="browseItemsPermissionName"
        class="button is-link m-l-gutter is-hidden-touch"
        routerLink="/{{ definition.path || definition.slug }}"
        [queryParams]="{ toXLS: 'true' }"
        queryParamsHandling="merge"
      >
        Exporter
      </a>
    </ng-container>
  </div>
</div>

<!-- Filters -->
<section *ngIf="resolvedFilters && resolvedFilters.length">
  <div class="card">
    <div class="columns">
      <div class="column">
        <h2 class="title is-6 is-bold is-uppercase text-is-grey">Filtres</h2>
      </div>
    </div>
    <div class="columns flex flex-wrap">
      <div
        class="column"
        [ngClass]="filter.className"
        *ngFor="let filter of resolvedFilters"
      >
        <abc-input
          [type]="filter.inputType"
          [properties]="filter.properties"
          [initialValue]="filter.initialValue"
          [selectOptions]="filter.selectOptions"
          [label]="filter.label"
          [searchResources]="filter.searchResources"
          [placeholder]="filter.placeholder"
          [validators]="filter.validators"
          (valueChanged)="onFilterValueChanged($event, filter)"
        ></abc-input>
      </div>
    </div>
  </div>
</section>
<div class="columns m-t-lg" *ngIf="paginator">
  <div class="column is-6">
    <div class="flex align-center full-height">
      <h3 class="title is-5 m-y-0">
        CA réalisé sur l'année {{ yearFilter }}
        <ng-container *ngIf="entityFilter === 'Customer'"
          >par client</ng-container
        >
        <ng-container *ngIf="entityFilter === 'Department'"
          >par practice</ng-container
        >
      </h3>
    </div>
  </div>
  <div class="column is-6">
    <!-- Key numbers -->
    <div class="has-text-right">
      <div class="flex align-center flex-end">
        <span
          class="badge"
          [ngClass]="keyNumber.className"
          *ngFor="let keyNumber of definition.keyNumbers"
        >
          <ng-container *ngIf="keyNumber.loading">Loading...</ng-container>
          <ng-container *ngIf="!keyNumber.loading"
            >{{ keyNumber.label }} :&nbsp;

            <abc-currency-yield
              *ngIf="keyNumber.type === YieldType.Currency"
              [amount]="keyNumber.value"
            ></abc-currency-yield>

            <abc-number-yield
              *ngIf="keyNumber.type === YieldType.Number"
              [value]="keyNumber.value"
            ></abc-number-yield>

            <abc-percentage-yield
              *ngIf="keyNumber.type === YieldType.Percentage"
              [value]="keyNumber.value"
            ></abc-percentage-yield>
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="columns is-multiline" *ngIf="paginator">
  <div class="column is-12">
    <!-- Table -->
    <div class="card p-b-colgap m-t-0 full-height has-table">
      <div>
        <div class="table-responsive">
          <abc-table
            [items]="paginator.data"
            [definition]="definition"
            [orderBy]="orderBy"
            [orderByDesc]="orderByDesc"
            (orderByChanged)="onOrderByChanged($event)"
            (reloadPrompted)="reload()"
          ></abc-table>
        </div>
      </div>
    </div>

    <abc-pagination
      [paginator]="paginator"
      (pageChange)="onPageChanged($event)"
    ></abc-pagination>
  </div>

  <div class="column is-12">
    <div class="card p-b-colgap m-t-0 full-height flax align-center">
      <div class="p-t-gutter">
        <app-business-review-chart
          [measurableEntity]="paginator.data"
        ></app-business-review-chart>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading" class="list-loading p-t-xl">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
  <div class="is-hidden-touch"></div>
</div>
