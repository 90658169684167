import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'

import { environment } from '../../../environments/environment'
import { MetaObject } from '../interfaces/meta-object.interface'

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  appName: string = environment.isAltVersion ? 'Zèlè SGE' : 'Zèle'

  constructor(private title: Title, private meta: Meta) {}

  defaultMeta: MetaObject = {
    title: this.appName,
    description: `Application de gestion d'activité`,
    path: '',
    ogImage: 'assets/images/logo.png'
  }

  setTags(metaObject: MetaObject): void {
    this.title.setTitle(
      `${metaObject.title || this.defaultMeta.title} | ${this.appName}`
    )
    this.meta.addTags([
      {
        name: 'description',
        content: metaObject.description || this.defaultMeta.description
      },
      {
        name: 'og:description',
        content: metaObject.description || this.defaultMeta.description
      },
      {
        name: 'og:title',
        content: metaObject.title || this.defaultMeta.title
      },
      {
        name: 'og:url',
        content: environment.baseUrl + metaObject.path
      },
      {
        name: 'og:image',
        content:
          environment.baseUrl + (metaObject.ogImage || this.defaultMeta.ogImage)
      }
    ])
  }
}
