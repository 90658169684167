import { Gender } from '../../../common/enums/gender.enum'
import { YieldType } from '../../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { Bonus } from '../../project/bonus.interface'

export const bonusDefinition: ResourceDefinition = {
  title: 'Primes',
  nameSingular: 'prime',
  namePlural: 'primes',
  hasDetailPage: true,
  hasListPage: true,
  gender: Gender.Masculine,
  slug: 'projects',
  path: 'primes',
  buttons: [],
  defaultLink: null,
  yields: [
    {
      label: 'Mission (N° - Référence)',
      property: 'project.department.color',
      className: 'has-mini-circle',
      secondProperty: 'project.label',
      orderByProperty: 'project.name',
      type: YieldType.Color,
      link: (bonus: Bonus) => `missions/${bonus.project.id}`
    },
    {
      label: 'Factures reliées',
      property: 'project.invoiceNumberList'
    },
    {
      label: 'Client',
      property: 'project.customer.name',
      link: (bonus: Bonus) =>
        bonus.project?.customer ? `clients/${bonus.project.customer.id}` : null
    },
    {
      label: 'Taux de prime',
      tooltip: `La prime du projet peut éventuellement être partagée avec d'autres collaborateurs. Dans ce cas, le calcul est détaillé`,
      property: 'detailedPercentage',
      headingClassName: 'has-text-right',
      className: 'has-text-right'
    },
    {
      label: 'Montant',
      tooltip:
        'Factures validées dans la periode sélectionnée x Taux de prime du collaborateur (envenuellement pondérée du pourcentage de prime individuel si prime partagée) ',
      property: 'amount',
      type: YieldType.Currency,
      headingClassName: 'has-text-right',
      className: 'has-text-right'
    }
  ]
}
