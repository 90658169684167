import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { referentDefinition } from '../referent.definition'

@Component({
  selector: 'app-referent-create-edit',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class ReferentCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = referentDefinition

  fields: Field[] = [
    {
      label: 'Nom du contact',
      placeholder: 'Nom du contact...',
      properties: {
        value: 'name'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'is-6 p-x-0-mobile'
    },
    {
      label: 'Client',
      placeholder: `Choisissez le client...`,
      properties: {
        customerId: 'customerId'
      },
      retrievedItemProperties: {
        customerId: 'customer.id'
      },
      searchResources: ['customers'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-6 p-x-0-mobile'
    },
    {
      label: 'Poste',
      placeholder: 'Poste...',
      properties: {
        value: 'position'
      },
      inputType: InputType.Text,
      validators: [],
      className: 'is-6 p-x-0-mobile'
    },
    {
      label: 'Télephone',
      placeholder: 'Télephone...',
      properties: {
        value: 'phone'
      },
      inputType: InputType.Tel,
      validators: [],
      className: 'is-6 p-x-0-mobile'
    },
    {
      label: 'E-mail',
      placeholder: 'E-mail...',
      properties: {
        value: 'email'
      },
      inputType: InputType.Tel,
      validators: [],
      className: 'is-6 p-x-0-mobile'
    },
    {
      label: 'Actif',
      helpText: `Seuls les contacts actifs peuvent être associés à une nouvelle mission.`,
      properties: { value: 'isActive' },
      initialValue: { value: true },
      className: 'p-x-0-mobile is-6 aligned-checkbox',
      inputType: InputType.Checkbox,
      validators: []
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
