import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const positionDefinition: ResourceDefinition = {
  title: 'Profils',
  nameSingular: 'profil',
  namePlural: 'profils',
  gender: Gender.Masculine,
  slug: 'positions',
  path: 'profils',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [
    {
      propName: 'userCount',
      preventDeleteMessage: `Certains collaborateurs lui sont attribués. Veuillez les supprimer ou ré-affecter à un autre profil avant.`
    },
    {
      propName: 'estimatedCount',
      preventDeleteMessage: `Certaines estimations sont basées sur ce profil et de son tarif journalier.`
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editPositions'
    },
    {
      type: LinkType.DELETE,
      permission: 'deletePositions'
    }
  ],
  yields: [
    {
      label: '',
      property: 'color',
      type: YieldType.Color,
      width: 50
    },
    {
      label: 'Nom',
      property: 'name'
    },
    {
      label: 'Tarif journalier',
      property: 'referenceYearDailyRate',
      tooltip: `Année de tarification spécifiée dans le filtre ci-dessus`,
      type: YieldType.Currency
    }
  ]
}
