import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { ProjectStatus } from '../project/project-status.enum'
import { Invoice } from './invoice.interface'

export const invoiceDefinition: ResourceDefinition = {
  title: 'Factures',
  nameSingular: 'facture',
  namePlural: 'fatures',
  gender: Gender.Feminine,
  slug: 'invoices',
  path: 'factures',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: null,
  childrenThatPreventDelete: [
    {
      propName: 'payments',
      preventDeleteMessage: `Certains paiements ont déjà été effectués pour cette facture`
    },
    {
      propName: 'credits',
      preventDeleteMessage: `Un ou plusieurs avoirs a été réalisé pour cette facture. Veuillez les supprimer d'abord`
    },
    {
      propName: 'expenseCount',
      preventDeleteMessage: `Une ou plusieurs dépenses sont reliées à cette facture`
    }
  ],
  actionButtons: [
    {
      label: 'Valider',
      className: 'button is-primary p-x-lg',
      permission: 'validateInvoices',
      condition: (i: Invoice) =>
        !i.validated &&
        (i.project.status === ProjectStatus.Signed ||
          i.project.status === ProjectStatus.Finished),
      patchAction: (i: Invoice) => ({
        resourceName: 'invoices',
        id: i.id,
        suffix: 'validate',
        successMessage: `La facture ${i.number} a bien été validé.`,
        errorMessage: `Une erreur a eu lieu lors de la validation de la facture ${i.number}.`
      })
    },
    {
      label: 'Ajouter un paiement',
      className: 'button is-info p-x-lg',
      permission: 'validateInvoices',
      condition: (i: Invoice) => i.validated && i.paymentPercentage < 1,
      linkAction: (i: Invoice) => ({
        path: `/missions/${i.project.id}`,
        queryParams: {
          selectedTab: 'accounting',
          invoiceToPay: i.id,
          reload: new Date().toISOString()
        }
      })
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.DUPLICATE,
      permission: 'addInvoices',
      condition: (i: Invoice) => !!i.project.customer
    },
    {
      type: LinkType.EDIT,
      permission: 'editInvoices',
      label: 'Editer',
      condition: (i: Invoice) => !i.validated,
      linkAction: (i: Invoice) => ({
        path: `/factures/${i.id}/edit`
      })
    },
    {
      type: LinkType.CUSTOM,
      permission: 'editInvoices',
      label: 'Ajouter un commentaire',
      linkAction: (i: Invoice) => ({
        path: `/missions/${i.project.id}`,
        queryParams: {
          selectedTab: 'accounting',
          invoiceToComment: i.id,
          reload: new Date().toISOString()
        }
      })
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteInvoices',
      condition: (i: Invoice) => !i.validated
    },
    {
      type: LinkType.CUSTOM,
      permission: 'deleteInvoices',
      label: 'Supprimer un paiement',
      condition: (i: Invoice) => !!i.paymentDates,
      linkAction: (i: Invoice) => ({
        path: `/missions/${i.project.id}`,
        queryParams: {
          selectedTab: 'accounting',
          invoiceToPay: i.id,
          reload: new Date().toISOString()
        }
      })
    }
  ],
  yields: [
    {
      label: 'Numéro',
      property: 'number'
    },
    {
      label: 'Libellé',
      property: 'reference'
    },
    {
      label: 'Mission ',
      property: 'project.label',
      link: (i) => `missions/${i.project.id}`
    },
    {
      label: 'Client',
      property: 'project.customer.name',
      orderByProperty: 'customer.name',
      link: (invoice: Invoice) =>
        invoice.project.customer
          ? `clients/${invoice.project.customer.id}`
          : null
    },
    {
      label: `Date d'émission`,
      property: 'issueDate',
      type: YieldType.Date
    },
    {
      label: `Date d'échéance`,
      property: 'dueDate',
      type: YieldType.Date
    },
    {
      label: 'Montant HT',
      property: 'amount',
      type: YieldType.Currency
    },
    {
      label: 'Montant TTC',
      property: 'amountWithTaxes',
      type: YieldType.Currency
    },
    {
      label: 'Budget',
      property: 'budget.name'
    },
    {
      label: 'Reglement',
      headingClassName: 'has-text-right',
      property: 'paymentPercentage',
      type: YieldType.AnalogProgressBar,
      disableOrderBy: true
    },
    {
      label: '',
      property: 'paymentDates'
    },
    {
      label: '',
      property: 'path',
      type: YieldType.Download
    },
    {
      label: '',
      property: 'comments',
      type: YieldType.Icon
    }
  ]
}
