import { Component, Input, OnInit } from '@angular/core'
import { ChartOptions, TooltipItem } from 'chart.js'
import { forkJoin } from 'rxjs'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../common/services/auth.service'
import { ResourceService } from '../../../common/services/resource.service'
import { BookedWork } from '../../booked-work/booked-work.interface'
import { PlannedWork } from '../../planned-work/planned-work.interface'
import { TimeSheet } from '../../time-sheet/time-sheet.interface'
import { User } from '../../user/user.interface'

@Component({
  selector: 'app-project-user-chart',
  templateUrl: './project-user-chart.component.html',
  styleUrls: ['./project-user-chart.component.scss']
})
export class ProjectUserChartComponent implements OnInit {
  @Input() projectId: number

  currentUser: User

  plannedWorkTotalDaysOfWork: number
  bookedWorkTotalDaysOfWork: number
  timeSheetTotalDaysOfWork: number
  remainingDaysOfWork: number

  chartOptions: ChartOptions<'bar'> = {
    indexAxis: 'y',
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<any>) =>
            `${this.chartData[context.datasetIndex].label}: ${
              context.formattedValue
            } jours`
        },
        backgroundColor: '#303032',
        titleFont: {
          size: 13
        },
        bodyFont: {
          size: 13
        },
        displayColors: false,
        cornerRadius: 2
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grace: '5%',
        ticks: {
          callback: (value: number) => `${value} j`
        }
      },
      y: {
        type: 'category',
        grid: {
          display: false
        },
        ticks: {
          display: false
        }
      }
    }
  }

  chartData: any[]

  constructor(
    private resourceService: ResourceService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes

      forkJoin([
        this.resourceService.list('planned-works', {
          projectId: this.projectId,
          userId: userRes.id
        }),
        this.resourceService.list('booked-works', {
          projectId: this.projectId,
          userId: userRes.id
        }),
        this.resourceService.list('time-sheets', {
          projectId: this.projectId,
          userId: userRes.id,
          withoutPagination: true
        })
      ]).subscribe(
        ([plannedWorkRes, bookedWorkRes, timeSheetRes]: [
          PlannedWork[],
          BookedWork[],
          TimeSheet[]
        ]) => {
          this.plannedWorkTotalDaysOfWork = plannedWorkRes.reduce(
            (sum, curr) => sum + curr.daysOfWork,
            0
          )
          this.bookedWorkTotalDaysOfWork = bookedWorkRes.reduce(
            (sum, curr) => sum + curr.daysOfWork,
            0
          )
          this.timeSheetTotalDaysOfWork = timeSheetRes.reduce(
            (sum, curr) => sum + curr.daysOfWork,
            0
          )

          this.remainingDaysOfWork =
            this.bookedWorkTotalDaysOfWork -
            this.timeSheetTotalDaysOfWork -
            this.plannedWorkTotalDaysOfWork

          const transparent = 'rgba(0,0,0,0)'
          this.chartData = [
            {
              label: 'Reservé',
              data: [this.bookedWorkTotalDaysOfWork],
              backgroundColor: environment.isAltVersion ? '#4E5CA6' : '#5C1EE8',
              hoverBackgroundColor: environment.isAltVersion
                ? '#4E5CA6'
                : '#5C1EE8',
              stack: '1',
              borderColor: transparent,
              hoverBorderColor: transparent,
              borderRadius: 5,
              maxBarThickness: 8,
              
              categoryPercentage: 0.7,
              barPercentage: 0.2
            },
            {
              label: 'Time sheet',
              data: [this.timeSheetTotalDaysOfWork],
              backgroundColor: '#1EB1E8',
              hoverBackgroundColor: '#1EB1E8',
              stack: '2',
              borderColor: transparent,
              hoverBorderColor: transparent,
              borderRadius: 5,
              maxBarThickness: 8,
              
              categoryPercentage: 0.7,
              barPercentage: 0.2
            },
            {
              label: 'Planifié',
              data: [this.plannedWorkTotalDaysOfWork],
              backgroundColor: '#2EFFCA',
              hoverBackgroundColor: '#2EFFCA',
              stack: '2',
              borderColor: transparent,
              hoverBorderColor: transparent,
              borderRadius: 5,
              maxBarThickness: 8,
              
              categoryPercentage: 0.7,
              barPercentage: 0.2
            },
            {
              label: 'Temps restant',
              data:
                this.remainingDaysOfWork > 0 ? [this.remainingDaysOfWork] : [],
              backgroundColor: '#dfe5ff',
              hoverBackgroundColor: '#dfe5ff',
              stack: '2',
              borderColor: transparent,
              hoverBorderColor: transparent,
              borderRadius: 5,
              maxBarThickness: 8,
              
              categoryPercentage: 0.7,
              barPercentage: 0.2
            }
          ]
        }
      )
    })
  }
}
