import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'

import { InputType } from '../../../common/enums/input-type.enum'
import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Deliverable } from '../../deliverable/deliverable.interface'

@Component({
  selector: 'app-service-deliverable',
  templateUrl: './service-deliverable.component.html',
  styleUrls: ['./service-deliverable.component.scss']
})
export class ServiceDeliverableComponent implements OnInit {
  @Input() deliverables: Deliverable[]
  @Input() isReadonly: boolean

  @Output() deliverableChanged: EventEmitter<
    { id: number; typeId: string | number }[]
  > = new EventEmitter()

  deliverableTypes: SelectOption[]
  showCreateDeliverableTypeModal = false
  newDeliverableType: string
  InputType = InputType

  form: UntypedFormGroup = this.formBuilder.group({
    deliverables: new UntypedFormArray([])
  })

  get deliverableFormArray(): UntypedFormArray {
    return this.form.get('deliverables') as UntypedFormArray
  }

  constructor(
    private resourceService: ResourceService,
    private formBuilder: UntypedFormBuilder,
    private flashMessageService: FlashMessageService,
    private renderer: Renderer2
  ) {}

  async ngOnInit() {
    this.deliverableTypes = await this.resourceService.listSelectOptions(
      'deliverable-types'
    )

    this.deliverables.forEach((d: Deliverable) => {
      this.deliverableFormArray.push(
        this.formBuilder.group({
          id: d.id,
          typeId: d.typeId
        })
      )
    })

    if (this.isReadonly) {
      this.deliverableFormArray.disable()
    }

    this.form.valueChanges.subscribe(
      (newValues: {
        deliverables: { id: number; typeId: string | number }[]
      }) => {
        this.deliverableChanged.emit(newValues.deliverables)
      }
    )
  }

  addDeliverable() {
    this.deliverableFormArray.push(
      this.formBuilder.group({
        id: null,
        typeId: this.deliverableTypes[0].value
      })
    )
  }

  removeDeliverable(index: number) {
    this.deliverableFormArray.removeAt(index)
  }

  storeDeliverableType(name: string) {
    this.resourceService
      .store('deliverable-types', { name })
      .subscribe(async (res) => {
        this.flashMessageService.success(
          'Le livrable a bien été créé. Vous pouvez maintenant le sélectionner dans la liste.'
        )
        this.deliverableTypes = await this.resourceService.listSelectOptions(
          'deliverable-types'
        )
      })
  }

  toggleModal() {
    if (!this.showCreateDeliverableTypeModal) {
      this.renderer.addClass(
        document.querySelector('.module-item'),
        'has-open-modal'
      )
    } else {
      this.renderer.removeClass(
        document.querySelector('.module-item'),
        'has-open-modal'
      )
    }
    this.showCreateDeliverableTypeModal = !this.showCreateDeliverableTypeModal
  }
}
