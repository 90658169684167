<div class="p-a-gutter">
  <div class="columns">
    <div class="column is-6">
      <!-- Date filters -->
      <abc-input
        [type]="InputType.DateRange"
        [initialValue]="{
          dateFrom: form.value.dateFrom,
          dateTo: form.value.dateTo
        }"
        label="Date de validation des factures."
        [showClearDateButton]="false"
        helpText="Seules les factures réglées à 100% durant la période sélectionnée sont prises en compte pour le calcul des montants."
        (valueChanged)="getBonuses($event.dateFrom, $event.dateTo)"
      ></abc-input>
    </div>
    <div class="column is-6 has-text-right" *ngIf="totalBonusAmount">
      <div class="flex align-center flex-end">
        <span class="badge m-y-0 is-success"
          >Total prime :&nbsp;{{ totalBonusAmount | euros }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="has-table p-a-0">
  <div class="table-responsive" *ngIf="projects">
    <abc-table
      [items]="projects"
      [definition]="bonusDefinition"
      [allowOrderBy]="false"
    ></abc-table>
  </div>
</div>
