<div [formGroup]="form">
  <div class="control">
    <label for="number-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >
    <input
      type="time"
      id="time-input-{{ uniqueId }}"
      class="input form-control"
      [ngClass]="{ 'is-danger': showErrors && form.invalid }"
      formControlName="time"
      (keyup)="onChange($event.target.value)"
    />
    <p class="help" *ngIf="helpText">{{ helpText }}</p>
  </div>
</div>
