import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { holidayDefinition } from '../holiday.definition'
import { TimeSheet } from '../time-sheet.interface'

@Component({
  selector: 'app-holiday-create-edit-modal',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit-modal.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class HolidayCreateEditModalComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  @Input() projectId: number
  @Input() mode = 'create'
  @Input() fieldSpecialRules?: FieldSpecialRule[] = []
  @Input() item: TimeSheet

  @Output() closedModal: EventEmitter<void> = new EventEmitter()

  definition: ResourceDefinition = holidayDefinition
  isModal = true

  fields: Field[] = [
    {
      id: 'userId',
      label: 'Collaborateur',
      placeholder: `Choisissez le collaborateur concerné...`,
      properties: {
        userId: 'userId'
      },
      retrievedItemProperties: {
        userId: 'user.id'
      },
      searchResources: ['users'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12'
    },
    {
      label: `Dates`,
      properties: {
        dateFrom: 'startDate',
        dateTo: 'endDate'
      },
      inputType: InputType.DateRange,
      className: 'is-12 is-display-mobile',
      validators: [Validators.required],
      copyDateFromOnDateTo: true
    },
    {
      label: 'Demi journée seulement',
      properties: {
        value: 'isHalfDay'
      },
      inputType: InputType.Checkbox,
      validators: [],
      className: 'is-12 '
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }

  close() {
    this.closedModal.emit()
  }

  @HostListener('document:keydown.escape') onEnterKeydown() {
    this.close()
  }
}
