import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const departmentDefinition: ResourceDefinition = {
  title: 'Practices',
  nameSingular: 'practice',
  namePlural: 'practices',
  gender: Gender.Masculine,
  slug: 'departments',
  path: 'practices',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [
    {
      propName: 'projectCount',
      preventDeleteMessage: `Certaines missions sont attribuéés à ce practice.`
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editDepartments'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteDepartments'
    }
  ],
  yields: [
    {
      label: '',
      property: 'color',
      type: YieldType.Color,
      width: 50
    },
    {
      label: 'department',
      property: 'name'
    },
    {
      label: 'Actif',
      property: 'isActive',
      type: YieldType.Check
    },
    {
      label: 'Pondérable',
      property: 'isPonderable',
      type: YieldType.Check
    }
  ]
}
