import { Gender } from '../../common/enums/gender.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const bookedWorkDefinition: ResourceDefinition = {
  title: 'Réservations',
  nameSingular: 'réservation',
  namePlural: 'réservations',
  gender: Gender.Feminine,
  slug: 'booked-works',
  path: 'réservations',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [],
  defaultLink: null,
  yields: []
}
