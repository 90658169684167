<!-- Results -->
<div *ngIf="loading">En chargement</div>

<section>
  <div class="columns">
    <div class="column is-12-mobile is-6-tablet">
      <abc-input
        [type]="InputType.DateRange"
        [initialValue]="dateRange"
        label="Période"
        (valueChanged)="onDateRangeChanged($event)"
      ></abc-input>
      <ul *ngIf="timeSheets && projects && !loading">
        <li class="flex space-between align-center list-separated flex-mobile">
          <div>
            <span class="m-l-sm">Jours disponibles :</span>
          </div>
          <div class="has-text-right">
            <span> {{ availableDaysOfWork | number : '1.0-2' }}j </span>
          </div>
        </li>
        <li class="flex space-between align-center list-separated flex-mobile">
          <div>
            <span class="m-l-sm">Jours saisis :</span>
          </div>
          <div class="has-text-right is-bold">
            <span> {{ totalTimeSheetDaysOfWork | number : '1.0-2' }} j</span>
            <span class="m-l-md has-text-grey">•</span>
            <span class="m-l-md has-text-link">{{
              totalTimeSheetDaysOfWork / availableDaysOfWork | percent
            }}</span>
          </div>
        </li>

        <li class="flex space-between align-center list-separated flex-mobile">
          <div>
            <span class="m-l-sm"
              >Taux de staffing
              <span
                class="tooltip has-tooltip has-tooltip-bottom has-tooltip-multiline"
                data-tooltip="Jours saisis billables / jours disponibles"
              >
                <i class="icon icon-info is-size-5 has-text-link"></i>
              </span>
              :</span
            >
          </div>
          <div class="m-l-md has-text-link">
            <span>
              {{
                billableTimeSheetDaysOfWork / availableDaysOfWork
                  | percent : '1.0-2'
              }}
            </span>
          </div>
        </li>
      </ul>

      <!-- DeliverableType table-->
      <div
        class="table-responsive m-t-xl"
        *ngIf="deliverableTypes && deliverableTypes.length"
      >
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Type de livrable</th>
              <th>Jours</th>
              <th>Pourcentage</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let deliverableType of deliverableTypes">
              <td>{{ deliverableType.name }}</td>
              <td>
                {{ deliverableType.timeSheetDaysOfWork | number : '1.0-2' }} j
              </td>
              <td>
                {{
                  deliverableType.timeSheetDaysOfWork / totalTimeSheetDaysOfWork
                    | percent
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="column is-12-mobile is-6-tablet"
      *ngIf="timeSheets && projects && !loading"
    >
      <div class="chart p-a-gutter">
        <canvas
          baseChart
          [options]="pieChartOptions"
          [datasets]="[
            {
              data: pieChartData,
              backgroundColor: pieChartColors
            }
          ]"
          [labels]="pieChartLabels"
          [type]="'pie'"
          *ngIf="pieChartData?.length && pieChartLabels?.length"
        ></canvas>
      </div>
    </div>
  </div>

  <div class="columns">
    <div class="column has-table">
      <!-- Project table -->
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Mission</th>
            <th>Client</th>
            <th>Jours</th>
            <th>Pourcentage</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let project of projects"
            [ngClass]="{ 'is-success': project.notBillable }"
          >
            <td>{{ project.name }}</td>
            <td>{{ project.customer ? project.customer.name : '-' }}</td>
            <td>{{ project.timeSheetDaysOfWork | number : '1.0-2' }} j</td>
            <td>
              {{
                project.timeSheetDaysOfWork / totalTimeSheetDaysOfWork | percent
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
