import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { YieldType } from '../../../common/enums/yield-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { Yield } from '../../../common/interfaces/yield.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { creditDefinition } from '../credit.definition'

@Component({
  selector: 'app-credit-list',
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class CreditListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = creditDefinition

  filters: Filter[] = [
    {
      label: `Recherche d'avoir`,
      placeholder: `N° d'avoir, de facture, mission ou client...`,

      properties: {
        projectIds: 'projectIds',
        invoiceIds: 'invoiceIds',
        creditIds: 'creditIds',
        customerIds: 'customerIds'
      },
      searchResources: ['projects', 'credits', 'invoices', 'customers'],
      inputType: InputType.MultiSearch,
      className: 'is-12-touch is-12-desktop is-6-widescreen p-x-0-mobile'
    },
    {
      label: `Date d'emission`,
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      placeholder: `Date d'emission...`,
      inputType: InputType.DateRange,
      className: 'is-12-mobile is-12-tablet is-6-widescreen p-x-0-mobile '
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
