import { Component, Input, OnChanges } from '@angular/core'

import { environment } from '../../../../../environments/environment'

@Component({
  selector: 'abc-image-yield',
  templateUrl: './image-yield.component.html',
  styleUrls: ['./image-yield.component.scss']
})
export class ImageYieldComponent implements OnChanges {
  @Input() imageObjects: {
    image: string
    label?: string
    secondLabel?: string
    link?: string
  }[]
  @Input() defaultImage = environment.isAltVersion
    ? '/assets/img/avatar-alt.svg'
    : '/assets/img/avatar.svg'
  @Input() label: string

  maxLength = 4
  notDisplayedImagesLabel: string

  ngOnChanges(): void {
    if (this.imageObjects.length > this.maxLength) {
      this.notDisplayedImagesLabel = this.imageObjects
        .slice(this.maxLength - 1)
        .map((iO) => iO.label)
        .join(', ')
    }
  }
}
