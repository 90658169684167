<!-- Invoices -->
<div class="flex space-between align-end flex-mobile m-t-gutter m-b-colgap">
  <div>
    <h3 class="title--small has-text-black p-l-lg">Factures</h3>
    <a
      class="p-l-md is-size-8"
      [routerLink]="['/missions', project.id, 'factures', 'create']"
      *ngIf="canManageAccounting"
      ><i class="icon icon-plus has-text-grey-dark m-r-sm"></i>Ajouter une
      facture</a
    >
  </div>
  <div class="is-hidden-mobile">
    <span class="badge is-link m-r-gutter is-hidden-mobile">
      Total factures moins avoirs
      {{ totalInvoicesAmount - totalCreditsAmount | euros }}
    </span>
    <span class="badge is-link m-r-gutter is-hidden-mobile">
      Montant restant à facturer {{ totalToBill | euros }}
    </span>
  </div>
</div>

<div class="columns">
  <div class="column is-12 top-margin-none" *ngIf="invoices && invoices.length">
    <div class="has-table p-a-0">
      <div class="table-responsive p-b-0">
        <abc-table
          [items]="invoices"
          [definition]="invoiceDefinition"
          [allowOrderBy]="false"
          [editLinkQueryParams]="{ redirectTo: redirectToHere }"
          [redirectToAfterDuplicate]="redirectToHere"
          (reloadPrompted)="reload()"
          (customEventEmitter)="onCustomEventHappen($event)"
        ></abc-table>
      </div>
    </div>
  </div>
</div>
<hr />

<!-- Expenses -->
<div class="flex space-between align-end flex-mobile m-t-gutter m-b-colgap">
  <div>
    <h3 class="title--small has-text-black p-l-lg">Frais</h3>
    <a
      class="p-l-md is-size-8"
      [routerLink]="['/missions', project.id, 'frais', 'create']"
      *ngIf="canManageAccounting"
      ><i class="icon icon-plus has-text-grey-dark m-r-sm"></i>Ajouter un
      frais</a
    >
  </div>
  <div>
    <span class="badge is-link m-r-gutter is-hidden-mobile">
      Total des frais {{ totalExpensesAmount | euros }}</span
    >
  </div>
</div>
<div class="columns">
  <div class="column is-12 top-margin-none" *ngIf="expenses && expenses.length">
    <div class="has-table p-a-0">
      <div class="table-responsive p-b-0">
        <abc-table
          [items]="expenses"
          [allowOrderBy]="false"
          [definition]="expenseDefinition"
        ></abc-table>
      </div>
    </div>
  </div>
</div>
<hr />

<!-- Credits -->
<div class="flex space-between align-end flex-mobile m-t-gutter m-b-colgap">
  <div>
    <h3 class="title--small has-text-black p-l-lg">Avoirs</h3>
    <a
      class="p-l-md is-size-8"
      [routerLink]="['/missions', project.id, 'avoirs', 'create']"
      *ngIf="canManageAccounting"
      ><i class="icon icon-plus has-text-grey-dark m-r-sm"></i>Ajouter un
      avoir</a
    >
  </div>
  <div>
    <span class="badge is-link m-r-gutter is-hidden-mobile">
      Total des avoirs {{ totalCreditsAmount | euros }}</span
    >
  </div>
</div>

<div class="columns">
  <div class="column is-12 top-margin-none" *ngIf="credits && credits.length">
    <div class="has-table p-a-0">
      <div class="table-responsive">
        <abc-table
          [items]="credits"
          [definition]="creditDefinition"
          [allowOrderBy]="false"
        ></abc-table>
      </div>
    </div>
  </div>
</div>

<app-invoice-payment-modal
  *ngIf="invoiceToPay"
  [invoice]="invoiceToPay"
  [project]="project"
  (modalClosed)="closeModal()"
  (paymentCreatedOrDeleted)="onModalAction()"
></app-invoice-payment-modal>

<app-invoice-comments-modal
  *ngIf="invoiceToComment"
  [item]="invoiceToComment"
  (closedModal)="closeModal()"
  (submitSuccessful)="onModalAction()"
></app-invoice-comments-modal>
