import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output
} from '@angular/core'

import { ResourceService } from '../../../common/services/resource.service'
import { User } from '../user.interface'

@Component({
  selector: 'app-user-with-availability-select-input',
  templateUrl: './user-with-availability-select-input.component.html',
  styleUrls: ['./user-with-availability-select-input.component.scss']
})
export class UserWithAvailabilitySelectInputComponent implements OnChanges {
  @Input() projectId: string
  @Input() positionId: string
  @Input() months: string[]
  @Input() userId: number

  @Output() userSelected: EventEmitter<number> = new EventEmitter()

  showList = false
  selectedUser: User
  users: User[]

  constructor(
    private resourceService: ResourceService,
    private elementRef: ElementRef
  ) {}

  ngOnChanges() {
    this.showList = false

    if (!this.months) {
      return
    }

    const params: { [key: string]: string | string[] } = {
      months: this.months
    }

    if (this.positionId) {
      params.positionId = this.positionId
    }

    this.resourceService
      .list(`projects/${this.projectId}/booked-works/per-user`, params)
      .then((userRes: User[]) => {
        this.users = userRes

        if (this.userId) {
          this.selectedUser = this.users.find((u) => u.id === this.userId)
        }
      })
  }

  select(user: User) {
    this.selectedUser = user
    this.showList = false
    this.userSelected.emit(user.id)
  }

  // Click outside closes list
  @HostListener('document:click', ['$event.target'])
  clickOut(eventTarget) {
    if (
      !this.elementRef.nativeElement.contains(eventTarget) &&
      !eventTarget.className.includes('select--simulate')
    ) {
      this.showList = false
    }
  }
}
