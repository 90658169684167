<ng-container *ngIf="!loading && budgets">
  <ng-container *ngIf="budgets.length">
    <!-- Tab links -->
    <div class="m-b-gutter">
      <div class="tabs is-centered">
        <ul>
          <li
            [ngClass]="{
              'is-active': activeBudget && activeBudget.id === budget.id
            }"
            (click)="activeBudget = budget"
            *ngFor="let budget of budgets"
          >
            <a>{{ budget.name }}</a>
          </li>
          <li *ngIf="!isReadonly">
            <a (click)="createBudget({ name: 'Avenant ' + budgets.length })"
              >Nouvel avenant</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Create Edit Budget Component -->
    <app-budget-create-edit
      [budget]="activeBudget"
      [isReadonly]="isReadonly"
    ></app-budget-create-edit>

    <div class="columns p-x-gutter">
      <div class="column is-12-mobile is-9-tablet has-text-right">
        <p>
          <a
            (click)="budgetToDelete = activeBudget"
            class="is-size-8"
            *ngIf="
              !isReadonly &&
              activeBudget &&
              (!activeBudget.invoices || !activeBudget.invoices.length)
            "
            ><i class="icon icon-trash-2 m-r-sm has-text-grey-dark"></i
            >Supprimer
            {{ activeBudget.name }}
          </a>
          <a
            class="is-size-8 is-disabled tooltip has-tooltip has-tooltip-right"
            *ngIf="
              !isReadonly &&
              activeBudget &&
              activeBudget.invoices &&
              activeBudget.invoices.length
            "
            data-tooltip="Pour supprimer ce budget, veuillez d'abord supprimer ses factures dans l'onglet 'Comptabilité'"
            ><i class="icon icon-trash-2 m-r-sm has-text-grey-dark"></i
            >Supprimer
            {{ activeBudget.name }}
          </a>
        </p>
      </div>
    </div>
  </ng-container>

  <!-- Create Budget buttons --->
  <div class="columns p-a-gutter" *ngIf="!budgets.length">
    <div class="column" *ngIf="!isReadonly">
      <p class="m-b-colgap">
        Cette mission n'a pas encore de budget. Ajoutez une prestation ou un
        module pour générer le budget.
      </p>
      <p>
        <a
          (click)="
            createBudget({ name: 'Budget', withService: 'Nouvelle prestation' })
          "
          class="button is-primary m-r-gutter"
          ><i class="icon icon-plus m-r-xs"></i>Ajouter la 1ère prestation</a
        >
        <a
          (click)="
            createBudget({ name: 'Budget', withModule: 'Nouveau module' })
          "
          class="button is-grey"
          ><i class="icon icon-plus m-r-xs has-text-link"></i>Ajouter le 1er
          module</a
        >
      </p>
    </div>
  </div>
</ng-container>

<span *ngIf="loading" class="m-t-xxl columns">
  <span class="spinner">
    <div class="spinner-inner"></div>
  </span>
</span>

<abc-confirm-delete-modal
  [emitConfirmation]="true"
  [resourceToDelete]="budgetToDelete"
  (deleteCanceled)="budgetToDelete = null"
  (deleteConfirmed)="deleteBudget(budgetToDelete.id)"
  *ngIf="budgetToDelete"
></abc-confirm-delete-modal>
