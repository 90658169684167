import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'

import { AbcListComponent } from '../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../common/enums/input-type.enum'
import { Filter } from '../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../common/services/flash-message.service'
import { ResourceService } from '../../common/services/resource.service'
import { ProjectStatus } from '../../resources/project/project-status.enum'
import { projectProfitabilityDefinition } from './project-profitability.definition'

@Component({
  selector: 'app-project-profitability',
  templateUrl: '../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: ['../../common/base-components/abc-list/abc-list.component.scss']
})
export class ProjectProfitabilityComponent
  extends AbcListComponent
  implements OnInit
{
  definition: ResourceDefinition = projectProfitabilityDefinition
  browseItemsPermissionName = 'browseAnalytics'

  filters: Filter[] = [
    {
      label: `Période de la mission (tout ou partie)  `,
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      inputType: InputType.DateRange,
      className: 'is-12-mobile is-12-tablet is-6-widescreen p-x-0-mobile ',
      initialValue: {
        dateFrom: moment().startOf('year').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD')
      }
    },
    {
      label: 'Partner mission',
      properties: {
        value: 'workSupervisorIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleName: 'partner'
        }),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet is-2-widescreen p-x-0-mobile'
    },
    {
      label: 'Resp. opérationnel',
      properties: {
        value: 'projectManagerIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleName: 'manager'
        }),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet is-2-widescreen p-x-0-mobile'
    },
    {
      label: 'Statut',
      properties: {
        value: 'statuses'
      },
      selectOptions: Object.values(ProjectStatus).map((status) => ({
        label: status,
        value: status
      })),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet  is-2-widescreen p-x-0-mobile'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private readonly componentResourceService: ResourceService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
