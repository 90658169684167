<div class="modal is-active modal-content-is-large">
  <div class="modal-background"></div>
  <div class="modal-content bg-white">
    <div class="columns">
      <div
        class="column is-12-mobile is-7-tablet left-part"
        *ngIf="remainingAmount > 0"
      >
        <h2 class="title is-2 is-light has-text-centered">
          Ajouter un paiement pour la facture pour la facture
          {{ invoice.number }}
        </h2>

        <p>Confirmez-vous l'ajout de ce paiement pour cette facture ?</p>
        <table class="table is-bordered is-large m-t-md">
          <tbody>
            <tr>
              <td>Montant TTC</td>
              <td>{{ invoice.amountWithTaxes | euros }}</td>
            </tr>
            <tr>
              <td>Restant à payer</td>
              <td>{{ remainingAmount | euros }}</td>
            </tr>
            <tr>
              <td>Mission</td>
              <td>{{ project.label }}</td>
            </tr>
          </tbody>
        </table>
        <form [formGroup]="form">
          <div class="columns">
            <div class="column is-12">
              <abc-input
                [type]="InputType.Datepicker"
                label="Date du paiement"
                [initialValue]="{ value: form.value.date }"
                (valueChanged)="form.patchValue({ date: $event.value })"
                [validators]="requiredValidators"
                [showErrors]="showErrors"
              ></abc-input>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <abc-input
                [type]="InputType.Number"
                label="Montant du paiement"
                [initialValue]="{ value: form.value.amount }"
                (valueChanged)="form.patchValue({ amount: $event.value })"
                [validators]="requiredValidators"
                [showErrors]="showErrors"
                [max]="remainingAmount"
                [min]="0"
              ></abc-input>
              <p
                class="is-regular has-text-danger p-t-xs"
                *ngIf="form.value.amount < remainingAmount"
              >
                Attention : le montant du paiement est inférieur au montant à
                payer pour cette facture. Souhaitez-vous toutefois le valider ?
              </p>
              <p
                class="is-regular has-text-danger p-t-xs"
                *ngIf="form.value.amount > remainingAmount"
              >
                Le montant du paiement est supérieur au montant à payer pour
                cette facture. Veuillez le modifier.
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column m-t-colgap p-b-0">
              <div class="flex align-stretch flex-mobile space-between">
                <button class="button is-link" (click)="submit()">
                  Valider
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Recap displayed if invoice already fully payed -->
      <div
        class="column is-12-mobile is-7-tablet left-part"
        *ngIf="remainingAmount <= 0"
      >
        <h2 class="title is-2 is-light has-text-centered">
          La facture {{ invoice.number }} a été reglée.
        </h2>

        <table class="table is-bordered is-large m-t-md">
          <tbody>
            <tr>
              <td>Montant TTC</td>
              <td>{{ invoice.amountWithTaxes | euros }}</td>
            </tr>
            <tr>
              <td>Restant à payer</td>
              <td>{{ remainingAmount | euros }}</td>
            </tr>
            <tr>
              <td>Mission</td>
              <td>{{ project.label }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-12-mobile is-5-tablet bl right-part">
        <h5 class="title is-6 has-text-black is-uppercase is-bold">
          Paiements
        </h5>
        <ul *ngIf="invoice.payments.length">
          <li class="list-separated" *ngFor="let payment of invoice.payments">
            <div class="flex align-center space-between full-width p-l-0">
              <span>
                {{ payment.date | date }}
              </span>
              <span>
                {{ payment.amount | euros }}
              </span>
              <span>
                <a (click)="delete(payment.id)" title="Effacer le paiement">
                  <i class="icon icon-trash-2 is-size-5"></i>
                </a>
              </span>
            </div>
          </li>
        </ul>
        <p *ngIf="!invoice.payments.length">
          Aucun paiement à cette facture n'est enregistré pour l'instant.
        </p>
      </div>
    </div>
    <div class="columns" *ngIf="remainingAmount <= 0">
      <div class="column has-text-right p-x-0-tablet">
        <div class="buttons m-t-colgap">
          <a routerLink="/factures" class="button is-primary"
            >Voir la liste des factures</a
          >

          <a class="button is-primary" (click)="close()">Voir la mission</a>
        </div>
      </div>
    </div>
    <button class="button is-light close" aria-label="close" (click)="close()">
      <i class="icon icon-x is-size-2"></i>
    </button>
  </div>
</div>
