import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import * as moment from 'moment'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { EstimatedWork } from '../../estimated-work/estimated-work.interface'
import { Position } from '../../position/position.interface'
import { Project } from '../../project/project.interface'
import { Service } from '../../service/service.interface'
import { BookedWork } from '../booked-work.interface'

// Heavily inspired on ClassicCreateEditModalComponent but adding custom features for BookedWorks.
@Component({
  selector: 'app-booked-work-create-edit-modal',
  templateUrl: './booked-work-create-edit-modal.component.html',
  styleUrls: ['./booked-work-create-edit-modal.component.scss']
})
export class BookedWorkCreateEditModalComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  @Input() project: Project
  @Input() definition: ResourceDefinition
  @Input() mode = 'create'
  @Input() fieldSpecialRules?: FieldSpecialRule[] = []
  @Input() item: BookedWork

  @Output() closedModal: EventEmitter<void> = new EventEmitter()

  monthSelectOptions: SelectOption[] = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25
  ].map((i: number) => {
    const momentDate: moment.Moment = moment()
      .utc()
      .locale('fr')
      .startOf('month')
      .add(i, 'months')
    return {
      label: momentDate.format('MMMM yyyy'),
      value: momentDate.format('YYYY-MM-DD')
    }
  })

  fields: Field[] = [
    {
      id: 'projectId',
      hidden: true,
      label: 'Mission',
      placeholder: 'Mission',
      properties: {
        projectId: 'projectId'
      },
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      searchResources: ['projects'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12'
    },
    {
      id: 'serviceId',
      label: 'Prestation',
      placeholder: 'Sélectionnez la prestation',
      properties: {
        value: 'serviceId'
      },
      retrievedItemProperties: {
        serviceId: 'service.id'
      },
      inputType: InputType.Select,
      validators: [Validators.required],
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('services', {
          projectId: this.project.id
        }),
      onChange: (newValue: { value: string }) => {
        this.componentResourceService
          .show(`projects/${this.project.id}/services`, newValue.value)
          .then((serviceRes: Service) => {
            this.selectedService = serviceRes
            this.positions = this.getPositionRecaps(this.selectedService)
          })
      }
    },
    {
      label: 'Nombre de jours',
      properties: {
        value: 'daysOfWork'
      },
      validators: [Validators.required],
      inputType: InputType.Number,
      min: 0,
      max: 100,
      className: 'm-t-lg'
    },
    {
      id: 'months',
      label: 'Mois concernés',
      placeholder: 'Choisir le(s) mois de réservation',
      className: 'm-t-lg',
      properties: {
        value: 'months'
      },
      validators: [Validators.required],
      editValidators: [],
      inputType: InputType.MultiSelect,
      selectOptions: this.monthSelectOptions
    },
    {
      id: 'month',
      label: 'Mois concerné',
      placeholder: 'Choisir le mois de réservation',
      className: 'm-t-lg',
      properties: {
        value: 'month'
      },
      validators: [],
      editValidators: [Validators.required],
      inputType: InputType.Select,
      selectOptions: this.monthSelectOptions
    },
    {
      id: 'positionId',
      label: `Filtrer par profil`,
      className: 'm-t-lg',
      placeholder: `Tous les profils`,
      properties: { value: 'positionId' },
      retrievedItemProperties: {
        positionId: 'position.id'
      },
      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('positions'),

      validators: []
    },
    {
      id: 'userId',
      hidden: true,
      label: 'Collaborateur',
      placeholder: 'Collaborateur',
      properties: {
        value: 'userId'
      },
      retrievedItemProperties: {
        userId: 'user.id'
      },
      searchResources: ['users'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required]
    }
  ]

  selectedService: Service

  isModal = true
  selectedPositionId: string
  showLateBookedWorkSelectionWarning = false

  positions: Position[] = []

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  async ngOnInit() {
    await this.initCreateEditView()

    this.definition.slug = `projects/${this.project.id}/booked-works`

    // On "edit" mode we fetch BookedWork Service to display Service detail.
    if (this.item) {
      this.componentResourceService
        .show(`projects/${this.project.id}/services`, this.item.service.id)
        .then((serviceRes: Service) => {
          this.selectedService = serviceRes
          this.positions = this.getPositionRecaps(this.selectedService)
        })
    }

    this.showLateBookedWorkSelectionWarningIfNeeded(this.form.value)

    this.form.valueChanges.subscribe(
      (formValue: { months: [string]; month: string }) => {
        this.showLateBookedWorkSelectionWarningIfNeeded(formValue)
      }
    )
  }

  getPositionRecaps(service: Service): Position[] {
    const positions: Position[] = []

    service.estimatedWorks.forEach((estimatedWork: EstimatedWork) => {
      const position: Position = positions.find(
        (p) => p.id === estimatedWork.position.id
      )

      if (position) {
        position.estimatedDaysOfWork += estimatedWork.daysOfWork
        position.amount += estimatedWork.daysOfWork * estimatedWork.dailyRate
      } else {
        const position: Position = estimatedWork.position
        position.estimatedDaysOfWork = estimatedWork.daysOfWork
        position.amount = estimatedWork.daysOfWork * estimatedWork.dailyRate
        position.bookedDaysOfWork = 0

        positions.push(position)
      }
    })

    service.bookedWorks.forEach((bookedWork: BookedWork) => {
      const position: Position = positions.find(
        (p) => p.id === bookedWork.user.position.id
      )

      if (position) {
        position.bookedDaysOfWork += bookedWork.daysOfWork
      } else {
        const position: Position = bookedWork.user.position
        position.estimatedDaysOfWork = 0
        position.amount = 0
        position.bookedDaysOfWork = bookedWork.daysOfWork
        positions.push(position)
      }
    })

    return positions
  }

  showLateBookedWorkSelectionWarningIfNeeded(formValue: {
    months: [string]
    month: string
  }) {
    this.showLateBookedWorkSelectionWarning =
      moment(formValue.month).isAfter(moment(this.project.estimatedEndDate)) ||
      formValue.months?.some((month) =>
        moment(month).isAfter(moment(this.project.estimatedEndDate))
      )
  }

  onUserSelected(userId: number) {
    this.form.get('userId').setValue(userId)
  }

  close() {
    this.closedModal.emit()
  }

  @HostListener('document:keydown.escape') onEnterKeydown() {
    this.close()
  }
}
