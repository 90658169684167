import { NgIf } from '@angular/common'
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output
} from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Project } from '../../project/project.interface'
import { PlannedWork } from '../planned-work.interface'

// Heavily inspired on ClassicCreateEditModalComponent but adding custom features for PlannedWorks.
@Component({
  selector: 'app-planned-work-create-edit-modal',
  templateUrl: './planned-work-create-edit-modal.component.html',
  styleUrls: ['./planned-work-create-edit-modal.component.scss']
})
export class PlannedWorkCreateEditModalComponent
  extends AbcCreateEditComponent
  implements OnChanges
{
  @Input() definition: ResourceDefinition
  @Input() mode = 'create'
  @Input() fieldSpecialRules?: FieldSpecialRule[] = []
  @Input() item: any
  @Input() project: Project
  @Input() autoSubmit = false

  @Output() closedModal: EventEmitter<void> = new EventEmitter()
  isModal = true
  selectedPositionId: string
  plannedWorkDaysOfWork: number
  remainingDaysToPlan: number

  fields: Field[] = [
    {
      id: 'projectId',
      label: 'Mission',
      placeholder: 'Mission',
      properties: {
        projectId: 'projectId'
      },
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      searchResources: ['projects'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12 p-x-0-mobile'
    },
    {
      id: 'userId',
      hidden: true,
      label: 'Collaborateur',
      placeholder: 'Collaborateur',
      properties: {
        value: 'userId'
      },
      retrievedItemProperties: {
        userId: 'user.id'
      },
      searchResources: ['users'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12 p-x-0-mobile'
    },
    {
      id: 'daysOfWork',
      label: 'Nombre de jours',
      properties: {
        value: 'daysOfWork'
      },
      validators: [Validators.required],
      inputType: InputType.Number,
      max: 5,
      min: 0.1,
      className: 'is-12 p-x-0-mobile'
    },
    {
      id: 'description',
      label: 'Tâches',
      properties: {
        value: 'description'
      },
      validators: [],
      inputType: InputType.Text,
      className: 'is-12 p-x-0-mobile'
    },
    {
      id: 'date',
      hidden: true,
      label: 'Date',
      placeholder: 'Choisir la semaine',
      properties: {
        value: 'date'
      },
      validators: [Validators.required],
      inputType: InputType.Select,
      selectOptions: [],
      className: 'is-12 p-x-0-mobile'
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  async ngOnChanges() {
    this.plannedWorkDaysOfWork = this.project.plannedWorks.reduce(
      (sum: number, curr: PlannedWork) => sum + curr.daysOfWork,
      0
    )
    this.remainingDaysToPlan =
      this.project.bookedWorkDaysOfWork -
      (this.project.timeSheetDaysOfWork + this.plannedWorkDaysOfWork)

    this.initCreateEditView()

    if (this.autoSubmit) {
      setTimeout(() => this.submit(), 0)
    }
  }

  onUserSelected(userId: number) {
    this.form.get('userId').setValue(userId)
  }

  close() {
    this.closedModal.emit()
  }

  @HostListener('document:keydown.escape') onEnterKeydown() {
    this.close()
  }
}
