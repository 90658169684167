<div class="columns flex flex-wrap">
  <div
    class="column is-12-mobile is-6-tablet is-3-desktop p-x-0-mobile"
    *ngFor="let option of selectOptions"
    (click)="select(option)"
  >
    <div class="control">
      <label
        class="radio is-boxed radio-label m-t-0"
        [ngClass]="{
          'is-selected': option.selected,
          'is-disabled': option.disabled
        }"
      >
        <img src="./assets/img/radio-check.svg" alt="check" *ngIf="!isAlt" />
        <img src="./assets/img/radio-check-alt.svg" alt="check" *ngIf="isAlt" />
        <span class="radio-cta">
          <span
            class="radio-label has-text-black"
            [ngClass]="{
              'has-text-grey': option.disabled
            }"
          >
            {{ option.label }}
          </span>
          <span class="radio-sub-label has-text-grey">
            {{ option.subLabel }}
          </span>
        </span>
      </label>
    </div>
  </div>
</div>
