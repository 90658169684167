<table class="table--planning">
  <thead class="top-margin">
    <tr>
      <th>
        <div class="flex align-center justify-start p-b-sm">
          <a (click)="navigate(-5)" *ngIf="dateFrom > initialDateFrom"
            ><i class="icon is-size-3 has-text-dark icon-arrow-left"></i
          ></a>
          <a *ngIf="dateFrom <= initialDateFrom" disabled
            ><i class="icon is-size-3 has-text-grey icon-arrow-left"></i
          ></a>
          <a (click)="navigate(0)"
            ><i class="icon is-size-3 has-text-dark icon-target m-x-gutter"></i
          ></a>
          <a (click)="navigate(5)"
            ><i class="icon is-size-3 has-text-dark icon-arrow-right"></i
          ></a>
        </div>
      </th>
      <th
        *ngFor="let week of weeks; let i = index"
        class="p-b-sm"
        [ngClass]="{ 'current-week': i === 0 && dateFrom <= initialDateFrom }"
      >
        <div class="flex space-between align-start planning-head">
          <div>
            <span class="has-text-dark">{{ week.number }}</span>
            - {{ week.firstDay }}
          </div>
          <div
            class="
              has-text-right
              flex flex-column
              align-end
              flex-end
              full-height
            "
          >
            <p class="days-info">
              <ng-container *ngIf="daysOffPerWeek && daysOffPerWeek[i]"
                >Jours offs : {{ daysOffPerWeek[i] }}</ng-container
              >
              <span
                class="m-x-xs"
                *ngIf="
                  daysOffPerWeek &&
                  daysOffPerWeek[i] &&
                  holidaysPerWeek &&
                  holidaysPerWeek[i]
                "
              >
                |
              </span>
              <ng-container *ngIf="holidaysPerWeek && holidaysPerWeek[i]"
                >Congés : {{ holidaysPerWeek[i] }}</ng-container
              >
            </p>
            <span
              class="planned-days"
              *ngIf="holidaysPerWeek && daysOffPerWeek && plannedWorksPerWeek"
              [ngClass]="{
                'has-text-danger':
                  plannedWorksPerWeek[i] >
                  5 - holidaysPerWeek[i] - daysOffPerWeek[i],
                'has-text-info':
                  plannedWorksPerWeek[i] ===
                  5 - holidaysPerWeek[i] - daysOffPerWeek[i]
              }"
            >
              Jours planifiés :
              {{ plannedWorksPerWeek[i] | number: '1.0-2' }} /
              {{ 5 - holidaysPerWeek[i] - daysOffPerWeek[i] | number: '1.0-2' }}
            </span>
          </div>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let project of projects"
      [ngClass]="{ 'is-success': project.notBillable }"
    >
      <td>
        {{ project.name }}
        <ng-container *ngIf="project.customer"
          >- {{ project.customer.name }}</ng-container
        >
        <br />
        <span class="help">
          <ng-container *ngIf="project.bookedWorkDaysOfWork">
            Reservé : {{ project.bookedWorkDaysOfWork | number: '1.0-2' }} j
          </ng-container>

          <ng-container
            *ngIf="project.bookedWorkDaysOfWork && project.timeSheetDaysOfWork"
          >
            -
          </ng-container>
          <ng-container *ngIf="project.timeSheetDaysOfWork">
            TS :
            {{ project.timeSheetDaysOfWork | number: '1.0-2' }} j
          </ng-container>
        </span>
      </td>
      <td
        class="relative"
        *ngFor="let plannedWork of project.plannedWorksPerWeek; let i = index"
        [ngClass]="{ 'current-week': i === 0 && dateFrom <= initialDateFrom }"
      >
        <div
          class="card card--planning-event"
          [ngClass]="{
            'tooltip has-tooltip has-tooltip-bottom':
              project.plannedWorkDescriptionsPerWeek[i]
          }"
          [attr.data-tooltip]="
            project.plannedWorkDescriptionsPerWeek[i] || null
          "
          (click)="createEditPlannedWork(project, weeks[i])"
          *ngIf="plannedWork"
        >
          <strong>{{ plannedWork | number: '1.0-2' }} j</strong>
        </div>

        <a
          class="delete-planned-work"
          (click)="promptDelete(project, weeks[i])"
          *ngIf="plannedWork"
        >
          <i class="icon icon-x is-size-5"></i>
        </a>

        <a class="button is-primary full-width add-plan" *ngIf="!plannedWork">
          <i
            class="icon icon-copy is-size-5 p-a-gutter m-r-gutter"
            *ngIf="project.plannedWorksPerWeek[i - 1]"
            (click)="
              createEditPlannedWork(
                project,
                weeks[i],
                project.plannedWorksPerWeek[i - 1],
                project.plannedWorkDescriptionsPerWeek[i - 1],
                true
              )
            "
          ></i>
          <i
            class="icon icon-plus is-size-4 p-a-gutter"
            (click)="createEditPlannedWork(project, weeks[i])"
          ></i>
        </a>
      </td>
    </tr>
  </tbody>
</table>
<a
  disabled
  class="
    button
    is-link
    m-t-colgap m-l-colgap
    is-disabled
    tooltip
    has-tooltip has-tooltip-right has-tooltip-multiline
  "
  data-tooltip="Il n'y a pas d'autres missions disponibles. Pour pouvoir se planifer sur une autre mission, il faut tout d'abord être réservé sur celle-ci."
  *ngIf="
    projectsWithBookedWorksOptions && !projectsWithBookedWorksOptions.length
  "
  >Ajouter une mission à planifier</a
>
<a
  (click)="showAddProjectModal = true"
  class="button is-link m-t-colgap m-l-colgap"
  *ngIf="
    projectsWithBookedWorksOptions && projectsWithBookedWorksOptions.length
  "
  >Ajouter une mission à planifier</a
>

<!-- Add project modal -->
<div
  class="modal is-active"
  *ngIf="projectsWithBookedWorksOptions && showAddProjectModal"
>
  <div class="modal-background"></div>
  <div class="modal-content notification is-light">
    <div class="columns">
      <div class="column">
        <h1 class="title is-2 is-light">Ajouter une mission</h1>
      </div>
    </div>

    <div class="columns flex flex-wrap">
      <div class="column p-x-0-mobile">
        <abc-input
          [type]="InputType.Select"
          [selectOptions]="projectsWithBookedWorksOptions"
          label="Sélectionnez la mission à ajouter"
          [validators]="requiredValidators"
          (valueChanged)="projectToAddId = $event.value"
        ></abc-input>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12 m-t-colgap p-b-0">
        <div class="flex align-stretch flex-mobile space-between">
          <button
            class="button p-x-0-mobile"
            (click)="showAddProjectModal = false"
          >
            Annuler
          </button>
          <button
            [disabled]="!projectToAddId"
            class="button is-primary is-hidden-touch"
            (click)="addProject(projectToAddId)"
          >
            Enregistrer
          </button>
          <button
            class="button is-primary is-rounded is-hidden-desktop"
            (click)="addProject(projectToAddId)"
          >
            <i class="icon icon-save"></i>
          </button>
        </div>
      </div>
    </div>
    <button
      class="button is-light close"
      aria-label="close"
      (click)="showAddProjectModal = false"
    >
      <i class="icon icon-x is-size-2"></i>
    </button>
  </div>
</div>

<app-planned-work-create-edit-modal
  [project]="concernedProject"
  [definition]="plannedWorkDefinition"
  [fieldSpecialRules]="formRules"
  [mode]="createEditMode"
  [item]="plannedWorkToEdit"
  [autoSubmit]="autoSubmitCreateEdit"
  (closedModal)="showCreateEditModal = false"
  (submitSuccessful)="
    showCreateEditModal = false; autoSubmitCreateEdit = false; buildTable()
  "
  *ngIf="showCreateEditModal"
></app-planned-work-create-edit-modal>

<abc-confirm-delete-modal
  [emitConfirmation]="true"
  [resourceToDelete]="plannedWorkToDelete"
  (deleteCanceled)="plannedWorkToDelete = null"
  (deleteConfirmed)="deletePlannedWork(plannedWorkToDelete)"
  *ngIf="plannedWorkToDelete"
></abc-confirm-delete-modal>
