import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'

import { environment } from '../../../../../environments/environment'
import { AbcInput } from '../../../../common/interfaces/abc-input.interface'
import { HTMLInputEvent } from '../../../../common/interfaces/html-input-event.interface'
import { FlashMessageService } from '../../../../common/services/flash-message.service'
import { UploadService } from '../../../../common/services/upload.service'

@Component({
  selector: 'abc-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements AbcInput, OnChanges {
  @Input() label: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() resourceName: string
  @Input() initialValue: { value: string }
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() accept = '*'

  @Output() valueChanged: EventEmitter<{
    value: string
  }> = new EventEmitter()

  @ViewChild('fileInput') fileInputEl: ElementRef

  filePath: string
  storagePath: string = environment.storagePath

  fileContent: any
  required: boolean
  loading: boolean

  constructor(
    private uploadService: UploadService,
    private flashMessageService: FlashMessageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    this.filePath = this.initialValue ? this.initialValue.value : null
    this.required = this.validators.includes(Validators.required)
  }

  // Upload file and update value.
  fileInputEvent(fileInput: HTMLInputEvent) {
    this.loading = true
    this.fileContent = this.fileInputEl.nativeElement.files.item(0)
    this.uploadService
      .uploadFile(this.resourceName, this.fileContent)
      .subscribe(
        (res: { path: string }) => {
          this.loading = false
          this.filePath = res.path
          this.valueChanged.emit({ value: this.filePath })
        },
        (err) => {
          this.loading = false
          this.flashMessageService.error(
            `Une erreur à eu lieu lors de l'envoi du fichier`
          )
        }
      )
  }

  removeFile() {
    delete this.filePath
    this.valueChanged.emit({ value: null })
  }
}
