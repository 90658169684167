import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import { InputType } from '../../../common/enums/input-type.enum'
import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Deliverable } from '../../deliverable/deliverable.interface'
import { EstimatedWork } from '../../estimated-work/estimated-work.interface'
import { Service } from '../service.interface'

@Component({
  selector: 'app-service-create-edit',
  templateUrl: './service-create-edit.component.html',
  styleUrls: ['./service-create-edit.component.scss']
})
export class ServiceCreateEditComponent implements OnInit {
  @Input() service: Service
  @Input() isReadonly: boolean
  @Input() pricingYear: number
  @Output() serviceChanged: EventEmitter<{ name: string }> = new EventEmitter()

  @ViewChild('nameInput') nameInputEl: ElementRef

  form: UntypedFormGroup = this.formBuilder.group({
    id: null,
    name: ['', Validators.required],
    description: null,
    serviceTypeId: [null, Validators.required],
    displayOrder: 0,
    amount: 0,
    extended: true,
    flatFeePricing: false,
    estimatedWorks: new UntypedFormArray([]),
    deliverables: new UntypedFormArray([])
  })

  serviceTypes: SelectOption[]
  isFixedPrice = false
  InputType = InputType
  showCreateServiceTypeModal = false
  newServiceType: string

  get estimatedWorkControls(): UntypedFormArray {
    return this.form.get('estimatedWorks') as UntypedFormArray
  }

  get deliverableControls(): UntypedFormArray {
    return this.form.get('deliverables') as UntypedFormArray
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService
  ) {}

  async ngOnInit() {
    this.serviceTypes = await this.resourceService.listSelectOptions(
      'service-types'
    )

    // Set form values from @Input. For FormArrays, we have to push each item manually.
    this.form.patchValue({
      id: this.service.id,
      name: this.service.name,
      description: this.service.description,
      displayOrder: this.service.displayOrder,
      flatFeePricing: !!this.service.flatFeePricing,
      serviceTypeId: this.service.type ? this.service.type.id : null,
      amount: this.service.amount
    })

    if (this.service.estimatedWorks && this.service.estimatedWorks.length) {
      this.service.estimatedWorks.forEach((eW: EstimatedWork) => {
        this.estimatedWorkControls.push(
          this.formBuilder.group({
            id: eW.id,
            positionId: eW.position ? eW.position.id : eW.positionId,
            daysOfWork: eW.daysOfWork,
            dailyRate: eW.dailyRate
          })
        )
      })
    }

    if (this.service.deliverables && this.service.deliverables.length) {
      this.service.deliverables.forEach((d: Deliverable) => {
        this.deliverableControls.push(
          this.formBuilder.group({
            id: d.id,
            typeId: d.type.id
          })
        )
      })
    }

    if (!this.isReadonly) {
      setTimeout(() => this.nameInputEl.nativeElement.focus(), 0)
    } else {
      this.form.controls.serviceTypeId.disable()
    }

    this.form.valueChanges.subscribe((formValues) => {
      this.serviceChanged.emit(formValues)
    })
  }

  setAmountToZero() {
    if (!this.isReadonly) {
      this.form.get('amount').setValue(0)
    }
  }

  // We reset form array and push item one by one. The form changes and emits several times but we have a debounce time on parent
  // so one HTTP request only is sended.
  onEstimatedWorkChanged({
    totalAmount,
    estimatedWorks
  }: {
    totalAmount: number
    estimatedWorks: EstimatedWork[]
  }) {
    this.form.setControl('estimatedWorks', new UntypedFormArray([]))

    estimatedWorks.forEach((eW: EstimatedWork) => {
      this.estimatedWorkControls.push(this.formBuilder.group(eW))
    })

    this.form.get('amount').setValue(totalAmount)
  }

  onDeliverableChanged(deliverables: Deliverable[]) {
    this.form.setControl('deliverables', new UntypedFormArray([]))

    deliverables.forEach((d: Deliverable) => {
      this.deliverableControls.push(this.formBuilder.group(d))
    })
  }

  async saveServiceType(name: string) {
    this.resourceService
      .store('service-types', { name })
      .subscribe(async (res) => {
        this.flashMessageService.success(
          'Le type de prestation a bien été créé. Vous pouvez désormais le sélectionner dans la liste.'
        )
        this.serviceTypes = await this.resourceService.listSelectOptions(
          'service-types'
        )
      })
  }
}
