import { Component, Input, OnInit } from '@angular/core'
import { ChartOptions, TooltipItem } from 'chart.js'

import { environment } from '../../../../environments/environment'
import { EurosPipe } from '../../../common/pipes/euros.pipe'

@Component({
  selector: 'app-business-review-chart',
  templateUrl: './business-review-chart.component.html',
  styleUrls: ['./business-review-chart.component.scss']
})
export class BusinessReviewChartComponent implements OnInit {
  @Input() measurableEntity: {
    name: string
    annualRevenue: number
    annualEstimatedRevenue: number
  }[]

  chartOptions: ChartOptions<'bar'> = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<any>) =>
            `${
              this.chartData[context.datasetIndex].label
            }: ${this.eurosPipe.transform(context.raw as number)}`
        },
        backgroundColor: '#303032',
        titleFont: {
          size: 13
        },
        bodyFont: {
          size: 13
        },
        displayColors: false,
        cornerRadius: 2
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          callback: (value: number) => this.eurosPipe.transform(value)
        }
      }
    }
  }

  chartLabels: string[]
  chartData: any[]
  transparentColor = 'rgba(0,0,0,0)'

  constructor(private eurosPipe: EurosPipe) {}

  ngOnInit() {
    this.chartLabels = this.measurableEntity.map((mE) => mE.name)

    this.chartData = [
      {
        label: 'Backlog',
        data: this.measurableEntity.map((mE) => mE.annualEstimatedRevenue),
        backgroundColor: '#57B3A0',
        hoverBackgroundColor: '#57B3A0',
        borderColor: this.transparentColor,
        hoverBorderColor: this.transparentColor,
        borderRadius: 4,
        maxBarThickness: 8,
barThickness: 8,
        
        categoryPercentage: 0.2,
        barPercentage: 0.7
      },
      {
        label: 'CA Réalisé',
        data: this.measurableEntity.map((mE) => mE.annualRevenue),
        backgroundColor: environment.isAltVersion ? '#4E5CA6' : '#5C1EE8',
        hoverBackgroundColor: environment.isAltVersion ? '#4E5CA6' : '#5C1EE8',
        borderColor: this.transparentColor,
        hoverBorderColor: this.transparentColor,
        borderRadius: 4,
        maxBarThickness: 8,
barThickness: 8,
        
        categoryPercentage: 0.2,
        barPercentage: 0.7
      }
    ]
  }
}
