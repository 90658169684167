import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { Credit } from './credit.interface'

export const creditDefinition: ResourceDefinition = {
  title: 'Avoirs',
  nameSingular: 'avoir',
  namePlural: 'avoirs',
  gender: Gender.Masculine,
  slug: 'credits',
  path: 'avoirs',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: null,
  dropdownLinks: [
    {
      type: LinkType.DELETE,
      permission: 'deleteCredits'
    }
  ],
  yields: [
    {
      label: 'Numéro',
      property: 'number'
    },
    {
      label: 'Libellé',
      property: 'label'
    },
    {
      label: 'Facture associée',
      property: 'invoice.number'
    },
    {
      label: 'Mission',
      property: 'invoice.project.label',
      link: (c: Credit) => `missions/${c.invoice.project.id}`
    },
    {
      label: `Date d'émission`,
      property: 'date',
      type: YieldType.Date
    },

    {
      label: 'Montant HT',
      property: 'amount',
      type: YieldType.Currency
    },
    {
      label: 'Commentaire',
      property: 'comments'
    },
    {
      label: '',
      property: 'path',
      type: YieldType.Download
    }
  ]
}
