<ng-container *ngFor="let imageObject of imageObjects; let i = index">
  <ng-container *ngIf="imageObjects.length <= maxLength || i < maxLength - 1">
    <a
      class="has-avatar tooltip has-tooltip has-tooltip-right"
      [routerLink]="imageObject.link"
      [attr.data-tooltip]="imageObject.label"
    >
      <app-image
        [path]="imageObject.image"
        [replacement]="defaultImage"
        [alt]="imageObject.label"
        className="avatar-sm"
      >
      </app-image>
    </a>
  </ng-container>
</ng-container>
<a
  class="has-avatar has-avatar tooltip has-tooltip has-tooltip-multiline has-tooltip-right"
  [attr.data-tooltip]="notDisplayedImagesLabel"
  *ngIf="imageObjects.length > maxLength"
>
  <span class="avatar-sm avatar-sm--number">
    + {{ imageObjects.length - maxLength + 1 }}
  </span>
</a>
<span class="pointer" *ngIf="label">{{ label }}</span>
