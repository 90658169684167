<!-- Create Document modal -->
<div class="modal is-active modal-content-is-large">
  <div class="modal-background"></div>
  <div class="modal-content bg-white">
    <div class="columns">
      <div class="column">
        <h1 class="title is-2 is-light">Projet terminé !</h1>
        <p class="m-b-md">
          Alerte projet stratégique Wikinext : Merci de solliciter les
          consultants de la mission afin d’envoyer aux responsables de la base
          les principales productions utiles (extraits de livrables – rapports
          de référence – cartographies etc…)
        </p>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <a (click)="close()" class="button is-primary">
          <span>C'est compris</span>
        </a>
      </div>
    </div>
  </div>
</div>
