<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container has-text-centered">
      <div class="columns">
        <div class="column col-login">
          <div>
            <figure class="avatar">
              <img src="./assets/img/logo.png" alt="logo" *ngIf="!isAlt" />
              <img src="./assets/img/logo-alt.png" alt="logo" *ngIf="isAlt" />
            </figure>
            <h3 class="title is-2 m-y-gutter">Connectez-vous</h3>
            <form [formGroup]="loginForm" (submit)="submitForm(loginForm)">
              <div class="field m-b-gutter">
                <div class="control">
                  <input
                    class="input is-large"
                    type="email"
                    placeholder="E-mail"
                    formControlName="email"
                    autocomplete="username"
                    autofocus=""
                  />
                </div>
              </div>

              <div class="field m-b-gutter">
                <div class="control">
                  <input
                    class="input is-large"
                    type="password"
                    placeholder="Mot de passe"
                    formControlName="password"
                    autocomplete="current-password"
                    (keydown.enter)="textareaEnterPressed($event)"
                  />
                </div>
              </div>
              <button
                class="button is-block is-primary is-large is-fullwidth m-b-md"
                [disabled]="loginForm.invalid"
              >
                <span class="is-bold">Se connecter</span>
              </button>
              <a routerLink="/forgot-password">Mot de passe oublié ?</a>
            </form>
          </div>
        </div>
        <div class="column col-decoration">
          <div class="drawing">
            <img src="../../../../assets/img/bg-3.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
