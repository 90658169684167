import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'
import * as HtmlDurationPicker from 'html-duration-picker'
import * as moment from 'moment'

@Component({
  selector: 'abc-time-duration-input',
  templateUrl: './time-duration-input.component.html',
  styleUrls: ['./time-duration-input.component.scss']
})
export class TimeDurationInputComponent implements OnInit, AfterViewInit {
  @Input() label: string
  @Input() initialValue: { value: string }
  @Input() placeholder: string
  @Input() helpText: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string

  @Output() valueChanged: EventEmitter<{ value: number }> = new EventEmitter()

  @ViewChild('timeDurationInput', { static: true })
  timeDurationInputEl: ElementRef

  required = false
  isInvalid = false

  ngOnInit(): void {
    this.required = this.validators.includes(Validators.required)
  }

  ngAfterViewInit() {
    HtmlDurationPicker.init()

    // Extract hours and minutes from initial value to use them in distinct inputs.
    const initialHours: string =
      this.initialValue && this.initialValue.value
        ? parseInt(this.initialValue.value.substr(0, 2), 10).toString()
        : '00'
    const initialMinutes: string =
      this.initialValue && this.initialValue.value
        ? parseInt(this.initialValue.value.substr(5, 2), 10).toString()
        : '00'

    this.timeDurationInputEl.nativeElement.value = `${initialHours}:${initialMinutes}`

    // Emit initial values in case they don't change as input and output format are different.
    this.emitDecimalTimeDuration(this.timeDurationInputEl.nativeElement.value)
  }

  // Emits decimal value of time based on "HH:mm" format.
  emitDecimalTimeDuration(time: string) {
    const hours: number = parseInt(time.split(':')[0], 10)
    const minutes: number = parseInt(time.split(':')[1], 10)

    // Convert separated values into a single decimal number.
    const decimalHour: number = hours + minutes / 60

    this.isInvalid =
      typeof decimalHour !== 'number' || (this.required && decimalHour === 0)

    this.valueChanged.emit({ value: decimalHour })
  }

  changeMinutes(minutes: number): void {
    const inputValue: string = this.timeDurationInputEl.nativeElement.value

    const newValue: string = moment(inputValue, 'HH:mm')
      .add(minutes, 'minutes')
      .format('HH:mm')

    this.timeDurationInputEl.nativeElement.value = newValue

    this.emitDecimalTimeDuration(newValue)
  }

  onTimeChange(event) {
    // UX: Select minutes after adding 2 digits for hours.
    if (
      event.target.value.split(':')[0].length === 2 &&
      event.target.selectionStart === 2
    ) {
      this.timeDurationInputEl.nativeElement.setSelectionRange(3, 5)
    }
    this.emitDecimalTimeDuration(event.target.value)
  }
}
