import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const businessReviewDefinition: ResourceDefinition = {
  title: 'Business review',
  nameSingular: 'business review',
  namePlural: 'business reviews',
  hasDetailPage: true,
  hasListPage: true,
  gender: Gender.Feminine,
  slug: 'analytics/business-review',
  path: 'analytics/business-review',
  buttons: [LinkType.EXPORT],
  defaultLink: null,
  yields: [
    {
      label: 'Nom',
      property: 'name',
      disableOrderBy: true
    },
    {
      label: 'Backlog (€)',
      headingClassName: 'has-text-right',
      className: 'has-text-right',
      property: 'annualEstimatedRevenue',
      disableOrderBy: true,
      type: YieldType.Currency
    },
    {
      label: 'Backlog (%)',
      property: 'annualEstimatedRevenuePercentageOfObjective',
      className: 'has-text-right has-border-right',
      tooltip: `% Backlog/Objectif`,
      disableOrderBy: true,
      type: YieldType.Percentage
    },
    {
      label: 'CA Realisé (€)',
      headingClassName: 'has-text-right',
      className: 'has-text-right',
      property: 'annualRevenue',
      tooltip: `Σ factures de l'année moins leurs éventuels avoirs`,
      disableOrderBy: true,
      type: YieldType.Currency
    },
    {
      label: 'CA réalisé (%)',
      property: 'annualRevenuePercentageOfObjective',
      className: 'has-text-right',
      tooltip: `"% Réalisé/Objectifs`,
      disableOrderBy: true,
      type: YieldType.Percentage
    },
    {
      label: 'Objectif',
      headingClassName: 'has-text-right',
      className: 'has-text-right',
      property: 'annualObjectiveAmount',
      disableOrderBy: true,
      type: YieldType.Currency
    }
  ],
  keyNumbers: [
    {
      label: 'Total backlog',
      className: 'is-success',
      type: YieldType.Currency,
      extraParams: {
        calculate: 'totalAnnualEstimatedRevenue'
      }
    },
    {
      label: '% Total backlog',
      className: 'is-success',
      type: YieldType.Percentage,
      extraParams: {
        calculate: 'totalAnnualEstimatedRevenuePercentage'
      }
    },
    {
      label: 'Total CA réalisé',
      className: 'is-success',
      type: YieldType.Currency,
      extraParams: {
        calculate: 'totalAnnualRevenue'
      }
    },
    {
      label: '% Total CA réalisé',
      className: 'is-success',
      type: YieldType.Percentage,
      extraParams: {
        calculate: 'totalAnnualRevenuePercentage'
      }
    }
  ]
}
