import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const dayOffDefinition: ResourceDefinition = {
  title: 'Jours offs',
  nameSingular: 'jour off',
  namePlural: 'jours off',
  gender: Gender.Masculine,
  slug: 'days-off',
  path: 'jours-off',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editDaysOff'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteDaysOff'
    }
  ],

  yields: [
    {
      label: 'Date',
      property: 'date',
      type: YieldType.Date
    },
    {
      label: 'Libellé',
      property: 'name'
    }
  ]
}
