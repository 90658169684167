<div
  id="overlay"
  [ngClass]="{ 'prevent-exit': budgetChanged && !userConfirmsLeave }"
  (click)="onOverlayClick($event)"
>
  <!-- Tabs -->
  <form [formGroup]="form" class="form--budget">
    <div class="columns flex flex-wrap flex-wrap-touch">
      <div
        class="column is-12 is-12-mobile is-9-widescreen top-margin-none p-r-0-desktop"
      >
        <div class="p-x-gutter p-b-gutter p-x-0-mobile">
          <app-budget-drag-and-drop-form
            [budgetProps]="{
              modules: budget.modules,
              services: budget.services
            }"
            [isReadonly]="isReadonly"
            [project]="budget.project"
            class="is-hidden-touch"
            (budgetPropsChanged)="onBudgetPropsChanged($event)"
          ></app-budget-drag-and-drop-form>

          <!-- Total -->
          <hr class="m-t-gutter is-hidden-touch" />
          <div class="columns flex flex-mobile">
            <div class="column is-6-mobile is-6 has-text-black">
              Sous-total<br />
              Frais de gestion<br />
              Remise<br />
              <strong>Total HT</strong>
            </div>
            <div class="column is-6-mobile is-6 has-text-black has-text-right">
              <span *ngIf="servicesAmount">{{ servicesAmount | euros }}</span
              ><br />
              <span>{{
                (servicesAmount * form.value.managementFeesPercentage) / 100
                  | euros
              }}</span
              ><br />
              <span>{{ form.value.discount | euros }}</span
              ><br />
              <strong
                >{{
                  servicesAmount +
                    (servicesAmount * form.value.managementFeesPercentage) /
                      100 -
                    form.value.discount | euros
                }}
              </strong>
            </div>
          </div>
        </div>
      </div>

      <!-- Side column : comments, discount and management fees -->
      <div class="column is-12 is-12-mobile is-3-widescreen">
        <!-- Budget name -->
        <div
          class="m-r-colgap p-l-gutter-until-widescreen p-x-0-mobile m-x-0-mobile"
        >
          <div class="control">
            <label for="name" class="">Nom du budget</label>
            <input
              class="input form-control"
              id="name"
              type="text"
              formControlName="name"
              [readonly]="isReadonly"
            />
          </div>
        </div>
        <div class="m-r-colgap m-t-gutter">
          <h3
            class="title--small has-text-black p-l-gutter-until-widescreen p-x-0-mobile"
          >
            Commentaire budget
          </h3>
          <a
            *ngIf="!isReadonly"
            class="p-l-md is-size-8"
            (click)="showEditCommentModal = true"
            id="open-comments-modal-button"
            ><i class="icon icon-edit-2 has-text-grey-dark m-r-xs"></i
            >Modifier</a
          >
        </div>

        <div class="comment" *ngIf="budget">
          <p>{{ budget.comments }}</p>
          <p class="has-text-grey m-t-md">
            {{ budget.commentsUpdatedAt | date }}
          </p>
        </div>

        <div
          class="m-r-colgap p-l-gutter-until-widescreen p-x-0-mobile m-x-0-mobile"
        >
          <h3 class="title--small has-text-black m-t-gutter">
            Informations supplémentaires
          </h3>

          <!-- Discount -->
          <div class="columns">
            <div class="column">
              <label for="discount-percentage-mode-select">Unité</label>
              <div class="control">
                <div class="select">
                  <select
                    class="select"
                    id="discount-percentage-mode-select"
                    (change)="
                      changeDiscountMode($event.target.value === 'true')
                    "
                    [disabled]="isReadonly"
                  >
                    <option [value]="false">En Euros</option>
                    <option [value]="true">En Pourcentage</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column" *ngIf="isDiscountPercentageMode">
              <div class="control">
                <label for="discount-percentage">Remise en %</label>
                <input
                  type="number"
                  id="discount-percentage"
                  class="input form-control"
                  min="0"
                  max="100"
                  step="0.01"
                  #discountPercentageInput
                  [readonly]="isReadonly"
                  (change)="onDiscountPercentageChange($event.target.value)"
                />
              </div>
              <p class="p-t-sm">Soit {{ form.value.discount | euros }}</p>
            </div>
            <div class="column" *ngIf="!isDiscountPercentageMode">
              <div class="control">
                <label for="discount">Remise en €</label>
                <input
                  class="input form-control"
                  type="number"
                  id="discount"
                  min="0"
                  step="0.01"
                  formControlName="discount"
                  [readonly]="isReadonly"
                  (change)="calculateDiscountPercentage()"
                />
              </div>
              <p class="p-t-sm">
                Soit {{ discountPercentage / 100 | percent: '1.0-2' }}
              </p>
            </div>
          </div>

          <!-- Management fees -->
          <div class="control">
            <label for="management-fees">Frais de gestion %</label>
            <input
              class="input form-control"
              type="number"
              id="management-fees"
              formControlName="managementFeesPercentage"
              [readonly]="isReadonly"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="update-budget-wrapper" *ngIf="budgetChanged">
      <a
        (click)="updateBudget()"
        class="button is-primary update-budget"
        [ngClass]="{
          'is-loading': loadingUpdateBudget,
          'is-disabled has-background-grey': form.invalid
        }"
        >Enregistrer les modifications</a
      >
      <p class="help is-danger" *ngIf="budgetChanged && form.invalid">
        Tous les champs obligatoires des modules et des services doivent être
        remplis pour pourvoir enregistrer le budget.
      </p>
    </div>
  </form>

  <!-- Edit comments modal -->
  <div class="modal" [ngClass]="{ 'is-active': showEditCommentModal }">
    <div class="modal-background"></div>
    <div class="modal-content notification is-light">
      <div class="columns">
        <div class="column">
          <h4 class="title is-4">Commentaire sur le budget</h4>
          <div class="control">
            <abc-input
              [type]="InputType.Textarea"
              [initialValue]="{ value: this.form.value.comments }"
              label="Modifier le commentaire"
              (valueChanged)="comments = $event.value"
            ></abc-input>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column m-t-colgap p-b-0">
          <div class="flex align-stretch flex-mobile space-between">
            <button class="button" (click)="showEditCommentModal = false">
              Annuler
            </button>
            <button
              class="button is-primary"
              (click)="showEditCommentModal = false; saveComments()"
            >
              Terminer
            </button>
          </div>
        </div>
      </div>
      <button
        class="button is-light close"
        aria-label="close"
        (click)="showEditCommentModal = false"
      >
        <i class="icon icon-x is-size-2"></i>
      </button>
    </div>
  </div>
</div>
