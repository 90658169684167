import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const deliverableTypeDefinition: ResourceDefinition = {
  title: 'Livrables',
  nameSingular: 'livrable',
  namePlural: 'livrables',
  gender: Gender.Masculine,
  slug: 'deliverable-types',
  path: 'livrables',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [
    {
      propName: 'deliverableCount',
      preventDeleteMessage: `Certains livrables sont attribués à ce type de livrable.`
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editDeliverableTypes'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteDeliverableTypes'
    }
  ],
  yields: [
    {
      label: 'Livrable',
      property: 'name'
    }
  ]
}
