import { Validators } from '@angular/forms'

import { Gender } from '../../common/enums/gender.enum'
import { InputType } from '../../common/enums/input-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const plannedWorkDefinition: ResourceDefinition = {
  title: 'Planification',
  nameSingular: 'planification',
  namePlural: 'planifications',
  gender: Gender.Feminine,
  slug: 'planned-works',
  path: 'planifications',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [],
  defaultLink: null,
  yields: []
}
