import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output
} from '@angular/core'
import { appConstants } from '../../../../../../app.constants'
import { ViewportService } from 'src/app/common/services/viewport.service'

import { environment } from '../../../../../environments/environment'
import { AuthService } from '../../../../common/services/auth.service'
import { EventService } from '../../../../common/services/event.service'
import { ResourceService } from '../../../../common/services/resource.service'
import { Notification } from '../../../../resources/notification/notification.interface'
import { User } from '../../../../resources/user/user.interface'
import { HelperService } from '../../../services/helper.service'

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  @Output() onLogout: EventEmitter<void> = new EventEmitter()
  isWidescreenResolution: boolean

  currentUser: User
  notifications: Notification[]
  newNotificationCount = 0

  showUserMenu: boolean
  showLinkMenu: boolean
  showNotificationMenu: boolean

  canEditUsers: boolean

  production = environment.production

  isAlt: boolean = environment.isAltVersion

  constructor(
    private authService: AuthService,
    private resourceService: ResourceService,
    private eventService: EventService,
    private viewportService: ViewportService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.resourceService
      .show('users', 'myself', '', { checkBonuses: true })
      .then(async (userRes: User) => {
        if (userRes) {
          this.canEditUsers = await this.authService.can('editUsers')
          this.currentUser = userRes
        }
      })

    this.viewportService.isWidescreenResolution.subscribe((newValue) => {
      this.isWidescreenResolution = newValue
    })
    this.setIsWidescreenResolution()

    // CLose menus on changing route
    this.eventService.routeChanged.subscribe(async (routeChanged) => {
      this.showLinkMenu = false
      this.showUserMenu = false
      this.showNotificationMenu = false

      const queryParams: { [key: string]: string } =
        HelperService.getQueryParamsFromUrl(routeChanged.url)

      if (queryParams.checkNotification) {
        await this.resourceService
          .patch('notifications', queryParams.checkNotification, 'check')
          .toPromise()
          .then()
      }

      this.getNotifications()
    })
  }

  @HostListener('window:resize')
  onResize() {
    this.setIsWidescreenResolution()
  }

  setIsWidescreenResolution(): void {
    this.viewportService.isWidescreenResolution.next(
      window.innerWidth < appConstants.breakpoints.widescreen
    )
  }

  toggleNotificationMenu(): void {
    this.showNotificationMenu = !this.showNotificationMenu
    this.showUserMenu = false
    this.showLinkMenu = false

    if (this.showNotificationMenu) {
      setTimeout(() => {
        this.newNotificationCount = 0
        this.resourceService
          .patch('notifications/check-my-notifications')
          .toPromise()
          .then()
      }, 0)
    }
  }

  getNotifications(): void {
    this.resourceService
      .list('notifications')
      .then((notificationRes: Notification[]) => {
        this.notifications = notificationRes

        this.notifications.forEach((notification) => {
          // Separate queryParams from linkPath to use in Angular Router.
          if (notification.linkPath && notification.linkPath.includes('?')) {
            const urlArray: string[] = notification.linkPath.split('?')
            notification.linkPath = urlArray[0]
            const stringParams: string = urlArray[1]
            notification.queryParams = JSON.parse(
              '{"' +
                decodeURI(stringParams)
                  .replace(/"/g, '\\"')
                  .replace(/&/g, '","')
                  .replace(/=/g, '":"') +
                '"}'
            )
          }
        })

        this.newNotificationCount = this.notifications.filter(
          (n) => !n.checked
        ).length
      })
  }

  logout(): void {
    this.onLogout.emit()
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target) {
    // We track clicks to close dropdown if open and click outside
    if (
      !this.elementRef.nativeElement
        .querySelector('#create-dropdown')
        .contains(target)
    ) {
      this.showLinkMenu = false
      this.showUserMenu = false
      this.showNotificationMenu = false
    }
  }
}
