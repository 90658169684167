import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms'

import { AbcInput } from '../../../interfaces/abc-input.interface'

@Component({
  selector: 'abc-month-range-input',
  templateUrl: './month-range-input.component.html',
  styleUrls: ['./month-range-input.component.scss']
})
export class MonthRangeInputComponent implements AbcInput, OnInit {
  @Input() label: string
  @Input() helpText: string
  // Accepts YYYY-MM formatted dates
  @Input() initialValue: { dateFrom: string; dateTo: string }
  @Input() placeholder: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string

  @Output() valueChanged: EventEmitter<{
    dateFrom: string
    dateTo: string
  }> = new EventEmitter()

  form: UntypedFormGroup = this.formBuilder.group({
    dateFrom: null,
    dateTo: null
  })

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    if (this.initialValue) {
      this.form.setValue({
        dateFrom: this.initialValue.dateFrom,
        dateTo: this.initialValue.dateTo
      })
    }

    this.form.valueChanges.subscribe(
      (value: { dateFrom: string; dateTo: string }) => {
        this.valueChanged.emit(value)
      }
    )
  }
}
