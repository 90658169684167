import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../../common/interfaces/resource-definition.interface'
import { SelectOption } from '../../../../common/interfaces/select-option.interface'
import { BreadcrumbService } from '../../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../../common/services/flash-message.service'
import { ResourceService } from '../../../../common/services/resource.service'
import { ProjectStatus } from '../../project-status.enum'
import { projectDefinition } from '../../project.definition'
import { Project } from '../../project.interface'

@Component({
  selector: 'app-project-status-edit-modal',
  templateUrl:
    '../../../../common/base-components/abc-create-edit/abc-create-edit-modal.component.html',
  styleUrls: [
    '../../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class ProjectStatusEditModalComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  @Input() fieldSpecialRules?: FieldSpecialRule[] = []
  @Input() item: Project

  @Output() closedModal: EventEmitter<ProjectStatus> = new EventEmitter()

  definition: ResourceDefinition = projectDefinition
  isModal = true
  mode = 'edit'

  fields: Field[] = [
    {
      label: 'Statut',
      helpText: `Attention, lors du passage au statut "terminé", si des réservations pour le mois suivant existent, celles-ci seront supprimées après enregistrement.`,
      properties: {
        value: 'status'
      },
      placeholder: 'Choisir un statut',
      inputType: InputType.Select,
      selectOptions: () =>
        this.item
          ? this.componentResourceService
              .show('projects', this.item.id, 'available-statuses')
              .then((selectOptionsRes: SelectOption[]) => selectOptionsRes)
          : Promise.resolve([
              {
                label: ProjectStatus.Project,
                value: ProjectStatus.Project
              }
            ]),
      initialValue: {
        value: ProjectStatus.Project
      },
      validators: [Validators.required]
    },
    {
      label: 'Commentaire',
      properties: {
        value: 'activityComments'
      },
      inputType: InputType.Textarea,
      validators: [],
      className: 'is-12'
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService,
    private customFlashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }

  async submit() {
    this.componentResourceService
      .patch('projects', this.item.id, 'status', {
        status: this.form.value.status,
        activityComments: this.form.value.activityComments
      })
      .subscribe(
        (res) => {
          this.customFlashMessageService.success(
            'Le statut de la mission a bien été modifié.'
          )
          this.submitSuccessful.emit(this.form.value.status)
        },
        (err) => {
          this.customFlashMessageService.error(
            `Une erreur a eu lieu. Le statut de la mission n'a pas pu être changé.`
          )
        }
      )
  }

  close() {
    this.closedModal.emit()
  }

  @HostListener('document:keydown.escape') onEnterKeydown() {
    this.close()
  }
}
