<form [formGroup]="form">
  <div class="columns">
    <div class="column is-10-mobile is-8-tablet is-8-widescreen p-b-0">
      <label for="">Sélection du livrable</label>
    </div>
  </div>
  <!-- Multi line form -->
  <div
    class="columns flex align-center justify-start"
    formArrayName="deliverables"
    *ngFor="let control of deliverableFormArray.controls; let i = index"
  >
    <ng-container [formGroupName]="i">
      <div class="column is-8-mobile is-8-tablet is-8-widescreen">
        <div class="control">
          <div class="select">
            <select
              name="deliverable-type-select"
              class="select"
              formControlName="typeId"
            >
              <option value="" disabled>Choisir le type</option>
              <option
                *ngFor="let deliverableType of deliverableTypes"
                [value]="deliverableType.value"
              >
                {{ deliverableType.label }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="column is-2-mobile">
        <a (click)="removeDeliverable(i)" *ngIf="!isReadonly">
          <i class="icon icon-trash-2 has-text-grey is-size-4"></i>
        </a>
      </div>
      <div
        class="column is-2 flex align-end justify-start"
        *abcHasPermission="'addDeliverableTypes'"
      >
        <a
          (click)="toggleModal()"
          class="button is-rounded is-link button--add"
          *ngIf="!isReadonly"
        >
          <i class="icon icon-plus is-size-2"></i>
        </a>
      </div>
    </ng-container>
  </div>

  <a (click)="addDeliverable()" *ngIf="!isReadonly">
    <i class="icon icon-plus has-text-grey-dark m-r-sm"></i>
    Ajouter un livrable
  </a>
</form>

<!-- Create DeliverableType modal -->
<div class="modal" [ngClass]="{ 'is-active': showCreateDeliverableTypeModal }">
  <div class="modal-background"></div>
  <div class="modal-content notification is-light">
    <div class="columns">
      <div class="column">
        <h4 class="title is-4">Créer un livrable</h4>
        <div class="control">
          <abc-input
            [type]="InputType.Text"
            label="Nom"
            (valueChanged)="newDeliverableType = $event.value"
          ></abc-input>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column m-t-colgap p-b-0">
        <div class="flex align-stretch flex-mobile space-between">
          <button class="button" (click)="toggleModal()">Annuler</button>
          <button
            class="button is-primary"
            (click)="toggleModal(); storeDeliverableType(newDeliverableType)"
          >
            Sauvegarder
          </button>
        </div>
      </div>
    </div>
    <button
      class="button is-light close"
      aria-label="close"
      (click)="toggleModal()"
    >
      <i class="icon icon-x is-size-2"></i>
    </button>
  </div>
</div>
