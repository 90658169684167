<!-- Create Document modal -->
<div class="modal is-active" *ngIf="!autoSubmit">
  <div class="modal-background"></div>
  <div class="modal-content bg-white">
    <div class="columns">
      <div class="column">
        <h1 class="title is-2 is-light" *ngIf="mode === 'create'">
          Ajouter une planification
        </h1>
        <h1 class="title is-2 is-light" *ngIf="mode === 'edit'">
          Modifier une planification
        </h1>
      </div>
    </div>

    <!-- Project info -->
    <div class="columns">
      <div class="column">
        <h6 class="title has-text-grey m-b-0 is-6 is-uppercase is-bold m-t-md">
          Mission
        </h6>

        <p class="m-t-xs m-b-gutter">{{ project.name }}</p>

        <h6 class="title has-text-grey m-b-0 is-6 is-uppercase is-bold">
          Partner grand compte
        </h6>

        <p class="m-t-xs m-b-gutter">{{ project.workSupervisor.name }}</p>

        <table class="table is-bordered lh-small">
          <tbody>
            <tr>
              <td class="p-l-colgap">Reservé</td>
              <td class="p-l-colgap">TS</td>
              <td class="p-l-colgap">Planifié</td>
            </tr>
            <tr>
              <td class="p-l-colgap">
                {{ project.bookedWorkDaysOfWork | number: '1.0-2' }} j
              </td>
              <td class="p-l-colgap">
                {{ project.timeSheetDaysOfWork | number: '1.0-2' }} j
              </td>
              <td class="p-l-colgap">
                {{ plannedWorkDaysOfWork | number: '1.0-2' }} j
              </td>
            </tr>
          </tbody>
        </table>
        <p class="has-text-right">
          Jours restants : {{ remainingDaysToPlan | number: '1.0-2' }} j
        </p>
      </div>
    </div>
    <div class="columns flex flex-wrap">
      <ng-container *ngFor="let field of resolvedFields">
        <div
          class="column m-t-colgap p-t-0 m-t-0"
          [ngClass]="field.className"
          *ngIf="!field.hidden"
        >
          <abc-input
            [type]="field.inputType"
            [initialValue]="field.initialValue"
            [searchResources]="field.searchResources"
            [searchParams]="field.searchParams"
            [maxSelectedItems]="field.maxSelectedItems"
            [selectOptions]="field.selectOptions"
            [min]="field.min"
            [max]="field.max"
            [label]="field.label"
            [placeholder]="field.placeholder"
            [validators]="field.validators"
            [showErrors]="showErrors"
            (valueChanged)="onValueChanged($event, field)"
          ></abc-input>
        </div>
      </ng-container>
    </div>

    <div class="columns">
      <div class="column m-t-colgap p-b-0">
        <div class="flex flex-mobile align-stretch space-between">
          <button class="button p-x-0-mobile" (click)="close()">Annuler</button>
          <button
            class="button is-primary is-hidden-touch"
            (click)="submit()"
            [ngClass]="{ 'is-loading': loadingSubmit }"
          >
            Enregistrer
          </button>
          <button
            class="button is-primary is-hidden-desktop"
            (click)="submit()"
            [ngClass]="{ 'is-loading': loadingSubmit }"
          >
            <i class="icon icon-save"></i>
          </button>
        </div>
      </div>
    </div>
    <button class="button is-light close" aria-label="close" (click)="close()">
      <i class="icon icon-x is-size-2"></i>
    </button>
  </div>
</div>
