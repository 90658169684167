import { Component, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import moment from 'moment'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { positionDefinition } from '../position.definition'

@Component({
  selector: 'app-position-list',
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class PositionListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = positionDefinition

  filters: Filter[] = [
    {
      label: 'Année de tarification',
      properties: {
        value: 'pricingYear'
      },
      initialValue: {
        value: moment().year().toString()
      },
      placeholder:
        'Sélectionnez une année pour afficher le tarif journalier correspondant',
      selectOptions: () =>
        this.componentResourceService
          .list('positions/daily-rates/years')
          .then((years: number[]) =>
            years.map((year) => ({
              label: year.toString(),
              value: year.toString()
            }))
          ),
      validators: [Validators.required],
      inputType: InputType.Select,
      className: 'is-12-mobile is-16-tablet is-6-widescreen p-x-0-mobile'
    }
  ]

  constructor(
    private componentResourceService: ResourceService,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
