<div class="flex flex-mobile space-between align-center m-b-gutter">
  <div class="left-part">
    <h1 class="title is-2 is-light">Ajouter une ou plusieurs factures</h1>
  </div>
</div>

<section>
  <div class="card">
    <!-- Project MuliSearch -->
    <div class="columns flex flex-wrap m-b-colgap">
      <div class="column is-12-mobile is-8-tablet is-6-desktop p-x-0-mobile">
        <abc-input
          [type]="InputType.MultiSearch"
          [initialValue]="{ projectId: projectId }"
          [searchResources]="['projects']"
          [searchParams]="{ billableProjectsOnly: true }"
          [maxSelectedItems]="1"
          [readonly]="isProjectRestricted"
          label="Sélectionnez la mission"
          placeholder="Titre de la mission..."
          (valueChanged)="buildRadioHelpers($event.projectId)"
        ></abc-input>
      </div>
    </div>

    <!-- Radio button selector -->
    <abc-input
      [type]="InputType.Radio"
      [selectOptions]="radioOptions"
      label="Affiliation de la facturation"
      (valueChanged)="onRadioSelect($event.value)"
    ></abc-input>

    <div
      *ngIf="
        selectedBudget &&
        selectedBudget.invoices &&
        selectedBudget.invoices.length
      "
    >
      <div class="columns">
        <div class="column is-12">
          <div class="card no-shadow has-table p-a-0">
            <h3 class="title--small has-text-black p-l-lg m-t-xl">
              Factures existantes pour ce budget
            </h3>
            <div class="table-responsive top-margin-none p-b-0">
              <abc-table
                [items]="selectedBudget.invoices"
                [definition]="customInvoiceDefinition"
                [allowOrderBy]="false"
              ></abc-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card m-t-gutter" *ngIf="selectedBudget || isNewBudgetMode">
    <!-- Multi invoice form -->
    <app-invoice-plan-form
      [budget]="selectedBudget"
      [projectId]="projectId"
      [redirectTo]="redirectTo"
    ></app-invoice-plan-form>
  </div>
  <div class="card m-t-gutter" *ngIf="isExpenseMode">
    <!-- Expenses -->
    <app-invoice-of-expenses-form
      [projectId]="projectId"
      [expenses]="expenses"
    ></app-invoice-of-expenses-form>
  </div>
</section>
