import { Gender } from '../../../../common/enums/gender.enum'
import { LinkType } from '../../../../common/enums/link-type.enum'
import { ResourceDefinition } from '../../../../common/interfaces/resource-definition.interface'

export const activityDefinition: ResourceDefinition = {
  title: 'Activités',
  nameSingular: 'activité',
  namePlural: 'activités',
  gender: Gender.Feminine,
  slug: 'activities',
  path: 'activities',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [],
  defaultLink: LinkType.DETAIL,
  yields: []
}
