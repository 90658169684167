import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { forkJoin } from 'rxjs'

import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { Budget } from '../../budget/budget.interface'
import { Credit } from '../../credit/credit.interface'
import { expenseDefinition } from '../../expense/expense.definition'
import { Expense } from '../../expense/expense.interface'
import { Invoice } from '../../invoice/invoice.interface'
import { projectDefinition } from '../project.definition'
import { Project } from '../project.interface'
import { projectAccountingCreditDefinition } from './project-accounting-credit.definition'
import { projectAccountingInvoiceDefinition } from './project-accounting-invoice.definition'

@Component({
  selector: 'app-project-accounting',
  templateUrl: './project-accounting.component.html',
  styleUrls: ['./project-accounting.component.scss']
})
export class ProjectAccountingComponent implements OnInit {
  @Input() project: Project
  @Input() invoiceToPayId: string
  @Input() invoiceToCommentId: string
  @Input() canManageAccounting: boolean

  @Output() modalClosed: EventEmitter<void> = new EventEmitter()

  expenses: Expense[]
  invoices: Invoice[]
  invoiceToPay: Invoice
  invoiceToComment: Invoice
  credits: Credit[]

  expenseDefinition: ResourceDefinition = expenseDefinition
  creditDefinition: ResourceDefinition = projectAccountingCreditDefinition
  projectDefinition: ResourceDefinition = projectDefinition
  invoiceDefinition: ResourceDefinition = projectAccountingInvoiceDefinition

  projectRules: string
  redirectToHere: string

  totalToBill: number
  totalInvoicesAmount: number
  totalExpensesAmount: number
  totalCreditsAmount: number

  constructor(
    private resourceService: ResourceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.projectRules = JSON.stringify([
      {
        fieldId: 'projectId',
        forcedValue: { projectId: this.project.id }
      }
    ])
    this.redirectToHere = JSON.stringify(
      `/${projectDefinition.path || projectDefinition.slug}/${
        this.project.id
      }?selectedTab=accounting&reload=${new Date().toISOString()}`
    )

    forkJoin([
      this.resourceService.list(`projects/${this.project.id}/invoices`),
      this.resourceService.list(`projects/${this.project.id}/expenses`),
      this.resourceService.list(`projects/${this.project.id}/credits`)
    ]).subscribe(
      ([invoiceRes, expenseRes, creditRes]: [
        Invoice[],
        Expense[],
        Credit[]
      ]) => {
        this.invoices = invoiceRes
        this.totalInvoicesAmount = this.invoices.reduce(
          (sum, curr) => sum + curr.amount,
          0
        )

        this.expenses = expenseRes
        this.totalExpensesAmount = this.expenses.reduce(
          (sum, curr) => sum + curr.amount,
          0
        )

        this.credits = creditRes
        this.totalCreditsAmount = this.credits.reduce(
          (sum, curr) => sum + curr.amount,
          0
        )

        if (this.invoiceToPayId) {
          this.invoiceToPay = this.invoices.find(
            (i) => i.id.toString() === this.invoiceToPayId
          )
        }
        if (this.invoiceToCommentId) {
          this.invoiceToComment = this.invoices.find(
            (i) => i.id.toString() === this.invoiceToCommentId
          )
        }
        // Get budgets to calculate totalToBill.
        this.resourceService
          .list('budgets', { projectId: this.project.id })
          .then((budgetRes: Budget[]) => {
            const totalBudgetsAmount: number = budgetRes.reduce(
              (sum, curr) => sum + curr.totalAmount,
              0
            )

            const billableToCustomerExpensesAmount: number = expenseRes
              .filter((e) => e.billableToCustomer)
              .reduce((sum, curr) => sum + curr.amount, 0)

            this.totalToBill =
              totalBudgetsAmount -
              this.totalInvoicesAmount +
              this.totalCreditsAmount +
              billableToCustomerExpensesAmount
          })
      }
    )
  }

  reload() {
    this.router.navigate([`/missions/${this.project.id}`], {
      queryParams: {
        selectedTab: 'accounting',
        invoiceToComment: '',
        reload: new Date().toISOString()
      }
    })
  }

  closeModal() {
    this.invoiceToPay = null
    this.invoiceToComment = null
    this.modalClosed.emit()
  }

  onModalAction() {
    this.invoiceToPay = null
    this.invoiceToComment = null
    this.reload()
  }

  onCustomEventHappen(event: { name: string; value: Invoice }) {
    if (event.name === 'promptDeletePayment') {
      this.invoiceToPay = event.value
    }
  }
}
