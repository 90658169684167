import { Component, Input, OnChanges } from '@angular/core'
import { Params } from '@angular/router'
import { ChartOptions } from 'chart.js'
import * as moment from 'moment'
import * as momentBusiness from 'moment-business-days'

import { appConstants } from '../../../../app.constants'
import { InputType } from '../../common/enums/input-type.enum'
import { ResourceService } from '../../common/services/resource.service'
import { DayOff } from '../../resources/day-off/day-off.interface'
import { DeliverableType } from '../../resources/deliverable-type/deliverable-type.interface'
import { Deliverable } from '../../resources/deliverable/deliverable.interface'
import { Project } from '../../resources/project/project.interface'
import { TimeSheet } from '../../resources/time-sheet/time-sheet.interface'

@Component({
  selector: 'app-user-time-sheet-dashboard',
  templateUrl: './user-time-sheet-dashboard.component.html',
  styleUrls: ['./user-time-sheet-dashboard.component.scss']
})
export class UserTimeSheetDashboardComponent implements OnChanges {
  @Input() userId: number

  timeSheets: TimeSheet[]
  projects: Project[]
  deliverableTypes: DeliverableType[]
  holidays: TimeSheet[]
  daysOff: DayOff[]

  availableDaysOfWork: number
  totalTimeSheetDaysOfWork: number
  billableTimeSheetDaysOfWork: number

  dateRange: { dateFrom: string; dateTo: string } = {
    dateFrom: moment()
      .subtract(1, 'week')
      .startOf('isoWeek')
      .format('YYYY-MM-DD'),
    dateTo: moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
  }

  loading = false
  InputType = InputType

  pieChartData: number[]
  pieChartLabels: string[] | string[][]
  pieChartColors: string[]
  notBillableColor = '#57B3A0'
  pieChartOptions: ChartOptions<'pie'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: () => ''
        }
      }
    }
  }

  constructor(private resourceService: ResourceService) {}

  async ngOnChanges() {
    this.getTimeSheets()
  }

  async onDateRangeChanged(event: { dateFrom: string; dateTo: string }) {
    this.dateRange = event
    this.getTimeSheets()
  }

  async getTimeSheets(): Promise<void> {
    this.loading = true

    const params: Params = {
      userId: this.userId,
      withoutPagination: true
    }
    const holidayParams: Params = {
      userId: this.userId,
      withoutPagination: true,
      holidaysOnly: true
    }

    // Add dateFrom and dateTo if specified.
    if (this.dateRange.dateFrom) {
      params.dateFrom = this.dateRange.dateFrom
      holidayParams.dateFrom = this.dateRange.dateFrom
    }
    if (this.dateRange.dateTo) {
      params.dateTo = this.dateRange.dateTo
      holidayParams.dateTo = this.dateRange.dateTo
    }

    // Get resources.
    this.timeSheets = await this.resourceService
      .list('time-sheets', params)
      .then((timeSheetRes) => timeSheetRes)

    this.holidays = await this.resourceService
      .list('time-sheets', holidayParams)
      .then((holidayRes) => holidayRes)

    this.daysOff = await this.resourceService
      .list('days-off', params)
      .then((res) => res)

    // Calculate stats.
    this.projects = this.timeSheets.reduce(
      (acc: Project[], curr: TimeSheet) => {
        if (!curr.project) {
          return acc
        }

        const project: Project = acc.find(
          (p: Project) => p.id === curr.project.id
        )

        if (project) {
          project.timeSheetDaysOfWork += curr.daysOfWork
        } else {
          const newProject: Project = curr.project
          newProject.timeSheetDaysOfWork = curr.daysOfWork
          acc.push(newProject)
        }

        return acc
      },
      []
    )

    this.deliverableTypes = this.timeSheets.reduce(
      (acc: DeliverableType[], curr: TimeSheet) => {
        const deliverableCount: number = curr.deliverables.length
        if (deliverableCount) {
          curr.deliverables.forEach((deliverable: Deliverable) => {
            const deliverableType: DeliverableType = acc.find(
              (dT: DeliverableType) => dT.id === deliverable.type.id
            )

            if (deliverableType) {
              deliverableType.timeSheetDaysOfWork += curr.daysOfWork
            } else {
              const newDeliverableType: DeliverableType = deliverable.type
              newDeliverableType.timeSheetDaysOfWork =
                curr.daysOfWork / deliverableCount
              acc.push(newDeliverableType)
            }
          })
        }
        return acc
      },
      []
    )

    this.availableDaysOfWork =
      momentBusiness(params.dateFrom)
        .startOf('d')
        .businessDiff(momentBusiness(params.dateTo).endOf('d')) -
      this.daysOff.length -
      this.holidays.reduce(
        (sum: number, curr: TimeSheet) => sum + curr.daysOfWork,
        0
      )

    this.totalTimeSheetDaysOfWork = this.timeSheets
      .filter((t) => t.reference !== appConstants.holidayLabel)
      .reduce((sum: number, curr: TimeSheet) => sum + curr.daysOfWork, 0)

    this.billableTimeSheetDaysOfWork = this.projects
      .filter((p) => !p.notBillable)
      .reduce((sum: number, curr: Project) => sum + curr.timeSheetDaysOfWork, 0)

    // Pie chart.
    this.pieChartData = this.projects.map((p) => p.timeSheetDaysOfWork)
    this.pieChartLabels = this.projects.map((p) =>
      p.customer ? [p.name, p.customer.name] : [p.name]
    )
    this.pieChartColors = this.projects.map((p) =>
      p.notBillable ? this.notBillableColor : p.department.color
    )

    this.loading = false
  }
}
