<form [formGroup]="form" novalidate>
  <div class="control">
    <label id="datepicker-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >
    <div
      class="input-wrapper"
      [ngClass]="{ 'is-danger': showErrors && form.invalid }"
    >
      <input
        angular-mydatepicker
        class="input"
        id="datepicker-input-{{ uniqueId }}"
        [placeholder]="placeholder || 'Choisissez une date...'"
        [options]="datePickerOptions"
        (dateChanged)="onDateChanged($event)"
        (click)="dp.openCalendar()"
        #dp="angular-mydatepicker"
        formControlName="date"
        *ngIf="!readonly"
      />
      <input
        class="input form-control"
        readonly
        type="text"
        *ngIf="readonly"
        [value]="initialValue.value | date"
      />

      <p class="help" *ngIf="helpText">{{ helpText }}</p>
    </div>
  </div>
</form>
