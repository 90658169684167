import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { customerDefinition } from '../customer.definition'

@Component({
  selector: 'app-customer-list',
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class CustomerListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = customerDefinition

  filters: Filter[] = [
    {
      label: 'Rechercher un client',
      placeholder: 'Chercher par nom de client, mission, facture..',
      properties: {
        customerIds: 'customerIds',
        projectIds: 'projectIds',
        invoiceIds: 'invoiceIds'
      },
      searchResources: ['customers', 'projects', 'invoices'],
      inputType: InputType.MultiSearch,
      className: 'is-6 p-x-0-mobile'
    },
    {
      label: 'Partner grand compte',
      properties: {
        value: 'accountManagerId'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleName: 'partner'
        }),
      inputType: InputType.Select,
      className: 'is-12-mobile is-6-tablet is-3-fullhd p-x-0-mobile'
    },
    {
      label: 'Uniquement clients avec mission en cours',
      properties: {
        value: 'withOngoingProjectOnly'
      },
      inputType: InputType.Checkbox,
      className: 'is-12-mobile is-12-tablet is-3-fullhd no-label p-x-0-mobile'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
