<!-- Create Document modal -->
<div class="modal is-active modal-content-is-large">
  <div class="modal-background"></div>
  <div class="modal-content bg-white">
    <div class="columns">
      <div class="column">
        <h1 class="title is-2 is-light">
          Valider la mission {{ project.label }}
        </h1>
        <p class="m-b-md">
          Veuillez sélectionner le bénéficiaire de la prime afin de valider la
          mission. Le statut passera à
          <strong class="has-text-warning">Proposition</strong>.
        </p>
        <p class="is-bold m-b-0">Objet de la mission</p>
        <p class="m-b-md">{{ project.comments }}</p>
        <p class="m-b-md">
          <span class="is-bold">Dates de la mission:</span> Du
          {{ project.estimatedStartDate | date }} au
          {{ project.estimatedEndDate | date }}
        </p>
      </div>
    </div>
    <form [formGroup]="form">
      <div class="columns">
        <!-- Project info -->
        <div class="column">
          <p class="m-b-md">
            <span class="is-bold">Total des factures: </span
            >{{ totalInvoicesAmount - totalCreditsAmount | euros }}
          </p>

          <p class="m-b-md">
            Nombre de jours budgétés:
            {{ totalEstimatedWorksDaysOfWork | number: '1.0-2' }}j<br />
            Nombre de jours réservés:
            {{ totalBookedWorksDaysOfWork | number: '1.0-2' }}j
            <br />
            % de réservations:
            {{ totalBookedWorksAmount / totalBudgetsAmount | percent: '1.0-2' }}
          </p>

          <p class="tag is-info p-x-lg is-size-5">
            Taux de prime:
            <span class="has-text-link m-l-sm">
              {{ project.bonusRate / 100 | percent: '1.0-2' }}
            </span>
          </p>
        </div>
        <div class="column">
          <div
            class="card has-background-white-bis no-shadow m-b-md"
            *ngFor="let budget of budgets"
          >
            <p class="is-bold m-b-md">{{ budget.name }}</p>
            <p>
              <span class="is-bold">Sous-total HT:</span>
              <span> {{ budget.servicesAmount | euros }}</span>
            </p>
            <p>
              <span class="is-bold">Frais de gestion:</span>
              <span>
                {{
                  (budget.servicesAmount * budget.managementFeesPercentage) /
                    100 | euros
                }}</span
              >
            </p>
            <p>
              <span class="is-bold">Remise:</span>
              <span> {{ budget.discount | euros }}</span>
            </p>
            <p>
              <span class="is-bold">Total HT:</span>
              <span> {{ budget.totalAmount | euros }}</span>
            </p>
          </div>
        </div>
        <button
          class="button is-light close"
          aria-label="close"
          (click)="close()"
        >
          <i class="icon icon-x is-size-2"></i>
        </button>
      </div>
      <div class="columns">
        <div class="column p-b-0 p-t-0">
          <hr class="m-t-sm" />
          <p class="is-italic content is-small m-b-xs">
            <i class="icon icon-info has-text-link"></i>
            Vérifier que l'objet de la mission est complet et que les dates de
            la mission sont cohérentes.
          </p>
          <p class="is-italic content is-small m-b-xs">
            <i class="icon icon-info has-text-link"></i>
            Vérifier que le budget total est cohérent et que les frais de
            gestion sont corrects
          </p>
          <p class="is-italic content is-small m-b-md">
            <i class="icon icon-info has-text-link"></i>
            Vérifier que le nombre de jours réservé est cohérent : nous
            recommandons une réservation à au moins 80% pour une validation
          </p>

          <!-- Form -->
          <div
            class="columns flex align-end"
            *ngFor="let bonus of bonuses.controls; let i = index"
          >
            <ng-container [formGroup]="bonus">
              <div class="column is-6">
                <div class="control">
                  <label for="">Choisissez un bénéficiaire</label>
                  <div class="select">
                    <select name="" id="" formControlName="beneficiaryId">
                      <option [value]="user.value" *ngFor="let user of users">
                        {{ user.label }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <label for="">Part de la prime en %</label>
                <input
                  formControlName="percentage"
                  class="input"
                  type="number"
                  step="1"
                  min="1"
                  max="100"
                />
              </div>
              <div class="column is-2">
                <button
                  class="button is-danger is-rounded is-small has-icon"
                  (click)="removeBonus(i)"
                  *ngIf="i > 0"
                >
                  <i class="icon icon-x"></i>
                </button>
              </div>
            </ng-container>
          </div>
          <div class="columns">
            <div class="column">
              <a (click)="addBonus()" class="button is-primary">
                <i class="icon icon-plus"></i>
                <span>Ajouter un bénéficiaire</span>
              </a>
            </div>
            <div class="column has-text-right">
              <button
                class="button is-link"
                (click)="submit()"
                [disabled]="form.invalid"
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
