import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms'

import { AbcInput } from '../../../../common/interfaces/abc-input.interface'

@Component({
  selector: 'abc-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnChanges, AbcInput {
  @Input() label: string
  @Input() initialValue: { value: string }
  @Input() placeholder: string
  @Input() helpText: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() readonly = false

  @Output() valueChanged: EventEmitter<{ value: string }> = new EventEmitter()

  form: UntypedFormGroup
  required: boolean

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    if (!this.form) {
      this.form = this.formBuilder.group({
        text: [
          this.initialValue ? this.initialValue.value : null,
          this.validators || []
        ]
      })
      this.required = this.validators.includes(Validators.required)
    }
  }

  onChange(newValue: string) {
    this.valueChanged.emit({ value: newValue })
  }
}
