<!-- Invoices -->

<div>
  <h3 class="title--small has-text-black p-l-lg">Documents</h3>
  <a class="p-l-md is-size-8" (click)="showCreateModal = true"
    ><i class="icon icon-plus has-text-grey-dark m-r-sm"></i>Ajouter document</a
  >
</div>

<!-- Table -->

<div *ngIf="documents && documents.length" class="top-margin-none">
  <div class="has-table p-a-0">
    <div class="table-responsive">
      <abc-table
        [items]="documents"
        [definition]="documentDefinition"
        [allowOrderBy]="false"
      ></abc-table>
    </div>
  </div>
</div>

<app-document-create-edit-modal
  [fieldSpecialRules]="createDocumentSpecialRules"
  (closedModal)="showCreateModal = false"
  (submitSuccessful)="showCreateModal = false; getDocuments()"
  *ngIf="showCreateModal"
></app-document-create-edit-modal>
