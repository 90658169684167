import { Component, Input, OnInit } from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'
import * as moment from 'moment'

import { InputType } from '../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { AuthService } from '../../../common/services/auth.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { BookedWork } from '../../booked-work/booked-work.interface'
import { DayOff } from '../../day-off/day-off.interface'
import { plannedWorkDefinition } from '../../planned-work/planned-work.definition'
import { PlannedWork } from '../../planned-work/planned-work.interface'
import { Project } from '../../project/project.interface'
import { TimeSheet } from '../../time-sheet/time-sheet.interface'
import { User } from '../user.interface'

@Component({
  selector: 'app-user-planning',
  templateUrl: './user-planning.component.html',
  styleUrls: ['./user-planning.component.scss']
})
export class UserPlanningComponent implements OnInit {
  @Input() userId: number

  projects: Project[] = []
  projectsWithBookedWorksOptions: SelectOption[]
  daysOffPerWeek: number[]
  holidaysPerWeek: number[]
  plannedWorksPerWeek: number[]

  concernedProject: Project
  plannedWorks: PlannedWork[]
  plannedWorkToDelete: PlannedWork
  plannedWorkToEdit: PlannedWork
  currentUser: User

  weeks: { number: string; firstDay: string }[]
  visibleWeeks = 5
  initialDateFrom: moment.Moment = moment().utc().locale('fr').startOf('week')
  dateFrom: moment.Moment = this.initialDateFrom.clone()
  showAddProjectModal = false
  showCreateEditModal = false
  createEditMode: string
  formRules: FieldSpecialRule[]
  plannedWorkDefinition: ResourceDefinition = plannedWorkDefinition
  InputType = InputType
  projectToAddId: number
  requiredValidators: ValidatorFn[] = [Validators.required]
  autoSubmitCreateEdit = false

  constructor(
    private authService: AuthService,
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService
  ) {
    moment.locale('fr')
  }

  async ngOnInit() {
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })

    this.buildTable()
  }

  async buildTable() {
    this.projects = []

    this.weeks = this.buildWeeksArray()

    const dateTo: moment.Moment = this.dateFrom
      .clone()
      .add(this.visibleWeeks, 'weeks')
      .startOf('week')

    this.plannedWorks = await this.resourceService
      .list('planned-works', {
        userId: this.userId,
        dateFrom: this.dateFrom.format('YYYY-MM-DD'),
        dateTo: dateTo.format('YYYY-MM-DD')
      })
      .then((plannedWorkRes: PlannedWork[]) => plannedWorkRes)

    this.plannedWorksPerWeek = this.countDaysOfWorkPerWeek(this.plannedWorks)

    // Format planned works per projects.
    const pinnedProjects = this.projects.filter((p) => p.pinned)
    this.projects = this.getProjectsFromWorks(this.plannedWorks, 'plannedWorks')

    // Add pinned project to existing array of Projects. If we have new plannedWorks on a pinned project, we merge.
    pinnedProjects.forEach((pP: Project) => {
      const pinnedProjectWithPlannedWorks: Project = this.projects.find(
        (p) => p.id === pP.id
      )
      if (pinnedProjectWithPlannedWorks) {
        pinnedProjectWithPlannedWorks.pinned = true
      } else {
        this.projects.push(pP)
      }
    })

    this.projects.forEach(async (project: Project) => {
      project.plannedWorksPerWeek = this.countDaysOfWorkPerWeek(
        project.plannedWorks
      )

      // Create a string with plannedWork descriptions for a week.
      project.plannedWorkDescriptionsPerWeek = this.weeks.map(
        (week: { number: string; firstDay: string }) =>
          project.plannedWorks
            .filter((r) => moment(r.date).format('[S]w') === week.number)
            .reduce(
              (acc: string, curr: PlannedWork, i: number) =>
                `${acc}${i > 0 ? ' · ' : ''}${
                  curr.description ? curr.description : ''
                }`,
              ''
            )
      )
    })

    // Async loop to trigger API calls one after another and prevent API memory leak.
    for (const project of this.projects) {
      project.timeSheetDaysOfWork = await this.getTimeSheetDaysOfWork(
        project.id
      )
      project.bookedWorkDaysOfWork = await this.getBookedWorkDaysOfWork(
        project.id
      )
    }

    this.resourceService
      .list('days-off', {
        withoutPagination: true,
        dateFrom: this.dateFrom.format('YYYY-MM-DD'),
        dateTo: dateTo.format('YYYY-MM-DD')
      })
      .then((dayOffRes: DayOff[]) => {
        this.daysOffPerWeek = this.countDaysOfWorkPerWeek(dayOffRes)
      })

    this.resourceService
      .list('time-sheets', {
        userId: this.userId,
        dateFrom: this.dateFrom.format('YYYY-MM-DD'),
        dateTo: dateTo.format('YYYY-MM-DD'),
        holidaysOnly: true,
        withoutPagination: true
      })
      .then((holidayRes: TimeSheet[]) => {
        this.holidaysPerWeek = this.countDaysOfWorkPerWeek(holidayRes)
      })

    // Build Select Options.
    this.projectsWithBookedWorksOptions = await this.resourceService
      .list('booked-works', {
        userId: this.userId
      })
      .then((bookedWorkRes: BookedWork[]) =>
        this.getProjectsFromWorks(bookedWorkRes, 'bookedWorks')
          .filter(
            (p: Project) =>
              !this.projects.find(
                (existingProject) => existingProject.id === p.id
              )
          )
          .map((p: Project) => ({
            label: p.label,
            value: p.id.toString()
          }))
      )
  }

  getProjectsFromWorks(
    works: { project: Project }[],
    propName: string
  ): Project[] {
    return works.reduce((acc: Project[], curr: PlannedWork | BookedWork) => {
      const project = acc.find((p) => p.id === curr.project.id)

      if (project) {
        project[propName].push(curr)
      } else {
        const newProject: Project = curr.project
        newProject[propName] = [curr]
        acc.push(newProject)
      }
      return acc
    }, [])
  }

  getBookedWorkDaysOfWork(projectId: number): Promise<number> {
    return this.resourceService
      .list('booked-works', {
        projectId,
        userId: this.userId
      })
      .then((bookedWorkRes: BookedWork[]) =>
        bookedWorkRes.reduce(
          (sum: number, curr: BookedWork) => sum + curr.daysOfWork,
          0
        )
      )
  }

  getTimeSheetDaysOfWork(projectId: number): Promise<number> {
    return this.resourceService
      .list('time-sheets', {
        projectId,
        userId: this.userId,
        withoutPagination: true
      })
      .then((timeSheetRes: TimeSheet[]) =>
        timeSheetRes.reduce(
          (sum: number, curr: TimeSheet) => sum + curr.daysOfWork,
          0
        )
      )
  }

  navigate(weekDifference: number) {
    if (typeof weekDifference === 'undefined') {
      this.dateFrom = this.initialDateFrom.clone()
    } else {
      this.dateFrom.add(weekDifference, 'weeks')
    }
    this.buildTable()
  }

  buildWeeksArray(): { number: string; firstDay: string }[] {
    const currentWeek: moment.Moment = this.dateFrom.clone()
    const weeks = []

    for (let i = 0; i < this.visibleWeeks; i++) {
      weeks.push({
        number: currentWeek.format('[S]w'),
        firstDay: currentWeek.format('DD[/]MM')
      })
      currentWeek.add(1, 'week')
    }

    return weeks
  }

  countDaysOfWorkPerWeek(
    resources: { date: string; daysOfWork?: number }[]
  ): number[] {
    return this.weeks.map((week: { number: string; firstDay: string }) =>
      resources
        .filter((r) => moment(r.date).format('[S]w') === week.number)
        .reduce((sum, curr) => sum + (curr.daysOfWork || 1), 0)
    )
  }

  createEditPlannedWork(
    project: Project,
    week: { number: string; firstDay: string },
    daysOfWork?: number,
    description?: number,
    autoSubmit?: boolean
  ): void {
    this.concernedProject = project

    this.plannedWorkToEdit = this.plannedWorks.find(
      (pW) =>
        pW.project.id === project.id &&
        moment(pW.date).format('[S]w') === week.number
    )

    if (this.plannedWorkToEdit) {
      this.createEditMode = 'edit'
    } else {
      this.createEditMode = 'create'
    }

    this.formRules = [
      {
        fieldId: 'date',
        hidden: true,
        forcedValue: {
          value: moment(week.number, '[S]w').locale('fr').format('YYYY-MM-DD')
        }
      },
      {
        fieldId: 'userId',
        hidden: true,
        forcedValue: { value: this.userId }
      },
      {
        fieldId: 'projectId',
        hidden: true,
        forcedValue: { projectId: project.id }
      }
    ]

    if (daysOfWork) {
      this.formRules.push({
        fieldId: 'daysOfWork',
        forcedValue: { value: daysOfWork }
      })
    }
    if (description) {
      this.formRules.push({
        fieldId: 'description',
        forcedValue: { value: description }
      })
    }

    if (autoSubmit) {
      this.autoSubmitCreateEdit = true
    }

    this.showCreateEditModal = true
  }

  addProject(projectId: number) {
    this.showAddProjectModal = false
    this.resourceService
      .show('projects', projectId)
      .then(async (projectRes: Project) => {
        if (!this.projects.find((p) => p.id === projectRes.id)) {
          projectRes.plannedWorksPerWeek = this.countDaysOfWorkPerWeek([])
          projectRes.bookedWorkDaysOfWork = await this.getBookedWorkDaysOfWork(
            projectRes.id
          )
          projectRes.timeSheetDaysOfWork = await this.getTimeSheetDaysOfWork(
            projectRes.id
          )
          projectRes.plannedWorks = []
          // Manually added projects does not disappear on navigation.
          projectRes.pinned = true
          this.projects.push(projectRes)

          // Directly prompt create planned work on current week.
          this.createEditPlannedWork(projectRes, this.weeks[0])
        }
      })
  }

  promptDelete(
    project: Project,
    week: { number: string; firstDay: string }
  ): void {
    this.plannedWorkToDelete = this.plannedWorks.find(
      (pW) =>
        pW.project.id === project.id &&
        moment(pW.date).format('[S]w') === week.number
    )
  }

  deletePlannedWork(plannedWork: PlannedWork): void {
    this.resourceService
      .delete('planned-works', plannedWork.id)
      .subscribe((res) => {
        this.buildTable()
        this.flashMessageService.success('La planification a bien été effacée.')
      })
  }
}
