<section>
  <!-- Head -->
  <div
    class="flex flex-mobile space-between align-center m-b-sm"
    *ngIf="customer"
  >
    <div class="left-part">
      <div class="flex flex-mobile align-center">
        <app-image
          [path]="customer.logo"
          [title]="customer.name"
          [alt]="customer.name"
          className="avatar-sm"
        >
        </app-image>
        <h1 class="title is-2 is-light">
          {{ customer.name }}
          <span
            class="tag is-info is-medium is-hidden-touch"
            *ngIf="customer.supplierNumber"
            >{{ customer.supplierNumber }}</span
          >
        </h1>
      </div>
      <div class="flex flex-start align-start p-t-xs">
        <!-- Header -->
        <div clas="flex flex-column">
          <div>
            <i class="icon icon-map has-text-grey m-r-sm is-hidden-mobile"></i>
            {{ customer.address }}
          </div>
          <div>
            <ng-container *ngIf="customer.billingEmail">
              <i
                class="has-text-grey icon icon-mail m-r-sm is-hidden-mobile"
              ></i>
              <a href="mailto:{{ customer.billingEmail }}">{{
                customer.billingEmail
              }}</a>
            </ng-container>
          </div>
        </div>

        <div *ngIf="customer.billingAddress">
          <i
            class="has-text-grey icon icon-paperclip m-r-sm m-l-gutter is-hidden-mobile"
          ></i>
          {{ customer.billingAddress }}
        </div>
      </div>
    </div>
    <div class="right-part">
      <a
        [routerLink]="['/clients', customer.id, 'edit']"
        class="button is-primary is-hidden-touch"
        *abcHasPermission="'editCustomers'"
      >
        Modifier
      </a>
      <a
        [routerLink]="['/clients', customer.id, 'edit']"
        class="button is-primary is-hidden-desktop is-rounded"
        *abcHasPermission="'editCustomers'"
      >
        <i class="icon icon-edit-2"></i>
      </a>
    </div>
  </div>

  <!-- Project list-->
  <div class="columns" *ngIf="projects">
    <div class="column is-12-mobile is-8-desktop is-9-fullhd">
      <div class="card has-table p-a-0 full-height">
        <div class="table-responsive">
          <abc-table
            [items]="projects"
            [hiddenProps]="['customer.name']"
            [definition]="projectDefinition"
            [allowOrderBy]="false"
          ></abc-table>
        </div>
      </div>
    </div>
    <div class="column is-12-mobile is-4-desktop is-3-fullhd">
      <div class="card full-height">
        <h3 class="title is-5">Contacts client</h3>
        <ul>
          <li
            *ngFor="let referent of customer.referents"
            class="list-separated"
          >
            <a
              [routerLink]="['/missions']"
              [queryParams]="{ referentIds: referent.id }"
              >{{ referent.name }}
              <span *ngIf="!referent.isActive">(inactif)</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
