import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
import { AuthService } from '../../../common/services/auth.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required]
  })
  redirectTo: string
  isAlt: boolean = environment.isAltVersion

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private flashMessageService: FlashMessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.redirectTo =
      this.activatedRoute.snapshot.queryParams &&
      this.activatedRoute.snapshot.queryParams.redirectTo
  }

  submitForm(loginForm: UntypedFormGroup) {
    this.authService
      .login(loginForm.value.email, loginForm.value.password)
      .subscribe(
        async (res: { roleName: string; userId: number }) => {
          if (this.redirectTo) {
            return this.router.navigate([this.redirectTo])
          }

          // Custom redirection based on roleName.
          let redirectionPath: string
          let queryParams: { [key: string]: string } = {}
          switch (res.roleName) {
            case 'admin':
              redirectionPath = '/missions'
              break
            case 'partner':
              redirectionPath = '/missions'
              queryParams = {
                statuses: 'En attente de validation',
                workSupervisorIds: '2'
              }

              break
            case 'officeManager':
              redirectionPath = '/missions'
              break
            case 'manager':
              redirectionPath = '/analytics/rapport-suivi-missions'
              break
            case 'consultant':
              redirectionPath = '/collaborateurs/myself'
              break

            default:
              redirectionPath = '/collaborateurs/myself'
              break
          }

          return this.router.navigate([redirectionPath], { queryParams })
        },
        (err: HttpErrorResponse) => {
          this.flashMessageService.error(
            err.status === 401
              ? `Erreur : Identifiant et/ou mot de passe invalide : Veuillez vérifier vos identifiants de connexion.`
              : err.error.message
          )
          this.loginForm.reset()
        }
      )
  }

  // Fix error on console with LastPass Chrome extension : https://github.com/KillerCodeMonkey/ngx-quill/issues/351.
  textareaEnterPressed($event: KeyboardEvent) {
    $event.preventDefault()
    $event.stopPropagation()
    this.submitForm(this.loginForm)
  }
}
