<div class="modal is-active modal-content-is-large">
  <div class="modal-background"></div>
  <div class="modal-content bg-white">
    <div class="columns">
      <div class="column">
        <h1 class="title is-2 is-light">Contrat cadre en cours !</h1>
        <p class="m-b-md">
          Merci de vous référer aux modalités du contrat téléchargeable
        </p>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <a (click)="close()" class="button is-primary">
          <span>C'est compris</span>
        </a>
      </div>
    </div>
  </div>
</div>
