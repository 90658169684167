<label for="number-input-{{ uniqueId }}"
  >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
>

<div class="is-relative" [ngClass]="{ 'is-danger': showErrors && isInvalid }">
  <input
    type="text"
    (keyup)="onTimeChange($event)"
    (click)="onTimeChange($event)"
    [className]="'html-duration-picker'"
    data-hide-seconds
    #timeDurationInput
  />
  <a (click)="changeMinutes(15)" class="set-time is-up is-primary">+</a>
  <a (click)="changeMinutes(-15)" class="set-time is-down is-primary">-</a>
</div>
