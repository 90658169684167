import { Component, Input, OnInit } from '@angular/core'
import { Params } from '@angular/router'
import * as moment from 'moment'

import { User } from '../../../../resources/user/user.interface'
import { AuthService } from '../../../services/auth.service'

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Input() path: string
  @Input() isCollapsed = false

  activeAccordion: string

  defaultMonitoringParams: Params = {
    dateFrom: moment()
      .subtract(3, 'month')
      .startOf('month')
      .format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD')
  }
  defaultPlanningReportParams: Params = {
    dateFrom: moment().startOf('month').add(1, 'month').format('YYYY-MM'),
    dateTo: moment().endOf('month').add(1, 'month').format('YYYY-MM')
  }
  defaultDaysOffParams: Params = {
    year: moment().format('YYYY')
  }
  defaultProjectProfitabilityParams: Params

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.currentUser.subscribe((currentUser: User) => {
      if (currentUser && currentUser.role.name === 'partner') {
        this.defaultProjectProfitabilityParams = {
          workSupervisorIds: currentUser.id
        }
      } else if (currentUser && currentUser.role.name === 'manager') {
        this.defaultProjectProfitabilityParams = {
          projectManagerIds: currentUser.id
        }
      }
    })
  }

  toggleAccordion(accordion: string): void {
    if (this.activeAccordion === accordion) {
      this.activeAccordion = null
    } else {
      this.activeAccordion = accordion
    }
  }

  hideCollapsedAccordion() {
    if (this.isCollapsed) {
      delete this.activeAccordion
    }
  }

  showCollapsedAccordion(accordion: string): void {
    if (this.isCollapsed) {
      this.activeAccordion = accordion
    }
  }

  openSelectedAccordion() {
    // Open correct dropdown based on current path.
    if (this.path.includes('/clients') || this.path.includes('/referents')) {
      this.toggleAccordion('customer')
    } else if (this.path.includes('/factures')) {
      this.toggleAccordion('invoice')
    } else if (
      this.path.includes('/collaborateurs') ||
      this.path.includes('/time-sheets')
    ) {
      this.toggleAccordion('user')
    } else if (this.path.includes('/analytics')) {
      this.toggleAccordion('analytics')
    } else {
      this.toggleAccordion('setting')
    }
  }
}
