import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { ProjectStatus } from './project-status.enum'
import { Project } from './project.interface'

export const projectDefinition: ResourceDefinition = {
  title: 'Missions',
  nameSingular: 'mission',
  namePlural: 'missions',
  hasDetailPage: true,
  hasListPage: true,
  gender: Gender.Feminine,
  slug: 'projects',
  path: 'missions',
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: LinkType.DETAIL,
  childrenThatPreventDelete: [
    {
      propName: 'plannedWorkCount',
      preventDeleteMessage: `Certains plannings dépendent de cette mission`
    },
    {
      propName: 'timeSheetCount',
      preventDeleteMessage: `Certaines timesheets dépendent de cette mission`
    },
    {
      propName: 'invoiceCount',
      preventDeleteMessage: `Une ou plusieurs factures sont reliées à ce projet`
    },
    {
      propName: 'isStatusGreaterThanProject',
      preventDeleteMessage:
        'Seulement les missions au statut "Projet" peuvent être supprimées.'
    }
  ],
  actionButtons: [
    {
      label: 'Valider',
      className: 'button is-primary p-x-lg',
      permission: 'validateProjects',
      condition: (project: Project) =>
        project.status === ProjectStatus.PendingValidation,
      linkAction: (p: Project) => ({
        path: `/missions/${p.id}`,
        queryParams: {
          showValidationModal: 1
        }
      })
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.DETAIL,
      permission: 'readProjects'
    },
    {
      type: LinkType.EDIT,
      permission: 'editProjects'
    },
    {
      type: LinkType.DUPLICATE,
      permission: 'addProjects'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteProjects'
    }
  ],
  yields: [
    {
      label: 'Mission (Titre - Référence)',
      property: 'department.color',
      className: 'has-mini-circle',
      secondProperty: 'label',
      orderByProperty: 'reference',
      type: YieldType.Color
    },
    {
      label: 'Client',
      property: 'customer.name',
      link: (project: Project) =>
        project.customer ? `clients/${project.customer.id}` : null
    },
    {
      label: 'Res mission',
      property: 'referent.name'
    },
    {
      label: 'Budget',
      property: 'totalBudgetsAmount',
      type: YieldType.Currency
    },
    {
      label: 'Montant facturé',
      property: 'totalInvoicesAmount',
      type: YieldType.Currency
    },
    {
      label: 'Equipe projet',
      orderByProperty: 'workSupervisor.id',
      property: 'team',
      type: YieldType.Image
    },
    {
      label: 'Démarrage',
      property: 'estimatedStartDate',
      type: YieldType.Date
    },
    {
      label: 'Fin',
      property: 'estimatedEndDate',
      type: YieldType.Date
    },
    {
      label: 'Statut',
      property: 'status',
      secondProperty: 'isAbandoned',
      type: YieldType.ProgressBar
    }
  ]
}
