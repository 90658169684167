import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import * as HttpStatus from 'http-status-codes'
import { Observable, of, ReplaySubject } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { User } from '../../resources/user/user.interface'
import { FlashMessageService } from './flash-message.service'

@Injectable()
export class AuthService {
  token: string
  permissions: string[]
  baseUrl = `${environment.apiBaseUrl}/auth`

  public currentUser = new ReplaySubject(1)

  constructor(
    private http: HttpClient,
    private router: Router,
    private flashMessageService: FlashMessageService
  ) {
    // Set token if saved in local storage
    this.token = localStorage.getItem(environment.tokenName)
  }

  login(
    email: string,
    password: string
  ): Observable<{
    accessToken: string
    permissions: string[]
    roleName: string
    userId: number
  }> {
    return this.http.post(`${this.baseUrl}/login`, { email, password }).pipe(
      map(
        (res: {
          accessToken: string
          permissions: string[]
          roleName: string
          userId: number
        }) => {
          const token = res && res.accessToken

          if (token) {
            this.token = token
            this.permissions = res.permissions

            // Store JWT token and Permissions in local storage
            localStorage.setItem(environment.tokenName, token)
            return res
          }
          return
        }
      )
    )
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem(environment.tokenName)
  }

  getToken(): string {
    return localStorage.getItem(environment.tokenName)
  }

  // Get Current user permissions from Service or from remote API.
  getPermissions(): Promise<string[]> {
    if (this.permissions) {
      return Promise.resolve(this.permissions)
    } else {
      return this.me()
        .toPromise()
        .then((userRes: User) => userRes.role.permissions.map((p) => p.name))
    }
  }

  // Check if current user have a specific permission
  async can(permission: string): Promise<boolean> {
    const userPermissions: string[] = await this.getPermissions()

    return userPermissions?.includes(permission)
  }

  logout(): void {
    delete this.token
    delete this.permissions
    localStorage.removeItem(environment.tokenName)
    this.currentUser.next(null)
  }

  me(): Observable<User> {
    return this.http.get(`${this.baseUrl}/me`).pipe(
      map((userRes: User) => {
        // Store permissions in service.
        this.permissions = userRes.role.permissions.map((p) => p.name)
        return userRes
      }),
      catchError((err: HttpErrorResponse) => {
        // Redirect to login if no user in DB.
        if (err.status === HttpStatus.FORBIDDEN) {
          this.router.navigate(['/logout'])
        } else {
          this.flashMessageService.error(
            `Erreur: Impossible de se connecter au serveur. Veuillez vérifier la connexion internet et rafraîchir la page.`
          )
        }

        return of(null)
      })
    )
  }

  sendResetPasswordEmail(email: string) {
    let params = new HttpParams()
    params = params.set('email', email)

    return this.http
      .get(`${this.baseUrl}/forgot-password`, { params })
      .pipe(map((res: any) => res))
  }

  resetPassword(newPassword: string, token: string) {
    return this.http
      .post(`${this.baseUrl}/reset-password`, {
        newPassword,
        token
      })
      .pipe(map((res: any) => res))
  }
}
