import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { Permission } from '../../../common/interfaces/permission.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { roleDefinition } from '../role.definition'

@Component({
  selector: 'app-role-create-edit',
  templateUrl: './role-create-edit.component.html',
  styleUrls: ['./role-create-edit.component.scss']
})
export class RoleCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = roleDefinition
  permissions: Permission[]

  fields: Field[] = [
    {
      label: 'Identifiant',
      properties: {
        value: 'name'
      },
      inputType: InputType.Text,
      validators: [Validators.required]
    },
    {
      label: 'Nom',
      properties: {
        value: 'displayName'
      },
      inputType: InputType.Text,
      validators: [Validators.required]
    },
    {
      hidden: true,
      label: 'Permissions',
      properties: {
        value: 'permissionIds'
      },
      selectOptions: [],
      inputType: InputType.MultiSelect,
      validators: [],
      className: 'is-2'
    }
  ]

  constructor(
    activatedRoute: ActivatedRoute,
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    private customActivatedRoute: ActivatedRoute,
    private componentResourceService: ResourceService,
    private customFormBuilder: UntypedFormBuilder
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    this.initRoleCreateEditView()
  }

  async initRoleCreateEditView() {
    this.mode = this.customActivatedRoute.snapshot.data.mode
    this.redirectTo = this.customActivatedRoute.snapshot.queryParams.redirectTo

    // Get list of permissions to display.
    this.permissions = await this.componentResourceService
      .list('permissions')
      .then((res) => res)

    // Apply special rules from queryParams.
    if (this.customActivatedRoute.snapshot.queryParams.specialRules) {
      this.fieldSpecialRules = JSON.parse(
        this.customActivatedRoute.snapshot.queryParams.specialRules
      )
    }

    this.resolvedFields = await this.resolveFields(this.fields)

    // Get remote resource on edit mode.
    if (this.mode === 'edit') {
      await this.getItem(this.customActivatedRoute.snapshot.params.id)
    }

    this.form = this.generateForm(this.fields)

    // Check permissionIds boxes.
    this.permissions.forEach((permission: Permission) => {
      if (
        this.item &&
        this.item.permissions.find((p: Permission) => p.id === permission.id)
      ) {
        permission.selected = true
      }
    })
    this.form.addControl('permissionIds', this.customFormBuilder.array([]))
    this.form
      .get('permissionIds')
      .setValue(this.item ? this.item.permissions.map((p) => p.id) : [])

    this.setBreadcrumbs()
  }

  toggleSelected(permission: Permission) {
    permission.selected = !permission.selected

    const rolePermissionIds: number[] = this.form.get('permissionIds').value
    const index = rolePermissionIds.indexOf(permission.id)

    if (index !== -1) {
      rolePermissionIds.splice(index, 1)
    } else {
      rolePermissionIds.push(permission.id)
    }
  }

  selectAll() {
    this.permissions.forEach((p) => (p.selected = true))
    this.form.get('permissionIds').setValue(this.permissions.map((p) => p.id))
  }

  selectNone() {
    this.permissions.forEach((p) => (p.selected = false))
    this.form.get('permissionIds').setValue([])
  }
}
