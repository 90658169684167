import { HttpErrorResponse } from '@angular/common/http'
import { Component, HostListener, OnInit } from '@angular/core'
import { ActivatedRoute, Params } from '@angular/router'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'

import { environment } from '../../../../environments/environment'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { BookedWork } from '../../booked-work/booked-work.interface'
import { Budget } from '../../budget/budget.interface'
import { Module } from '../../module/module.interface'
import { User } from '../../user/user.interface'
import { ProjectStatus } from '../project-status.enum'
import { Project } from '../project.interface'

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {
  project: Project
  budgets: Budget[]
  bookedWorks: BookedWork[]
  bookedUsers: User[]

  selectedTab: string
  invoiceToPay: string
  invoiceToComment: string
  hasServices: boolean

  abacPermissions: string[]

  showFrameworkContractModal = false
  showDownloadProposal = false
  showValidationModal = false
  showStatusEditModal = false
  showProjectFinishedModal = false

  storagePath: string = environment.storagePath
  ProjectStatus = ProjectStatus

  constructor(
    private activatedRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private breadcrumbService: BreadcrumbService,
    private flashMessageService: FlashMessageService
  ) {}

  ngOnInit() {
    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(
        map((res) => ({
          params: res[0],
          queryParams: res[1]
        }))
      )
      .subscribe(
        async ({
          params,
          queryParams
        }: {
          params: { id: string }
          queryParams: Params
        }) => {
          // Query params management.
          this.invoiceToPay = queryParams.invoiceToPay
          this.invoiceToComment = queryParams.invoiceToComment

          if (queryParams.selectedTab) {
            this.selectedTab = queryParams.selectedTab
          }

          this.showValidationModal = !!queryParams.showValidationModal

          this.resourceService
            .show('policies/projects', params.id)
            .then((abacPermissionRes: string[]) => {
              this.abacPermissions = abacPermissionRes

              if (!queryParams.selectedTab) {
                this.selectedTab = this.isUserAllowedToAccess('seeFollowUp')
                  ? 'followUp'
                  : 'activity'
              }

              this.getProject(params.id).then(
                async ([projectRes, bookedUserRes]: [Project, User[]]) => {
                  this.project = projectRes

                  this.bookedUsers = bookedUserRes

                  if (
                    this.project.customer?.frameworkContract &&
                    queryParams.resourceCreated === 'true'
                  ) {
                    this.showFrameworkContractModal = true
                  }

                  this.resourceService
                    .list('budgets', { projectId: params.id })
                    .then((budgetRes: Budget[]) => {
                      this.budgets = budgetRes

                      this.hasServices = this.hasAtLeastOneService(this.budgets)
                    })
                }
              )
            })
        }
      )
  }

  async getProject(id: string | number): Promise<[Project, User[]]> {
    this.project = null

    return Promise.all([
      this.resourceService.show('projects', id).then((projectRes: Project) => {
        // Set Meta and Breadcrumbs.
        this.breadcrumbService.breadcrumbLinks.next([
          {
            label: 'Missions',
            path: '/missions'
          },
          {
            label: projectRes.label
          }
        ])
        return projectRes
      }),

      // Get Booked users.
      this.isUserAllowedToAccess('seeFollowUp')
        ? this.resourceService
            .list(`projects/${id}/booked-works`)
            .then((bookedWorkRes: BookedWork[]) => {
              this.bookedWorks = bookedWorkRes
              return bookedWorkRes.reduce(
                (acc: User[], bookedWork: BookedWork) => {
                  if (!acc.find((u) => u.id === bookedWork.user.id)) {
                    acc.push(bookedWork.user)
                  }
                  return acc
                },
                []
              )
            })
        : Promise.resolve([])
    ]).then((results: [Project, User[]]) => results)
  }

  downloadProposal(budgetId: number, language: string) {
    this.resourceService
      .show('budgets', budgetId, 'proposal', { language })
      .then((res: { path: string }) => {
        window.open(`${environment.storagePath}/${res.path}`)
      })
  }

  downloadDetailedExport(budgetId: number) {
    this.resourceService
      .show('budgets', budgetId, 'detailed-export')
      .then((res: { path: string }) => {
        window.open(`${environment.storagePath}/${res.path}`)
      })
  }

  hasAtLeastOneService(budgets: Budget[]): boolean {
    let hasServices = false
    budgets.forEach((budget: Budget) => {
      if (budget.services && budget.services.length) {
        hasServices = true
      }

      budget.modules.forEach((module: Module) => {
        if (module.services && module.services.length) {
          hasServices = true
        }

        module.modules.forEach((subModule: Module) => {
          if (subModule.services && subModule.services.length) {
            hasServices = true
          }
        })
      })
    })
    return hasServices
  }

  onValidationModalClosed(hasValidatedProject: boolean) {
    delete this.showValidationModal

    if (hasValidatedProject) {
      this.project.status = ProjectStatus.Proposal
    }

    this.reload()
  }

  reload(): void {
    this.getProject(this.project.id).then(
      async ([projectRes, bookedUserRes]: [Project, User[]]) => {
        this.project = projectRes
        this.bookedUsers = bookedUserRes
      }
    )
  }

  abandon(isAbandoned: boolean) {
    const confirmMessage: string = isAbandoned
      ? 'Êtes-vous sûr de vouloir abandonner cette mission ? Toutes les factures associées seront DEFINITIVEMENT SUPPRIMEES.'
      : 'Êtes-vous sûr de vouloir ré-activer cette mission abandonnée ?'

    if (confirm(confirmMessage)) {
      this.resourceService
        .patch('projects', this.project.id, 'abandon', {
          isAbandoned
        })
        .subscribe(
          (res) => this.reload(),
          (error: { error: HttpErrorResponse }) => {
            this.flashMessageService.error(error.error.message)
          }
        )
    }
  }

  isUserAllowedToAccess(policy: string): boolean {
    return this.abacPermissions?.includes(policy)
  }

  // Click outside closes list
  @HostListener('document:click', ['$event.target'])
  clickOut(eventTarget) {
    if (
      this.showDownloadProposal &&
      !eventTarget.className.includes('button')
    ) {
      this.showDownloadProposal = false
    }
  }
}
