import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'

import { BudgetItem } from '../../budget/budget-item'

@Component({
  selector: 'app-module-create-edit',
  templateUrl: './module-create-edit.component.html',
  styleUrls: ['./module-create-edit.component.scss']
})
export class ModuleCreateEditComponent implements OnInit {
  @Input() module: BudgetItem

  @Output() moduleChanged: EventEmitter<{ name: string }> = new EventEmitter()
  @Output() closeForm: EventEmitter<void> = new EventEmitter()

  @ViewChild('nameInput') nameInputEl: ElementRef

  form: UntypedFormGroup = this.formBuilder.group({
    name: ['', Validators.required]
  })

  constructor(
    private formBuilder: UntypedFormBuilder,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.form.setValue({
      name: this.module.name
    })
    setTimeout(() => this.nameInputEl.nativeElement.focus(), 0)
  }

  // Click outside closes form.
  @HostListener('document:click', ['$event.target'])
  clickOut(eventTarget) {
    if (
      !this.elementRef.nativeElement.contains(eventTarget) &&
      !eventTarget.className.includes('extend-item-button')
    ) {
      this.moduleChanged.emit(this.form.value)
      this.closeForm.emit()
    }
  }

  @HostListener('document:keydown.enter', ['$event']) onEnterKeydown() {
    this.moduleChanged.emit(this.form.value)
    this.closeForm.emit()
  }
}
