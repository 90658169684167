<nav class="navbar" role="navigation" aria-label="main navigation" #menu>
  <div>
    <div
      class="dropdown is-right"
      [ngClass]="{ 'is-active': showUserMenu }"
      *ngIf="currentUser"
    >
      <div class="user-box">
        <a
          [routerLink]="['/collaborateurs', 'myself']"
          aria-haspopup="true"
          aria-controls="dropdown-menu2"
        >
          <app-image
            [path]="currentUser.image"
            [title]="currentUser.name"
            [alt]="currentUser.name"
            className="avatar-sm"
          >
          </app-image>
        </a>
      </div>
    </div>
    <a class="logo" routerLink="/" (click)="closeMenu()">
      <img src="/assets/img/logo.svg" width="84" height="32" *ngIf="!isAlt" />
      <img
        src="/assets/img/logo-alt.svg"
        width="84"
        height="32"
        *ngIf="isAlt"
      />
    </a>

    <a
      role="button"
      class="navbar-burger burger"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
      (click)="toggleMenu()"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>
  <div class="hiding-layer" #hidingLayer></div>
  <div class="navbar-menu" id="create-dropdown" #menuItems>
    <div class="navbar-end">
      <ul class="menu-list m-t-gutter menu-list--top">
        <li
          *abcHasPermission="{
            requireOr: ['browseProjects', 'browseOwnProjects']
          }"
        >
          <a routerLink="/missions" routerLinkActive="is-active">
            <i class="icon m-r-md icon-grid is-size-4"></i>
            <span class="p-l-xs">Missions</span>
          </a>
        </li>
        <li>
          <a (click)="toggleAccordion('analytics')" class="has-submenu">
            <span>
              <i class="icon m-r-md icon-bar-chart is-size-4"></i>
              <span class="p-l-xs">Analytique</span>
            </span>
            <i
              class="icon icon-chevron-down m-r-xs"
              *ngIf="activeAccordion !== 'analytics'"
            ></i>
            <i
              class="icon icon-chevron-up m-r-xs"
              *ngIf="activeAccordion === 'analytics'"
            ></i>
          </a>
          <ul *ngIf="activeAccordion === 'analytics'" class="last-ul">
            <li *abcHasPermission="'browseAnalytics'">
              <a
                routerLink="/analytics/rapport-suivi-missions"
                routerLinkActive="is-active"
              >
                <span class="p-l-xs">Suivi des missions</span>
              </a>
            </li>
            <li>
              <a
                routerLink="/analytics/rapport-planning-individuels"
                routerLinkActive="is-active"
              >
                <span class="p-l-xs">Plannings individuels</span>
              </a>
            </li>
            <li *abcHasPermission="'browseBookedWorkReport'">
              <a
                routerLink="/analytics/rapport-reservations"
                routerLinkActive="is-active"
              >
                <span class="p-l-xs">Rapport des réservations</span>
              </a>
            </li>
            <li *abcHasPermission="'browseAnalytics'">
              <a
                routerLink="/analytics/business-review"
                routerLinkActive="is-active"
              >
                <span class="p-l-xs">Business review</span>
              </a>
            </li>
            <!-- <li *abcHasPermission="'browseAnalytics'">
              <a routerLink="/analytics/cut-off" routerLinkActive="is-active">
                <span class="p-l-xs">Cut off</span>
              </a>
            </li> -->
          </ul>
        </li>
        <!-- User sub menu -->
        <li
          *abcHasPermission="{
            requireOr: [
              'browseUsers',
              'browseTimeSheets',
              'browseOwnTimeSheets',
              'browseProjects'
            ]
          }"
        >
          <a (click)="toggleAccordion('user')" class="has-submenu">
            <span>
              <i class="icon m-r-md icon-user is-size-4"></i>
              <span class="p-l-xs">Collaborateurs</span>
            </span>
            <i
              class="icon icon-chevron-down m-r-xs"
              *ngIf="activeAccordion !== 'user'"
            ></i>
            <i
              class="icon icon-chevron-up m-r-xs"
              *ngIf="activeAccordion === 'user'"
            ></i>
          </a>

          <ul *ngIf="activeAccordion === 'user'">
            <li *abcHasPermission="'browseUsers'">
              <a routerLink="/collaborateurs" routerLinkActive="is-active">
                <span class="p-l-xs">Collaborateurs</span>
              </a>
            </li>
            <li *abcHasPermission="'browseTimeSheets'">
              <a routerLink="/time-sheets" routerLinkActive="is-active">
                <span class="p-l-xs">Timesheets</span>
              </a>
            </li>
            <li
              *abcHasPermission="{
                requireAnd: ['browseOwnTimeSheets'],
                hideIf: ['browseTimeSheets']
              }"
            >
              <a routerLink="/mes-time-sheets" routerLinkActive="is-active">
                <span class="p-l-xs">Mes Timesheets</span>
              </a>
            </li>
            <li *abcHasPermission="'browseProjects'">
              <a routerLink="/primes" routerLinkActive="is-active">
                <span class="p-l-xs">Primes</span>
              </a>
            </li>
          </ul>
        </li>
        <li
          *abcHasPermission="{
            requireOr: ['browseCustomers', 'browseReferents']
          }"
        >
          <a (click)="toggleAccordion('customer')" class="has-submenu">
            <span>
              <i class="icon m-r-md icon-briefcase is-size-4"></i>
              <span class="p-l-xs">CRM</span>
            </span>
            <i
              class="icon icon-chevron-down m-r-xs"
              *ngIf="activeAccordion !== 'customer'"
            ></i>
            <i
              class="icon icon-chevron-up m-r-xs"
              *ngIf="activeAccordion === 'customer'"
            ></i>
          </a>
          <ul *ngIf="activeAccordion === 'customer'">
            <li *abcHasPermission="'browseCustomers'">
              <a routerLink="/clients" routerLinkActive="is-active">
                <span class="p-l-xs">Clients</span>
              </a>
            </li>
            <li *abcHasPermission="'browseReferents'">
              <a routerLink="/responsables" routerLinkActive="is-active">
                <span class="p-l-xs">Contacts client</span>
              </a>
            </li>
          </ul>
        </li>
        <li *abcHasPermission="'browseInvoices'">
          <a (click)="toggleAccordion('invoice')" class="has-submenu">
            <span>
              <i class="icon m-r-md icon-file-text is-size-4"></i>
              <span class="p-l-xs">Facturation</span>
            </span>
            <i
              class="icon icon-chevron-down m-r-xs"
              *ngIf="activeAccordion !== 'invoice'"
            ></i>
            <i
              class="icon icon-chevron-up m-r-xs"
              *ngIf="activeAccordion === 'invoice'"
            ></i>
          </a>
          <ul *ngIf="activeAccordion === 'invoice'">
            <li *abcHasPermission="'browseInvoices'">
              <a routerLink="/factures" routerLinkActive="is-active">
                <span class="p-l-xs">Factures</span>
              </a>
            </li>
          </ul>
        </li>

        <li
          *abcHasPermission="{
            requireOr: [
              'browsePositions',
              'editDaysOff',
              'browseServiceTypes',
              'browseDeliverableTypes',
              'browseAnalytics',
              'browseDepartments'
            ]
          }"
        >
          <a (click)="toggleAccordion('humanResource')" class="has-submenu">
            <span>
              <i class="icon m-r-md icon-settings is-size-4"></i>
              <span class="p-l-xs">Paramètres</span>
            </span>
            <i
              class="icon icon-chevron-down m-r-xs"
              *ngIf="activeAccordion !== 'humanResource'"
            ></i>
            <i
              class="icon icon-chevron-up m-r-xs"
              *ngIf="activeAccordion === 'humanResource'"
            ></i>
          </a>
          <ul *ngIf="activeAccordion === 'humanResource'" class="last-ul">
            <li *abcHasPermission="'browsePositions'">
              <a routerLink="/profils" routerLinkActive="is-active">
                <span class="p-l-xs">Profils</span>
              </a>
            </li>
            <li *abcHasPermission="'browseDepartments'">
              <a routerLink="/practices" routerLinkActive="is-active">
                <span class="p-l-xs">Practices</span>
              </a>
            </li>
            <li *abcHasPermission="'browseObjectives'">
              <a routerLink="/objectifs" routerLinkActive="is-active">
                <span class="p-l-xs">Objectifs de practices</span>
              </a>
            </li>
            <li *abcHasPermission="'editDaysOff'">
              <a
                routerLink="/jours-off"
                [queryParams]="defaultDaysOffParams"
                routerLinkActive="is-active"
              >
                <span class="p-l-xs">Jours off</span>
              </a>
            </li>

            <li *abcHasPermission="'browseServiceTypes'">
              <a routerLink="/types-de-prestation" routerLinkActive="is-active">
                <span class="p-l-xs">Types de prestation</span>
              </a>
            </li>
            <li *abcHasPermission="'browseDeliverableTypes'">
              <a routerLink="/livrables" routerLinkActive="is-active">
                <span class="p-l-xs">Livrables</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="menu-list menu-list--bottom">
        <li>
          <a routerLink="/logout" (click)="closeMenu(); logout()">
            <i class="icon m-r-md icon-log-out is-size-4"></i>
            <span class="p-l-xs">Déconnexion</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
