import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { combineLatest } from 'rxjs'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { expenseDefinition } from '../expense.definition'

@Component({
  selector: 'app-expense-create-edit',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class ExpenseCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = expenseDefinition

  fields: Field[] = [
    {
      id: 'projectId',
      label: 'Mission',
      placeholder: `Choisissez une mission...`,
      properties: {
        projectId: 'projectId'
      },
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      searchResources: ['projects'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'is-12'
    },
    {
      label: 'Libellé',
      properties: {
        value: 'name'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'is-12-mobile is-6-tablet'
    },
    {
      label: 'Référence',
      properties: {
        value: 'reference'
      },
      inputType: InputType.Text,
      validators: [],
      className: 'is-12-mobile is-6-tablet is-3-widescreen'
    },
    {
      label: 'Montant HT',
      placeholder: 'Montant HT',
      properties: {
        value: 'amount'
      },
      inputType: InputType.Number,
      min: 0,
      validators: [Validators.required],
      className: 'is-12-mobile is-6-tablet is-3-widescreen'
    },
    {
      label: 'Reçu',
      placeholder: 'Choisir le reçu',
      properties: { value: 'attachment' },
      className: 'is-12-mobile is-6-tablet is-3-widescreen',
      inputType: InputType.File,
      validators: []
    },
    {
      label: 'Refacturable au client',
      placeholder: 'Refacturable au client',
      properties: { value: 'billableToCustomer' },
      initialValue: { value: false },
      className: 'is-12-mobile is-6-tablet is-3-widescreen no-label',
      inputType: InputType.Checkbox,
      validators: []
    },
    {
      label: `Date d'achat`,
      placeholder: `Date d'achat..`,
      properties: {
        value: 'date'
      },
      inputType: InputType.Datepicker,
      validators: [],
      className: 'is-12-mobile is-6-tablet is-3-widescreen'
    },
    {
      label: `Date de paiement`,
      placeholder: `Date de paiement`,
      properties: {
        value: 'paymentDate'
      },
      inputType: InputType.Datepicker,
      validators: [],
      className: 'is-12-mobile is-6-tablet is-3-widescreen'
    },
    {
      label: 'Commentaires',
      placeholder: 'Commentaires...',
      properties: {
        value: 'comments'
      },
      inputType: InputType.Text,
      validators: [],
      className: 'is-12'
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private componentActivatedRoute: ActivatedRoute
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    combineLatest([
      this.componentActivatedRoute.data,
      this.componentActivatedRoute.params
    ]).subscribe({
      next: ([data, params]) => {
        if (data.projectRestricted) {
          const projectField: Field = this.fields.find(
            (field) => field.id === 'projectId'
          )
          projectField.initialValue = {
            projectId: params.projectId
          }
          projectField.readonly = true
          this.definition.slug = `projects/${params.projectId}/expenses`
          this.redirectTo = `/missions/${params.projectId}?selectedTab=accounting`
        }
        this.initCreateEditView()
      }
    })
  }
}
