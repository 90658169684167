import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { YieldType } from '../../common/enums/yield-type.enum'
import { InputType } from '../../common/enums/input-type.enum'
import { Validators } from '@angular/forms'

export const documentDefinition: ResourceDefinition = {
  title: 'Documents',
  nameSingular: 'document',
  namePlural: 'documents',
  gender: Gender.Masculine,
  slug: 'documents',
  path: 'documents',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [LinkType.CREATE],
  defaultLink: null,
  dropdownLinks: [
    {
      type: LinkType.DELETE,
      permission: 'deleteDocuments'
    }
  ],
  yields: [
    {
      label: '',
      property: 'path',
      width: '10%',
      type: YieldType.FileIcon
    },
    {
      label: 'Nom',
      property: 'name'
    },
    {
      label: '',
      property: 'path',
      width: 53,
      type: YieldType.Download
    }
  ]
}
