import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  ViewChild
} from '@angular/core'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'

import { InputType } from '../../../common/enums/input-type.enum'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Deliverable } from '../../deliverable/deliverable.interface'
import { EstimatedWork } from '../../estimated-work/estimated-work.interface'
import { Module } from '../../module/module.interface'
import { Service } from '../../service/service.interface'
import { Budget } from '../budget.interface'

@Component({
  selector: 'app-budget-create-edit',
  templateUrl: './budget-create-edit.component.html',
  styleUrls: ['./budget-create-edit.component.scss']
})
export class BudgetCreateEditComponent implements OnChanges {
  @Input() budget: Budget
  @Input() isReadonly: boolean
  @ViewChild('discountPercentageInput')
  discountPercentageInputEl: ElementRef

  form: UntypedFormGroup = this.formBuilder.group({
    id: [null, Validators.required],
    name: [null, Validators.required],
    comments: null,
    commentsUpdatedAt: null,
    discount: 0,
    managementFeesPercentage: null,
    modules: new UntypedFormArray([]),
    services: new UntypedFormArray([]),
    isAllServicesAndModuleHaveRequiredProps: [true, Validators.requiredTrue]
  })

  formChangeSubscription: Subscription

  budgetChanged = false
  showEditCommentModal = false
  isDiscountPercentageMode = false
  userConfirmsLeave = false
  discountPercentage: number
  servicesAmount: number
  comments: string
  InputType = InputType

  loading = false
  loadingUpdateBudget = false

  get moduleControls(): UntypedFormArray {
    return this.form.get('modules') as UntypedFormArray
  }

  get serviceControls(): UntypedFormArray {
    return this.form.get('services') as UntypedFormArray
  }

  // Get sum of budget services in 3 levels.
  static getBudgetServicesAmount(budget: {
    services: Service[]
    modules: Module[]
  }): number {
    let amount = 0
    budget.services.forEach((s: Service) => {
      amount += s.amount
    })
    budget.modules.forEach((m: Module) => {
      m.services.forEach((s: Service) => {
        amount += s.amount
      })
      m.modules.forEach((subModule: Module) => {
        subModule.services.forEach((s: Service) => {
          amount += s.amount
        })
      })
    })

    return amount
  }

  constructor(
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnChanges() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe()
    }

    // Reset form.
    this.form.reset({
      id: this.budget.id,
      name: this.budget.name,
      comments: this.budget.comments,
      commentsUpdatedAt: this.budget.commentsUpdatedAt,
      discount: this.budget.discount,
      managementFeesPercentage: Number(
        (this.budget.managementFeesPercentage || 0).toFixed(2)
      ),
      modules: new UntypedFormArray([]),
      services: new UntypedFormArray([])
    })
    this.setModuleAndServiceFormControls({
      modules: this.budget.modules,
      services: this.budget.services
    })

    // Set discount percentage.
    this.calculateDiscountPercentage()

    this.formChangeSubscription = this.form.valueChanges.subscribe((values) => {
      this.budgetChanged = true
      this.userConfirmsLeave = false
    })
  }

  updateBudget(): void {
    this.loadingUpdateBudget = true
    this.resourceService
      .update(
        `projects/${this.budget.project.id}/budgets`,
        this.budget.id,
        this.form.value
      )
      .subscribe(
        (updateBudgetRes: Budget) => {
          this.loadingUpdateBudget = false
          // Update OK. We reload page to display updated budget.
          this.flashMessageService.success(
            `Le budget "${updateBudgetRes.name}" a bien été enregistré.`
          )
          this.router.navigate(['/missions', this.budget.project.id], {
            queryParams: {
              selectedTab: 'budget',
              reload: new Date().toISOString()
            }
          })
        },
        (err) => {
          this.loadingUpdateBudget = false
          this.flashMessageService.error(
            'Une erreur a eu lieu lors de la mise à jour du budget.'
          )
        }
      )
  }

  onBudgetPropsChanged({
    modules,
    services
  }: {
    modules: Module[]
    services: Service[]
  }): void {
    this.setModuleAndServiceFormControls({ modules, services })

    // If discount is set by percentage, we change absolute discount to maintain that percentage true.
    if (this.isDiscountPercentageMode) {
      this.form
        .get('discount')
        .setValue(
          Number(
            ((this.servicesAmount * this.discountPercentage) / 100).toFixed(2)
          )
        )
    } else {
      this.calculateDiscountPercentage()
    }
  }

  setModuleAndServiceFormControls({
    modules,
    services
  }: {
    modules: Module[]
    services: Service[]
  }): void {
    // Validate that we have mandatory values for each module and service in the hierarchy.
    this.form
      .get('isAllServicesAndModuleHaveRequiredProps')
      .setValue(
        services.every((s) => s.name && (s.serviceTypeId || s.type)) &&
          modules.every(
            (m) =>
              m.name &&
              m.services.every(
                (sS) => sS.name && (sS.serviceTypeId || sS.type)
              ) &&
              m.modules.every(
                (sM) =>
                  sM.name &&
                  sM.services.every(
                    (sSS) => sSS.name && (sSS.serviceTypeId || sSS.type)
                  )
              )
          )
      )

    this.form.patchValue(
      {
        modules,
        services
      },
      { emitEvent: true }
    )
    this.servicesAmount = BudgetCreateEditComponent.getBudgetServicesAmount({
      modules,
      services
    })

    this.setModulesControlRecursive(this.form, modules)
    this.setServicesControl(this.form, services)
  }

  setModulesControlRecursive(formGroup: UntypedFormGroup, modules: Module[]): void {
    formGroup.setControl('modules', new UntypedFormArray([]))
    const moduleControls: UntypedFormArray = formGroup.get('modules') as UntypedFormArray

    if (modules && modules.length) {
      modules.forEach((module: Module) => {
        const moduleFormGroup: UntypedFormGroup = this.formBuilder.group(module)

        this.setServicesControl(moduleFormGroup, module.services)
        this.setModulesControlRecursive(moduleFormGroup, module.modules)

        moduleControls.push(moduleFormGroup)
      })
    }
  }

  setServicesControl(formGroup: UntypedFormGroup, services: Service[]): void {
    formGroup.setControl('services', new UntypedFormArray([]))
    const serviceControls: UntypedFormArray = formGroup.get('services') as UntypedFormArray
    services.forEach((service: Service) => {
      const serviceFormGroup: UntypedFormGroup = this.formBuilder.group(service)

      this.setEstimatedWorksControl(serviceFormGroup, service.estimatedWorks)
      this.setDeliverablesControl(serviceFormGroup, service.deliverables)

      serviceControls.push(serviceFormGroup)
    })
  }

  setEstimatedWorksControl(
    formGroup: UntypedFormGroup,
    estimatedWorks: EstimatedWork[]
  ): void {
    formGroup.setControl('estimatedWorks', new UntypedFormArray([]))
    const estimatedWorkControls: UntypedFormArray = formGroup.get(
      'estimatedWorks'
    ) as UntypedFormArray
    estimatedWorks.forEach((estimatedWork: EstimatedWork) => {
      estimatedWorkControls.push(this.formBuilder.group(estimatedWork))
    })
  }

  setDeliverablesControl(
    formGroup: UntypedFormGroup,
    deliverables: Deliverable[]
  ): void {
    formGroup.setControl('deliverables', new UntypedFormArray([]))
    const deliverableControls: UntypedFormArray = formGroup.get(
      'deliverables'
    ) as UntypedFormArray
    deliverables.forEach((deliverable: Deliverable) => {
      deliverableControls.push(this.formBuilder.group(deliverable))
    })
  }

  saveComments() {
    this.form.get('comments').setValue(this.comments)
    this.budget.comments = this.comments
    this.budget.commentsUpdatedAt = new Date()
  }

  calculateDiscountPercentage(): void {
    this.discountPercentage = Number(
      ((this.form.value.discount / this.servicesAmount) * 100).toFixed(2)
    )

    if (this.discountPercentageInputEl) {
      this.discountPercentageInputEl.nativeElement.value =
        this.discountPercentage
    }
  }

  changeDiscountMode(switchToPercentage: boolean) {
    this.isDiscountPercentageMode = switchToPercentage
    if (this.isDiscountPercentageMode) {
      setTimeout(() => this.calculateDiscountPercentage(), 0)
    }
  }

  onDiscountPercentageChange(percentage: number): void {
    this.discountPercentage = percentage
    this.form
      .get('discount')
      .setValue(Number(((this.servicesAmount * percentage) / 100).toFixed(2)))
  }

  // Prevent users to leave the form without saving it.
  onOverlayClick($event) {
    if ($event.target.className === 'prevent-exit') {
      if (
        confirm(
          'Vous vous apprêtez à quitter un formulaire non sauvegardé. Les modifications ne seront pas prises en compte'
        )
      ) {
        this.userConfirmsLeave = true
      }
    }
  }

  // Click outside closes list.
  @HostListener('document:click', ['$event.target'])
  clickOut(eventTarget) {
    if (
      this.showEditCommentModal &&
      eventTarget.className.includes('modal-background')
    ) {
      this.showEditCommentModal = false
    }
  }
}
