<div class="columns m-b-gutter flex flex-wrap">
  <!-- Chart -->
  <div
    class="column is-12-mobile is-12-tablet is-7-desktop is-8-widescreen is-9-fullhd p-x-0-mobile"
  >
    <div class="card p-b-colgap m-t-md full-height">
      <h3 class="title--small has-text-black">
        Gestion de mon temps en jours (seulement {{ currentUser.name }})
      </h3>
      <div class="full-height">
        <div style="display: block" class="full-height">
          <canvas
            baseChart
            [datasets]="chartData"
            [labels]="['Gestion de mon temps']"
            [options]="chartOptions"
            [legend]="false"
            [type]="'bar'"
            *ngIf="chartData"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>

  <!-- Totals -->
  <div
    class="column is-12-mobile is-12-tablet is-5-desktop is-4-widescreen is-3-fullhd p-x-0-mobile"
  >
    <div class="card p-b-colgap m-t-md full-height">
      <h3 class="title--small has-text-black">Totaux</h3>

      <ul class="has-mini-circle">
        <li class="flex space-between align-center list-separated flex-mobile">
          <div>
            <span class="total-label">
              <div class="color-yield-wrapper">
                <div class="small-circle m-a-0 bg-link"></div>
                <span class="m-l-sm">Reservé</span>
              </div>
            </span>
          </div>
          <div class="has-text-right">
            <span> {{ bookedWorkTotalDaysOfWork | number : '1.0-2' }}j </span>
          </div>
        </li>
        <li class="flex space-between align-center list-separated flex-mobile">
          <div>
            <span class="total-label">
              <div class="color-yield-wrapper">
                <div class="small-circle m-a-0 bg-info"></div>
                <span class="m-l-sm">Timesheet</span>
              </div>
            </span>
          </div>
          <div class="has-text-right">
            <span
              class="tooltip has-tooltip has-text-link is-bold"
              data-tooltip="TS / jours reservés"
              *ngIf="bookedWorkTotalDaysOfWork"
            >
              {{
                timeSheetTotalDaysOfWork / bookedWorkTotalDaysOfWork | percent
              }}</span
            >
            <span> {{ timeSheetTotalDaysOfWork | number : '1.0-2' }}j </span>
          </div>
        </li>
        <li class="flex space-betxeen align-center list-separated flex-mobile">
          <div>
            <span class="total-label">
              <div class="color-yield-wrapper">
                <div class="small-circle m-a-0 bg-plan"></div>
                <span class="m-l-sm">Temps planifié</span>
              </div>
            </span>
          </div>

          <div class="has-text-right">
            <span
              class="tooltip has-tooltip has-text-link is-bold"
              data-tooltip="jours planifés / jours reservés"
              *ngIf="bookedWorkTotalDaysOfWork"
            >
              {{
                plannedWorkTotalDaysOfWork / bookedWorkTotalDaysOfWork | percent
              }}</span
            >
            <span>{{ plannedWorkTotalDaysOfWork | number : '1.0-2' }}j</span>
          </div>
        </li>
        <li class="flex space-betxeen align-center list-separated flex-mobile">
          <div>
            <span class="total-label">
              <div class="color-yield-wrapper">
                <div class="small-circle m-a-0 bg-link-light"></div>
                <span class="m-l-sm">Temps restant</span>
              </div>
            </span>
          </div>
          <div class="has-tex-tright">
            <span
              class="tooltip has-tooltip has-text-link is-bold"
              *ngIf="bookedWorkTotalDaysOfWork"
              data-tooltip="Temps restant / jours reservés"
            >
              {{
                remainingDaysOfWork / bookedWorkTotalDaysOfWork | percent
              }}</span
            >
            <span>{{ remainingDaysOfWork | number : '1.0-2' }}j</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
