<div class="welcome-wrapper">
  <img src="../../../../assets/img/error404.svg" alt="Error 404" width="360" />
  <h1 class="title is-1">Page introuvable</h1>
  <p>Il se peut que cette page soit privée, ou qu'elle n'éxiste plus.</p>
  <p>
    <a routerLink="/missions" class="button is-link m-t-gutter m-r-sm">
      Voir la liste des missions
    </a>
  </p>
</div>
