<div class="welcome-wrapper">
  <img
    src="../../../../assets/img/welcome.svg"
    alt="Error 404"
    width="360"
    *ngIf="!isAlt"
  />
  <img
    src="../../../../assets/img/welcome-alt.svg"
    alt="Error 404"
    width="360"
    *ngIf="isAlt"
  />
  <h1 class="title is-1" *ngIf="!isAlt">Bienvenue sur Zèle !</h1>
  <h1 class="title is-1" *ngIf="isAlt">Bienvenue sur Zèle - SGE !</h1>

  <p
    *abcHasPermission="{
      requireOr: ['browseProjects', 'browseOwnProjects']
    }"
  >
    <a
      routerLink="/missions"
      class="button is-link m-t-gutter m-r-sm"
      routerLinkActive="is-active"
    >
      Voir la liste des missions
    </a>
  </p>
</div>
