export const appConstants = {
  storagePath: 'public/storage',
  defaultResultsPerPage: 20,

  hoursPerDay: 8,

  roleNames: {
    consultant: 'consultant',
    manager: 'manager',
    partner: 'partner',
    officeManager: 'officeManager'
  },
  holidayLabel: 'Congés',

  // Format is same as Sharp : http://sharp.pixelplumbing.com/en/stable/api-resize/#resize
  imageSizes: {
    users: {
      thumbnail: {
        width: 80,
        height: 80
      }
    },
    customers: {
      thumbnail: {
        width: 80,
        height: 80
      }
    }
  },

  flashMessageTimeOut: 6000,

  breakpoints: {
    touch: 999,
    widescreen: 1457
  }
}
