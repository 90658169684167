import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

export const objectiveDefinition: ResourceDefinition = {
  title: 'Objectifs',
  nameSingular: 'objectif',
  namePlural: 'objectifs',
  gender: Gender.Masculine,
  slug: 'objectives',
  path: 'objectifs',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editObjectives'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteObjectives'
    }
  ],
  yields: [
    {
      label: 'Practice',
      property: 'department.color',
      secondProperty: 'department.name',
      className: 'has-mini-circle',
      orderByProperty: 'department.name',
      type: YieldType.Color
    },
    {
      label: 'Année',
      property: 'year'
    },
    {
      label: 'Montant',
      property: 'amount',
      type: YieldType.Currency
    }
  ]
}
