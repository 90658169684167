<div class="flex space-between align-end flex-mobile m-t-gutter m-b-colgap">
  <div>
    <h3 class="title--small has-text-black p-l-lg">Suivi de la mission :</h3>
  </div>
  <div class="flex justify-end align-center flex-mobile is-hidden-mobile">
    <span class="badge is-link m-r-gutter" *ngIf="totalBookedWorks"
      >Jours reservés : {{ totalBookedWorks | number : '1.0-2' }}</span
    ><br />
    <span class="badge is-link m-r-gutter" *ngIf="totalTimeSheets"
      >TS : {{ totalTimeSheets | number : '1.0-2' }}</span
    ><br />
    <span class="badge is-link m-r-gutter" *ngIf="totalPlannedWorks"
      >Planifiés : {{ totalPlannedWorks | number : '1.0-2' }}</span
    >
  </div>
</div>
<!-- Table -->
<div class="table-responsive">
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>Collaborateur</th>
        <th>Profil</th>
        <th>Total jours réservés</th>
        <th>
          Jours réservés restants
          <span
            class="tooltip has-tooltip has-tooltip-bottom has-tooltip-multiline"
            data-tooltip="Incluant ce mois-ci."
          >
            <i class="icon icon-info is-size-5 has-text-link"></i>
          </span>
        </th>
        <th>Timesheet</th>
        <th>Planifié</th>
        <th>
          Avancement
          <span
            class="tooltip has-tooltip has-tooltip-bottom"
            data-tooltip="Timesheet / Temps réservé dans ce projet"
          >
            <i class="icon icon-info is-size-5 has-text-link"></i>
          </span>
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let user of users">
        <tr>
          <td>
            <a
              [routerLink]="['/collaborateurs', user.id]"
              class="project-user m-b-0"
              *abcHasPermission="'readUsers'"
            >
              <app-image
                [path]="user.image"
                [title]="user.name"
                [alt]="user.name"
                className="avatar-sm"
              >
              </app-image>
              <div>
                <span class="has-text-black is-regular">{{ user.name }}</span>
              </div>
            </a>
            <div
              class="project-user m-b-0"
              *abcHasPermission="{ hideIf: ['readUsers'] }"
            >
              <app-image
                [path]="user.image"
                [title]="user.name"
                [alt]="user.name"
                className="avatar-sm"
              >
              </app-image>
              <div>
                <span class="has-text-black is-regular">{{ user.name }}</span>
              </div>
            </div>
          </td>
          <td>{{ user.position.name }}</td>
          <td>{{ user.bookedWorkTotalDays | number : '1.0-2' }}</td>
          <td>{{ user.futureBookedWorkDays | number : '1.0-2' }}</td>
          <td>{{ user.timeSheetTotalDays | number : '1.0-2' }}</td>
          <td>{{ user.plannedWorkTotalDays | number : '1.0-2' }}</td>
          <td>
            <ng-container
              *ngIf="user.timeSheetTotalDays && user.bookedWorkTotalDays"
            >
              <div class="flex flex-start align-center">
                <span
                  class="p-r-sm"
                  [ngClass]="{
                    'has-text-info':
                      user.timeSheetTotalDays / user.bookedWorkTotalDays > 0.5,
                    'has-text-danger':
                      user.timeSheetTotalDays / user.bookedWorkTotalDays > 0.8
                  }"
                  >{{
                    user.timeSheetTotalDays / user.bookedWorkTotalDays | percent
                  }}</span
                >
                <abc-analog-progress-bar-yield
                  [progressPercentage]="
                    user.timeSheetTotalDays / user.bookedWorkTotalDays
                  "
                ></abc-analog-progress-bar-yield>
              </div>
            </ng-container>
          </td>
          <td class="has-text-right">
            <a
              *ngIf="user.timeSheets.length"
              (click)="toggleExpandUser(user.id)"
            >
              <i
                class="icon is-size-4 extend-item-button"
                [ngClass]="{
                  'icon-chevron-down':
                    !expandedUserId || expandedUserId !== user.id,
                  'icon-chevron-up':
                    expandedUserId && expandedUserId === user.id
                }"
              ></i>
            </a>
          </td>
        </tr>
        <ng-container *ngIf="expandedUserId && expandedUserId === user.id">
          <tr>
            <td colspan="7" class="bg-white-bis">
              <div
                class="expanded-user-box columns flex-mobile flex-wrap-mobile"
              >
                <div class="column is-12-mobile is-12-tablet">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>
                          <strong
                            >Détail des timesheets de {{ user.name }}</strong
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12-mobile is-12-tablet p-t-0">
                  <table class="table is-narrow">
                    <thead>
                      <tr>
                        <th>date</th>
                        <th>jours</th>
                        <th>prestation</th>
                        <th></th>
                        <th>tâche</th>
                        <th>livrables</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let timeSheet of user.timeSheets; let i = index"
                      >
                        <td width="150">
                          {{ timeSheet.date | date }}
                        </td>
                        <td width="130">
                          {{ timeSheet.daysOfWork | number : '1.0-2' }} j
                        </td>
                        <td width="200" class="is-truncate">
                          {{ timeSheet.service.name }}
                        </td>

                        <td></td>
                        <td>{{ timeSheet.reference }}</td>
                        <td>
                          <ul *ngIf="timeSheet.deliverables.length">
                            <li
                              *ngFor="let deliverable of timeSheet.deliverables"
                            >
                              {{ deliverable.type.name }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
