import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute, Params } from '@angular/router'
import * as moment from 'moment'

import { InputType } from '../../../common/enums/input-type.enum'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { Project } from '../../project/project.interface'
import { bonusDefinition } from './bonus.definition'

@Component({
  selector: 'app-user-bonuses',
  templateUrl: './user-bonuses.component.html',
  styleUrls: ['./user-bonuses.component.scss']
})
export class UserBonusesComponent implements OnInit {
  @Input() userId: number

  projects: Project[]
  totalBonusAmount: number

  bonusDefinition: ResourceDefinition = bonusDefinition

  InputType = InputType
  loading = false

  form: UntypedFormGroup = this.formBuilder.group({
    dateFrom: moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD'),
    dateTo: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
  })

  constructor(
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.toPromise().then((queryParams: Params) => {
      this.getBonuses(
        this.form.value.dateFrom,
        this.form.value.dateTo,
        queryParams.toXLS
      )
    })
  }

  getBonuses(dateFrom: string, dateTo: string, toXLS?: string) {
    delete this.projects
    delete this.totalBonusAmount
    this.loading = true
    this.resourceService
      .list('projects/bonuses', {
        beneficiaryId: this.userId,
        dateFrom,
        dateTo,
        toXLS
      })
      .then(
        (res: Project[]) => {
          this.loading = false
          this.projects = res
          this.totalBonusAmount = this.projects.reduce(
            (sum: number, project: Project) => sum + project.totalBonusesAmount,
            0
          )
        },
        (err) => {
          this.loading = false
        }
      )
  }
}
