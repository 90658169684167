<nav class="navbar" role="navigation" aria-label="main navigation">
  <div>
    <a class="logo" routerLink="/">
      <img src="./assets/img/logo.svg" width="84" height="32" *ngIf="!isAlt" />
      <img
        src="./assets/img/logo-alt.svg"
        width="128"
        height="32"
        *ngIf="isAlt"
      />
    </a>
  </div>

  <div class="navbar-menu" id="create-dropdown">
    <!-- Breadcrumbs -->
    <app-breadcrumbs *ngIf="!isWidescreenResolution"></app-breadcrumbs>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <span class="tag is-grey tag--event" *ngIf="!production">
            <i
              class="icon icon-info m-l-xs m-r-xs is-size-4 has-text-warning"
            ></i>
            <b>Pre-Production</b></span
          >

          <!-- Notifications -->
          <div
            class="dropdown"
            [ngClass]="{ 'is-active': showNotificationMenu }"
          >
            <div class="dropdown-trigger">
              <a
                class="is-size-3 relative become-link tooltip has-tooltip has-tooltip-bottom"
                data-tooltip="Notifications"
                aria-haspopup="true"
                aria-controls="dropdown-menu2"
                (click)="toggleNotificationMenu()"
              >
                <span class="icon is-small">
                  <i class="icon icon-bell" aria-hidden="true"></i>
                  <span class="new-notifications" *ngIf="newNotificationCount">
                    <span>{{ newNotificationCount }}</span>
                  </span>
                </span>
              </a>
            </div>
            <div class="drop--notifications" role="menu">
              <div class="notification-list">
                <div
                  class="notification-item"
                  *ngFor="let notification of notifications"
                >
                  <div
                    class="flex flex-wrap p-a-gutter justify-start align-center"
                  >
                    <div class="notification-excerpt">
                      <ng-container *ngIf="!notification.linkPath">
                        <p>
                          {{ notification.description }}
                        </p>
                      </ng-container>
                      <p *ngIf="notification.linkPath">
                        <a
                          [routerLink]="notification.linkPath"
                          [queryParams]="notification.queryParams"
                          >{{ notification.description }}
                        </a>
                      </p>
                      <p class="content is-small has-text-grey">
                        le {{ notification.date | date: ' d MMM  y à HH:mm ' }}
                      </p>
                    </div>
                    <span
                      *ngIf="!notification.checked"
                      class="notification-dot"
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Dashboard link-->
          <div>
            <a
              [routerLink]="['/collaborateurs', 'myself']"
              [queryParams]="{ selectedTab: 'dashboard' }"
              class="dropdown-item is-size-3 become-link tooltip has-tooltip has-tooltip-bottom"
              data-tooltip="Tableau de bord"
            >
              <i class="icon icon-pie-chart"></i>
            </a>
          </div>
          <!-- Missions link-->
          <div>
            <a
              [routerLink]="['/collaborateurs', 'myself']"
              [queryParams]="{ selectedTab: 'projects' }"
              class="dropdown-item is-size-3 become-link tooltip has-tooltip has-tooltip-bottom"
              data-tooltip="Mes missions "
            >
              <i class="icon icon-list"></i>
            </a>
          </div>

          <!-- Planning link-->
          <div>
            <a
              [routerLink]="['/collaborateurs', 'myself']"
              [queryParams]="{ selectedTab: 'planning' }"
              class="dropdown-item is-size-3 become-link tooltip has-tooltip has-tooltip-bottom"
              data-tooltip="Planning"
            >
              <i class="icon icon-clock"></i>
            </a>
          </div>
          <!-- TimeSheet link-->
          <div>
            <a
              [routerLink]="['/collaborateurs', 'myself']"
              [queryParams]="{ selectedTab: 'timeSheets' }"
              class="dropdown-item is-size-3 become-link tooltip has-tooltip has-tooltip-bottom"
              data-tooltip="Time sheets"
            >
              <i class="icon icon-calendar"></i>
            </a>
          </div>

          <!-- Bonus list link -->
          <div *ngIf="currentUser && currentUser.hasBonuses">
            <a
              [routerLink]="['/collaborateurs', 'myself']"
              [queryParams]="{ selectedTab: 'bonuses' }"
              class="dropdown-item is-size-3 become-link tooltip has-tooltip has-tooltip-bottom m-r-md"
              data-tooltip="Primes"
            >
              <i class="icon icon-award"></i>
            </a>
          </div>

          <!-- UserMenu dropdown -->
          <div
            class="dropdown is-right"
            [ngClass]="{ 'is-active': showUserMenu }"
            *ngIf="currentUser"
          >
            <div class="dropdown-trigger">
              <a
                aria-haspopup="true"
                aria-controls="dropdown-menu2"
                (click)="
                  showUserMenu = !showUserMenu;
                  showLinkMenu = false;
                  showNotificationMenu = false
                "
              >
                <app-image
                  [path]="currentUser.image"
                  [title]="currentUser.name"
                  [alt]="currentUser.name"
                  className="avatar-sm"
                >
                </app-image>
              </a>
            </div>
            <div class="dropdown-menu is-small" role="menu">
              <div
                class="dropdown-content is-small"
                *abcHasPermission="'editOwnUsers'"
              >
                <a
                  [routerLink]="['collaborateurs', currentUser.id, 'edit']"
                  class="dropdown-item"
                  *ngIf="canEditUsers"
                >
                  Modifier mon profil
                </a>
                <a
                  routerLink="/collaborateurs/myself/edit"
                  class="dropdown-item"
                  *ngIf="!canEditUsers"
                >
                  Modifier mon profil
                </a>
              </div>
              <div class="dropdown-content is-small">
                <a (click)="logout()" class="dropdown-item"> Deconnexion </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
