import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'app-project-finished-modal',
  templateUrl: './project-finished-modal.component.html'
})
export class ProjectFinishedModalComponent {
  @Output() closedModal: EventEmitter<void> = new EventEmitter()

  close() {
    this.closedModal.emit()
  }
}
