import { appConstants } from '../../../../app.constants'
import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { User } from '../../resources/user/user.interface'

export const bookedWorkReportDefinition: ResourceDefinition = {
  title: 'Rapport des réservations',
  nameSingular: 'réservation',
  namePlural: 'réservations',
  hasDetailPage: true,
  hasListPage: true,
  gender: Gender.Masculine,
  slug: 'analytics/booked-work-report',
  path: 'analytics/rapport-reservations',
  buttons: [LinkType.EXPORT],
  defaultLink: null,
  yields: [
    {
      label: 'Utilisateur',
      property: 'imageObjects',
      secondProperty: 'name',
      orderByProperty: 'name',
      type: YieldType.Image,
      link: (user: User) => `collaborateurs/${user.id}`
    },

    {
      label: appConstants.holidayLabel,
      property: 'holidays',
      disableOrderBy: true,
      type: YieldType.Number
    },
    {
      label: 'Signés',
      tooltip:
        'Total Jours réservés sur missions non abandonnées au statut "Acceptée par le client" et "Signées"',
      property: 'signedBookedWorkDaysOfWork',
      disableOrderBy: true,
      type: YieldType.Number
    },
    {
      label: 'Projets',
      tooltip: `Total jours réservés sur missions non abandonnées au statut "Projet", "En attente de validation" et "Proposition"`,
      property: 'projectBookedWorkDaysOfWork',
      disableOrderBy: true,
      type: YieldType.Number
    },
    {
      label: '% Planifié missions signées',
      headingClassName: 'has-text-right',
      tooltip:
        'Total jours réservés sur missions non abandonnées au statut "Acceptée par le client" et "Signées" / Jours disponibles',
      property: 'signedBookedWorkDaysOfWorkPercentage',
      secondProperty: 'name', // Hack: always truthy value to enable AnalogProgressBar opposite colors.
      disableOrderBy: true,
      type: YieldType.AnalogProgressBar
    },
    {
      label: '% Cumul',
      headingClassName: 'has-text-right has-tooltip-left',
      tooltip: `Total jours réservés sur missions non abandonnées au statut "Projet", "En attente de validation", "Proposition", "Acceptée par le client" et "Signées" / Jours disponibles`,
      property: 'projectBookedWorkDaysOfWorkPercentage',
      secondProperty: 'name', // Hack: always truthy value to enable AnalogProgressBar opposite colors.
      disableOrderBy: true,
      type: YieldType.AnalogProgressBar
    }
  ],
  keyNumbers: [
    {
      label: 'Total signés',
      className: 'is-success',
      type: YieldType.Number,
      extraParams: {
        calculate: 'signedBookedWorkDaysOfWork'
      }
    },
    {
      label: 'Total projets',
      className: 'is-success',
      type: YieldType.Number,
      extraParams: {
        calculate: 'projectBookedWorkDaysOfWork'
      }
    },
    {
      label: '% Planifié missions signées (moyenne)',
      className: 'is-success',
      type: YieldType.Percentage,
      extraParams: {
        calculate: 'signedBookedWorkDaysOfWorkPercentage'
      }
    },
    {
      label: '% Cumul (moyenne)',
      className: 'is-success',
      type: YieldType.Percentage,
      extraParams: {
        calculate: 'projectBookedWorkDaysOfWorkPercentage'
      }
    }
  ]
}
