import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { Router } from '@angular/router'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { invoiceDefinition } from '../invoice.definition'
import { Invoice } from '../invoice.interface'

@Component({
  selector: 'app-invoice-comments-modal',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit-modal.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class InvoiceCommentsModalComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  @Input() item: Invoice
  @Input() mode = 'edit'

  @Output() closedModal: EventEmitter<void> = new EventEmitter()

  definition: ResourceDefinition = invoiceDefinition
  isModal = true

  fields: Field[] = [
    {
      label: 'Commentaire de la facture',
      placeholder: 'Ajouter un commmentaire pour cette facture...',
      properties: {
        value: 'comments'
      },
      inputType: InputType.Textarea,
      validators: [],
      className: 'is-12'
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  async ngOnInit() {
    await this.initCreateEditView()
    this.definition.slug = `projects/${this.item.project.id}/invoices/comment`
  }

  close() {
    this.closedModal.emit()
  }

  @HostListener('document:keydown.escape') onEnterKeydown() {
    this.close()
  }
}
