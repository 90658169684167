import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Department } from '../../department/department.interface'
import { objectiveDefinition } from '../objective.definition'

@Component({
  selector: 'app-objective-create-edit',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class ObjectiveCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = objectiveDefinition

  fields: Field[] = [
    {
      id: 'departmentId',
      label: 'Practice',
      placeholder: 'Sélectionnez un practice',
      properties: {
        value: 'departmentId'
      },
      retrievedItemProperties: {
        departmentId: 'department.id'
      },
      inputType: InputType.Select,
      validators: [Validators.required],
      selectOptions: () =>
        this.componentResourceService
          .list('departments', { withoutPagination: true, activeOnly: true })
          .then((departmentRes: Department[]) =>
            departmentRes.map((n) => ({
              label: n.name,
              value: n.id.toString()
            }))
          ),
      className: 'is-4 p-x-0-mobile'
    },
    {
      label: `Année`,
      placeholder: `Année de l'objectif.`,
      properties: {
        value: 'year'
      },
      inputType: InputType.Number,
      min: 1,
      initialValue: {
        value: parseInt(moment().format('YYYY'))
      },
      validators: [Validators.required],
      className: 'is-4 p-x-0-mobile align-self-start'
    },
    {
      label: `Montant en €`,
      placeholder: `Montant de l'objectif en Euros (€)...`,
      properties: {
        value: 'amount'
      },
      inputType: InputType.Number,
      min: 1,
      initialValue: {
        value: 0
      },
      validators: [Validators.required],
      className: 'is-4 p-x-0-mobile align-self-start'
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private componentResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
