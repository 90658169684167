import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  static getQueryParamsFromUrl(url: string): { [key: string]: string } {
    const query = url.split('?')[1]
    const result = {}
    if (query) {
      query.split('&').forEach((part) => {
        const item = part.split('=')
        result[item[0]] = decodeURIComponent(item[1])
      })
    }
    return result
  }

  static removeQueryParamsFromUrl(url: string): string {
    return url.split('?')[0]
  }

  static uniqueArrayOfObjects(arr: any[], key: string): any[] {
    return arr.filter((item, index, self) => {
      return (
        index ===
        self.findIndex((t) => {
          return t[key] === item[key]
        })
      )
    })
  }
}
