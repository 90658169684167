<div class="flex align-center justify-center" *ngIf="mime === FileMime.Pdf">
  <img src="./assets/img/file-pdf.svg" alt="" />
</div>
<div class="flex align-center justify-center" *ngIf="mime === FileMime.Word">
  <img src="./assets/img/file-doc.svg" alt="" />
</div>
<div class="flex align-center justify-center" *ngIf="mime === FileMime.Excel">
  <img src="./assets/img/file-xls.svg" alt="" />
</div>
<div class="flex align-center justify-center" *ngIf="mime === FileMime.Ppt">
  <img src="./assets/img/file-ppt.svg" alt="" />
</div>
<div class="flex align-center justify-center" *ngIf="mime === FileMime.Other">
  <img src="./assets/img/file-other.svg" *ngIf="!isAlt" />
  <img src="./assets/img/file-other-alt.svg" *ngIf="isAlt" />
</div>
