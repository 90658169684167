import { Validators } from '@angular/forms'

import { Gender } from '../../common/enums/gender.enum'
import { InputType } from '../../common/enums/input-type.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'

// Holidays are a TimeSheet resource (pre-formatted on the back-office).aa
export const holidayDefinition: ResourceDefinition = {
  title: 'Jour de congé',
  nameSingular: 'jour de congé',
  namePlural: 'jours de congés',
  gender: Gender.Masculine,
  slug: 'time-sheets/holidays',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.EXPORT],
  defaultLink: null,
  yields: []
}
