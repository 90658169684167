import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { dayOffDefinition } from '../day-off.definition'
import * as moment from 'moment'

@Component({
  selector: 'app-day-off-list',
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class DayOffListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = dayOffDefinition

  filters: Filter[] = [
    {
      label: 'Année',
      properties: {
        value: 'year'
      },
      placeholder: `Sélectionner une année`,
      selectOptions: [0, 1, 2, 3, 4, 5].map((index: number) => {
        const year: string = moment().add(index, 'years').format('YYYY')
        return {
          label: year,
          value: year
        }
      }),
      inputType: InputType.Select,
      className: 'p-x-0-mobile is-12-mobile is-6'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
