import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core'
import { Router } from '@angular/router'
import { DndDropEvent } from 'ngx-drag-drop'
import { Subject } from 'rxjs'

import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Module } from '../../module/module.interface'
import { Project } from '../../project/project.interface'
import { Service } from '../../service/service.interface'
import { BudgetItem } from '../budget-item'

enum BudgetableNature {
  Service,
  Module
}

@Component({
  selector: 'app-budget-drag-and-drop-form',
  templateUrl: './budget-drag-and-drop-form.component.html',
  styleUrls: ['./budget-drag-and-drop-form.component.scss']
})
export class BudgetDragAndDropFormComponent implements OnChanges {
  @Input() budgetProps: { modules: Module[]; services: Service[] }
  @Input() project: Project
  @Input() isReadonly: boolean

  @Output() budgetPropsChanged: EventEmitter<{
    modules: Module[]
    services: Service[]
  }> = new EventEmitter()

  showCreateDeliverableTypeModal: false
  showCreateServiceTypeModal: false

  itemList$: Subject<BudgetItem[]> = new Subject<BudgetItem[]>()
  itemList: BudgetItem[]

  currentDraggableEvent: DragEvent
  currentDragEffectMsg: string
  isModuleDragged: boolean

  Nature = BudgetableNature
  itemToDelete: { list: BudgetItem[]; index: number }

  preventNextDrag: boolean

  constructor(
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService,
    private router: Router
  ) {}

  ngOnChanges() {
    this.itemList = this.buildItemList(this.budgetProps)

    // Emit event to parent component on change.
    this.itemList$.subscribe((list: BudgetItem[]) => {
      if (this.hasMoreThan2NestedModules(list)) {
        this.flashMessageService.error(
          `Erreur : Il ne peut y avoir plus de 2 modules empilés.` +
            `Veuillez modifier votre budget en conséquence afin de sauvegarder vos données.`
        )
      } else {
        this.budgetPropsChanged.emit(this.formatIntoBudgetProps(list))
      }
    })
  }

  addModule() {
    this.itemList.push({
      name: 'Nouveau module',
      nature: BudgetableNature.Module,
      children: [],
      dataObject: {
        id: null,
        displayOrder: this.itemList.length + 1,
        name: 'Nouveau module',
        services: [],
        modules: []
      }
    })
    this.itemList$.next(this.itemList)

    // Scroll to created element.
    const dropzoneBottom: number =
      document.getElementById('main-dropzone').scrollHeight
    window.scrollTo({ top: dropzoneBottom, behavior: 'smooth' })
  }
  addService() {
    this.itemList.push({
      name: 'Nouvelle prestation',
      nature: BudgetableNature.Service,
      dataObject: {
        id: null,
        displayOrder: this.itemList.length + 1,
        flatFeePricing: false,
        extended: true,
        name: 'Nouvelle prestation',
        description: null,
        deliverables: [],
        estimatedWorks: [],
        bookedWorks: []
      }
    })
    this.itemList$.next(this.itemList)
  }

  deleteItem(list: BudgetItem[], index: number) {
    list.splice(index, 1)
    this.itemList = this.calculateModuleAmounts(this.itemList)
    this.itemList$.next(this.itemList)
  }

  duplicateService(serviceItem: BudgetItem, list: BudgetItem[], index: number) {
    this.resourceService
      .duplicate('services', serviceItem.dataObject.id)
      .subscribe((newService: Service) => {
        this.flashMessageService.success(
          `La prestation a été dupliquée avec succès.`
        )
        // Reload to get updated list.
        this.router.navigate(['/missions', this.project.id], {
          queryParams: {
            selectedTab: 'budget',
            reload: new Date().toISOString()
          }
        })
      })
  }

  onModuleChanged(
    moduleItem: { name: string },
    list: BudgetItem[],
    index: number
  ) {
    list[index].name = moduleItem.name
    list[index].dataObject.name = moduleItem.name

    this.itemList$.next(this.itemList)
  }

  onServiceChanged(serviceItem: Service, list: BudgetItem[], index: number) {
    list[index].name = serviceItem.name
    list[index].dataObject = serviceItem
    list[index].amount = serviceItem.amount

    this.itemList = this.calculateModuleAmounts(this.itemList)

    this.itemList$.next(this.itemList)
  }

  closeService(list: BudgetItem[], index: number) {
    list[index].extended = false
    const serviceDataObject = list[index].dataObject as Service
    serviceDataObject.extended = false
  }

  onDrag(item: any, list: BudgetItem[]) {
    if (this.preventNextDrag) {
      this.preventNextDrag = false

      this.flashMessageService.error(
        `Erreur : Impossible d'empiler plus de 2 modules.`
      )

      return
    }

    const index = list.indexOf(item)
    list.splice(index, 1)

    // Update displayOrder prop based on array index.
    list.forEach((budgetItem: BudgetItem, budgetItemIndex: number) => {
      budgetItem.dataObject.displayOrder = budgetItemIndex

      if (budgetItem.children && budgetItem.children.length) {
        budgetItem.children.forEach(
          (subBudgetItem: BudgetItem, subBudgetItemIndex: number) => {
            subBudgetItem.dataObject.displayOrder = subBudgetItemIndex

            if (subBudgetItem.children && subBudgetItem.children.length) {
              subBudgetItem.children.forEach(
                (
                  subSubBudgetItem: BudgetItem,
                  subSubBudgetItemIndex: number
                ) => {
                  subSubBudgetItem.dataObject.displayOrder =
                    subSubBudgetItemIndex
                }
              )
            }
          }
        )
      }
    })

    this.itemList = this.calculateModuleAmounts(this.itemList)
    this.itemList$.next(this.itemList)
  }

  onDrop(params: {
    event: DndDropEvent
    list: BudgetItem[]
    isDeepElement?: boolean
    isMainDropzone?: boolean
  }) {
    //  We prevent 3-level modules by blocking Drag.
    if (
      (params.isDeepElement &&
        params.event.data.nature === BudgetableNature.Module) ||
      (!params.isMainDropzone &&
        params.event.data.children &&
        params.event.data.children.some(
          (child: BudgetItem) => child.nature === BudgetableNature.Module
        ))
    ) {
      this.preventNextDrag = true
      return
    }

    let index = params.event.index
    if (typeof index === 'undefined') {
      index = params.list.length
    }

    params.list.splice(index, 0, params.event.data)
  }

  buildItemList(budgetProps: {
    modules: Module[]
    services: Service[]
  }): BudgetItem[] {
    const itemList: BudgetItem[] = []

    budgetProps.modules.forEach((m: Module) => {
      // Sub-services.
      const subServiceItems: BudgetItem[] = (m.services || []).map(
        (sS: Service) => ({
          id: sS.id,
          name: sS.name,
          amount: sS.amount,
          nature: BudgetableNature.Service,
          dataObject: sS
        })
      )

      // Sub-modules and Sub-sub-services.
      const subModuleItems: BudgetItem[] = (m.modules || []).map(
        (sM: Module) => ({
          id: sM.id,
          name: sM.name,
          nature: BudgetableNature.Module,
          dataObject: sM,
          children: (sM.services || [])
            .map((sSS: Service) => ({
              id: sSS.id,
              name: sSS.name,
              amount: sSS.amount,
              nature: BudgetableNature.Service,
              dataObject: sSS
            }))
            .sort(
              (a, b) => a.dataObject.displayOrder - b.dataObject.displayOrder
            )
        })
      )

      itemList.push({
        id: m.id,
        name: m.name,
        nature: BudgetableNature.Module,
        children: subModuleItems
          .concat(subServiceItems)
          .sort(
            (a, b) => a.dataObject.displayOrder - b.dataObject.displayOrder
          ),
        dataObject: m
      })
    })

    budgetProps.services.forEach((s: Service) => {
      itemList.push({
        id: s.id,
        name: s.name,
        amount: s.amount,
        nature: BudgetableNature.Service,
        dataObject: s
      })
    })

    itemList.sort(
      (a, b) => a.dataObject.displayOrder - b.dataObject.displayOrder
    )

    return this.calculateModuleAmounts(itemList)
  }

  // Transform list into "budgetProps" elements to output it.
  formatIntoBudgetProps(list: BudgetItem[]): {
    modules: Module[]
    services: Service[]
  } {
    const rootServices: Service[] = list
      .filter((item: BudgetItem) => item.nature === BudgetableNature.Service)
      .map((item: BudgetItem) => item.dataObject) as Service[]

    const moduleItems: BudgetItem[] = list.filter(
      (item: BudgetItem) => item.nature === BudgetableNature.Module
    )

    const rootModules: Module[] = []

    moduleItems.forEach((moduleItem: BudgetItem) => {
      const module: Module = moduleItem.dataObject as Module
      const children: BudgetItem[] = moduleItem.children

      module.modules = children
        .filter((child: BudgetItem) => child.nature === BudgetableNature.Module)
        .map((child) => {
          const subModule: Module = child.dataObject as Module

          subModule.services = child.children
            .filter((item) => item.nature === BudgetableNature.Service)
            .map((item) => item.dataObject as Service)
          return subModule
        })

      module.services = children
        .filter(
          (child: BudgetItem) => child.nature === BudgetableNature.Service
        )
        .map((child) => child.dataObject as Service)

      rootModules.push(module)
    })
    return { modules: rootModules, services: rootServices }
  }

  // Return true if we have modules at third level.
  hasMoreThan2NestedModules(list: BudgetItem[]): boolean {
    return list.some(
      (item) =>
        item.children &&
        item.children.some(
          (subItem) =>
            subItem.children &&
            subItem.children.some(
              (subSubItem) => subSubItem.nature === BudgetableNature.Module
            )
        )
    )
  }

  private getModuleAmount(module: BudgetItem): number {
    return (
      (module.children || [])
        .filter((c) => c.nature === BudgetableNature.Service)
        .reduce(
          (sum: number, curr: BudgetItem) =>
            (curr.dataObject as Service).amount + sum,
          0
        ) +
      (module.children || [])
        .filter((c) => c.nature === BudgetableNature.Module)
        .reduce(
          (acc: number, subModule: BudgetItem) =>
            acc +
            (subModule.children || [])
              .filter((c) => c.nature === BudgetableNature.Service)
              .reduce((sum: number, curr: BudgetItem) => curr.amount + sum, 0),
          0
        )
    )
  }

  private calculateModuleAmounts(list: BudgetItem[]): BudgetItem[] {
    return list.map((item: BudgetItem) => {
      if (item.nature === BudgetableNature.Module) {
        item.amount = this.getModuleAmount(item)
        ;(item.children || [])
          .filter((c) => c.nature === BudgetableNature.Module)
          .forEach((subModule: BudgetItem) => {
            subModule.amount = this.getModuleAmount(subModule)
          })
      }

      return item
    })
  }
}
