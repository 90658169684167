import { Component } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { timer } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { saveAs } from 'file-saver'

import { environment } from '../../../../environments/environment'
import { LinkType } from '../../enums/link-type.enum'
import { YieldType } from '../../enums/yield-type.enum'
import { Filter } from '../../interfaces/filter.interface'
import { KeyNumber } from '../../interfaces/key-number.interface'
import { OrderByChangedEvent } from '../../interfaces/order-by-changed-event.interface'
import { Paginator } from '../../interfaces/paginator.interface'
import { ResourceDefinition } from '../../interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../services/breadcrumb.service'
import { FlashMessageService } from '../../services/flash-message.service'
import { ResourceService } from '../../services/resource.service'

@Component({
  templateUrl: './abc-list.component.html',
  styleUrls: ['./abc-list.component.scss']
})
export class AbcListComponent {
  definition: ResourceDefinition
  paginator: Paginator<any>

  filters: Filter[]
  resolvedFilters: Filter[]

  orderBy: string
  orderByDesc = false
  loading = false
  loadingExport = false

  LinkType = LinkType
  YieldType = YieldType
  addItemPermissionName: string
  browseItemsPermissionName: string

  onlyNumbersRegex: RegExp = new RegExp('^[0-9]+$')

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService
  ) {}

  async initListView() {
    this.resolvedFilters = await this.resolveFilters(this.filters)

    this.activatedRoute.queryParams.subscribe(async (queryParams) => {
      this.setFilterInitialValues(queryParams)

      const initialValueParams: Params = this.resolvedFilters.reduce(
        (acc, rF) => {
          const initialParams = {}

          if (rF.initialValue) {
            Object.keys(rF.initialValue).forEach((key: string) => {
              if (rF.properties.hasOwnProperty(key)) {
                initialParams[rF.properties[key]] = rF.initialValue[key]
              }
            })
          }

          return Object.assign(acc, initialParams)
        },
        {}
      )
      const getParams: Params = Object.assign(initialValueParams, queryParams)

      delete this.paginator

      if (getParams.toXLS) {
        return this.downloadFile(getParams)
      } else {
        this.loading = true
        this.getItems(getParams)
          .then((res: Paginator<any>) => {
            this.loading = false
            this.paginator = res as Paginator<any>
            this.getKeyNumbers(getParams)
          })
          .catch((err) => {
            this.loading = false
            this.flashMessageService.error(
              `Une erreur a eu lieu : impossible de récupérer la liste des ${this.definition.namePlural}.`
            )
          })
      }

      this.setBreadcrumbs()

      this.addItemPermissionName =
        'add' + this.kebabToCapitalizedCamel(this.definition.slug)

      if (!this.browseItemsPermissionName) {
        this.browseItemsPermissionName =
          'browse' + this.kebabToCapitalizedCamel(this.definition.slug)
      }
    })
  }

  getItems(params: Params): Promise<Paginator<any> | any> {
    if (this.activatedRoute.snapshot.data.ownResourcesOnly) {
      params.ownResourcesOnly = '1'
    }
    return this.resourceService.list(this.definition.slug, params)
  }

  // Return a promise of an array of filters with all async items rendered.
  resolveFilters(filters: Filter[]): Promise<Filter[]> {
    if (!filters || !filters.length) {
      return Promise.resolve([])
    }

    const asyncFilterPromises: Promise<any>[] = []

    filters.forEach((filter: Filter) => {
      if (typeof filter.selectOptions === 'function') {
        asyncFilterPromises.push(
          filter.selectOptions().then((res) => {
            filter.selectOptions = res
          })
        )
      }
    })

    return Promise.all(asyncFilterPromises).then(() => filters)
  }

  getKeyNumbers(queryParams: Params) {
    if (!this.definition.keyNumbers || !this.definition.keyNumbers.length) {
      return
    }

    this.definition.keyNumbers.forEach((keyNumber: KeyNumber) => {
      if (keyNumber.subscription) {
        keyNumber.subscription.unsubscribe()
        keyNumber.value = null
      }

      keyNumber.subscription = timer(2000)
        .pipe(
          switchMap(() => {
            keyNumber.loading = true
            return this.resourceService.list(
              this.definition.slug,
              Object.assign(keyNumber.extraParams, queryParams)
            )
          })
        )
        .subscribe((res: { value: number }) => {
          keyNumber.loading = false
          keyNumber.value = res.value
        })
    })
  }

  setFilterInitialValues(queryParams: Params) {
    // Common for all lists.
    this.orderBy = queryParams.orderBy
    this.orderByDesc = queryParams.orderByDesc

    // Specific filters.
    this.resolvedFilters.forEach((filter: Filter) => {
      if (!filter.initialValue) {
        filter.initialValue = {}
      }

      Object.keys(filter.properties).forEach((inputProp: string) => {
        const filterProp: string = filter.properties[inputProp]
        if (queryParams.hasOwnProperty(filterProp)) {
          filter.initialValue[inputProp] = queryParams[filterProp]
        }
      })
    })
  }

  setBreadcrumbs() {
    this.breadcrumbService.breadcrumbLinks.next([
      {
        path: `/${this.definition.path || this.definition.slug}`,
        label: this.definition.title
      }
    ])
  }

  downloadFile(getParams: Params) {
    this.loadingExport = true
    this.getItems(getParams).then(
      (res: { filePath: string }) => {
        this.loadingExport = false

        // Not good practice. Refactor if we have time.
        const fileName: string = getParams.creditVersion === 'true'
          ? 'avoirs'
          : this.definition.path || this.definition.slug

        saveAs(
          `${environment.storagePath}/${res.filePath}`,
          `export_${fileName}.xlsx`
        )

        // Remove param and reload list.
        const queryParams: Params = {}
        queryParams.toXLS = null
        this.router.navigate(
          [`/${this.definition.path || this.definition.slug}`],
          {
            queryParams,
            queryParamsHandling: 'merge'
          }
        )
      },
      (err) => {
        this.flashMessageService.error(
          `Une erreur a eu lieu: Impossible d'exporter la liste.`
        )
        this.loadingExport = false
      }
    )
  }

  onFilterValueChanged(value: { [key: string]: string }, filter: Filter) {
    // Return to page 1 when changing a filter. Reload to force reloading (not automatic in array queryParams)
    const queryParams: Params = { page: '1', reload: new Date().toISOString() }

    Object.keys(filter.properties).forEach((inputProp: string) => {
      queryParams[filter.properties[inputProp]] = value[inputProp]
    })
    this.router.navigate([`/${this.definition.path || this.definition.slug}`], {
      queryParams,
      queryParamsHandling: 'merge'
    })
  }

  onPageChanged(page: number) {
    const queryParams: Params = { page: page.toString() }
    this.router.navigate([`/${this.definition.path || this.definition.slug}`], {
      queryParams,
      queryParamsHandling: 'merge'
    })
  }

  onOrderByChanged(event: OrderByChangedEvent) {
    const queryParams: Params = {
      page: '1',
      orderBy: event.orderBy,
      orderByDesc: event.orderByDesc || null
    }

    this.router.navigate([`/${this.definition.path || this.definition.slug}`], {
      queryParams,
      queryParamsHandling: 'merge'
    })
  }

  reload() {
    this.router.navigate([`/${this.definition.path || this.definition.slug}`], {
      queryParams: {
        reload: new Date().toISOString()
      },
      queryParamsHandling: 'merge'
    })
  }

  // Return value from queryParams considering that everything is a string in a URL.
  formatValueFromQueryParams(value: string): string | boolean | number {
    if (value === 'true') {
      return true
    } else if (value === 'false') {
      return false
    } else if (this.onlyNumbersRegex.test(value)) {
      return parseInt(value, 10)
    } else {
      return value
    }
  }

  kebabToCapitalizedCamel(kebabString: string): string {
    let arr = kebabString.split('-')
    let camelArray = arr.map(
      (item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
    )
    let camelString = camelArray.join('')

    return camelString
  }

  destroySubscriptions() {
    if (this.definition.keyNumbers && this.definition.keyNumbers.length) {
      this.definition.keyNumbers.forEach((keyNumber: KeyNumber) => {
        if (keyNumber.subscription) {
          keyNumber.subscription.unsubscribe()
        }
      })
    }
  }
}
