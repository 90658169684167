<h3 class="title--small has-text-black">Budget</h3>

<p class="help" *ngIf="isReadonly">
  Il n'est plus possible de modifier ce budget car la mission est en statut
  "Proposition" ou supérieur.
</p>

<a
  class="p-l-md is-size-8 tooltip has-tooltip has-tooltip-bottom"
  data-tooltip="titre de section/ensemble de prestations"
  *ngIf="!isReadonly"
  (click)="addModule()"
  ><i class="icon icon-plus has-text-grey-dark m-r-sm"></i>Ajouter un module</a
>
<a class="p-l-md is-size-8" (click)="addService()" *ngIf="!isReadonly"
  ><i class="icon icon-plus has-text-grey-dark m-r-sm"></i>Ajouter une
  prestation</a
>

<div *ngIf="!itemList.length">
  <p class="m-b-colgap m-t-sm">Ce budget ne contient aucune prestation.</p>
  <p>
    <a class="button is-primary" (click)="addService()" *ngIf="!isReadonly"
      >Ajouter une prestation</a
    >
  </p>
</div>

<ng-template #recursiveList let-list let-isDeep="isDeep">
  <div dndPlaceholderRef class="dndPlaceholder"></div>

  <div
    *ngFor="let item of list; let i = index"
    [dndDraggable]="item"
    [dndDisableIf]="isReadonly || item.extended"
    dndEffectAllowed="move"
    (dndMoved)="onDrag(item, list)"
    class="item"
    [ngClass]="{
      'service-item': item.nature === Nature.Service,
      'module-item': item.nature === Nature.Module
    }"
  >
    <!-- Module content -->
    <div
      class="drop-zone"
      *ngIf="item.nature === Nature.Module"
      dndDropzone
      (dndDrop)="
        onDrop({ event: $event, list: item.children, isDeepElement: isDeep })
      "
    >
      <div class="flex align-center space-between">
        <div class="flex align-center justify-start full-width">
          <ng-container *ngIf="!item.extended">
            <div
              class="flex align-center justify-center handler"
              [ngClass]="{ 'no-drag': isReadonly }"
              dndHandle
            >
              <img src="./assets/img/handler-black.svg" alt="handler" />
            </div>
            <strong>Module</strong> : {{ item.name }}
          </ng-container>

          <app-module-create-edit
            *ngIf="!isReadonly && item.extended"
            [module]="item"
            (moduleChanged)="onModuleChanged($event, list, i)"
            (closeForm)="item.extended = false"
          ></app-module-create-edit>
        </div>

        <div *ngIf="!item.extended" class="flex align-center justify-center">
          <div class="amount p-r-colgap">
            <strong>{{ item.amount | euros }}</strong>
          </div>
          <a
            class="item-action flex align-center justify-center"
            (click)="item.extended = true"
            *ngIf="!isReadonly"
          >
            <i class="icon icon-edit is-size-5 extend-item-button"></i>
          </a>
          <a
            class="item-action flex align-center justify-center"
            (click)="itemToDelete = { list: list, index: i }"
            *ngIf="!isReadonly"
          >
            <i class="icon icon-trash-2 is-size-5"></i>
          </a>
        </div>
      </div>
    </div>

    <!-- Service content -->
    <div *ngIf="item.nature === Nature.Service">
      <div class="flex align-center space-between">
        <div class="flex align-center justify-start">
          <div
            class="flex align-center justify-center handler"
            [ngClass]="{ 'no-drag': isReadonly }"
          >
            <img src="./assets/img/handler-purple.svg" alt="handler" />
          </div>
          <strong class="service-pre-title">Prestation</strong> :
          {{ item.name }}
        </div>

        <div class="flex align-center justify-center">
          <div class="amount p-r-colgap">
            {{ item.amount | euros }}
          </div>
          <a
            (click)="duplicateService(item, list, i)"
            class="item-action flex align-center justify-center"
            *ngIf="!isReadonly && item.id"
          >
            <i class="icon icon-copy is-size-5"></i>
          </a>
          <a
            (click)="itemToDelete = { list: list, index: i }"
            class="item-action flex align-center justify-center"
            *ngIf="!isReadonly"
          >
            <i class="icon icon-x is-size-5"></i>
          </a>
          <a
            *ngIf="!item.extended && !item.dataObject.extended"
            (click)="item.extended = true"
            class="item-action flex align-center justify-center"
          >
            <i class="icon icon-chevron-down is-size-4 extend-item-button"></i>
          </a>
          <a
            *ngIf="item.extended || item.dataObject.extended"
            (click)="closeService(list, i)"
            class="item-action flex align-center justify-center"
          >
            <i class="icon icon-chevron-up is-size-4 extend-item-button"></i>
          </a>
        </div>
      </div>
      <app-service-create-edit
        *ngIf="item.extended || item.dataObject.extended"
        [service]="item.dataObject"
        [pricingYear]="project.pricingYear"
        [isReadonly]="isReadonly"
        (serviceChanged)="onServiceChanged($event, list, i)"
      ></app-service-create-edit>
    </div>

    <ng-container *ngIf="item.children">
      <ng-container
        *ngTemplateOutlet="
          recursiveList;
          context: { $implicit: item.children, isDeep: true }
        "
      ></ng-container>
    </ng-container>
  </div>
</ng-template>

<div
  dndDropzone
  (dndDrop)="onDrop({ event: $event, list: itemList, isMainDropzone: true })"
  id="main-dropzone"
>
  <ng-container
    *ngTemplateOutlet="recursiveList; context: { $implicit: itemList }"
  ></ng-container>
</div>

<abc-confirm-delete-modal
  [emitConfirmation]="true"
  [resourceToDelete]="itemToDelete"
  (deleteCanceled)="itemToDelete = null"
  (deleteConfirmed)="deleteItem(itemToDelete.list, itemToDelete.index)"
  *ngIf="itemToDelete"
></abc-confirm-delete-modal>
