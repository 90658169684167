export enum ProjectOrigin {
  AccountManager = 'Appel d offre',
  WorkSupervisor = 'Partner grand compte',
  ProjectManager = 'Manager',
  Intermediary = 'Apporteur d affaires',
  Medvance = 'Medvance',
  IncomingCall = 'Appel entrant',
  Website = 'Site internet',
  Other = 'Autres'
}
