import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component';
import { InputType } from '../../../common/enums/input-type.enum';
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface';
import { Field } from '../../../common/interfaces/field.interface';
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface';
import { BreadcrumbService } from '../../../common/services/breadcrumb.service';
import { FlashMessageService } from '../../../common/services/flash-message.service';
import { ResourceService } from '../../../common/services/resource.service';
import { ProjectOrigin } from '../project-origin.enum';
import { ProjectStatus } from '../project-status.enum';
import { ProjectType } from '../project-type.enum';
import { projectDefinition } from '../project.definition';

@Component({
  selector: 'app-project-create-edit',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class ProjectCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = projectDefinition

  fields: Field[] = [
    {
      label: 'Mission non facturable',
      properties: {
        value: 'notBillable'
      },
      initialValue: {
        value: false
      },
      inputType: InputType.Checkbox,
      validators: [],
      className: 'is-3 no-label p-x-0-mobile',
      onChange: (newValue: { value: boolean }) => {
        this.toggleNotBillableFields(newValue.value)
      }
    },
    {
      id: 'customer',
      label: 'Client',
      placeholder: 'Chercher un client...',
      properties: {
        customerId: 'customerId'
      },
      retrievedItemProperties: {
        customerId: 'customer.id'
      },
      searchResources: ['customers'],
      maxSelectedItems: 1,
      validators: [Validators.required],
      inputType: InputType.MultiSearch,
      className: 'is-3 p-x-0-mobile',
      onChange: async (newValue: { customerId: number }) => {
        const referentIdField: Field = this.getFieldById('referentId')

        if (newValue.customerId) {
          referentIdField.selectOptions =
            await this.componentResourceService.listSelectOptions('referents', {
              customerId: newValue.customerId
            })
          this.setFieldValue(this.getFieldById('billingEmail'), {
            value: await this.componentResourceService
              .show('customers', newValue.customerId, 'billing-email')
              .then((res: { billingEmail: string }) => res.billingEmail)
          })
        } else {
          referentIdField.selectOptions = []
        }
      }
    },
    {
      id: 'type',
      label: 'Type',
      hidden: true,
      properties: {
        value: 'type'
      },
      placeholder: 'Type de mission',
      inputType: InputType.Select,
      selectOptions: () => {
        const selectOptions = Object.keys(ProjectType).map((key) => ({
          label: ProjectType[key],
          value: ProjectType[key]
        }))

        // Remove "Billable" option as it is only for billable projects.
        selectOptions.shift()
        return Promise.resolve(selectOptions)
      },
      initialValue: {
        value: ProjectType.Billable
      },
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: `Date de création`,
      placeholder: `Date de création...`,
      properties: {
        value: 'creationDate'
      },
      initialValue: {
        value: moment().format('YYYY-MM-DD')
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: 'Statut',
      properties: {
        value: 'status'
      },
      readonly: true,
      placeholder: 'Statut du projet',
      helpText: `La modification de statut d'une mission est uniquement possible depuis sa page de présentation`,
      inputType: InputType.Select,
      selectOptions: this.item
        ? [
            {
              label: this.item.status,
              value: this.item.status
            }
          ]
        : [
            {
              label: ProjectStatus.Project,
              value: ProjectStatus.Project
            }
          ],
      initialValue: {
        value: ProjectStatus.Project
      },
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: 'Type de sourcing',
      properties: {
        value: 'origin'
      },
      placeholder: 'Type de sourcing',
      inputType: InputType.Select,
      selectOptions: Object.keys(ProjectOrigin).map((key) => ({
        label: ProjectOrigin[key],
        value: ProjectOrigin[key]
      })),
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: 'Nom de sourcing',
      properties: {
        value: 'originName'
      },
      placeholder: 'Nom de sourcing',
      inputType: InputType.Text,
      validators: [],
      className: 'is-3 p-x-0-mobile'
    },
    {
      id: 'name',
      label: `Titre de la mission`,
      placeholder: `Titre de la mission...`,
      properties: {
        value: 'name'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'is-12-mobile is-6-tablet p-x-0-mobile'
    },
    {
      label: `Ref bon de commande`,
      placeholder: `Ref bon de commande...`,
      properties: {
        value: 'purchaseOrder'
      },
      inputType: InputType.Text,
      validators: [],
      className: 'is-3 p-x-0-mobile  align-self-start'
    },
    {
      label: `Probabilité de signature (%)`,
      placeholder: `Probabilité de signature en pourcentage...`,
      properties: {
        value: 'closeProbability'
      },
      inputType: InputType.Number,
      min: 1,
      max: 100,
      initialValue: {
        value: 1
      },
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile align-self-start'
    },
    {
      id: 'estimatedStartDate',
      label: `Date de démarrage`,
      placeholder: `Date de démarrage...`,
      helpText: `Ces données sont des données contractuelles : pensez à tenir compte des délais de relecture des contrats et des exigeances du client en matière de planification`,
      properties: {
        value: 'estimatedStartDate'
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile align-self-start'
    },
    {
      id: 'estimatedEndDate',
      label: `Date de fin`,
      placeholder: `Date de fin...`,
      properties: {
        value: 'estimatedEndDate'
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      helpText:
        'Ces données sont des données contractuelles : pensez à tenir compte des délais de relecture des contrats et des exigeances du client en matière de planification',
      className: 'is-3 p-x-0-mobile align-self-start'
    },
    {
      label: `Taux de prime (%)`,
      placeholder: `Taux de prime en pourcentage...`,
      properties: {
        value: 'bonusRate'
      },
      inputType: InputType.Number,
      min: 0,
      max: 20,
      initialValue: {
        value: 0
      },
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile align-self-start'
    },
    {
      id: 'billingEmail',
      label: 'Email de facturation',
      placeholder: 'Email de facturation...',
      properties: {
        value: 'billingEmail'
      },
      inputType: InputType.Email,
      validators: [],
      className: 'is-3 p-x-0-mobile'
    },
    {
      id: 'referentId',
      label: 'Responsable mission (contact client)',
      placeholder: 'Responsable mission',
      properties: {
        value: 'referentId'
      },
      selectOptions: () =>
        this.item && this.item.customer
          ? this.componentResourceService.listSelectOptions('referents', {
              customerId: this.item.customer.id
            })
          : Promise.resolve([
              {
                label:
                  '=> Sélectionnez un client pour afficher la liste des responsable missions disponibles.',
                value: '',
                disabled: true
              }
            ]),
      retrievedItemProperties: {
        referentId: 'referent.id'
      },
      inputType: InputType.Select,
      validators: [Validators.required],
      className: 'is-3 p-x-0-mobile'
    },
    {
      id: 'departmentId',
      label: 'Practice',
      placeholder: 'Sélectionnez un practice',
      properties: {
        value: 'departmentId'
      },
      retrievedItemProperties: {
        departmentId: 'department.id'
      },
      inputType: InputType.Select,
      validators: [Validators.required],
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('departments', {
          activeOnly: true
        }),
      className: 'is-3 p-x-0-mobile'
    },
    {
      label: `Partner mission`,
      placeholder: `Choisir le Partner mission`,
      properties: {
        value: 'workSupervisorId'
      },
      retrievedItemProperties: {
        workSupervisorId: 'workSupervisor.id'
      },
      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleName: 'partner'
        }),
      className: 'is-3 p-x-0-mobile',
      validators: [Validators.required]
    },
    {
      label: `Responsable opérationnel`,
      placeholder: `Choisir le responsable opérationnel`,
      properties: {
        value: 'projectManagerId'
      },
      retrievedItemProperties: {
        projectManagerId: 'projectManager.id'
      },
      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleName: 'manager'
        }),
      className: 'is-3 p-x-0-mobile',
      validators: [Validators.required]
    },
    {
      label: `Chef de projet`,
      placeholder: `Aucun`,
      properties: {
        value: 'projectLeaderId'
      },
      retrievedItemProperties: {
        projectLeaderId: 'projectLeader.id'
      },
      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          positionNames: [
            'Consultant.',
            'Consultant senior',
            'Senior Expert',
            'Internal Expert I',
            'Consultant. - SGE',
            'Consultant Senior - SGE'
          ]
        }),

      className: 'is-3 p-x-0-mobile',
      validators: []
    },
    {
      label: 'Autorisation création du chef de projet',
      properties: { value: 'isProjectLeaderManager' },
      initialValue: { value: false },
      className: 'is-12-mobile is-6-tablet is-3-widescreen no-label',
      inputType: InputType.Checkbox,
      validators: []
    },
    {
      label: `Année de tarification`,
      placeholder: `Année de tarification pour chiffrage des prestations par profil`,
      properties: {
        value: 'pricingYear'
      },
      selectOptions: () =>
        this.componentResourceService
          .list('positions/daily-rates/years')
          .then((years: number[]) =>
            years.map((year) => ({
              label: year.toString(),
              value: year.toString()
            }))
          ),
      validators: [Validators.required],
      inputType: InputType.Select,
      className: 'is-3 p-x-0-mobile'
    },
    {
      id: 'comments',
      label: 'Objet de la mission',
      placeholder: 'Objet de la mission...',
      properties: {
        value: 'comments'
      },
      inputType: InputType.Textarea,
      validators: [],
      className: 'is-12 p-x-0-mobile'
    }
  ]

  editModeSpecialRules: FieldSpecialRule[] = [
    {
      fieldId: 'isAbandoned',
      hidden: false
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private componentResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    await this.initCreateEditView()

    // Hide fields for not billable projects.
    if (this.item && this.item.notBillable) {
      this.toggleNotBillableFields(this.item.notBillable)
    }

    if (
      this.item &&
      (this.item.status === ProjectStatus.Signed ||
        this.item.status === ProjectStatus.Finished)
    ) {
      const fieldsToSetReadonly: string[] = [
        'customer',
        'name',
        'estimatedStartDate',
        'estimatedEndDate',
        'comments'
      ]

      fieldsToSetReadonly.forEach((fieldName: string) => {
        const field: Field = this.getFieldById(fieldName)
        field.readonly = true
      })
    }
  }

  toggleNotBillableFields(notBillable: boolean) {
    const fieldsToHide: string[] = [
      'customer',
      'referentId',
      'departmentId',
      'billingEmail'
    ]

    fieldsToHide.forEach((fieldName: string) => {
      const field: Field = this.getFieldById(fieldName)

      if (notBillable) {
        field.hidden = true
        field.validators = []
        this.resetFieldFormControls(field)
      } else {
        field.hidden = false
        field.validators = [Validators.required]
      }
    })

    const fieldsToShow: string[] = ['type']

    fieldsToShow.forEach((fieldName: string) => {
      const field: Field = this.getFieldById(fieldName)

      if (notBillable) {
        field.hidden = false
        field.validators = [Validators.required]
      } else {
        field.hidden = true
        field.validators = []
        this.resetFieldFormControls(field)
      }
    })
  }
}
