<form [formGroup]="form">
  <!-- Labels -->
  <div class="columns">
    <div class="column is-12-mobile is-5-tablet is-4-widescreen p-b-0">
      <label>Profils affectés</label>
    </div>
    <div class="column is-6-mobile is-2-tablet p-b-0">
      <label>Jours</label>
    </div>
    <div class="column column is-6-mobile is-2-tablet p-b-0">
      <label>Montant</label>
    </div>
  </div>

  <!-- Multi line form -->
  <div
    class="columns flex align-center justify-start"
    formArrayName="estimatedWorks"
    *ngFor="let control of estimatedWorkFormArray.controls; let i = index"
  >
    <ng-container [formGroupName]="i">
      <div class="column is-12-mobile is-5-tablet is-4-widescreen">
        <div class="control">
          <div class="select">
            <select
              name="service-type-select"
              class="select"
              formControlName="positionId"
            >
              <option value="" disabled>Choisir le profil</option>
              <option
                *ngFor="let position of positions"
                [value]="position.value"
              >
                {{ position.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="column is-6-mobile is-2-tablet">
        <div class="control">
          <input
            type="number"
            class="input"
            formControlName="daysOfWork"
            min="1"
            max="500"
            step="0.01"
            [readonly]="isReadonly"
          />
        </div>
      </div>
      <div class="column is-6-mobile is-2-tablet">
        <div class="control">
          {{ estimatedWorkAmounts[i] | euros }}
        </div>
      </div>
      <div class="column is-2-mobile is-1-tablet">
        <a (click)="removeEstimatedWork(i)" *ngIf="!isReadonly">
          <i class="icon icon-trash-2 has-text-grey is-size-4"></i>
        </a>
      </div>
    </ng-container>
  </div>

  <a (click)="addEstimatedWork()" *ngIf="!isReadonly">
    <i class="icon icon-plus has-text-grey-dark m-r-sm"></i>Ajouter un profil
  </a>
</form>
