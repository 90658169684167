import {
  CommonModule,
  DecimalPipe,
  PercentPipe,
  registerLocaleData
} from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import { LOCALE_ID, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { JwtModule } from '@auth0/angular-jwt'
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { AngularMyDatePickerModule } from '@nodro7/angular-mydatepicker'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { NgChartsModule } from 'ng2-charts'
import { DndModule } from 'ngx-drag-drop'

import { environment } from '../environments/environment'
import { BookedWorkReportComponent } from './analytics/booked-work-report/booked-work-report.component'
import { BusinessReviewChartComponent } from './analytics/business-review/business-review-chart/business-review-chart.component'
import { BusinessReviewComponent } from './analytics/business-review/business-review.component'
import { CutOffComponent } from './analytics/cut-off/cut-off.component'
import { ProjectProfitabilityComponent } from './analytics/project-profitability/project-profitability.component'
import { UserAvailabilityComponent } from './analytics/user-availability/user-availability.component'
import { UserTimeSheetDashboardComponent } from './analytics/user-time-sheet-dashboard/user-time-sheet-dashboard.component'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AbcCreateEditComponent } from './common/base-components/abc-create-edit/abc-create-edit.component'
import { AbcListComponent } from './common/base-components/abc-list/abc-list.component'
import { DatepickerComponent } from './common/base-components/datepicker.component'
import { HasPermissionDirective } from './common/directives/has-permission.directive'
import { AbcInputComponent } from './common/elements/inputs/abc-input/abc-input.component'
import { CheckboxInputComponent } from './common/elements/inputs/checkbox-input/checkbox-input.component'
import { ColorPickerInputComponent } from './common/elements/inputs/color-picker-input/color-picker-input.component'
import { DateRangeInputComponent } from './common/elements/inputs/date-range-input/date-range-input.component'
import { DatepickerInputComponent } from './common/elements/inputs/datepicker-input/datepicker-input.component'
import { EmailInputComponent } from './common/elements/inputs/email-input/email-input.component'
import { FileInputComponent } from './common/elements/inputs/file-input/file-input.component'
import { ImageInputComponent } from './common/elements/inputs/image-input/image-input.component'
import { MonthRangeInputComponent } from './common/elements/inputs/month-range-input/month-range-input.component'
import { MultiSearchInputComponent } from './common/elements/inputs/multi-search-input/multi-search-input.component'
import { MultiSelectInputComponent } from './common/elements/inputs/multi-select-input/multi-select-input.component'
import { NumberInputComponent } from './common/elements/inputs/number-input/number-input.component'
import { PasswordInputComponent } from './common/elements/inputs/password-input/password-input.component'
import { RadioInputComponent } from './common/elements/inputs/radio-input/radio-input.component'
import { RichTextInputComponent } from './common/elements/inputs/rich-text-input/rich-text-input.component'
import { SelectInputComponent } from './common/elements/inputs/select-input/select-input.component'
import { TelInputComponent } from './common/elements/inputs/tel-input/tel-input.component'
import { TextInputComponent } from './common/elements/inputs/text-input/text-input.component'
import { TextareaInputComponent } from './common/elements/inputs/textarea-input/textarea-input.component'
import { TimeDurationInputComponent } from './common/elements/inputs/time-duration-input/time-duration-input.component'
import { TimeInputComponent } from './common/elements/inputs/time-input/time-input.component'
import { ToggleInputComponent } from './common/elements/inputs/toggle-input/toggle-input.component'
import { SideMenuComponent } from './common/elements/navigation/side-menu/side-menu.component'
import { TopMenuComponent } from './common/elements/navigation/top-menu/top-menu.component'
import { TouchMenuComponent } from './common/elements/navigation/touch-menu/touch-menu.component'
import { ActionDropdownComponent } from './common/elements/partials/action-dropdown/action-dropdown.component'
import { BreadcrumbsComponent } from './common/elements/partials/breadcrumbs/breadcrumbs.component'
import { ConfirmDeleteModalComponent } from './common/elements/partials/confirm-delete-modal/confirm-delete-modal.component'
import { FlashMessageComponent } from './common/elements/partials/flash-message/flash-message.component'
import { ImageComponent } from './common/elements/partials/image/image.component'
import { MetaComponent } from './common/elements/partials/meta/meta.component'
import { PaginationComponent } from './common/elements/partials/pagination/pagination.component'
import { TableComponent } from './common/elements/partials/table/table.component'
import { AnalogProgressBarYieldComponent } from './common/elements/yields/analog-progress-bar-yield/analog-progress-bar-yield.component'
import { ColorYieldComponent } from './common/elements/yields/color-yield/color-yield.component'
import { CurrencyYieldComponent } from './common/elements/yields/currency-yield/currency-yield.component'
import { DateYieldComponent } from './common/elements/yields/date-yield/date-yield.component'
import { DownloadYieldComponent } from './common/elements/yields/download-yield/download-yield.component'
import { FileIconYieldComponent } from './common/elements/yields/file-icon-yield/file-icon-yield.component'
import { IconYieldComponent } from './common/elements/yields/icon-yield/icon-yield.component'
import { ImageYieldComponent } from './common/elements/yields/image-yield/image-yield.component'
import { NumberYieldComponent } from './common/elements/yields/number-yield/number-yield.component'
import { PercentageYieldComponent } from './common/elements/yields/percentage-yield/percentage-yield.component'
import { ProgressBarYieldComponent } from './common/elements/yields/progress-bar-yield/progress-bar-yield.component'
import { EurosPipe } from './common/pipes/euros.pipe'
import { StripHtmlPipe } from './common/pipes/strip-html.pipe'
import { TruncatePipe } from './common/pipes/truncate.pipe'
import { AuthService } from './common/services/auth.service'
import { BreadcrumbService } from './common/services/breadcrumb.service'
import { EventService } from './common/services/event.service'
import { ViewportService } from './common/services/viewport.service'
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component'
import { LoginComponent } from './pages/auth/login/login.component'
import { LogoutComponent } from './pages/auth/logout/logout.component'
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component'
import { Error404Component } from './pages/error404/error404.component'
import { HomeComponent } from './pages/home/home.component'
import { BookedWorkCreateEditModalComponent } from './resources/booked-work/booked-work-create-edit-modal/booked-work-create-edit-modal.component'
import { BookedWorkListComponent } from './resources/booked-work/booked-work-list/booked-work-list.component'
import { BudgetCreateEditComponent } from './resources/budget/budget-create-edit/budget-create-edit.component'
import { BudgetDragAndDropFormComponent } from './resources/budget/budget-drag-and-drop-form/budget-drag-and-drop-form.component'
import { BudgetListComponent } from './resources/budget/budget-list/budget-list.component'
import { CreditCreateEditComponent } from './resources/credit/credit-create-edit/credit-create-edit.component'
import { CreditListComponent } from './resources/credit/credit-list/credit-list.component'
import { CustomerCreateEditComponent } from './resources/customer/customer-create-edit/customer-create-edit.component'
import { CustomerDetailComponent } from './resources/customer/customer-detail/customer-detail.component'
import { CustomerListComponent } from './resources/customer/customer-list/customer-list.component'
import { DayOffCreateEditComponent } from './resources/day-off/day-off-create-edit/day-off-create-edit.component'
import { DayOffListComponent } from './resources/day-off/day-off-list/day-off-list.component'
import { DeliverableTypeCreateEditComponent } from './resources/deliverable-type/deliverable-type-create-edit/deliverable-type-create-edit.component'
import { DeliverableTypeListComponent } from './resources/deliverable-type/deliverable-type-list/deliverable-type-list.component'
import { DepartmentCreateEditComponent } from './resources/department/department-create-edit/department-create-edit.component'
import { DepartmentListComponent } from './resources/department/department-list/department-list.component'
import { DocumentCreateEditModalComponent } from './resources/document/document-create-edit-modal/document-create-edit-modal.component'
import { DocumentListComponent } from './resources/document/document-list/document-list.component'
import { ExpenseCreateEditComponent } from './resources/expense/expense-create-edit/expense-create-edit.component'
import { InvoiceCommentsModalComponent } from './resources/invoice/invoice-comments-modal/invoice-comments-modal.component'
import { InvoiceCreateComponent } from './resources/invoice/invoice-create/invoice-create.component'
import { InvoiceListComponent } from './resources/invoice/invoice-list/invoice-list.component'
import { InvoiceOfExpensesFormComponent } from './resources/invoice/invoice-of-expenses-form/invoice-of-expenses-form.component'
import { InvoicePaymentModalComponent } from './resources/invoice/invoice-payment-modal/invoice-payment-modal.component'
import { InvoicePlanFormComponent } from './resources/invoice/invoice-plan-form/invoice-plan-form.component'
import { TemporaryInvoiceEditComponent } from './resources/invoice/temporary-invoice-edit/temporary-invoice-edit.component'
import { ModuleCreateEditComponent } from './resources/module/module-create-edit/module-create-edit.component'
import { ObjectiveCreateEditComponent } from './resources/objective/objective-create-edit/objective-create-edit.component'
import { ObjectiveListComponent } from './resources/objective/objective-list/objective-list.component'
import { PlannedWorkCreateEditModalComponent } from './resources/planned-work/planned-work-create-edit-modal/planned-work-create-edit-modal.component'
import { PositionCreateEditComponent } from './resources/position/position-create-edit/position-create-edit.component'
import { PositionListComponent } from './resources/position/position-list/position-list.component'
import { ProjectAccountingComponent } from './resources/project/project-accounting/project-accounting.component'
import { ProjectChartComponent } from './resources/project/project-chart/project-chart.component'
import { ProjectCreateEditComponent } from './resources/project/project-create-edit/project-create-edit.component'
import { ProjectDetailComponent } from './resources/project/project-detail/project-detail.component'
import { ProjectFinishedModalComponent } from './resources/project/project-detail/project-finished-modal/project-finished-modal.component'
import { ProjectStatusEditModalComponent } from './resources/project/project-detail/project-status-edit-modal/project-status-edit-modal.component'
import { ProjectFollowUpComponent } from './resources/project/project-follow-up/project-follow-up.component'
import { ProjectFrameworkContractModalComponent } from './resources/project/project-framework-contract-modal/project-framework-contract-modal.component'
import { ProjectListComponent } from './resources/project/project-list/project-list.component'
import { ActivityCreateEditModalComponent } from './resources/project/project-timeline/activity-create-edit-modal/activity-create-edit-modal.component'
import { ProjectTimelineComponent } from './resources/project/project-timeline/project-timeline.component'
import { ProjectUserChartComponent } from './resources/project/project-user-chart/project-user-chart.component'
import { ProjectValidationModalComponent } from './resources/project/project-validation-modal/project-validation-modal.component'
import { ReferentCreateEditComponent } from './resources/referent/referent-create-edit/referent-create-edit.component'
import { ReferentListComponent } from './resources/referent/referent-list/referent-list.component'
import { RoleCreateEditComponent } from './resources/role/role-create-edit/role-create-edit.component'
import { RoleListComponent } from './resources/role/role-list/role-list.component'
import { ServiceTypeCreateEditComponent } from './resources/service-type/service-type-create-edit/service-type-create-edit.component'
import { ServiceTypeListComponent } from './resources/service-type/service-type-list/service-type-list.component'
import { ServiceCreateEditComponent } from './resources/service/service-create-edit/service-create-edit.component'
import { ServiceDeliverableComponent } from './resources/service/service-deliverable/service-deliverable.component'
import { ServiceEstimatedWorkComponent } from './resources/service/service-estimated-work/service-estimated-work.component'
import { HolidayCreateEditModalComponent } from './resources/time-sheet/holiday-create-edit-modal/holiday-create-edit-modal.component'
import { TimeSheetCreateEditModalComponent } from './resources/time-sheet/time-sheet-create-edit-modal/time-sheet-create-edit-modal.component'
import { TimeSheetListComponent } from './resources/time-sheet/time-sheet-list/time-sheet-list.component'
import { UserBonusesListComponent } from './resources/user/user-bonuses-list/user-bonuses-list.component'
import { UserBonusesComponent } from './resources/user/user-bonuses/user-bonuses.component'
import { UserCreateEditComponent } from './resources/user/user-create-edit/user-create-edit.component'
import { UserDetailComponent } from './resources/user/user-detail/user-detail.component'
import { UserListComponent } from './resources/user/user-list/user-list.component'
import { UserPlanningComponent } from './resources/user/user-planning/user-planning.component'
import { UserReportsComponent } from './resources/user/user-reports/user-reports.component'
import { UserTimeSheetsComponent } from './resources/user/user-time-sheets/user-time-sheets.component'
import { UserWithAvailabilitySelectInputComponent } from './resources/user/user-with-availability-select-input/user-with-availability-select-input.component'

// Register locale data
registerLocaleData(localeFr, 'fr')

export function jwtTokenGetter() {
  return localStorage.getItem(environment.tokenName)
}

if (environment.enableBugsnag) {
  Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    releaseStage: environment.envName
  })
}

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    LoginComponent,
    HomeComponent,
    SideMenuComponent,
    TopMenuComponent,
    RoleCreateEditComponent,
    MetaComponent,
    PaginationComponent,
    ConfirmDeleteModalComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    BreadcrumbsComponent,
    Error404Component,
    TouchMenuComponent,
    TruncatePipe,
    TableComponent,
    ActionDropdownComponent,
    HasPermissionDirective,
    RichTextInputComponent,
    MultiSearchInputComponent,
    MultiSelectInputComponent,
    ColorPickerInputComponent,
    ImageYieldComponent,
    DatepickerInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    TextInputComponent,
    EmailInputComponent,
    NumberInputComponent,
    TelInputComponent,
    DateRangeInputComponent,
    FileInputComponent,
    ToggleInputComponent,
    AbcInputComponent,
    StripHtmlPipe,
    PasswordInputComponent,
    ImageInputComponent,
    DownloadYieldComponent,
    UserDetailComponent,
    ColorYieldComponent,
    IconYieldComponent,
    CurrencyYieldComponent,
    DateYieldComponent,
    ProgressBarYieldComponent,
    FileIconYieldComponent,
    AnalogProgressBarYieldComponent,
    FlashMessageComponent,
    EurosPipe,
    ImageComponent,
    CustomerDetailComponent,
    ProjectDetailComponent,
    BudgetCreateEditComponent,
    ModuleCreateEditComponent,
    BudgetDragAndDropFormComponent,
    ServiceCreateEditComponent,
    ProjectTimelineComponent,
    UserPlanningComponent,
    ServiceEstimatedWorkComponent,
    ServiceDeliverableComponent,
    DocumentListComponent,
    ProjectAccountingComponent,
    BudgetListComponent,
    BookedWorkListComponent,
    ProjectFollowUpComponent,
    BookedWorkCreateEditModalComponent,
    UserWithAvailabilitySelectInputComponent,
    UserTimeSheetsComponent,
    ProjectChartComponent,
    PlannedWorkCreateEditModalComponent,
    ProjectUserChartComponent,
    InvoiceCreateComponent,
    RadioInputComponent,
    InvoicePlanFormComponent,
    InvoicePaymentModalComponent,
    InvoiceOfExpensesFormComponent,
    TimeInputComponent,
    TextareaInputComponent,
    AbcCreateEditComponent,
    AbcListComponent,
    ReferentListComponent,
    CustomerListComponent,
    DepartmentListComponent,
    PositionListComponent,
    UserListComponent,
    InvoiceListComponent,
    TimeSheetListComponent,
    DayOffListComponent,
    DeliverableTypeListComponent,
    ServiceTypeListComponent,
    RoleListComponent,
    ProjectListComponent,
    CreditCreateEditComponent,
    ExpenseCreateEditComponent,
    DayOffCreateEditComponent,
    DeliverableTypeCreateEditComponent,
    ServiceTypeCreateEditComponent,
    ProjectCreateEditComponent,
    ReferentCreateEditComponent,
    CustomerCreateEditComponent,
    DepartmentCreateEditComponent,
    PositionCreateEditComponent,
    UserCreateEditComponent,
    TimeSheetCreateEditModalComponent,
    HolidayCreateEditModalComponent,
    DocumentCreateEditModalComponent,
    UserTimeSheetDashboardComponent,
    DatepickerComponent,
    ProjectProfitabilityComponent,
    NumberYieldComponent,
    UserAvailabilityComponent,
    BookedWorkReportComponent,
    BusinessReviewComponent,
    CutOffComponent,
    BusinessReviewChartComponent,
    PercentageYieldComponent,
    TimeDurationInputComponent,
    ObjectiveCreateEditComponent,
    ObjectiveListComponent,
    ProjectValidationModalComponent,
    UserBonusesComponent,
    TemporaryInvoiceEditComponent,
    UserBonusesListComponent,
    ActivityCreateEditModalComponent,
    ProjectStatusEditModalComponent,
    InvoiceCommentsModalComponent,
    ProjectFinishedModalComponent,
    ProjectFrameworkContractModalComponent,
    CreditListComponent,
    MonthRangeInputComponent,
    UserReportsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularMyDatePickerModule,
    CKEditorModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: environment.tokenWhitelistedDomains
      }
    }),
    DndModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgChartsModule
  ],
  providers: [
    AuthService,
    EventService,
    BreadcrumbService,
    ViewportService,
    { provide: LOCALE_ID, useValue: 'fr' },
    EurosPipe,
    DecimalPipe,
    PercentPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
