import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { roleDefinition } from '../role.definition'

@Component({
  selector: 'app-role-list',
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class RoleListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition = roleDefinition

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
