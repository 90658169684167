import { Component, OnInit, Input } from '@angular/core'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { ProjectStatus } from '../../project/project-status.enum'
import { Project } from '../../project/project.interface'
import { Budget } from '../budget.interface'

// This component is responsible for displaying the list of budgets in a project,
// and adding and deleting budgets. The create/edit form is located on its child : budget-create-edit-component.
@Component({
  selector: 'app-budget-list',
  templateUrl: './budget-list.component.html',
  styleUrls: ['./budget-list.component.scss']
})
export class BudgetListComponent implements OnInit {
  @Input() project: Project
  @Input() isReadonly: boolean

  activeBudget: Budget
  budgetToDelete: Budget
  budgets: Budget[]

  loading = false

  constructor(
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService
  ) {}

  ngOnInit() {
    this.getBudgets()
  }

  getBudgets(): void {
    this.loading = true
    this.resourceService.list('budgets', { projectId: this.project.id }).then(
      (budgetRes: Budget[]) => {
        this.loading = false
        this.budgets = budgetRes

        if (this.budgets.length) {
          this.activeBudget = this.budgets[0]
        }
      },
      (err) => {
        this.loading = false
        this.flashMessageService.error(
          'Erreur : Impossible de récupérer le budget.'
        )
      }
    )
  }

  createBudget({
    name,
    withModule,
    withService
  }: {
    name: string
    withModule?: string
    withService?: string
  }): void {
    this.resourceService
      .store(`projects/${this.project.id}/budgets`, {
        name,
        projectId: this.project.id,
        withModule,
        withService
      })
      .subscribe((budgetRes: Budget) => {
        this.budgets.push(budgetRes)
        this.activeBudget = budgetRes
      })
  }

  deleteBudget(id: number): void {
    this.resourceService.delete('budgets', id).subscribe((res) => {
      this.getBudgets()
    })
  }
}
