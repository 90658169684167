import { Component, EventEmitter, Output } from '@angular/core'

@Component({
  selector: 'app-project-framework-contract-modal',
  templateUrl: './project-framework-contract-modal.component.html',
  styleUrls: ['./project-framework-contract-modal.component.scss']
})
export class ProjectFrameworkContractModalComponent {
  @Output() closedModal: EventEmitter<void> = new EventEmitter()

  close() {
    this.closedModal.emit()
  }
}
