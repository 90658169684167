<div class="columns m-b-gutter flex flex-wrap">
  <!-- Chart -->
  <div
    class="column is-12-mobile is-12-tablet is-6-desktop is-7-widescreen is-8-fullhd is-9-retina p-x-0-mobile"
  >
    <div class="card p-b-colgap m-t-md full-height">
      <div class="full-height">
        <div style="display: block" class="full-height">
          <canvas
            baseChart
            [datasets]="chartData"
            [labels]="chartLabels"
            [options]="chartOptions"
            [legend]="false"
            [type]="'bar'"
            *ngIf="chartData && chartLabels"
          >
          </canvas>
        </div>
      </div>
    </div>
  </div>

  <!-- Totals -->
  <div
    class="column is-12-mobile is-12-tablet is-6-desktop is-5-widescreen is-4-fullhd is-3-retina p-x-0-mobile"
  >
    <div class="card p-b-colgap m-t-md full-height">
      <h3 class="title--small has-text-black">Totaux</h3>

      <ul class="has-mini-circle">
        <li class="flex space-betxeen align-center list-separated flex-mobile">
          <div>
            <span class="total-label">
              <div class="color-yield-wrapper">
                <div class="small-circle m-a-0 bg-success"></div>
                <span class="m-l-sm"
                  >Total budgeté
                  <span
                    class="tooltip has-tooltip has-tooltip-multiline"
                    data-tooltip="Total du budget sans frais de gestion"
                  >
                    <i
                      class="icon icon-info is-size-5 has-text-link"
                    ></i> </span
                ></span>
              </div>
            </span>
            <span>{{ budgetServicesAmount | euros }}</span>
          </div>
          <div class="has-tex-tright">
            <span>
              {{ estimatedWorkTotalDaysOfWork | number : '1.0-2' }}j
            </span>
          </div>
        </li>
        <li class="flex space-betxeen align-center list-separated flex-mobile">
          <div>
            <span class="total-label">
              <div class="color-yield-wrapper">
                <div class="small-circle m-a-0 bg-link"></div>
                <span class="m-l-sm"
                  >Total prévisionnel
                  <span
                    class="tooltip has-tooltip has-tooltip-multiline"
                    data-tooltip="Total des jours Timesheetés et des jours réservés à venir: ce montant est au plus exact en fin de mois."
                  >
                    <i
                      class="icon icon-info is-size-5 has-text-link"
                    ></i> </span
                ></span>
              </div>
            </span>
            <span>{{
              timeSheetTotalAmount + futureBookedWorkTotalAmount | euros
            }}</span>
          </div>
          <div class="has-text-right">
            <span>
              {{
                timeSheetTotalDaysOfWork + futureBookedWorkTotalDaysOfWork
                  | number : '1.0-2'
              }}j</span
            >
          </div>
        </li>

        <li class="flex space-betxeen align-center list-separated flex-mobile">
          <div>
            <span class="total-label">
              <div class="color-yield-wrapper">
                <div class="small-circle m-a-0 bg-info"></div>
                <span class="m-l-sm"
                  >Total TS
                  <span
                    class="tooltip has-tooltip has-tooltip-multiline"
                    data-tooltip="Total timesheeté sur la mission"
                  >
                    <i
                      class="icon icon-info is-size-5 has-text-link"
                    ></i> </span
                ></span>
              </div>
            </span>
            <span>{{ timeSheetTotalAmount | euros }}</span>
          </div>
          <div class="has-text-right">
            <span
              class="has-text-link is-bold"
              *ngIf="budgetServicesAmount && timeSheetTotalAmount"
              >{{
                timeSheetTotalAmount / budgetServicesAmount | percent : '1.0-2'
              }}</span
            >
            <span>{{ timeSheetTotalDaysOfWork | number : '1.0-2' }}j </span>
          </div>
        </li>

        <li class="flex space-betxeen align-center list-separated flex-mobile">
          <div>
            <span class="total-label">
              <div class="color-yield-wrapper">
                <div class="small-circle m-a-0 bg-warning"></div>
                <span class="m-l-sm"
                  >Frais imputables
                  <span
                    class="tooltip has-tooltip has-tooltip-multiline"
                    data-tooltip="Total des frais non refacturables affectés à cette mission; à consulter dans l'onglet comptabilité."
                  >
                    <i
                      class="icon icon-info is-size-5 has-text-link"
                    ></i> </span
                ></span>
              </div>
            </span>
            <span>{{ totalNotBillableExpenses | euros }}</span>
          </div>
          <div></div>
        </li>

        <li class="flex space-betxeen align-center list-separated flex-mobile">
          <div>
            <span class="total-label">
              <div class="color-yield-wrapper">
                <div class="small-circle m-a-0 bg-warning"></div>
                <span class="m-l-sm"
                  >Markup
                  <span
                    class="tooltip has-tooltip has-tooltip-multiline"
                    data-tooltip="Total budgeté - total timesheeté - frais imputables; ne prend pas en compte les frais de gestion."
                  >
                    <i
                      class="icon icon-info is-size-5 has-text-link"
                    ></i> </span
                ></span>
              </div>
            </span>
            <span>{{
              budgetServicesAmount -
                timeSheetTotalAmount -
                totalNotBillableExpenses | euros
            }}</span>
          </div>
          <div></div>
        </li>

        <li class="flex space-betxeen align-center list-separated flex-mobile">
          <div>
            <span class="total-label">
              <div class="color-yield-wrapper">
                <div class="small-circle m-a-0 bg-warning"></div>
                <span class="m-l-sm"
                  >Markup prévisionnel
                  <span
                    class="tooltip has-tooltip has-tooltip-multiline"
                    data-tooltip="Total budgeté - total prévisionnel - frais imputables; ne prend pas en compte les frais de gestion."
                  >
                    <i
                      class="icon icon-info is-size-5 has-text-link"
                    ></i> </span
                ></span>
              </div>
            </span>
            <span>{{
              budgetServicesAmount -
                (timeSheetTotalAmount + futureBookedWorkTotalAmount) -
                totalNotBillableExpenses | euros
            }}</span>
          </div>
          <div></div>
        </li>
      </ul>
    </div>
  </div>
</div>
