import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms'
import { IMyDateModel } from '@nodro7/angular-mydatepicker'

import { DatepickerComponent } from '../../../base-components/datepicker.component'
import { AbcInput } from '../../../interfaces/abc-input.interface'

@Component({
  selector: 'abc-datepicker-input',
  templateUrl: './datepicker-input.component.html',
  styleUrls: ['./datepicker-input.component.scss']
})
export class DatepickerInputComponent
  extends DatepickerComponent
  implements AbcInput, OnChanges
{
  @Input() label: string
  @Input() helpText: string
  // Accepts YYYY-MM-DD formatted date
  @Input() initialValue: { value: string }
  @Input() placeholder: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string
  @Input() readonly = false

  @Output() valueChanged: EventEmitter<{ value: string }> = new EventEmitter()

  form: UntypedFormGroup
  required: boolean

  constructor(private formBuilder: UntypedFormBuilder) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    this.form = this.formBuilder.group({
      date: [
        this.initialValue && this.initialValue.value
          ? this.formatStandardDate(this.initialValue.value)
          : null,
        this.validators || []
      ]
    })
    this.required = this.validators.includes(Validators.required)
  }

  // Emits YYYY-MM-DD date or or null if date was removed.
  onDateChanged(event: IMyDateModel) {
    this.valueChanged.emit(
      event.singleDate.jsDate
        ? { value: this.formatMyDatePickerDate(event) }
        : { value: null }
    )
  }
}
