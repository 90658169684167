<section>
  <div class="flex flex-mobile space-between align-center m-b-gutter">
    <div class="left-part">
      <h1 class="title is-2 is-light" *ngIf="mode === 'create'">
        Ajouter {{ definition.gender === 'Masculine' ? 'un' : 'une' }}
        {{ definition.nameSingular }}
      </h1>
      <h1 class="title is-2 is-light" *ngIf="mode === 'edit'">
        Modifier {{ definition.gender === 'Masculine' ? 'un' : 'une' }}
        {{ definition.nameSingular }}
      </h1>
    </div>
    <div class="right-part" *ngIf="!loadingForm">
      <button
        class="button is-primary is-hidden-touch"
        (click)="submit()"
        [ngClass]="{ 'is-loading': loadingSubmit }"
      >
        Enregistrer
      </button>
      <button
        class="button is-primary is-rounded is-hidden-desktop"
        (click)="submit()"
        [ngClass]="{ 'is-loading': loadingSubmit }"
      >
        <i class="icon icon-save"></i>
      </button>
    </div>
  </div>

  <form [formGroup]="form" *ngIf="form && !loadingForm">
    <div class="card m-b-lg">
      <!-- Fields -->
      <div class="columns flex flex-wrap">
        <ng-container *ngFor="let field of resolvedFields">
          <ng-container *abcHasPermission="field.permission">
            <div
              class="column column--classic"
              [ngClass]="field.className"
              *ngIf="!field.hidden"
            >
              <abc-input
                [type]="field.inputType"
                [accept]="field.accept"
                [initialValue]="field.initialValue"
                [searchResources]="field.searchResources"
                [resourceName]="definition.slug"
                [searchParams]="field.searchParams"
                [maxSelectedItems]="field.maxSelectedItems"
                [selectOptions]="field.selectOptions"
                [helpText]="field.helpText"
                [min]="field.min"
                [max]="field.max"
                [label]="field.label"
                [placeholder]="field.placeholder"
                [readonly]="field.readonly"
                [validators]="field.validators"
                [showErrors]="showErrors"
                (valueChanged)="onValueChanged($event, field)"
              ></abc-input>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </form>

  <span *ngIf="loadingForm" class="m-t-xxl columns">
    <span class="spinner">
      <div class="spinner-inner"></div>
    </span>
  </span>
</section>
