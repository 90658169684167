import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import * as moment from 'moment'

import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Project } from '../../project/project.interface'
import { bookedWorkDefinition } from '../booked-work.definition'
import { BookedWork } from '../booked-work.interface'
import { UserServiceImplication } from '../user-service-implication.interface'

@Component({
  selector: 'app-booked-work-list',
  templateUrl: './booked-work-list.component.html',
  styleUrls: ['./booked-work-list.component.scss']
})
export class BookedWorkListComponent implements OnInit {
  @Input() project: Project
  @Input() hasServices: boolean
  @Input() canManageBookedWorks: boolean

  @Output() changed: EventEmitter<void> = new EventEmitter()

  tableLines: UserServiceImplication[]
  tableLineToDelete: UserServiceImplication
  deleteTableLineConfirmationText: string

  months: { label: string; value: string; isAfterEndDate: boolean }[]

  totalDaysOfWork: number
  totalAmount: number

  futureBookedWorkTotalDaysOfWork: number
  futureBookedWorkTotalAmount: number

  bookedWorkToDelete: BookedWork
  bookedWorkToEdit: BookedWork

  showCreateEditModal = false
  createEditMode: string
  bookedWorkDefinition: ResourceDefinition = bookedWorkDefinition
  formRules: FieldSpecialRule[]

  constructor(
    private resourceService: ResourceService,
    private flashMessageService: FlashMessageService
  ) {
    moment.locale('fr')
  }

  ngOnInit() {
    this.getBookedWorks()
  }

  getBookedWorks(): void {
    this.resourceService
      .list(`projects/${this.project.id}/booked-works`)
      .then(async (bookedWorks: BookedWork[]) => {
        // Build table variables.
        this.months = this.buildMonthsArray(
          bookedWorks,
          this.project.estimatedEndDate
        )
        this.tableLines = await this.buildTableLines(bookedWorks)

        // Calculate totals.
        this.totalDaysOfWork = bookedWorks.reduce(
          (sum: number, curr: BookedWork) => sum + curr.daysOfWork,
          0
        )
        this.totalAmount = bookedWorks.reduce(
          (sum: number, curr: BookedWork) =>
            sum + curr.daysOfWork * curr.user.position.referenceYearDailyRate,
          0
        )

        const futureBookedWorks = bookedWorks.filter((bW) =>
          moment(bW.date).isAfter()
        )
        this.futureBookedWorkTotalAmount = futureBookedWorks.reduce(
          (sum: number, bW: BookedWork) =>
            sum + bW.daysOfWork * bW.user.position.referenceYearDailyRate,
          0
        )
        this.futureBookedWorkTotalDaysOfWork = futureBookedWorks.reduce(
          (sum: number, bW: BookedWork) => sum + bW.daysOfWork,
          0
        )
      })
  }

  buildMonthsArray(
    bookedWorks: BookedWork[],
    estimatedEndDate: Date
  ): { label: string; value: string; isAfterEndDate: boolean }[] {
    const currentMonth: moment.Moment = moment.utc().startOf('month')
    const minArrayLength = 24
    const lastBookedWorkMonth: moment.Moment = moment.max(
      bookedWorks.map((bW) => moment(bW.date))
    )

    const months = []

    while (
      currentMonth.isBefore(lastBookedWorkMonth) ||
      months.length < minArrayLength
    ) {
      months.push({
        label: currentMonth.format('MMM YY'),
        value: currentMonth.format('YYYY-MM-DD'),
        isAfterEndDate: moment(currentMonth).isAfter(moment(estimatedEndDate))
      })
      currentMonth.add(1, 'month')
    }
    return months
  }

  buildTableLines(
    bookedWorks: BookedWork[]
  ): Promise<UserServiceImplication[]> {
    const tableLines: UserServiceImplication[] = []

    bookedWorks.forEach((bookedWork: BookedWork) => {
      const tableLine: UserServiceImplication = tableLines.find(
        (tL) =>
          (tL.user.id === bookedWork.user.id && tL.service.id) ===
          bookedWork.service.id
      )
      if (tableLine) {
        tableLine.months[moment(bookedWork.date).format('YYYY-MM-DD')] = {
          monthOccupationRate: 0.2,
          daysOfWork: bookedWork.daysOfWork,
          bookedWork
        }
      } else {
        tableLines.push({
          user: bookedWork.user,
          service: bookedWork.service,
          months: {
            [moment(bookedWork.date).format('YYYY-MM-DD')]: {
              monthOccupationRate: 1,
              daysOfWork: bookedWork.daysOfWork,
              bookedWork
            }
          }
        })
      }
    })

    // Sort alphabetically.
    tableLines.sort((a, b) => {
      if (a.user.name < b.user.name) {
        return -1
      }
      if (a.user.name > b.user.name) {
        return 1
      }
      return 0
    })

    return Promise.all(
      tableLines.map((tableLine: UserServiceImplication) =>
        this.resourceService
          .list('booked-works/user-monthly-availability', {
            userId: tableLine.user.id,
            months: this.months.map((m) => m.value)
          })
          .then(
            (
              monthOccupationRates: { month: string; occupationRate: number }[]
            ) => {
              monthOccupationRates.forEach(
                (monthOccupationRate: {
                  month: string
                  occupationRate: number
                }) => {
                  if (tableLine.months[monthOccupationRate.month]) {
                    tableLine.months[
                      monthOccupationRate.month
                    ].monthOccupationRate = monthOccupationRate.occupationRate
                  } else {
                    tableLine.months[monthOccupationRate.month] = {
                      daysOfWork: 0,
                      monthOccupationRate: monthOccupationRate.occupationRate
                    }
                  }
                }
              )
              return tableLine
            }
          )
      )
    )
  }

  openCreateModal(
    userId?: number,
    serviceId?: number,
    monthValue?: string
  ): void {
    this.formRules = [
      {
        fieldId: 'projectId',
        hidden: true,
        forcedValue: { projectId: this.project.id }
      },
      // "Create" allows for work spread on several months whereas "Edit" is only in a month.
      {
        fieldId: 'months',
        hidden: false,
        forcedValue: {
          value: monthValue ? [monthValue] : []
        }
      },
      {
        fieldId: 'month',
        hidden: true,
        forcedValue: null
      },
      {
        fieldId: 'userId',
        hidden: true,
        forcedValue: { value: userId }
      },
      {
        fieldId: 'serviceId',
        forcedValue: { value: serviceId }
      }
    ]
    this.bookedWorkToEdit = null
    this.createEditMode = 'create'
    this.showCreateEditModal = true
  }

  openEditModal(bookedWork: BookedWork, monthValue: string): void {
    this.formRules = [
      {
        fieldId: 'projectId',
        hidden: true,

        forcedValue: { projectId: this.project.id }
      },
      {
        fieldId: 'month',
        hidden: false,
        forcedValue: {
          value: monthValue
        }
      },
      {
        fieldId: 'months',
        hidden: true,
        forcedValue: null
      }
    ]
    this.bookedWorkToEdit = bookedWork
    this.createEditMode = 'edit'
    this.showCreateEditModal = true
  }

  deleteBookedWork(bookedWork: BookedWork): void {
    this.resourceService
      .delete(`projects/${this.project.id}/booked-works`, bookedWork.id)
      .subscribe((res) => {
        this.getBookedWorks()
        this.flashMessageService.success('La réservation a bien été effacée.')
        this.emitChanges()
      })
  }

  getDeleteConfirmationText(tableLine: UserServiceImplication): string {
    const activeMonths: {
      label: string
      value: string
      isAfterEndDate: boolean
    }[] = this.months.filter(
      (month) => tableLine.months[month.value].daysOfWork > 0
    )

    return `Voulez-vous vraiment supprimer toutes les réservations de <strong>${
      tableLine.user.name
    }</strong> sur la prestation <strong>${
      tableLine.service.name
    }</strong> pour ${
      activeMonths.length > 1 ? 'les' : 'le'
    } mois de <strong>${activeMonths
      .map((aM) => aM.label)
      .join(', ')}</strong> ?`
  }

  deleteTableLine(tableLine: UserServiceImplication): void {
    this.resourceService
      .delete(
        `projects/${this.project.id}/booked-works/user/${tableLine.user.id}/service`,
        tableLine.service.id
      )
      .subscribe(
        (res) => {
          this.getBookedWorks()
          this.flashMessageService.success(
            `Toutes les réservations de ${tableLine.user.name} sur la prestation ${tableLine.service.name} ont bien été supprimées.`
          )
          this.emitChanges()
        },
        (err) => {
          this.flashMessageService.error(
            `Impossible de supprimer les réservations de ${tableLine.user.name} sur la prestation ${tableLine.service.name}.`
          )
        }
      )
  }

  emitChanges() {
    this.changed.emit()
  }
}
