import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { Project } from '../../resources/project/project.interface'

export const projectProfitabilityDefinition: ResourceDefinition = {
  title: 'Rapport de suivi des missions',
  nameSingular: 'mission',
  namePlural: 'missions',
  hasDetailPage: true,
  hasListPage: true,
  gender: Gender.Feminine,
  slug: 'analytics/project-consumptions',
  path: 'analytics/rapport-suivi-missions',
  buttons: [LinkType.EXPORT],
  defaultLink: null,
  yields: [
    {
      label: 'Mission (N° - Référence)',
      property: 'department.color',
      className: 'has-mini-circle',
      secondProperty: 'label',
      orderByProperty: 'reference',
      type: YieldType.Color,
      link: (project: Project) => `missions/${project.id}`
    },
    {
      label: 'Statut',
      property: 'status',
      type: YieldType.ProgressBar
    },
    {
      label: 'Démarrage',
      property: 'estimatedStartDate',
      type: YieldType.Date
    },
    {
      label: 'Fin',
      property: 'estimatedEndDate',
      type: YieldType.Date
    },
    {
      label: 'Jours vendus',
      property: 'estimatedWorkTotalDays',
      type: YieldType.Number
    },
    {
      label: 'Jours Timesheet',
      property: 'timeSheetTotalDays',
      type: YieldType.Number
    },
    {
      label: 'Montant vendu',
      property: 'totalBudgetsAmount',
      type: YieldType.Currency
    },
    {
      label: 'Montant consommé',
      property: 'consumedTotalAmount',
      type: YieldType.Currency
    },
    {
      label: 'Frais non refacturables',
      property: 'totalNotBillableExpenses',
      type: YieldType.Currency
    },
    {
      label: 'Montant consommé / Montant vendu',
      property: 'consumedOfEstimatedAmountPercentage',
      type: YieldType.Percentage
    }
  ]
}
