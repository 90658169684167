<div
  class="flex align-center tooltip has-tooltip-left nowrap"
  [ngClass]="{
    'status-0': statusNumber < 1,
    'status-1': statusNumber === 1,
    'status-2': statusNumber === 2,
    'status-3': statusNumber === 3,
    'status-4': statusNumber === 4,
    'status-5': statusNumber === 5,
    'is-disabled': isDisabled
  }"
  [attr.data-tooltip]="status"
>
  <span class="bullet status-1"></span>
  <span
    class="bullet status-2"
    [ngClass]="{ 'is-white': statusNumber < 1 }"
  ></span>
  <span
    class="bullet status-3"
    [ngClass]="{ 'is-white': statusNumber < 2 }"
  ></span>
  <span
    class="bullet status-4"
    [ngClass]="{ 'is-white': statusNumber < 3 }"
  ></span>

  <span
    class="bullet status-5"
    [ngClass]="{ 'is-white': statusNumber < 4 }"
  ></span>
  <span
    class="bullet status-6"
    [ngClass]="{ 'is-white': statusNumber < 5 }"
  ></span>
</div>
