<div class="control">
  <label (click)="showList = true"
    >{{ label }}
    <span class="has-text-danger" *ngIf="required">*</span>
  </label>

  <!-- Fake select -->
  <div
    class="select select--simulate"
    [ngClass]="{
      'is-danger': showErrors && required && !selectedOptions.length
    }"
    (click)="showList = !showList"
  >
    <ng-container
      [ngPlural]="selectedOptions.length"
      *ngIf="
        selectedOptions.length && selectedOptions.length < selectOptions.length
      "
    >
      <ng-template ngPluralCase="=0">{{ placeholder }}</ng-template>
      <ng-template ngPluralCase="=1"
        >1 {{ itemNameSingular }} sélectionné</ng-template
      >
      <ng-template ngPluralCase="other"
        >{{ selectedOptions.length }}
        {{ itemNamePlural }} sélectionnés</ng-template
      >
    </ng-container>
    <ng-container
      *ngIf="
        !selectedOptions.length ||
        selectedOptions.length === selectOptions.length
      "
      >Tous</ng-container
    >
  </div>

  <!-- Dropdown checkbox list -->
  <div
    class="list m-b-gutter bg-white"
    *ngIf="showList && selectOptions && selectOptions.length"
  >
    <!-- Mass selection -->
    <a
      class="small-meta mass-selection-button has-text-grey-dark"
      *ngIf="selectedOptions.length < selectOptions.length"
      (click)="selectAll()"
      >Tout sélectionner</a
    >
    <a
      class="small-meta mass-selection-button has-text-grey-dark"
      *ngIf="selectedOptions.length === selectOptions.length"
      (click)="selectNone()"
      >Déséléctionner tout</a
    >

    <div class="control" *ngFor="let option of selectOptions">
      <label
        class="checkbox fake-checkbox"
        [ngClass]="{
          'is-checked': option.selected
        }"
        (click)="toggleSelected(option)"
      >
        {{ option.label }}
        <span class="checkmark"></span>
      </label>
      <p class="help" *ngIf="helpText">{{ helpText }}</p>
    </div>
  </div>
</div>
