import { Gender } from '../../../common/enums/gender.enum'
import { LinkType } from '../../../common/enums/link-type.enum'
import { YieldType } from '../../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { Credit } from '../../credit/credit.interface'

export const projectAccountingCreditDefinition: ResourceDefinition = {
  title: 'Avoirs',
  nameSingular: 'avoir',
  namePlural: 'avoirs',
  gender: Gender.Masculine,
  slug: 'credits',
  path: 'avoirs',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: null,
  dropdownLinks: [
    // ABAC instead of RBAC like creditDefinition.
    {
      type: LinkType.DELETE,
      deletePath: (c: Credit) => `projects/${c.invoice.project.id}/credits`,
      policy: {
        name: 'manageAccounting',
        resourceSlug: 'projects',
        resourceId: (c: Credit) => c.invoice.project.id
      }
    }
  ],
  // Less yields that creditDefinition.
  yields: [
    {
      label: 'Numéro',
      property: 'number'
    },
    {
      label: 'Libellé',
      property: 'label'
    },
    {
      label: 'Facture associée',
      property: 'invoice.number'
    },
    {
      label: `Date d'émission`,
      property: 'date',
      type: YieldType.Date
    },

    {
      label: 'Montant HT',
      property: 'amount',
      type: YieldType.Currency
    },
    {
      label: 'Commentaire',
      property: 'comments'
    },
    {
      label: '',
      property: 'path',
      type: YieldType.Download
    }
  ]
}
