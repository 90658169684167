<div class="control">
  <label for="rich-text-input-{{ uniqueId }}"
    >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
  >
  <div
    class="input-wrapper"
    [ngClass]="{ 'is-danger': showErrors && form.invalid }"
  >
    <ckeditor
      id="rich-text-input-{{ uniqueId }}"
      [editor]="editor"
      tagName="textarea"
      [data]="initialValue ? initialValue.value : null"
      [config]="editorConfig"
      (change)="onChange($event)"
    ></ckeditor>
  </div>
  <p class="help" *ngIf="helpText">{{ helpText }}</p>
</div>
