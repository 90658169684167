import { Component, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { departmentDefinition } from '../department.definition'

@Component({
  selector: 'app-department-create-edit',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class DepartmentCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = departmentDefinition

  fields: Field[] = [
    {
      label: 'Nom',
      properties: {
        value: 'name'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'is-6'
    },
    {
      label: 'Couleur',
      properties: { value: 'color' },
      className: 'p-x-0-mobile is-3',
      inputType: InputType.ColorPicker,
      validators: [Validators.required]
    },
    {
      label: 'Actif',
      helpText: `Seuls les practices actifs peuvent être associés à une nouvelle mission.`,
      properties: { value: 'isActive' },
      initialValue: { value: true },
      className: 'p-x-0-mobile is-6 aligned-checkbox',
      inputType: InputType.Checkbox,
      validators: []
    },
    {
      label: 'Pondérable',
      helpText: `Business Review : Le backlog et le CA réalisé sont calculés différement sur les practices pondérables.`,
      properties: { value: 'isPonderable' },
      initialValue: { value: false },
      className: 'p-x-0-mobile is-6 aligned-checkbox',
      inputType: InputType.Checkbox,
      validators: []
    }
  ]

  ngOnInit() {
    this.initCreateEditView()
  }
}
