import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  constructor(private http: HttpClient) {}

  hasUserPolicyAccess(
    resourceSlug: string,
    resourceId: number,
    policy: string
  ): Promise<boolean> {
    return this.http
      .get(
        `${environment.apiBaseUrl}/policies/${resourceSlug}/${resourceId}/policies/${policy}`
      )
      .toPromise() as Promise<boolean>
  }
}
