import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { AbcListComponent } from '../../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Filter } from '../../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { AuthService } from '../../../common/services/auth.service'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { projectOwnOnlyDefinition } from '../project-own-only.definition'
import { ProjectStatus } from '../project-status.enum'
import { projectDefinition } from '../project.definition'

@Component({
  selector: 'app-project-list',
  templateUrl:
    '../../../common/base-components/abc-list/abc-list.component.html',
  styleUrls: [
    '../../../common/base-components/abc-list/abc-list.component.scss'
  ]
})
export class ProjectListComponent extends AbcListComponent implements OnInit {
  definition: ResourceDefinition
  filters: Filter[] = [
    {
      label: 'Recherche de mission',
      placeholder: 'N° de mission, référence, client, responsable mission...',
      properties: {
        projectIds: 'projectIds',
        customerIds: 'customerIds',
        referentIds: 'referentIds'
      },
      searchResources: ['projects', 'customers', 'referents'],
      inputType: InputType.MultiSearch,
      className: 'is-12-touch is-6 p-x-0-mobile'
    },
    {
      label: 'Missions avec frais à refacturer',
      properties: {
        value: 'withExpensesToBillOnly'
      },
      inputType: InputType.Checkbox,
      className:
        'is-12-mobile is-6-tablet is-2-widescreen no-label p-x-0-mobile'
    },
    {
      label: 'Missions avec budget',
      properties: {
        value: 'withBudgetOnly'
      },
      inputType: InputType.Checkbox,
      className:
        'is-12-mobile is-6-tablet is-2-widescreen no-label p-x-0-mobile'
    },
    {
      label: 'Inclure les missions abandonnées',
      properties: {
        value: 'includeAbandoned'
      },
      inputType: InputType.Checkbox,
      className:
        'is-12-mobile is-6-tablet is-2-widescreen no-label p-x-0-mobile'
    },
    {
      label: 'Partner grand compte',
      properties: {
        value: 'accountManagerIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleName: 'partner'
        }),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet is-2-widescreen p-x-0-mobile'
    },
    {
      label: 'Partner mission',
      properties: {
        value: 'workSupervisorIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleName: 'partner'
        }),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet is-2-widescreen p-x-0-mobile'
    },
    {
      label: 'Resp. opérationnel',
      properties: {
        value: 'projectManagerIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users', {
          roleName: 'manager'
        }),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet is-2-widescreen p-x-0-mobile'
    },
    {
      label: 'Practice',
      properties: {
        value: 'departmentId'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('departments'),
      inputType: InputType.Select,
      className: 'is-12-mobile is-6-tablet is-2-widescreen p-x-0-mobile'
    },
    {
      label: 'Statut',
      properties: {
        value: 'statuses'
      },
      selectOptions: Object.values(ProjectStatus).map((status) => ({
        label: status,
        value: status
      })),
      inputType: InputType.MultiSelect,
      className: 'is-12-mobile is-6-tablet  is-2-widescreen p-x-0-mobile'
    },
    {
      label: 'Billable',
      properties: {
        value: 'billable'
      },
      placeholder: 'Tous',
      selectOptions: [
        {
          label: 'Seulement billable',
          value: 'billableOnly'
        },
        {
          label: 'Seulement non-billable',
          value: 'notBillableOnly'
        }
      ],
      inputType: InputType.Select,
      className: 'is-12-mobile is-6-tablet  is-2-widescreen p-x-0-mobile'
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService,
    private authService: AuthService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.authService
      .can('browseProjects')
      .then((canBrowseProjects: boolean) => {
        this.definition = canBrowseProjects
          ? projectDefinition
          : projectOwnOnlyDefinition

        this.initListView()
      })
  }
}
