import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { Referent } from './referent.interface'

export const referentDefinition: ResourceDefinition = {
  title: 'Contacts client',
  nameSingular: 'contact client',
  namePlural: 'contacts client',
  gender: Gender.Masculine,
  path: 'responsables',
  slug: 'referents',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [
    {
      propName: 'projects',
      preventDeleteMessage: `Ce contact est associé a une ou plusieurs missions`
    }
  ],
  dropdownLinks: [
    {
      type: LinkType.EDIT,
      permission: 'editReferents'
    },
    {
      type: LinkType.DELETE,
      permission: 'deleteReferents'
    }
  ],
  yields: [
    {
      label: 'Contact',
      property: 'name'
    },
    {
      label: 'Client',
      property: 'customer.name',
      link: (referent: Referent) => `clients/${referent.customer.id}`
    },
    {
      label: 'Mail',
      property: 'email'
    },
    {
      label: 'Tél',
      property: 'phone'
    },
    {
      label: 'Actif',
      property: 'isActive',
      type: YieldType.Check
    }
  ]
}
