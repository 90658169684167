export const environment = {
  envName: 'stagingAlt',
  isAltVersion: true,
  production: true,
  baseUrl: 'https://preprod.zele-sge.nextep-health.com',
  apiBaseUrl: 'https://preprod.api.zele-sge.nextep-health.com',
  storagePath: 'https://preprod.api.zele-sge.nextep-health.com/storage',
  tokenName: 'zeleAltShopToken',
  tokenWhitelistedDomains: ['preprod.api.zele-sge.nextep-health.com'],
  enableBugsnag: true,
  bugsnagApiKey: 'd11732012ca18ec602ffb0e1823d6aa7'
}
