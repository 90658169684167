import { Gender } from '../../common/enums/gender.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { Project } from '../../resources/project/project.interface'
import * as moment from 'moment'

const lastYear: string = moment().subtract(1, 'year').format('YYYY')
const currentYear: string = moment().format('YYYY')

export const cutOffDefinition: ResourceDefinition = {
  title: 'Cut off',
  nameSingular: 'cut off',
  namePlural: 'cut offs',
  hasDetailPage: true,
  hasListPage: true,
  gender: Gender.Masculine,
  slug: 'analytics/cut-off',
  path: 'analytics/cut-off',
  buttons: [],
  defaultLink: null,
  yields: [
    {
      label: 'Mission (N° - Référence)',
      property: 'department.color',
      className: 'has-mini-circle',
      secondProperty: 'label',
      orderByProperty: 'name',
      type: YieldType.Color,
      link: (project: Project) =>
        `missions/${project.id}?selectedTab=accounting`
    },
    {
      label: 'Client',
      property: 'customer.name',
      link: (project: Project) =>
        project.customer ? `clients/${project.customer.id}` : null
    },
    {
      label: 'Début',
      property: 'startDate',
      type: YieldType.Date
    },
    {
      label: 'Fin',
      property: 'endDate',
      type: YieldType.Date
    },
    {
      label: 'Budget',
      tooltip: 'Total budget + avenants',
      property: 'totalBudgetAmounts',
      type: YieldType.Currency
    },
    {
      label: 'Jrs budgétés',
      tooltip: 'Total des jours vendus (budgets + avenants)',
      property: 'totalBudgetDaysOfWork'
    },
    {
      label: `TS ${lastYear}`,
      tooltip: `Total des jours timesheetés en ${lastYear}`,
      property: 'lastYearTimeSheetDaysOfWork',
      type: YieldType.Number
    },
    {
      label: `TS ${currentYear}`,
      tooltip: `Total des jours timesheetés en ${currentYear}`,
      property: 'currentYearTimeSheetDaysOfWork',
      type: YieldType.Number
    },
    {
      label: `% Budget jrs consommés ${lastYear}`,
      tooltip: `TS ${lastYear} / Jours budgetés`,
      property: 'lastYearBudgetConsumed',
      type: YieldType.Percentage
    },
    {
      label: `% Avancement au 31/12/${lastYear}`,
      tooltip: `TS ${lastYear} / Total TS`,
      property: 'lastYearTimeSheetPercentage',
      type: YieldType.Percentage,
      className: 'has-mini-circle'
    },
    {
      label: `% Fact. au 31/12/${lastYear}`,
      tooltip: `Montant facturé ${lastYear} / Budget`,
      property: 'lastYearInvoicedPercentage',
      type: YieldType.Percentage
    },
    {
      label: `PCA ${lastYear}`,
      tooltip: `(Facturation au 31/12/${lastYear} - Avancement au 31/12/${lastYear}) / `,
      property: 'lastYearPCA',
      type: YieldType.Currency
    },
    {
      label: 'Statut',
      property: 'status',
      type: YieldType.ProgressBar
    }
  ]
}
