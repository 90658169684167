export const colors: string[] = [
  '#E7E79F',
  '#BCCD37',
  '#908102',
  '#655905',
  '#312300',
  '#009060',
  '#A1DD16',

  '#00FFE0',
  '#74BFB2',
  '#9FF5EF',
  '#0ED7F2',
  '#77BAED',
  '#4978CA',
  '#356CFF',
  '#195180',
  '#85092C',
  '#AB3773',
  '#C70150',
  '#1B0426',
  '#5B4149',
  '#D40000',
  '#FF5F62',
  '#F9984D',
  '#F78E25',
  '#FFCC0E',
  '#EBE818',
  '#FFCF67',
  '#DEBFB6',
  '#99858B',
  '#514A66',
  '#2F3052',
  '#00485E',
  '#178084',
  '#7D5576',
  '#BDA213',
  '#749D65'
]
