import { Component, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'

import { AbcListComponent } from '../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../common/enums/input-type.enum'
import { Filter } from '../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { SelectOption } from '../../common/interfaces/select-option.interface'
import { BreadcrumbService } from '../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../common/services/flash-message.service'
import { ResourceService } from '../../common/services/resource.service'
import { businessReviewDefinition } from './business-review.definition'

@Component({
  selector: 'app-business-review',
  templateUrl: './business-review.component.html',
  styleUrls: ['./business-review.component.scss']
})
export class BusinessReviewComponent
  extends AbcListComponent
  implements OnInit
{
  browseItemsPermissionName = 'browseAnalytics'

  filters: Filter[] = [
    {
      label: 'Affichage',
      properties: {
        value: 'entityName'
      },
      inputType: InputType.Select,
      validators: [Validators.required],
      selectOptions: [
        {
          label: 'Par practice',
          value: 'Department'
        },
        {
          label: 'Par client',
          value: 'Customer'
        }
      ],
      initialValue: {
        value: 'Department'
      }
    },
    {
      label: 'Filtre par année',
      inputType: InputType.Select,
      validators: [Validators.required],
      properties: {
        value: 'year'
      },
      placeholder: `Choisir l'année`,
      initialValue: {
        value: moment().format('YYYY')
      },
      className: 'is-6',
      selectOptions: () =>
        this.componentResourceService
          .show('invoices', 'oldest')
          .then((res: { year: number }) => {
            // Add years from the oldest invoice to now into select dropdown.
            const selectOptions: SelectOption[] = []
            let year: number = res.year
            const nextYear: number = parseInt(
              moment().add(1, 'year').format('YYYY'),
              10
            )

            while (year <= nextYear) {
              selectOptions.push({
                label: year.toString(),
                value: year.toString()
              })
              year++
            }
            return selectOptions
          })
    }
  ]
  definition: ResourceDefinition = businessReviewDefinition

  yearFilter: string
  entityFilter: string

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService,
    private customActivatedRoute: ActivatedRoute
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()

    this.customActivatedRoute.queryParams.subscribe((queryParams) => {
      this.yearFilter = queryParams.year
      this.entityFilter = queryParams.entity
    })
  }
}
