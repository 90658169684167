import { Gender } from '../../common/enums/gender.enum'
import { LinkType } from '../../common/enums/link-type.enum'
import { YieldType } from '../../common/enums/yield-type.enum'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { Expense } from './expense.interface'

export const expenseDefinition: ResourceDefinition = {
  title: 'Frais',
  nameSingular: 'frais',
  namePlural: 'frais',
  gender: Gender.Masculine,
  slug: 'expenses',
  path: 'frais',
  hasDetailPage: false,
  hasListPage: false,
  buttons: [],
  defaultLink: null,
  dropdownLinks: [
    {
      type: LinkType.CUSTOM,
      label: 'Modifier',
      linkAction: (e: Expense) => ({
        path: `/missions/${e.project.id}/frais/${e.id}/edit`
      }),
      policy: {
        name: 'manageAccounting',
        resourceSlug: 'projects',
        resourceId: (e: Expense) => e.project.id
      }
    },
    {
      type: LinkType.DELETE,
      deletePath: (e: Expense) => `projects/${e.project.id}/expenses`,
      policy: {
        name: 'manageAccounting',
        resourceSlug: 'projects',
        resourceId: (e: Expense) => e.project.id
      }
    }
  ],
  yields: [
    {
      label: 'Libellé',
      property: 'name'
    },
    {
      label: 'Référence',
      property: 'reference'
    },
    {
      label: 'Refacturé',
      property: 'invoice.number'
    },
    {
      label: `Date d'achat`,
      property: 'date',
      type: YieldType.Date
    },
    {
      label: `Paiement`,
      property: 'paymentDate',
      type: YieldType.Date
    },
    {
      label: `Montant HT`,
      property: 'amount',
      type: YieldType.Currency
    },
    {
      label: 'Commentaire',
      property: 'comments'
    },
    {
      label: 'Reçu',
      property: 'attachment',
      type: YieldType.Download
    }
  ]
}
