<div class="control">
  <label (click)="showList = true"
    >Collaborateur
    <span class="has-text-danger">*</span>
  </label>
  <!-- Fake select -->
  <div class="select select--simulate" (click)="showList = !showList">
    <ng-container *ngIf="selectedUser">{{ selectedUser.name }}</ng-container>
    <ng-container *ngIf="!selectedUser">Choisir un collaborateur</ng-container>
  </div>

  <!-- Dropdown checkbox list -->
  <div class="list p-a-gutter bg-white" *ngIf="showList">
    <div class="flax align-center space-between">
      <label>Collaborateurs</label>
      <label>Occupation (mois)</label>
    </div>
    <div class="control" *ngFor="let user of users">
      <label
        class="checkbox checkbox--select"
        [ngClass]="{
          'is-checked': selectedUser && user.id === selectedUser.id
        }"
        (click)="select(user)"
      >
        <span
          >{{ user.name }}
          <strong *ngIf="user.currentProjectTotalDaysOfWork"
            >({{
              user.currentProjectTotalDaysOfWork | number : '1.0-2'
            }})</strong
          ></span
        >
        <abc-analog-progress-bar-yield
          [progressPercentage]="user.occupationRate"
          [isLarge]="true"
          [isColorOpposite]="true"
          *ngIf="user.occupationRate !== null"
        ></abc-analog-progress-bar-yield>
      </label>
    </div>
  </div>
</div>
