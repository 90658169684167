import { Component, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'

import { AbcListComponent } from '../../common/base-components/abc-list/abc-list.component'
import { InputType } from '../../common/enums/input-type.enum'
import { Filter } from '../../common/interfaces/filter.interface'
import { ResourceDefinition } from '../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../common/services/flash-message.service'
import { ResourceService } from '../../common/services/resource.service'
import { BookedWork } from '../../resources/booked-work/booked-work.interface'
import { Position } from '../../resources/position/position.interface'
import { Project } from '../../resources/project/project.interface'
import { User } from '../../resources/user/user.interface'
import { bookedWorkReportDefinition } from './booked-work-report.definition'

@Component({
  selector: 'app-booked-work-report',
  templateUrl: './booked-work-report.component.html',
  styleUrls: [
    '../../common/base-components/abc-list/abc-list.component.scss',
    './booked-work-report.component.scss'
  ]
})
export class BookedWorkReportComponent
  extends AbcListComponent
  implements OnInit
{
  definition: ResourceDefinition = bookedWorkReportDefinition
  browseItemsPermissionName = 'browseAnalytics'

  expandedUser: User
  expandedUserProjects: Project[]

  filters: Filter[] = [
    {
      label: 'Collaborateurs',
      properties: {
        value: 'userIds'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users'),
      inputType: InputType.MultiSelect,
      className: 'is-6-mobile is-3-tablet is-3-widescreen p-x-0-mobile'
    },
    {
      label: 'Profils',
      properties: {
        value: 'positionId'
      },
      placeholder: 'Tous',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('positions'),
      inputType: InputType.MultiSelect,
      className: 'is-6-mobile is-3-tablet is-3-widescreen p-x-0-mobile'
    },
    {
      label: 'Période du rapport',
      inputType: InputType.MonthRange,
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      validators: [Validators.required]
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private componentResourceService: ResourceService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService
    )
  }

  ngOnInit() {
    this.initListView()
  }

  toggleExpandUser(user: User) {
    this.expandedUser =
      this.expandedUser && this.expandedUser.id === user.id ? null : user
  }
}
