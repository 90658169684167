import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { combineLatest, forkJoin } from 'rxjs'

import { InputType } from '../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { Yield } from '../../../common/interfaces/yield.interface'
import { EurosPipe } from '../../../common/pipes/euros.pipe'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Budget } from '../../budget/budget.interface'
import { Expense } from '../../expense/expense.interface'
import { Project } from '../../project/project.interface'
import { invoiceDefinition } from '../invoice.definition'

@Component({
  selector: 'app-invoice-create',
  templateUrl: './invoice-create.component.html',
  styleUrls: ['./invoice-create.component.scss']
})
export class InvoiceCreateComponent implements OnInit {
  fields: Field[]
  fieldSpecialRules: FieldSpecialRule[]

  budgets: Budget[]
  expenses: Expense[]
  radioOptions: SelectOption[]

  selectedBudget: Budget
  redirectTo: string
  isProjectRestricted = false
  isNewBudgetMode = false
  isExpenseMode = false

  loadingRadio = false
  InputType = InputType
  projectId: number

  customInvoiceDefinition: ResourceDefinition

  constructor(
    private eurosPipe: EurosPipe,
    private resourceService: ResourceService,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    // Prevent display of non appropriate yields and buttons.
    invoiceDefinition.yields = invoiceDefinition.yields.filter(
      (y: Yield) => y.label !== 'Client' && y.label !== 'Budget'
    )
    this.customInvoiceDefinition = {
      ...invoiceDefinition,
      ...{ dropdownLinks: [], actionButtons: [] }
    }

    combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.data
    ]).subscribe(async ([params, data]) => {
      if (data.projectRestricted) {
        this.isProjectRestricted = true
        this.projectId = parseInt(params.projectId, 10)
        this.buildRadioHelpers(params.projectId)
        this.redirectTo = `/missions/${params.projectId}?selectedTab=accounting`

        const project: Project = await this.resourceService
          .show('projects', this.projectId)
          .then((projectRes: Project) => projectRes)
        this.breadcrumbService.breadcrumbLinks.next([
          {
            label: 'Missions',
            path: '/missions'
          },
          {
            label: project.label,
            path: `/missions/${project.id}`
          },
          {
            label: 'Ajouter une facture'
          }
        ])
      } else {
        this.breadcrumbService.breadcrumbLinks.next([
          {
            label: 'Factures',
            path: '/factures'
          }
        ])
      }
    })
  }

  buildRadioHelpers(projectId: number): void {
    if (!projectId) {
      delete this.projectId
      this.radioOptions = []
      return
    }

    this.projectId = projectId

    forkJoin([
      this.resourceService.list('budgets', { projectId }),
      this.resourceService.list(`projects/${projectId}/expenses`, {
        projectId,
        billableToCustomer: true,
        withoutInvoice: true
      })
    ]).subscribe(([budgetRes, expenseRes]: [Budget[], Expense[]]) => {
      this.budgets = budgetRes
      this.radioOptions = this.budgets.map((budget: Budget) => ({
        label: `${budget.name} (${this.eurosPipe.transform(
          budget.totalAmount
        )})`,
        subLabel:
          budget.remainingAmount > 0
            ? `${this.eurosPipe.transform(budget.remainingAmount)} à facturer`
            : `Facturé`,
        value: budget.id.toString(),
        disabled: budget.remainingAmount <= 0
      }))

      this.expenses = expenseRes
      const totalAmountExpensesWithoutInvoices: number = this.expenses.reduce(
        (sum, curr) => sum + curr.amount,
        0
      )

      this.radioOptions.push({
        label: 'Frais refacturables',
        subLabel: `${this.eurosPipe.transform(
          totalAmountExpensesWithoutInvoices
        )} à facturer`,
        disabled: totalAmountExpensesWithoutInvoices === 0,
        value: 'expenses'
      })

      this.radioOptions.push({
        label: 'Facture libre',
        value: 'newBudget'
      })
    })
  }

  onRadioSelect(itemValue: string) {
    this.isNewBudgetMode = itemValue === 'newBudget'
    this.isExpenseMode = itemValue === 'expenses'

    if (itemValue !== 'newBudget' && itemValue !== 'expenses') {
      this.selectedBudget = this.budgets.find(
        (b) => b.id === parseInt(itemValue, 10)
      )
    } else {
      delete this.selectedBudget
    }
  }
}
