import { Component, Input, OnChanges } from '@angular/core'
import { FileMime } from '../../../common/enums/file-mime.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { documentDefinition } from '../document.definition'

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnChanges {
  @Input() projectId: number

  documents: Document[]
  mime: FileMime

  showCreateModal = false
  documentDefinition: ResourceDefinition = documentDefinition
  createDocumentSpecialRules: FieldSpecialRule[]

  constructor(private resourceService: ResourceService) {}

  ngOnChanges() {
    this.getDocuments()

    this.createDocumentSpecialRules = [
      {
        fieldId: 'projectId',
        forcedValue: { projectId: this.projectId },
        hidden: true
      }
    ]
  }

  getDocuments(): void {
    this.documents = null
    this.resourceService
      .list('documents', { projectId: this.projectId })
      .then((documentRes: Document[]) => {
        this.documents = documentRes
      })
  }
}
