import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import { forkJoin } from 'rxjs'

import { InputType } from '../../../common/enums/input-type.enum'
import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { BookedWork } from '../../booked-work/booked-work.interface'
import { Budget } from '../../budget/budget.interface'
import { Credit } from '../../credit/credit.interface'
import { EstimatedWork } from '../../estimated-work/estimated-work.interface'
import { Expense } from '../../expense/expense.interface'
import { Invoice } from '../../invoice/invoice.interface'
import { Project } from '../project.interface'

@Component({
  selector: 'app-project-validation-modal',
  templateUrl: './project-validation-modal.component.html',
  styleUrls: ['./project-validation-modal.component.scss']
})
export class ProjectValidationModalComponent implements OnInit {
  @Input() project: Project
  @Input() budgets: Budget[]
  @Input() bookedWorks: BookedWork[]

  @Output() closed: EventEmitter<boolean> = new EventEmitter()

  form: UntypedFormGroup = this.formBuilder.group({
    bonuses: this.formBuilder.array(
      [
        this.formBuilder.group({
          beneficiaryId: new UntypedFormControl(null, Validators.required),
          percentage: new UntypedFormControl(100, Validators.required)
        })
      ],
      Validators.minLength(1)
    )
  })

  totalExpensesNotBillableToCustomer: number
  totalBudgetsAmount: number
  totalInvoicesAmount: number
  totalCreditsAmount: number
  totalBookedWorksAmount: number
  totalBookedWorksDaysOfWork: number
  totalEstimatedWorksDaysOfWork: number

  InputType = InputType
  requiredValidators = [Validators.required]
  users: SelectOption[]
  showErrors: boolean

  get bonuses(): UntypedFormArray {
    return this.form.get('bonuses') as UntypedFormArray
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private flashMessageService: FlashMessageService,
    private resourceService: ResourceService
  ) {}

  async ngOnInit() {
    this.users = await this.resourceService.listSelectOptions('users', {
      positionNames: ['Manager', 'Senior Expert', 'Senior Manager'],
      userIdsToExclude: this.project.projectLeader
        ? [this.project.projectLeader.id]
        : []
    })

    this.totalBudgetsAmount = this.budgets.reduce(
      (sum: number, b: Budget) => sum + b.totalAmount,
      0
    )
    this.totalBookedWorksAmount = this.bookedWorks.reduce(
      (sum: number, bW: BookedWork) =>
        sum + bW.daysOfWork * bW.user.position.referenceYearDailyRate,
      0
    )
    this.totalBookedWorksDaysOfWork = this.bookedWorks.reduce(
      (sum: number, bW: BookedWork) => sum + bW.daysOfWork,
      0
    )

    this.resourceService
      .list('expenses', { projectId: this.project.id })
      .then((expenseRes: Expense[]) => {
        this.totalExpensesNotBillableToCustomer = expenseRes
          .filter((e) => !e.billableToCustomer)
          .reduce((sum: number, e) => sum + e.amount, 0)
      })

    this.resourceService
      .list('estimated-works', { projectId: this.project.id })
      .then((estimatedWorkRes: EstimatedWork[]) => {
        this.totalEstimatedWorksDaysOfWork = estimatedWorkRes.reduce(
          (sum: number, eW: EstimatedWork) => sum + eW.daysOfWork,
          0
        )
      })

    forkJoin([
      this.resourceService.list('invoices', {
        projectId: this.project.id,
        withoutPagination: true
      }),
      this.resourceService.list('credits', {
        projectId: this.project.id,
        withoutPagination: true
      })
    ]).subscribe(([invoiceRes, creditRes]: [Invoice[], Credit[]]) => {
      this.totalInvoicesAmount = invoiceRes.reduce(
        (sum, curr) => sum + curr.amount,
        0
      )
      this.totalCreditsAmount = creditRes.reduce(
        (sum, curr) => sum + curr.amount,
        0
      )
    })
  }

  async submit() {
    const isTotalPercentageValid: boolean =
      this.form.value.bonuses.reduce(
        (sum, curr) => sum + curr.percentage,
        0
      ) === 100

    if (!isTotalPercentageValid) {
      return this.flashMessageService.error(
        `Le total des pourcentages doit être égal à 100%`
      )
    }

    this.resourceService
      .patch('projects', this.project.id, 'validate', {
        bonuses: this.form.value.bonuses
      })
      .subscribe(
        (res) => {
          this.flashMessageService.success('La mission a bien été validée.')
          this.closed.emit(true)
        },
        (err) => {
          this.flashMessageService.error(
            `Une erreur a eu lieu. La mission n'a pas pu être validée.`
          )
        }
      )
  }

  addBonus(): void {
    this.bonuses.push(
      new UntypedFormGroup({
        beneficiaryId: new UntypedFormControl(null, Validators.required),
        percentage: new UntypedFormControl(0, Validators.required)
      })
    )
  }

  removeBonus(index: number) {
    this.bonuses.removeAt(index)
  }

  close() {
    this.closed.emit(false)
  }
}
100
