import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { appConstants } from '../../../../../app.constants'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Position } from '../../position/position.interface'
import { Role } from '../../role/role.interface'
import { userDefinition } from '../user.definition'

@Component({
  selector: 'app-user-create-edit',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class UserCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit, OnDestroy
{
  definition: ResourceDefinition = userDefinition
  isEditMyself: boolean
  consultantRole: Role
  managerRole: Role

  // EditMyself only : Changing user's own email makes token obsolete.
  emailChanged = false

  fields: Field[] = [
    {
      id: 'name',
      label: 'Nom',
      properties: {
        value: 'name'
      },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.Text,
      validators: [Validators.required]
    },
    {
      id: 'email',
      label: 'Email',
      properties: {
        value: 'email'
      },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.Email,
      validators: [Validators.required, Validators.email]
    },
    {
      id: 'roleId',
      label: `Rôle`,
      placeholder: `Choisir le rôle du collaborateur...`,
      properties: { value: 'roleId' },
      retrievedItemProperties: {
        roleId: 'role.id'
      },
      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('roles'),
      className: 'p-x-0-mobile is-6',
      validators: [Validators.required],
      onChange: async (newValue: { value: string }) => {
        const superiorField: Field = this.getFieldById('superiorId')
        this.setFieldValue(superiorField, null)

        const isConsultant: boolean =
          this.consultantRole.id.toString() === newValue.value
        const isManager: boolean =
          this.managerRole.id.toString() === newValue.value

        if (isConsultant || isManager) {
          superiorField.hidden = false

          superiorField.selectOptions =
            await this.componentResourceService.listSelectOptions('users', {
              roleName: isConsultant
                ? appConstants.roleNames.manager
                : appConstants.roleNames.partner
            })
        } else {
          superiorField.hidden = true
        }
      }
    },
    {
      id: 'positionId',
      label: `Profil`,
      placeholder: `Choisir le profil du collaborateur...`,
      properties: { value: 'positionId' },
      retrievedItemProperties: {
        positionId: 'position.id'
      },
      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService
          .list('positions', { withoutPagination: true })
          .then((positionRes: Position[]) =>
            positionRes.map((p) => ({
              label: p.name,
              value: p.id.toString()
            }))
          ),
      className: 'p-x-0-mobile is-6',
      validators: [Validators.required]
    },
    {
      id: 'superiorId',
      label: 'Référent',
      hidden: true,
      properties: {
        value: 'superiorId'
      },
      retrievedItemProperties: {
        superiorId: 'superior.id'
      },
      placeholder: 'Aucun référant',
      selectOptions: [],
      inputType: InputType.Select,
      className: 'p-x-0-mobile is-6',
      validators: []
    },
    {
      label: 'Mot de passe',
      properties: {
        value: 'password'
      },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.Password,
      helpText:
        'Doit contenir au moins 18 caractères, 1 majuscule, 1 miniscule, 1 chiffre et 1 caractère spécial',
      validators: [
        Validators.required,
        Validators.pattern(
          '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&~#_^.+:;=])[A-Za-zd$@$!%*?&~#_^.+:;=].{18,}'
        )
      ],
      editValidators: [
        Validators.pattern(
          '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&~#_^.+:;=])[A-Za-zd$@$!%*?&~#_^.+:;=].{18,}'
        )
      ]
    },
    {
      label: 'Avatar',
      placeholder: 'Choisir un fichier image',
      properties: { value: 'image' },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.Image,
      validators: []
    },
    {
      label: 'Objectif',
      placeholder: 'Objectif en %...',
      properties: { value: 'objectivePercentage' },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.Number,
      max: 100,
      min: 0,
      validators: [],
      permission: 'editUserObjectivePercentage'
    },
    {
      label: 'Couleur',
      properties: { value: 'color' },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.ColorPicker,
      validators: [Validators.required]
    },
    {
      label: 'Actif',
      helpText: `Seuls les utilisateurs actifs peuvent se connecter à l'application`,
      properties: { value: 'isActive' },
      initialValue: { value: true },
      className: 'p-x-0-mobile is-6 aligned-checkbox',
      inputType: InputType.Checkbox,
      validators: []
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private customBreadcrumbService: BreadcrumbService,
    private customFlashMessageService: FlashMessageService,
    private customRouter: Router,
    private customActivatedRoute: ActivatedRoute,
    private componentResourceService: ResourceService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
    this.isEditMyself = activatedRoute.snapshot.data.editMyself
  }

  async ngOnInit() {
    const roles = await this.componentResourceService.list('roles', {
      withoutPagination: true
    })

    this.consultantRole = roles.find(
      (r) => r.name === appConstants.roleNames.consultant
    )

    this.managerRole = roles.find(
      (r) => r.name === appConstants.roleNames.manager
    )

    if (this.isEditMyself) {
      this.initUserEditMyselfView()
    } else {
      await this.initCreateEditView()

      if (
        (this.mode === 'edit' &&
          this.item.role.id === this.consultantRole.id) ||
        this.item.role.id === this.managerRole.id
      ) {
        const superiorField: Field = this.getFieldById('superiorId')
        superiorField.selectOptions =
          await this.componentResourceService.listSelectOptions('users', {
            roleName:
              this.item.role.id === this.consultantRole.id
                ? appConstants.roleNames.manager
                : appConstants.roleNames.partner
          })
        superiorField.hidden = false
      }
    }
  }

  // Special version of this form when user edits hisself or herself.
  async initUserEditMyselfView() {
    this.mode = this.customActivatedRoute.snapshot.data.mode
    this.fieldSpecialRules = [
      {
        fieldId: 'roleId',
        hidden: true
      },
      {
        fieldId: 'positionId',
        hidden: true
      }
    ]

    this.resolvedFields = await this.resolveFields(this.fields)

    await this.getItem('myself')
    this.item.id = 'myself'

    this.form = this.generateForm(this.fields)

    this.form.valueChanges.subscribe((newValue: { email: string }) => {
      if (newValue.email && newValue.email !== this.item.email) {
        this.emailChanged = true
      }
    })

    this.customBreadcrumbService.breadcrumbLinks.next([
      {
        path: `/${this.definition.path || this.definition.slug}`,
        label: this.definition.title
      },
      {
        label: `Modifier mon profil`
      }
    ])
  }

  ngOnDestroy() {
    if (this.isEditMyself && this.emailChanged) {
      this.customRouter.navigate(['/logout'])
      this.customFlashMessageService.info(
        `Vous avez changé votre adresse email. Veuillez vous re-connecter avec votre nouvelle adresse.`
      )
    }
  }
}
