<div
  class="multiselect-box"
  [ngClass]="{
    'is-danger': showErrors && required && !selectedSearchResults.length
  }"
>
  <div class="control">
    <label for="multi-search-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >

    <div class="input-wrapper">
      <div
        *ngIf="selectedSearchResults.length"
        class="m-y-xs searchresults-box"
      >
        <span
          class="tag is-dark"
          *ngFor="let selectedSearchResult of selectedSearchResults"
        >
          <span>
            <!-- Icon-->
            <i
              class="icon is-size-5"
              [ngClass]="{
                'icon-user':
                  selectedSearchResult.resourceName === 'users' ||
                  selectedSearchResult.resourceName === 'referents',
                'icon-file-text':
                  selectedSearchResult.resourceName === 'invoices' ||
                  selectedSearchResult.resourceName === 'credits',
                'icon-map-pin':
                  selectedSearchResult.resourceName === 'localities',
                'icon-home': selectedSearchResult.resourceName === 'agencies',
                'icon-trending-up':
                  selectedSearchResult.resourceName === 'expenses',
                'icon-hash':
                  selectedSearchResult.resourceName === 'technicians',
                'icon-clipboard':
                  selectedSearchResult.resourceName === 'statements',
                'icon-pie-chart':
                  selectedSearchResult.resourceName === 'markets',
                'icon-file': selectedSearchResult.resourceName === 'receipts',
                'icon-grid': selectedSearchResult.resourceName === 'projects',
                'icon-briefcase':
                  selectedSearchResult.resourceName === 'customers',
                'icon-users': selectedSearchResult.resourceName === 'referents',
                'icon-file-plus':
                  selectedSearchResult.resourceName === 'acceptanceReports',
                'icon-tag': selectedSearchResult.resourceName === 'types',
                'icon-box': selectedSearchResult.resourceName === 'orders'
              }"
            ></i>
          </span>

          <span>{{ selectedSearchResult.shortLabel }}</span>
          <span
            class="pointer close-tag"
            (click)="toggleItem(selectedSearchResult)"
            *ngIf="!readonly"
          >
            <i class="icon is-size-5 icon-x"></i>
          </span>
        </span>
      </div>
      <div class="multisearch-input-field">
        <input
          id="multi-search-input-{{ uniqueId }}"
          type="text"
          class="input"
          (keyup)="onSearchInputKeyup($event)"
          [placeholder]="selectedSearchResults.length ? '' : placeholder"
          (focus)="showList = true"
          [disabled]="
            readonly ||
            (maxSelectedItems &&
              selectedSearchResults.length >= maxSelectedItems)
          "
          #searchInput
        />
        <ul class="list bg-white" *ngIf="showList && suggestedSearchResults.length">
          <li
            *ngFor="let searchResult of suggestedSearchResults; let i = index"
            class="pointer"
            [ngClass]="{
              'is-active': selectedSearchResults.indexOf(searchResult) > -1,
              'is-focused': focusedItemIndex === i
            }"
            (click)="toggleItem(searchResult)"
          >
            <!-- Icon-->
            <i
              class="icon is-size-5"
              [ngClass]="{
                'icon-user has-text has-text-link':
                  searchResult.resourceName === 'users' ||
                  searchResult.resourceName === 'referents',
                'icon-file-text has-text-brown':
                  searchResult.resourceName === 'invoices' ||
                  searchResult.resourceName === 'credits',
                'icon-map-pin has-text-tomato':
                  searchResult.resourceName === 'localities',
                'icon-home has-text-cherry':
                  searchResult.resourceName === 'agencies',
                'icon-trending-up has-text-raspberry':
                  searchResult.resourceName === 'expenses',
                'icon-hash has-text-orange':
                  searchResult.resourceName === 'technicians',
                'icon-clipboard has-text-kiwi':
                  searchResult.resourceName === 'statements',
                'icon-pie-chart has-text-info':
                  searchResult.resourceName === 'markets',
                'icon-file has-text-blueberry':
                  searchResult.resourceName === 'receipts',
                'icon-grid has-text-kiwi':
                  searchResult.resourceName === 'projects',
                'icon-briefcase has-text-success':
                  searchResult.resourceName === 'customers',
                'icon-users has-text-primary':
                  searchResult.resourceName === 'referents',
                'icon-file-plus has-text-lime':
                  searchResult.resourceName === 'acceptanceReports',
                'icon-tag has-text-dark': searchResult.resourceName === 'types',
                'icon-box has-text-primary':
                  searchResult.resourceName === 'orders'
              }"
            ></i>
            <span innerHTML="{{ searchResult.label }}"></span>
          </li>
        </ul>
      </div>
      <p class="help" *ngIf="helpText">{{ helpText }}</p>
    </div>
  </div>
</div>
