import { Component, OnInit, Input } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { ActivityNature } from '../../../common/enums/activity-nature.enum'
import { ResourceService } from '../../../common/services/resource.service'
import { Activity } from '../../activity/activity.interface'
import { ProjectStatus } from '../project-status.enum'

@Component({
  selector: 'app-project-timeline',
  templateUrl: './project-timeline.component.html',
  styleUrls: ['./project-timeline.component.scss']
})
export class ProjectTimelineComponent implements OnInit {
  @Input() projectId: number

  activities: Activity[]
  ActivityNature = ActivityNature

  showCreateEditModal: boolean
  createEditMode: string
  activityToEdit: Activity

  isAlt: boolean = environment.isAltVersion

  constructor(private resourceService: ResourceService) {}

  ngOnInit() {
    this.getActivities()
  }

  getActivities(): void {
    this.resourceService
      .list('activities', { projectId: this.projectId })
      .then((res: Activity[]) => {
        this.activities = res
      })
  }

  getStatusNumber(status: ProjectStatus): number {
    return Object.values(ProjectStatus).indexOf(status)
  }

  openEditModal(activity: Activity) {
    this.activityToEdit = activity
    this.createEditMode = 'edit'
    this.showCreateEditModal = true
  }

  openCreateModal() {
    delete this.activityToEdit
    this.createEditMode = 'create'
    this.showCreateEditModal = true
  }
}
