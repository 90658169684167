<form [formGroup]="form" novalidate>
  <div class="control" [ngClass]="{ 'is-danger': showErrors && form.invalid }">
    <label for="date-from-{{ uniqueId }}-input">{{ label }}</label>
    <div class="flex">
      <div class="input-text">Du</div>
      <div class="is-relative input-wrapper input-wrapper--mydp">
        <input
          angular-mydatepicker
          id="date-from-{{ uniqueId }}-input"
          class="input"
          [placeholder]="placeholder || 'Choisissez une date...'"
          [options]="dateFromOptions"
          (dateChanged)="onDateChanged($event, 'dateFrom')"
          (click)="dp1.openCalendar()"
          #dp1="angular-mydatepicker"
          formControlName="dateFrom"
        />
      </div>
      <div class="input-text">au</div>
      <div class="is-relative input-wrapper input-wrapper--mydp">
        <input
          angular-mydatepicker
          id="date-to-{{ uniqueId }}-input"
          class="input"
          [placeholder]="placeholder || 'Choisissez une date...'"
          [options]="dateToOptions"
          (dateChanged)="onDateChanged($event, 'dateTo')"
          (click)="dp2.openCalendar()"
          #dp2="angular-mydatepicker"
          formControlName="dateTo"
        />
      </div>
    </div>
    <p class="help" *ngIf="helpText">{{ helpText }}</p>
  </div>
</form>
