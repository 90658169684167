import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { positionDefinition } from '../position.definition'

@Component({
  selector: 'app-position-create-edit',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class PositionCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = positionDefinition

  isQ4: boolean = moment().month() >= 9

  fields: Field[] = [
    {
      label: 'Nom',
      properties: {
        value: 'name'
      },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.Text,
      validators: [Validators.required]
    },
    {
      label: 'Couleur',
      properties: { value: 'color' },
      className: 'p-x-0-mobile is-6',
      inputType: InputType.ColorPicker,
      validators: [Validators.required]
    },
    {
      label: `Tarif journalier ${moment().subtract(1, 'year').year()}`,
      placeholder: '0',
      properties: {
        value: 'previousYearDailyRate'
      },
      inputType: InputType.Number,
      readonly: true,
      validators: [],
      className: 'p-x-0-mobile is-4'
    },
    {
      label: `Tarif journalier ${moment().year()}`,
      placeholder: '0',
      properties: {
        value: 'referenceYearDailyRate'
      },
      inputType: InputType.Number,
      min: 0,
      validators: [Validators.required],
      className: 'p-x-0-mobile is-4'
    },
    {
      label: `Tarif journalier ${moment().add(1, 'year').year()}`,
      placeholder: '0',
      properties: {
        value: 'nextYearDailyRate'
      },
      inputType: InputType.Number,
      readonly: !this.isQ4,
      validators: [],
      className: 'p-x-0-mobile is-4',
      helpText: this.isQ4
        ? ''
        : `L'édition du tarif journalier ${moment()
            .add(1, 'year')
            .year()} sera disponible à partir du 1er Septembre ${moment().year()}.`
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
