import { DecimalPipe } from '@angular/common'
import { Component, Input, OnChanges } from '@angular/core'
import { ChartOptions, TooltipItem } from 'chart.js'
import moment from 'moment'
import { forkJoin } from 'rxjs'

import { environment } from '../../../../environments/environment'
import { SelectOption } from '../../../common/interfaces/select-option.interface'
import { ResourceService } from '../../../common/services/resource.service'
import { BookedWork } from '../../booked-work/booked-work.interface'
import { Budget } from '../../budget/budget.interface'
import { EstimatedWork } from '../../estimated-work/estimated-work.interface'
import { Expense } from '../../expense/expense.interface'
import { TimeSheet } from '../../time-sheet/time-sheet.interface'

@Component({
  selector: 'app-project-chart',
  templateUrl: './project-chart.component.html',
  styleUrls: ['./project-chart.component.scss']
})
export class ProjectChartComponent implements OnChanges {
  @Input() projectId: number

  positions: {
    id: number
    label: string
    estimatedDaysOfWork: number
    bookedDaysOfWork: number
    timeSheetDaysOfWork: number
  }[]

  budgetServicesAmount: number
  bookedWorkTotalAmount: number
  futureBookedWorkTotalAmount: number
  timeSheetTotalAmount: number
  totalNotBillableExpenses: number

  estimatedWorkTotalDaysOfWork: number
  bookedWorkTotalDaysOfWork: number
  futureBookedWorkTotalDaysOfWork: number
  timeSheetTotalDaysOfWork: number

  chartOptions: ChartOptions<'bar'> = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<any>) =>
            `${this.chartData[context.datasetIndex].label}: ${
              context.formattedValue
            } jours`
        },
        backgroundColor: '#303032',
        titleFont: {
          size: 13
        },
        bodyFont: {
          size: 13
        },
        displayColors: false,
        cornerRadius: 2
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        type: 'category',
        grid: {
          display: false
        }
      },
      y: {
        ticks: {
          callback: (value: number) => `${value} j`
        }
      }
    }
  }
  chartLabels: string[]
  chartData: any[]

  transparentColor = 'rgba(0,0,0,0)'

  constructor(
    private resourceService: ResourceService,
    private decimalPipe: DecimalPipe
  ) {}

  async ngOnChanges() {
    // Get all the data and format together once everything arrived.
    forkJoin([
      this.resourceService.listSelectOptions('positions'),
      this.resourceService.list('budgets', {
        projectId: this.projectId
      }),
      this.resourceService.list(`projects/${this.projectId}/estimated-works`),
      this.resourceService.list(`projects/${this.projectId}/booked-works`, {
        includePast: true
      }),
      this.resourceService.list(`projects/${this.projectId}/time-sheets`),
      this.resourceService.list(`projects/${this.projectId}/expenses`, {
        notBillableToCustomer: true
      })
    ]).subscribe(
      ([
        positionRes,
        budgetRes,
        estimatedWorkRes,
        bookedWorkRes,
        timeSheetRes,
        expenseRes
      ]: [
        SelectOption[],
        Budget[],
        EstimatedWork[],
        BookedWork[],
        TimeSheet[],
        Expense[]
      ]) => {
        // Positions.
        this.positions = positionRes.map((position: SelectOption) =>
          Object.assign(position, {
            id: parseInt(position.value),
            estimatedDaysOfWork: 0,
            bookedDaysOfWork: 0,
            timeSheetDaysOfWork: 0
          })
        )

        this.budgetServicesAmount = budgetRes.reduce(
          (sum: number, b: Budget) => sum + b.servicesAmount,
          0
        )

        // Estimated works.
        this.estimatedWorkTotalDaysOfWork = estimatedWorkRes.reduce(
          (sum: number, bW: EstimatedWork) => sum + bW.daysOfWork,
          0
        )

        // Booked works.
        this.bookedWorkTotalAmount = bookedWorkRes.reduce(
          (sum: number, bW: BookedWork) =>
            sum + bW.daysOfWork * bW.user.position.referenceYearDailyRate,
          0
        )
        this.bookedWorkTotalDaysOfWork = bookedWorkRes.reduce(
          (sum: number, bW: BookedWork) => sum + bW.daysOfWork,
          0
        )

        const futureBookedWorks = bookedWorkRes.filter((bW) =>
          moment(bW.date).isAfter()
        )
        this.futureBookedWorkTotalAmount = futureBookedWorks.reduce(
          (sum: number, bW: BookedWork) =>
            sum + bW.daysOfWork * bW.user.position.referenceYearDailyRate,
          0
        )
        this.futureBookedWorkTotalDaysOfWork = futureBookedWorks.reduce(
          (sum: number, bW: BookedWork) => sum + bW.daysOfWork,
          0
        )

        // TimeSheets.
        this.timeSheetTotalAmount = timeSheetRes.reduce(
          (sum: number, tS: TimeSheet) => sum + tS.daysOfWork * tS.dailyRate,
          0
        )
        this.timeSheetTotalDaysOfWork = timeSheetRes.reduce(
          (sum: number, tS: TimeSheet) => sum + tS.daysOfWork,
          0
        )

        // Calculate total expenses.
        this.totalNotBillableExpenses = expenseRes.reduce(
          (sum: number, curr: Expense) => sum + curr.amount,
          0
        )

        // Add position-related dayCounts.
        this.positions.forEach((position) => {
          position.estimatedDaysOfWork = estimatedWorkRes
            .filter((eW) => eW.position.id === position.id)
            .reduce(
              (sum: number, curr: EstimatedWork) => sum + curr.daysOfWork,
              0
            )
          position.bookedDaysOfWork = bookedWorkRes
            .filter((bW) => bW.user.position.id === position.id)
            .reduce((sum: number, curr: BookedWork) => sum + curr.daysOfWork, 0)
          position.timeSheetDaysOfWork = timeSheetRes
            .filter((tS) => tS.user.position.id === position.id)
            .reduce((sum: number, curr: TimeSheet) => sum + curr.daysOfWork, 0)
        })

        this.positions = this.positions.filter(
          (p) =>
            p.estimatedDaysOfWork || p.bookedDaysOfWork || p.timeSheetDaysOfWork
        )

        this.chartLabels = this.positions.map((p) => p.label)

        this.chartData = [
          {
            label: 'Temps budgeté',
            data: this.positions.map((p) => p.estimatedDaysOfWork),
            backgroundColor: '#57B3A0',
            hoverBackgroundColor: '#57B3A0',
            borderColor: this.transparentColor,
            hoverBorderColor: this.transparentColor,
            borderRadius: 5,
            barPercentage: 0.7,
            maxBarThickness: 8,
            
            categoryPercentage: 0.2
          },
          {
            label: 'Temps réservé',
            data: this.positions.map((p) => p.bookedDaysOfWork),
            backgroundColor: environment.isAltVersion ? '#4E5CA6' : '#5C1EE8',
            hoverBackgroundColor: environment.isAltVersion
              ? '#4E5CA6'
              : '#5C1EE8',
            borderColor: this.transparentColor,
            hoverBorderColor: this.transparentColor,
            borderRadius: 5,
            barPercentage: 0.7,
            maxBarThickness: 8,
            
            categoryPercentage: 0.2
          },
          {
            label: 'Time sheet',
            data: this.positions.map((p) => p.timeSheetDaysOfWork),
            backgroundColor: '#1EB1E8',
            hoverBackgroundColor: '#1EB1E8',
            borderColor: this.transparentColor,
            hoverBorderColor: this.transparentColor,
            borderRadius: 5,
            maxBarThickness: 8,
            
            barPercentage: 0.7,
            categoryPercentage: 0.2
          }
        ]
      }
    )
  }
}
