<div [formGroup]="numberForm">
  <div class="control">
    <label for="number-input-{{ uniqueId }}"
      >{{ label }}<span class="has-text-danger" *ngIf="required">*</span></label
    >
    <input
      type="number"
      id="number-input-{{ uniqueId }}"
      class="input form-control"
      [ngClass]="{ 'is-danger': showErrors && numberForm.invalid }"
      [placeholder]="placeholder || label + '...'"
      [step]="step"
      [min]="min"
      [max]="max"
      formControlName="number"
      (keyup)="onChange($event.target.value)"
      (change)="onChange($event.target.value)"
    />
    <p class="help" *ngIf="helpText">{{ helpText }}</p>
  </div>
</div>
