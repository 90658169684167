<p class="m-b-md m-t-lg">
  <span *ngIf="paginator.lastPage > 1">
    Page {{ paginator.currentPage }} sur {{ paginator.lastPage }} •
  </span>
  <span>
    <ng-container [ngPlural]="paginator.total">
      <ng-template ngPluralCase="0">Aucun résultat</ng-template>
      <ng-template ngPluralCase="1">1 résultat</ng-template>
      <ng-template ngPluralCase="other">
        {{ paginator.total }} résultats
      </ng-template>
    </ng-container>
    <span *ngIf="paginator.lastPage > 1">au total</span>
  </span>
</p>
