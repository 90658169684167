import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core'
import { ValidatorFn, Validators } from '@angular/forms'

import { AbcInput } from '../../../../common/interfaces/abc-input.interface'

@Component({
  selector: 'abc-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss']
})
export class CheckboxInputComponent implements AbcInput, OnChanges {
  @Input() initialValue: { value: string }
  @Input() label: string
  @Input() placeholder: string
  @Input() helpText: string
  @Input() showErrors = false
  @Input() validators: ValidatorFn[] = []
  @Input() uniqueId: string

  @Output() valueChanged: EventEmitter<{
    value: boolean
  }> = new EventEmitter()

  checked: boolean
  required: boolean

  ngOnChanges(changes: SimpleChanges) {
    // Prevent value from being reset if showErrors changes.
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === 'showErrors'
    ) {
      return
    }

    this.checked = this.initialValue && !!this.initialValue.value
    this.required = this.validators.includes(Validators.required)
  }

  toggleCheck() {
    this.checked = !this.checked
    this.valueChanged.emit({ value: this.checked })
  }
}
