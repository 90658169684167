import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as moment from 'moment'
import { combineLatest } from 'rxjs'

import { AbcCreateEditComponent } from '../../../common/base-components/abc-create-edit/abc-create-edit.component'
import { InputType } from '../../../common/enums/input-type.enum'
import { FieldSpecialRule } from '../../../common/interfaces/field-special-rule.interface'
import { Field } from '../../../common/interfaces/field.interface'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../common/services/breadcrumb.service'
import { FlashMessageService } from '../../../common/services/flash-message.service'
import { ResourceService } from '../../../common/services/resource.service'
import { Invoice } from '../../invoice/invoice.interface'
import { creditDefinition } from '../credit.definition'

@Component({
  selector: 'app-credit-create-edit',
  templateUrl:
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.html',
  styleUrls: [
    '../../../common/base-components/abc-create-edit/abc-create-edit.component.scss'
  ]
})
export class CreditCreateEditComponent
  extends AbcCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = creditDefinition

  fields: Field[] = [
    {
      id: 'projectId',
      label: 'Mission',
      placeholder: 'Mission',
      properties: {
        projectId: 'projectId'
      },
      retrievedItemProperties: {
        projectId: 'project.id'
      },
      searchResources: ['projects'],
      maxSelectedItems: 1,
      inputType: InputType.MultiSearch,
      validators: [Validators.required],
      className: 'p-x-0-mobile is-12',
      onChange: async (newValue: { projectId: number }) => {
        // Fill InvoiceIds select with selected Project Invoices
        const invoices: Invoice[] = await this.componentResourceService
          .list(`projects/${newValue.projectId}/invoices`, {
            validationStatus: 'validatedOnly'
          })
          .then((res) => res)
        this.getFieldById('invoiceIds').selectOptions = invoices.map((i) => ({
          label: `${i.number} - ${i.amount}€`,
          value: i.id.toString()
        }))
        // Redirect to selected project.
        this.redirectTo = `/missions/${newValue.projectId}?selectedTab=accounting`
      }
    },
    {
      id: 'invoiceIds',
      label: `Facture associée`,
      placeholder: 'Sélectionnez la facture désirée...',
      properties: { value: 'invoiceId' },
      inputType: InputType.Select,
      className: 'p-x-0-mobile is-3',
      selectOptions: () => {
        const projectIdSpecialRule: FieldSpecialRule =
          this.fieldSpecialRules &&
          this.fieldSpecialRules.length &&
          this.fieldSpecialRules.find((rule) => rule.fieldId === 'projectId')

        return projectIdSpecialRule
          ? this.componentResourceService
              .list('invoices', {
                validationStatus: 'validatedOnly',
                withoutPagination: true,
                projectId: projectIdSpecialRule.forcedValue.projectId
              })
              .then((invoiceRes: Invoice[]) =>
                invoiceRes.map((i) => ({
                  label: `${i.number} - ${i.amount}€`,
                  value: i.id.toString()
                }))
              )
          : Promise.resolve([
              {
                label:
                  '=> Sélectionnez un projet pour afficher la liste des factures disponibles.',
                value: '',
                disabled: true
              }
            ])
      },
      validators: [Validators.required]
    },
    {
      label: 'Libellé',
      properties: {
        value: 'label'
      },
      inputType: InputType.Text,
      validators: [Validators.required],
      className: 'p-x-0-mobile is-3'
    },
    {
      label: `Date de l'avoir`,
      properties: {
        value: 'date'
      },
      initialValue: {
        value: moment().format('YYYY-MM-DD')
      },
      inputType: InputType.Datepicker,
      validators: [Validators.required],
      className: 'p-x-0-mobile is-3'
    },
    {
      label: 'Montant HT',
      properties: {
        value: 'amount'
      },
      validators: [Validators.required, Validators.min(0.01)],
      inputType: InputType.Number,
      min: 0,
      className: 'p-x-0-mobile is-3'
    },
    {
      label: `Commentaires internes`,
      placeholder: `Commentaires internes...`,
      properties: {
        value: 'comments'
      },
      inputType: InputType.Textarea,
      validators: [],
      className: 'is-12 p-x-0-mobile'
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute,
    private componentResourceService: ResourceService,
    private componentActivatedRoute: ActivatedRoute
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    combineLatest([
      this.componentActivatedRoute.data,
      this.componentActivatedRoute.params
    ]).subscribe({
      next: async ([data, params]) => {
        await this.initCreateEditView()

        if (data.projectRestricted) {
          const projectField: Field = this.getFieldById('projectId')
          const projectRestrictedValue = {
            projectId: params.projectId
          }

          this.setFieldValue(projectField, projectRestrictedValue)
          projectField.onChange(projectRestrictedValue)

          projectField.readonly = true
          this.definition.slug = `projects/${params.projectId}/credits`
        }
      }
    })
  }
}
