import { Component, Input, OnInit } from '@angular/core'
import { ResourceService } from '../../../common/services/resource.service'
import { Report } from '../../../common/interfaces/report.interface'
import { reportDefinition } from './report.definition'
import { ResourceDefinition } from '../../../common/interfaces/resource-definition.interface'

@Component({
  selector: 'app-user-reports',
  templateUrl: './user-reports.component.html',
  styleUrls: ['./user-reports.component.scss']
})
export class UserReportsComponent implements OnInit {
  @Input() userId: number

  reports: Report[]
  reportDefinition: ResourceDefinition = reportDefinition

  constructor(private resourceService: ResourceService) {}

  ngOnInit(): void {
    this.resourceService
      .list('reports', { userId: this.userId })
      .then((reports: Report[]) => {
        this.reports = reports
      })
  }
}
